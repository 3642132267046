import React, {useContext, useState} from "react";

import {useTranslation} from "react-multi-lang";
import CTab from "src/cors/tab/cTab";
import {ajaStyle} from "./aja-style";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import CInput from "src/cors/input/cInput";
import CTextarea from "src/cors/textarea/cTextarea";
import Upload from "src/components/upload-image/upload-file";
import {Axios} from "src/api/api";
import {GlobalContext} from "src/context/global-context";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import propTypes from "prop-types";

const AjaModal = ({closeModal, updateView,user_id}) => {
    const t = useTranslation()
    const classes = ajaStyle();
    const {state, setState} = useContext(GlobalContext)
    const [tabLanguage, setTabLanguage] = useState(0)
    const [images, setImages] = useState([])
    const [en, setEn] = useState({
        name: '',
        description: ''
    })
    const [ru, setRu] = useState({
        name: '',
        description: ''
    })
    const [am, setAm] = useState({
        name: '',
        description: ''
    })

    const handleChangeLange = (e, keyName) => {
        let newValues1 = {...en}
        let newValues2 = {...ru}
        let newValues3 = {...am}

        if (tabLanguage === 0) {
            newValues1[keyName] = e
            setEn(newValues1)


        } else if (tabLanguage === 1) {
            newValues2[keyName] = e
            setRu(newValues2)
        } else if (tabLanguage === 2) {
            newValues3[keyName] = e
            setAm(newValues3)
        }
    }


    const onUploadImage = (e) => {
        setImages(e)
    }

    const handleSaveAboutUs = () => {
        setState({...state, loader: true})
        Axios.put(`aja/about_us`, {
            "description_translations": {
                "additionalProp1": en.description,
                "additionalProp2":am.description,
                "additionalProp3": ru.description
            },
            "title_translations": {
                "additionalProp1": en.name,
                "additionalProp2": am.name,
                "additionalProp3": ru.name
            }
        }).then(res=>{

        }).catch(err => {
            toast.error(errorMessages(err?.response?.data?.code))
            console.log(err, 'err')
        }).finally(() => {
            setState({...state, loader: false})
        })
    }

    const handleSaveImage = () => {
        if (images.length) {
            setState({...state, loader: true})
            const formData = new FormData();
            formData.append("files", images[0]?.file);
            Axios.put(`aja/image/${user_id}`, formData).then(res => {
                updateView()
                closeModal()
                toast.success(t("notification.saved"))
                setImages([])

            }).catch(err => {
                toast.error(errorMessages(err?.response?.data?.code))
                console.log(err, 'err')
            }).finally(() => {
                setState({...state, loader: false})
            })
        }
    }

    return (
        <div className={classes.modal}>
            <div className={classes.modalSection}>
                <p className={classes.modalTitles}>about us</p>
                <CTab
                    onPress={setTabLanguage}
                    active={tabLanguage}
                    items={[{value: 'ENG', key: 0}, {value: 'Рус', key: 1}, {value: 'Հայ', key: 2}]}
                />

                <div style={{marginTop: 20}}>
                    {tabLanguage === 0 && <>
                        <CInput
                            value={en.name}
                            onChange={(e) => handleChangeLange(e.target.value, 'name')}
                            placeholder={t("seller_product.name")}
                            label={t("seller_product.name")}
                        />
                        <div className={classes.textAreaWrapp}>
                            <CTextarea
                                value={en.description}
                                onChange={(e) => handleChangeLange(e.target.value, 'description')}
                                placeholder={t("seller_product.description")}
                                label={t("seller_product.description")}
                            />
                        </div>
                    </>}
                    {tabLanguage === 1 && <>
                        <CInput
                            value={ru.name}
                            onChange={(e) => handleChangeLange(e.target.value, 'name')}
                            placeholder={t("seller_product.name")}
                            label={t("seller_product.name")}
                        />
                        <div className={classes.textAreaWrapp}>
                            <CTextarea
                                value={ru.description}
                                onChange={(e) => handleChangeLange(e.target.value, 'description')}
                                placeholder={t("seller_product.description")}
                                label={t("seller_product.description")}
                            />
                        </div>
                    </>}
                    {tabLanguage === 2 && <>
                        <CInput
                            value={am.name}
                            onChange={(e) => handleChangeLange(e.target.value, 'name')}
                            placeholder={t("seller_product.name")}
                            label={t("seller_product.name")}
                        />
                        <div className={classes.textAreaWrapp}>
                            <CTextarea
                                value={am.description}
                                onChange={(e) => handleChangeLange(e.target.value, 'description')}
                                placeholder={t("seller_product.description")}
                                label={t("seller_product.description")}
                            />
                        </div>
                    </>}
                </div>

                <div className={classes.modalBtn}>
                    <CButton backgroundColor={colors.blue} title={t('project.save')} onPress={handleSaveAboutUs}/>
                </div>
            </div>
            <div className={classes.modalSection}>
                <Upload
                    multiple={false}
                    list={images}
                    onChange={(e) => onUploadImage(e)}
                    // hideImage={false}
                    // url={`product/add_pictures/${productItem.id}`}
                />
                <div className={classes.modalBtn}>
                    <CButton backgroundColor={colors.blue} title={t('project.save')} onPress={handleSaveImage}/>
                </div>
            </div>
        </div>

    )
}

AjaModal.propTypes = {
    closeModal: propTypes.func,
    updateView: propTypes.func,
};

export default AjaModal