import Select from 'react-select';
import {cSelectStyles} from "./cSelect-style";
import {colors} from "src/utils/color";
import {ReactComponent as IconError} from "src/assets/icons/error.svg";
import {useTranslation} from "react-multi-lang";

interface CSelectProps {
    onChange?: any
    isSearchable?: boolean
    placeholder?: string
    label?: string
    errorMessage?: string
    options?: any
    isDisabled?: boolean
    showErrorMessage?: boolean
    defaultValue?: any
    isMulti?: boolean
}

export default function CSelect({
                                    onChange,
                                    isSearchable = false,
                                    placeholder,
                                    label,
                                    errorMessage,
                                    options,
                                    isDisabled,
                                    showErrorMessage = true,
                                    defaultValue,
                                    isMulti = false
                                }: CSelectProps) {
    const t = useTranslation()
    const style = {
        control: (base: any) => ({
            ...base,
            '&:hover': {
                border: `1px solid ${colors.blue}`,
            },
            '&:active': {
                border: `1px solid ${colors.blue}`,
            },
            border: `1px solid ${errorMessage ? colors.red : 'transparent'}`,
            boxShadow: 'none',
            minHeight: 40,
            borderRadius: 0,
            backgroundColor: colors.grey,
        }),
        option: (provided:any, state:any) => ({
            ...provided,
            color: state.isSelected ? colors.blue : colors.black,
            padding: 10,
            '&:hover': {
                backgroundColor: colors.blue,
                color: colors.white
            },
        }),
        menuList: (provided:any, state:any) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        singleValue: (provided:any, state:any) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return {...provided, opacity, transition};
        },

    };

    const classes = cSelectStyles();

    return (
        <div className='custom-select'>
            {label && <p className={`${classes.label} ${errorMessage && classes.labelError} `}>{label}</p>}
            <Select
                isMulti={isMulti}
                defaultValue={defaultValue}
                styles={style}
                isDisabled={isDisabled}
                onChange={onChange}
                options={options}
                isSearchable={isSearchable}
                name={placeholder}
                placeholder={placeholder ? placeholder : t('project.select_by_list')}
                noOptionsMessage={() => t('project.no_option_yet')}
                className={classes.container}
                components={{
                    IndicatorSeparator: () => null
                }}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    backgroundColor: colors.grey,
                    colors: {
                        ...theme.colors,
                        primary25: colors.grey,
                        primary: colors.grey,
                    },
                })}
            />
            {
                showErrorMessage && <div className={classes.errorBlock}>
                    {errorMessage && <IconError width={17} height={17}/>}
                    <p className={classes.errorText}>{errorMessage}</p>
                </div>
            }
        </div>
    );
}