import React, {useContext, useEffect, useState} from "react";
import {languageBarStyle} from "./languageBar-style";
import iconEng from "src/assets/icons/languageIcons/eng.svg";
import iconRus from "src/assets/icons/languageIcons/rus.svg";
import iconHye from "src/assets/icons/languageIcons/hye.svg";
import {ReactComponent as IconChecked} from "src/assets/icons/iconCheck.svg";
import {storage} from "src/utils/storage";
import {setLanguage, useTranslation} from "react-multi-lang";
import {GlobalContext} from "src/context/global-context";

const LanguageBar = () => {
    const {state, setState} = useContext(GlobalContext)
    const {languagePopUp} = state
    const t = useTranslation()
    const [languageList, setCurrentLanguage] = useState([
        {
            language: t('language.en'),
            icon: iconEng,
            isActive: false,
            id: 0
        },
        {
            language: t('language.ru'),
            icon: iconRus,
            isActive: false,
            id: 1
        },
        {
            language: t('language.am'),
            icon: iconHye,
            isActive: true,
            id: 2
        }
    ]);

    const classes = languageBarStyle()
    const local = storage('get', 'gold')

    const handleChangeLanguage = (lang, i) => {
        if (lang.isActive) {
            setState({languagePopUp: false})
            return
        }
        let arr = [...languageList]
        arr.forEach((item, index) => {
            arr[index].isActive = i === index;
        })
        setCurrentLanguage(arr)
        setState({languagePopUp: false, loader: true})
        setLanguage(lang.language)
        storage('set', {language: lang.id})
        window.location.reload();
    }
    useEffect(() => {
        if (local.language || local.language === 0) {
            let arr = [...languageList]
            arr.forEach((item, index) => {
                arr[index].isActive = item.id === local.language;
            })
            setCurrentLanguage(arr)
        }
    }, [])


    return (
        <div className={classes.languageBar}>
            {
                languageList.map((item, index) => (
                    item.isActive &&
                    <div key={item.id} onClick={() => setState({languagePopUp: !languagePopUp})}
                         className={classes.languageSelected}>
                        <img src={item.icon} alt=""/>
                        <p>{item.language}</p>
                    </div>
                ))
            }
            {
                languagePopUp ? <ul className={classes.languageList} onClick={(event => event.stopPropagation())}>
                    {
                        languageList.map((item, index) => {
                            return (
                                <li onClick={() => {
                                    handleChangeLanguage(item, index)
                                }} key={item.id}>
                                    <div className={classes.languageListItem}>
                                        <div>
                                            <img src={item.icon} alt="languageIcon"/>
                                            <p>{item.language}</p>
                                        </div>
                                        {item.isActive ? <IconChecked/> : <></>}
                                    </div>
                                </li>)
                        })}</ul> : null}
        </div>
    )
}
export default LanguageBar