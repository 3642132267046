import React, {useState} from 'react'
import {colors} from "src/utils/color";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import StarRatings from "react-star-ratings";

interface CRatingProps {
    rate: any
    id: any
}

export default function CRating({rate, id}: CRatingProps) {
    const [rating, setRating] = useState(rate);

    const handleRating = (rate: any) => {
        Axios.put(`admin/put_stars`, {"stars": rate, "user_id": id}).then(res => {
            setRating(+rate)
            toast('checked')
        }).catch(err => {
            console.log(err, 'err');
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    return (
        <>
            <StarRatings
                rating={rating}
                starRatedColor={colors.gold}
                changeRating={handleRating}
                numberOfStars={5}
                starEmptyColor={colors.mainGrey}
                starHoverColor={colors.mainGreyDark}
                name='rating'
                starDimension='17'
                starSpacing='3'
            />
        </>
    )
}