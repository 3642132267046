import UnderHeader from "src/components/underHeader/underHeader";
import CInput from "src/cors/input/cInput";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import {settingsStyle} from "./settings-style";
import {useEffect, useState} from "react";
import {Axios} from "src/api/api";
import {checkError} from "src/helpers/check-error";
import {toast} from "react-toastify";
import CSelect from "src/cors/select/cSelect";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";

function Settings() {
    const t = useTranslation()
    const classes = settingsStyle()
    const [update, setUpdate] = useState(false)
    const [initialData, setInitialData] = useState({})
    const [userInfo, setUserInfo] = useState({
        firstname: '',
        lastname: '',
        company_name: '',
        phone: "",
        email: '',
        country: 'armenia',
        region: '',
    })
    const [userPassword, setUserPassword] = useState({
        password: '',
        newPassword: '',
        confirmNewPassword: '',
    })
    const [userPin, setUserPin] = useState({
        old_pin: '',
        new_pin: '',
        confirm_new_pin: '',
    })
    const [errorUserInfo, setErrorUserInfo] = useState({
        firstname: '',
        lastname: '',
        company_name: '',
        phone: "",
        email: '',
        region: '',
    })

    const [errorUserPassword, setErrorUserPassword] = useState({
        password: '',
        newPassword: '',
        confirmNewPassword: ''
    })
    const [errorPin, setErrorPin] = useState({
        old_pin: '',
        new_pin: '',
        confirm_new_pin: '',
    })
    const request = () => {
        Axios(`user/user_info`).then(res => {
            setUpdate(true)
            setInitialData(res.data)
            setUserInfo({
                firstname: res.data.firstname,
                lastname: res.data.lastname,
                company_name: res.data.company_name,
                phone: res.data.phone,
                email: res.data.email,
                country: 'armenia',
                region: res.data.region,
            })
        }).catch(err => {
            setUpdate(true)
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err')
        })
    }
    const saveUserInfo = () => {
        if (userInfo.firstname && userInfo.lastname && userInfo.company_name && userInfo.phone && userInfo.email && userInfo.country && userInfo.region) {
            Axios.put(`user/update_user_info`,
                {
                    company_name: userInfo.company_name,
                    email: userInfo.email,
                    firstname: userInfo.firstname,
                    lastname: userInfo.lastname,
                    phone: userInfo.phone,
                    region: userInfo.region,
                    country: 'armenia',
                }
            ).then(res => {
                toast(t("notification.saved"))
            }).catch(err => {
                console.log(err, 'err');
                toast(errorMessages(err?.response?.data?.code))
            })
        } else {
            checkError(userInfo, setErrorUserInfo)
        }
    }

    const saveUserInfoPassword = () => {
        if (userPassword.password && userPassword.newPassword && userPassword.confirmNewPassword) {
            if (userPassword.newPassword.length >= 8) {
                if (userPassword.newPassword === userPassword.confirmNewPassword) {
                    Axios.put(`auth/update_password`, {
                        "new_password": userPassword.newPassword,
                        "new_password_confirm": userPassword.confirmNewPassword,
                        "old_password": userPassword.password
                    }).then(res => {
                        toast(t("notification.saved"))
                    }).catch(err => {
                        console.log(err, 'errr');
                        toast(errorMessages(err?.response?.data?.code))
                    })
                } else {
                    toast(t("notification.confirm_and_new_password"))
                }
            } else {
                toast(t("notification.length_must_be_more"))
            }
        } else {
            checkError(userPassword, setErrorUserPassword)
        }
    }

    const savePin = () => {
        if ( userPin.new_pin && userPin.confirm_new_pin) {
            if (userPin.new_pin.length === 6) {
                if (userPin.new_pin === userPin.confirm_new_pin) {
                    Axios.put(`admin/set_pin`, {
                        "new_pin": userPin.new_pin,
                        "old_pin": userPin.old_pin
                    }).then(res => {
                        toast(t("notification.saved"))
                    }).catch(err => {
                        console.log(err, 'errr');
                        toast(errorMessages(err?.response?.data?.code))
                    })
                } else {
                    toast(t("notification.confirm_and_new_pin"))
                }
            } else {
                toast(t("notification.length_for_pin"))
            }
        } else {
            checkError(userPin, setErrorPin)
        }
    }
    const handleChangeInfo = (value, input) => {
        if (value.length || typeof value === 'number') {
            setErrorUserInfo({...errorUserInfo, [input]: ''})
        }
        let info = {...userInfo}
        info[input] = value
        setUserInfo(info)
    }

    const handleChangeUserPassword = (value, input) => {
        if (value.length || typeof value === 'number') {
            setErrorUserPassword({...errorUserPassword, [input]: ''})
        }
        let info = {...userPassword}
        info[input] = value
        setUserPassword(info)
    }

    const handleChangePin = (value, input) => {
        if (value.length || typeof value === 'number') {
            setErrorPin({...errorPin, [input]: ''})
        }
        let info = {...userPin}
        info[input] = value
        setUserPin(info)
    }

    const saveUserInfoValidation = () => {
        let request = false
        Object.keys(userInfo).forEach(item => {
            if (userInfo[item] !== initialData[item]) {
                request = true
            }
        })
        if (request) {
            saveUserInfo()
        }
    }


    useEffect(() => {
        request()
    }, [])

    return (
        <div className={classes.container}>
            <UnderHeader title={t("header.settings")}/>
            <div className={classes.containerSettings}>
                <p className={classes.title}>{t("setting.my_account")}</p>
                {update && <div className={classes.inputBlock}>
                    <div className={classes.inputWrapp}>
                        <CInput
                            value={userInfo.firstname}
                            onChange={(e) => handleChangeInfo(e.target.value, 'firstname')}
                            label={t("setting.name")}
                            placeholder={t("setting.name")}
                            errorMessage={errorUserInfo.firstname}
                        />
                    </div>
                    <div className={classes.inputWrapp}>
                        <CInput
                            value={userInfo.lastname}
                            onChange={(e) => handleChangeInfo(e.target.value, 'lastname')}
                            errorMessage={errorUserInfo.lastname}
                            label={t("setting.surname")}
                            placeholder={t("setting.surname")}
                        />
                    </div>
                    <div className={classes.inputWrapp}>
                        <CInput
                            value={userInfo.company_name}
                            errorMessage={errorUserInfo.company_name}
                            onChange={(e) => handleChangeInfo(e.target.value, 'company_name')}
                            label={t("setting.company_name")}
                            placeholder={t("setting.company_name")}
                        />
                    </div>
                    <div className={classes.inputWrapp}>
                        <CInput
                            value={userInfo.phone}
                            onChange={(e) => handleChangeInfo(e.target.value, 'phone')}
                            errorMessage={errorUserInfo.phone}
                            label={t("setting.phone")}
                            type={'number'}
                            placeholder={t("setting.phone")}
                        />
                    </div>
                    <div className={classes.inputWrapp}>
                        <CInput
                            value={userInfo.email}
                            onChange={(e) => handleChangeInfo(e.target.value, 'email')}
                            errorMessage={errorUserInfo.email}
                            label={t("setting.email")}
                            placeholder={t("setting.email")}
                            type={'email'}
                        />
                    </div>
                    <div className={classes.inputWrapp}>

                        <CSelect
                            defaultValue={{label: t("setting.armenia"), value: 'armenia'}}
                            onChange={(e) => handleChangeInfo(e.value, 'country')}
                            label={t("setting.country")}
                            placeholder={t("setting.country")}
                            options={[{label: t("setting.armenia"), value: 'armenia'}]}
                            isDisabled={true}
                        />
                    </div>
                    <div className={classes.inputWrapp}>
                        <CSelect
                            defaultValue={{
                                label: userInfo.region ? t(`region.${userInfo.region}`) : t("setting.region"),
                                value: userInfo.region
                            }}
                            onChange={(e) => handleChangeInfo(e.value, 'region')}
                            errorMessage={errorUserInfo.region}
                            label={t("setting.region")}
                            placeholder={t("setting.region")}

                            options={[
                                {label: t("region.yerevan"), value: 'yerevan'},
                                {label: t("region.aragacotn"), value: 'aragacotn'},
                                {label: t("region.ararat"), value: 'ararat'},
                                {label: t("region.armavir"), value: 'armavir'},
                                {label: t("region.gegharkunik"), value: 'gegharkunik'},
                                {label: t("region.kotayk"), value: 'kotayk'},
                                {label: t("region.lori"), value: 'lori'},
                                {label: t("region.shirak"), value: 'shirak'},
                                {label: t("region.syunik"), value: 'syunik'},
                                {label: t("region.tavush"), value: 'tavush'},
                                {label: t("region.vayots_dzor"), value: 'vayots_dzor'},
                            ]}
                        />
                    </div>
                </div>}
                <div className={classes.containerPrivacyBtns}>
                    <div className={classes.containerPrivacySaveWrap}>
                        <CButton
                            onPress={saveUserInfoValidation}
                            title={t("project.save")}
                            backgroundColor={colors.blue}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.containerSettings}>
                <p className={classes.title}>{t("setting.password")}</p>
                <div className={`${classes.inputWrapp} ${classes.inputPassword}`}>
                    <CInput
                        value={userPassword.password}
                        onChange={(e) => handleChangeUserPassword(e.target.value, 'password')}
                        errorMessage={errorUserPassword.password}
                        label={t("setting.old_password")}
                        placeholder={t("setting.old_password")}
                        type={'password'}
                    />
                </div>
                <div className={`${classes.inputBlock}`}>
                    <div className={`${classes.inputWrapp} ${classes.inputPassword}`}>
                        <CInput
                            value={userPassword.newPassword}
                            onChange={(e) => handleChangeUserPassword(e.target.value, 'newPassword')}
                            errorMessage={errorUserPassword.newPassword}
                            label={t("setting.new_password")}
                            placeholder={t("setting.new_password")}
                            type={'password'}
                        />
                    </div>
                    <div className={`${classes.inputWrapp} ${classes.inputPassword}`}>
                        <CInput
                            value={userPassword.confirmNewPassword}
                            onChange={(e) => handleChangeUserPassword(e.target.value, 'confirmNewPassword')}
                            errorMessage={errorUserPassword.confirmNewPassword}
                            label={t("setting.confirm_new_password")}
                            placeholder={t("setting.confirm_new_password")}
                            type={'password'}
                        />
                    </div>
                </div>
                <div className={classes.containerPrivacyBtns}>
                    <div className={classes.containerPrivacySaveWrap}>
                        <CButton
                            onPress={saveUserInfoPassword}
                            title={t("project.save")}
                            backgroundColor={colors.blue}
                        />
                    </div>
                </div>
            </div>

            <div className={classes.containerSettings}>
                <p className={classes.title}>{t("setting.pin")}</p>
                <div className={`${classes.inputWrapp} ${classes.inputPassword}`}>
                    <CInput
                        value={userPin.old_pin}
                        onChange={(e) => handleChangePin(e.target.value, 'old_pin')}
                        errorMessage={errorPin.old_pin}
                        label={t("setting.old_pin")}
                        placeholder={t("setting.old_pin")}
                        type={'password'}
                    />
                </div>
                <div className={`${classes.inputBlock}`}>
                    <div className={`${classes.inputWrapp} ${classes.inputPassword}`}>
                        <CInput
                            value={userPin.new_pin}
                            onChange={(e) => handleChangePin(e.target.value, 'new_pin')}
                            errorMessage={errorPin.new_pin}
                            label={t("setting.new_pin")}
                            placeholder={t("setting.new_pin")}
                            type={'password'}
                        />
                    </div>
                    <div className={`${classes.inputWrapp} ${classes.inputPassword}`}>
                        <CInput
                            value={userPin.confirm_new_pin}
                            onChange={(e) => handleChangePin(e.target.value, 'confirm_new_pin')}
                            errorMessage={errorPin.confirm_new_pin}
                            label={t("setting.confirm_new_pin")}
                            placeholder={t("setting.confirm_new_pin")}
                            type={'password'}
                        />
                    </div>
                </div>
                <div className={classes.containerPrivacyBtns}>
                    <div className={classes.containerPrivacySaveWrap}>
                        <CButton
                            onPress={savePin}
                            title={t("project.save")}
                            backgroundColor={colors.blue}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Settings;



