import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const servicesStyle = makeStyles(() =>
    createStyles({
        serviceContainer: {
            maxWidth: 1440,
            width: '100%',
            margin: '25px auto 0',
        },
        checkBoxWrap: {
            display: "flex",
            alignItems: 'center',
            marginLeft: 20,
            marginBottom: 8,
            '& p': {
                color: colors.black,
                fontSize: 16,
                marginLeft: 9,
                cursor: 'pointer',
                "@media (max-width:480px)": {
                    fontSize: 14,
                },
            },
            "@media (max-width:480px)": {
                marginLeft: 20,
                marginTop: 11
            },
        },
    })
)