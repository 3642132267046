import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const cTabStyles = makeStyles(() =>
    createStyles({
        container: {
            display: "flex",
            borderBottom: `2px solid ${colors.greyLiner}`,
        },
        tab: {
            paddingBottom: 24,
            borderBottom: `2px solid ${colors.white}`,
            cursor: 'pointer'
        },
        tabActive: {
            borderBottom: `2px solid ${colors.blue}`,
            marginBottom: -2,
        },
        tabText: {
            padding: ' 0 24px ',
            color: colors.black,
            fontSize: 14,
            textTransform: 'upperCase',
            letterSpacing: 1,
        },
        tabTextActive: {
            color: colors.blue,
        }
    })
);
