import {aboutUsStyle} from "./about-us-style";
import UnderHeader from "src/components/underHeader/underHeader";
import Upload from "src/components/upload-image/upload-file";
import CInput from "src/cors/input/cInput";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import CTextarea from "src/cors/textarea/cTextarea";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import React, {useContext, useEffect, useState} from "react";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import {GlobalContext} from "src/context/global-context";
import CTab from "src/cors/tab/cTab";


function AboutUsAja() {
    const classes = aboutUsStyle()
    const {state, setState} = useContext(GlobalContext)
    const t = useTranslation()
    const [tabLanguage, setTabLanguage] = useState(0)
    const [image, setImage] = useState([])
    const [newImage, setNewImage] = useState([])
    const [description_translations, setDescriptions] = useState({
        am: '',
        en: '',
        ru: ""
    })
    const [title_translations, setTitles] = useState({
        am: '',
        en: '',
        ru: ""
    })

    const requestGet = () => {
        Axios(`aja/admin/about_us`).then(res => {
            setDescriptions(res.data.description_translations)
            // setTitles(res.data.title_translations)
            setImage(res.data.pictures)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'errr');
        })
    }

    useEffect(() => {
        requestGet()
    }, [])


    const saveAboutUs = () => {
        if (description_translations.am && description_translations.en && description_translations.ru) {
            Axios.put(`aja/about_us`, {
                description_translations,
                // title_translations
            }).then(res => {
                toast(t("notification.saved"))
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
            })
        } else {

        }
    }

    const handleChangeDescription = (value, input) => {
        let info = {...description_translations}
        info[input] = value
        setDescriptions(info)
    }
    const handleChangeTitle = (value, input) => {
        let info = {...title_translations}
        info[input] = value
        setTitles(info)
    }
    const handleSaveImage = () => {
        if (newImage.length){
            const formData = new FormData();
            newImage.forEach((item, index) => {
                formData.append("files", newImage[index]?.file);
            })
            Axios.put(`aja/about_us/pictures`,{formData}).then(res=>{
                requestGet()
                toast.success(t("notification.saved"))
            }).catch(err => {
                toast.error(errorMessages(err?.response?.data?.code))
                console.log(err, 'err')
            }).finally(() => {
                setState({...state, loader: false})
            })
        }
    }

    useEffect(()=>{
        handleSaveImage()
    },[newImage])

    return (
        <div className={classes.container}>
            <UnderHeader title={t("header.aja")}/>
            <div className={classes.containerAboutUs}>
                <p className={classes.title}>{t("about_us.add_images")}</p>
                <Upload
                    list={newImage}
                    onChange={setNewImage}
                    hideImage={true}
                    url={'aja/about_us/pictures'}
                    // productEdit={true}
                />
                <div>
                    {/*<ImageListAbout images={image}  />*/}
                    {image.length >0 && <img src={image[image.length - 1]} className={classes.uploadImg} alt="img"/>}
                </div>
                <div className={classes.aboutUsBtns}>
                    {/*<div className={classes.aboutUsBtnSaveWrap}>*/}
                    {/*    <CButton onPress={handleSaveImage} title={t('project.save')} backgroundColor={colors.blue}/>*/}
                    {/*</div>*/}
                </div>
                <CTab
                    onPress={setTabLanguage}
                    active={tabLanguage}
                    items={[{value: 'Հայ', key: 2},{value: 'ENG', key: 0}, {value: 'Рус', key: 1}, ]}
                />
                {tabLanguage === 0 && <>
                    {/*<div className={classes.inputWrapp}>*/}
                    {/*    <CInput*/}
                    {/*        label={t("about_us.title")}*/}
                    {/*        placeholder={t("about_us.title")}*/}
                    {/*        value={title_translations.am}*/}
                    {/*        onChange={(e) => handleChangeTitle(e.target.value, 'am')}*/}
                    {/*    />*/}
                    {/*</div>*/}
                        <div className={classes.textAreaWrapp}>
                            <CTextarea
                                value={description_translations.am}
                                onChange={(e) => handleChangeDescription(e.target.value, 'am')}
                                label={t("about_us.description")}
                                placeholder={t("about_us.description")}
                            />
                        </div>

                </>}
                {tabLanguage === 1 && <>
                    {/*<div className={classes.inputWrapp}>*/}
                    {/*    <CInput*/}
                    {/*        label={t("about_us.title")}*/}
                    {/*        placeholder={t("about_us.title")}*/}
                    {/*        value={title_translations.en}*/}
                    {/*        onChange={(e) => handleChangeTitle(e.target.value, 'en')}*/}
                    {/*    />*/}
                    {/*</div>*/}

                        <div className={classes.textAreaWrapp}>
                            <CTextarea
                                value={description_translations.en}
                                onChange={(e) => handleChangeDescription(e.target.value, 'en')}
                                label={t("about_us.description")}
                                placeholder={t("about_us.description")}
                            />
                        </div>

                </>}
                {tabLanguage === 2 && <>
                    {/*<div className={classes.inputWrapp}>*/}
                    {/*    <CInput*/}
                    {/*        label={t("about_us.title")}*/}
                    {/*        placeholder={t("about_us.title")}*/}
                    {/*        value={title_translations.ru}*/}
                    {/*        onChange={(e) => handleChangeTitle(e.target.value, 'ru')}*/}
                    {/*    />*/}
                    {/*</div>*/}

                        <div className={classes.textAreaWrapp}>
                            <CTextarea
                                value={description_translations.ru}
                                onChange={(e) => handleChangeDescription(e.target.value, 'ru')}
                                label={t("about_us.description")}
                                placeholder={t("about_us.description")}
                            />
                    </div>
                </>}
                <div className={classes.aboutUsBtns}>
                    <div className={classes.aboutUsBtnSaveWrap}>
                        <CButton onPress={saveAboutUs} title={t("project.save")} backgroundColor={colors.blue}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUsAja;