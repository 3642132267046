import React, {useState} from "react";
import propTypes from "prop-types";
import SmallBtn from "src/cors/smallBtn/smallBtn";
import removeIcon from 'src/assets/icons/iconRemove.svg';
import editIcon from 'src/assets/icons/iconEdit.svg';
import {colors} from "src/utils/color";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";
import {useTranslation} from "react-multi-lang";
import {Axios} from "src/api/api";
import {CModal} from "src/cors/modal/cModal";
import CInput from "src/cors/input/cInput";
import CButton from "src/cors/button/cButton";

const SellerCustomPriceTable = ({dataBody, deleteCategory, type, updateView, setDeleteModalState,deleteModalState}) => {
    const navigation = useNavigate();
    const t = useTranslation()
    const [modalState, setModalState] = useState(false)
    const [newName, setNewName] = useState('')
    const [id, setId] = useState(null)


    const handleChangeName = () => {
        Axios.put(`category/name/${id}`, {
            "name": newName
        }).finally(res => {
            updateView()
            setModalState(false)
        })
    }

    const handleOnItem = (item) => {
        if (!item.is_default) {
            setModalState(true)
            setId(item.id)
        }

    }

    return (
        <>
            {
                (dataBody && dataBody.length) ?
                    <>
                        <div className='tableWrapper'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>{t('table.name')}</th>
                                    <th>{t('table.rate_price')}</th>
                                    <th>{`$/֏ ${t('table.price')}`}</th>
                                    <th>{t("table.summary_items")}</th>
                                    <th>{t("table.last_update")}</th>
                                    <th>{t("table.metal_price_coefficient")}</th>
                                    <th style={{textAlign: 'center'}}>{t("table.action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dataBody.map((tdItem, index) => {
                                        return (
                                            <tr key={tdItem.id + tdItem.name}>
                                                <td onClick={() => handleOnItem(tdItem)}
                                                    style={{display: 'flex', cursor: 'pointer'}}>
                                                    <p>{tdItem?.is_default ? t(`type.${tdItem?.key}`) : tdItem.name}</p>
                                                    {!tdItem.is_default && <img
                                                        style={{marginLeft: 7}}
                                                        src={editIcon}
                                                        alt="remove icon"
                                                    />}
                                                </td>
                                                <td> {tdItem.metal_usd_rate && `${tdItem.metal_usd_rate} ${type === 'silver' ? ' ֏' : '$'}`}</td>
                                                <td>{`${tdItem.usd_amd_rate} ֏`}</td>
                                                <td>{tdItem.summary_items}</td>
                                                <td>{moment(tdItem.last_update).format('YYYY MM DD')}</td>
                                                <td>{`${tdItem?.metal_price_coefficient} ${tdItem.metal_price_update_type === 1 ? '$' : '%'}`}</td>
                                                <td>
                                                    <div className='tableButtonWrapper flexRow'>
                                                        {!tdItem.is_default && <SmallBtn
                                                            onPress={() => {
                                                                setDeleteModalState(true)
                                                                setId(tdItem.id)
                                                            }}
                                                            icon={removeIcon}
                                                            borderColor={colors.softRed}
                                                        />}
                                                        <SmallBtn
                                                            onPress={() => navigation('/customPrice/customPriceEdit', {
                                                                state: {
                                                                    data: {
                                                                        ...tdItem,
                                                                        type,
                                                                        edit: true
                                                                    }
                                                                }
                                                            })}
                                                            icon={editIcon}
                                                            borderColor={colors.black}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            dataBody.map((item, index) => {
                                return (
                                    <div key={item.id + index.toString()} className='cardContainer'>
                                        <ul className='cardContent'>
                                            <li className='cardItem'>
                                                <div onClick={() => handleOnItem(item)}
                                                     style={{display: 'flex', cursor: 'pointer'}}>
                                                    <p className='cardKey'>{item?.is_default ? t(`type.${item?.key}`) : item.name}</p>
                                                    {!item.is_default && <img
                                                        style={{marginLeft: 7}}
                                                        src={editIcon}
                                                        alt="remove icon"
                                                    />}

                                                </div>
                                                <p className='cardValue'>{item.name}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t('table.rate_price')}</p>
                                                <p className='cardValue'>{item.metal_usd_rate}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{`$/֏ ${t('table.price')}`}</p>
                                                <p className='cardValue'>{item.usd_amd_rate}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.summary_items")}</p>
                                                <p className='cardValue'>{item.summary_items}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.last_update")}</p>
                                                <p className='cardValue'>{moment(item.last_update).format('YYYY MM DD')}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.metal_price_coefficient")}</p>
                                                <p className='cardValue'>{`${item?.metal_price_coefficient} ${item.metal_price_update_type === 1 ? '$' : '%'}`}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <div className='cardButtonWrapper flexEnd'>
                                                    {!item.is_default && <SmallBtn
                                                        onPress={() => {
                                                            setDeleteModalState(true)
                                                            setId(item.id)
                                                        }}
                                                        icon={removeIcon}
                                                        borderColor={colors.softRed}
                                                    />}
                                                    <SmallBtn
                                                        onPress={() => navigation('/customPrice/customPriceEdit', {
                                                            state: {
                                                                data: {
                                                                    ...item,
                                                                    type,
                                                                    edit: true
                                                                }
                                                            }
                                                        })}
                                                        icon={editIcon}
                                                        borderColor={colors.black}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                        <CModal
                            visible={modalState}
                            closeModal={() => {
                                setModalState(false)
                                setNewName('')
                            }
                            }
                            title={t('categories.change_name')}
                            children={<div style={{marginTop: 30}}>
                                <CInput
                                    value={newName}
                                    onChange={(e) => setNewName(e.target.value)}
                                    label={t('categories.name')}
                                    placeholder={t('categories.change_name')}
                                />
                                <CButton
                                    backgroundColor={colors.blue}
                                    title={t('project.change')}
                                    onPress={handleChangeName}
                                    disable={!newName}
                                />
                            </div>}
                        />
                        {deleteModalState &&
                            <CModal
                            visible={deleteModalState}
                            closeModal={() => {setDeleteModalState(false)}
                            }
                            title={t('project.delete')}
                            children={<div style={{marginTop: 30, display:'flex'}}>
                                <CButton
                                    borderWidth={1}
                                    title={t('project.cancel')}
                                    onPress={() => setDeleteModalState(false)}
                                />
                                <CButton
                                    backgroundColor={colors.blue}
                                    title={t('project.delete')}
                                    onPress={()=>{deleteCategory(id)}}
                                />
                            </div>}
                        />}
                    </> : null
            }

        </>

    )
}

SellerCustomPriceTable.propTypes = {
    dataBody: propTypes.array,
    deleteCategory: propTypes.func,
    type: propTypes.any,
};

export default SellerCustomPriceTable