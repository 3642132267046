import React from "react";
import {sellerProductStyle} from "./seller-product-style";
import CSelect from "src/cors/select/cSelect";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import {CModal} from "src/cors/modal/cModal";
import {useTranslation} from "react-multi-lang";
import CInput from "src/cors/input/cInput";
import Upload from "src/components/upload-image/upload-file";
import ImageList from "../seller-add-product/image-list";
import UploadVideo from "src/components/uploadVideo/upload-video";
import ImageListEdit from "../seller-add-product/image-list-edit";


function SellerProductAddSetModal({
                                      selectValue,
                                      closeModal,
                                      man_rings,
                                      woman_rings,
                                      onWeddingValues,
                                      addWeddingSet,
                                      products,
                                      onSetValues,
                                      deleteImg,
                                      addSet,
                                      setImages,
                                      images,
                                      setVideo,
                                      video,
                                      productItem,
                                      handleOpenCropperModal
                                  }) {
    const classes = sellerProductStyle();
    const t = useTranslation()
    const edit = Object.keys(productItem).length === 0

    return (
        <div className={classes.sellerProductContainer}>
            <CModal
                title={t("seller_product.create_wedding_set")}
                visible={selectValue === 'wedding'}
                closeModal={closeModal}
                children={<div>
                    <div style={{marginTop: 25}}/>
                    {man_rings?.length > 0 && woman_rings?.length > 0 ? <>
                        <CSelect
                            onChange={(e) => onWeddingValues(e.value, 'man')}
                            placeholder={t("seller_product.select_product_code")}
                            label={t("seller_product.man_ring")}
                            options={man_rings}
                            isSearchable={true}
                        />
                        <CSelect
                            onChange={(e) => onWeddingValues(e.value, 'woman')}
                            placeholder={t("seller_product.select_product_code")}
                            label={t("seller_product.woman_ring")}
                            options={woman_rings}
                            isSearchable={true}
                        />
                    </> : <p>{t("project.no_option_yet")}</p>}
                    <div className={classes.deleteModalBtns}>
                        <div style={{width: '48%'}}>
                            <CButton
                                borderWidth={1}
                                title={t("project.cancel")}
                                onPress={closeModal}
                            />
                        </div>
                        <div style={{width: '48%'}}>
                            <CButton
                                backgroundColor={colors.blue}
                                title={t("project.save")}
                                onPress={addWeddingSet}
                            />
                        </div>
                    </div>
                </div>}
            />
            <CModal
                title={edit ? t("seller_product.create_set") : t("seller_product.edit_set")}
                visible={selectValue === 'set'}
                closeModal={closeModal}
                children={
                    <div>
                        <div style={{marginTop: 25}}/>
                        {edit ? <>
                            <CSelect
                                options={products}
                                onChange={(e) => onSetValues(e.value, 'ring')}
                                placeholder={t("seller_product.select_product_code")}
                                label={`${t("seller_product.product")} 1`}
                                isSearchable={true}
                            />
                            <CSelect
                                onChange={(e) => onSetValues(e.value, 'bracelet')}
                                placeholder={t("seller_product.select_product_code")}
                                label={`${t("seller_product.product")} 2`}
                                options={products}
                                isSearchable={true}
                            />
                            <CSelect
                                onChange={(e) => onSetValues(e.value, 'necklace')}
                                placeholder={t("seller_product.select_product_code")}
                                label={`${t("seller_product.product")} 3`}
                                options={products}
                                isSearchable={true}
                            />
                            <CSelect
                                onChange={(e) => onSetValues(e.value, 'earrings')}
                                placeholder={t("seller_product.select_product_code")}
                                label={`${t("seller_product.product")} 4`}
                                options={products}
                                isSearchable={true}
                            />
                            <CInput
                                onChange={(e) => onSetValues(e.target.value, 'code')}
                                placeholder={t("seller_product.add_product_code")}
                                label={t("seller_product.product_code")}
                            />
                        </> : <>
                            {productItem?.set_products.map((item, index) => {
                                return (
                                    <>
                                        <p style={{
                                            marginBottom: 10,
                                            fontSize: 14,
                                            padding: '10px'
                                        }}>{`${t("seller_product.product")} ${index + 1}`}</p>
                                        <div style={{display: 'flex', alignItems: "center"}}>
                                            {item.picture && <img className={classes.productImg} src={item.picture} alt='img'/>}
                                            <p className={classes.productCode}>{item.code}</p>
                                        </div>
                                    </>
                                )
                            })}
                            <p style={{
                                borderTop: '1px solid #ccc',
                                paddingTop: 10,
                                marginTop: 20
                            }}> {t("seller_product.product_code")}</p>
                            <p className={classes.product_code}> {productItem.product_code}</p>
                        </>}
                        <p className={classes.addStonesAttributesTitleBlockTitle}>{t("seller_product.add_image")}</p>
                        {!edit ?
                            <ImageListEdit
                                url={`product/update_pictures/${productItem.id}`}
                                images={images}
                                setImages={setImages}
                                deleteImg={deleteImg}
                            /> :
                            <ImageList
                                images={images}
                                setImages={setImages}
                                deleteImg={deleteImg}
                            />}
                        <Upload
                            multiple={false}
                            list={images}
                            onChange={(imgUrl) => handleOpenCropperModal(imgUrl)}
                            hideImage={false}
                            url={`product/add_pictures/${productItem.id}`}
                        />
                        <p className={classes.addStonesAttributesTitleBlockTitle}>{t("seller_product.add_video_link")}</p>
                        <UploadVideo
                            onChange={setImages}
                            edit={!edit}
                            setVideo={setVideo}
                            video={video} deleteImg={deleteImg}
                            url={`product/add_pictures/${productItem.id}`}
                        />
                        <div className={classes.deleteModalBtns}>
                            <div style={{width: '48%'}}>
                                <CButton
                                    borderWidth={1}
                                    title={t("project.cancel")}
                                    onPress={closeModal}
                                />
                            </div>
                            <div style={{width: '48%'}}>
                                <CButton
                                    backgroundColor={colors.blue}
                                    title={t("project.save")}
                                    onPress={!edit ? closeModal : addSet}
                                />
                            </div>
                        </div>
                    </div>
                }
            />
        </div>
    );
}

export default SellerProductAddSetModal;
