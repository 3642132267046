import {ReactComponent as UploadIcon} from "src/assets/icons/upload.svg";
import ImageUploading from "react-images-uploading";
import {uploadStyles} from "./upload-style";
import PropTypes from "prop-types";
import {ReactComponent as IconError} from "src/assets/icons/error.svg";
import React from "react";
import {colors} from "src/utils/color";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";

export default function Upload({
                                   title,
                                   info,
                                   hideImage = true,
                                   list = [],
                                   onChange = () => undefined,
                                   multiple,
                                   errorMessage = '',
                                   setError = () => undefined,
                                   productEdit = false,
                                   url
                               }) {

    const classes = uploadStyles()
    const t = useTranslation()
    const onChangeUpload = (imageList) => {
        if (productEdit) {

            let arr = []
            imageList.forEach(item => {
                if (item.file) {
                    arr.push(item)
                }
            })
            const formData = new FormData();
            arr.forEach((item, index) => {
                formData.append("files", arr[index]?.file);
            })
            Axios.put(url, formData).then(res => {
                console.log(res,'res4444444444')
                onChange(res.data.pictures)
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
                console.log(err, 'err')
            })

        } else {
            onChange(imageList);
        }
        setError({message: ''})
    };

    return (
        <div>
            <ImageUploading
                multiple={multiple}
                value={list}
                onChange={onChangeUpload}
                dataURLKey="data_url"
            >
                {({imageList, onImageUpload, isDragging, dragProps}) => (
                    <div style={errorMessage.message ? {border: `1px dashed ${colors.red}`} : {}}
                         className={classes.container} onClick={onImageUpload}
                         {...dragProps}>
                        <UploadIcon/>
                        <p className={classes.titleClick}>{title ? title : t("project.click_here_or_drag_to_upload_image")}</p>
                        {info && <p className={classes.info}>{info}</p>}

                        {hideImage && imageList?.map((image, index) => (
                            <div key={index} className="image-item">
                                <img src={image["data_url"]} alt="" width="100"/>
                            </div>
                        ))}
                    </div>

                )}
            </ImageUploading>
            <div className={classes.errorBlock}>
                {errorMessage?.message && <IconError width={17} height={17}/>}
                <p className={classes.errorText}>{errorMessage?.message}</p>
            </div>
        </div>
    );
}


Upload.propTypes = {
    title: PropTypes.string,
    info: PropTypes.string,
    hideImage: PropTypes.bool,
    multiple: PropTypes.bool,
    list: PropTypes.array,
    onChange: PropTypes.func,
    setError: PropTypes.func,
    id: PropTypes.any
}
