import {collectionStyle} from "./collection-style";
import CollectionItem from "./collection-item";
import UnderHeader from "src/components/underHeader/underHeader";
import CollectionUnderHeader from "./collection-underHeader";
import {useState} from "react";
import {CModal} from "src/cors/modal/cModal";
import CInput from "src/cors/input/cInput";
import Upload from "src/components/upload-image/upload-file";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";

function Collection() {
    const classes = collectionStyle()
    const [openModal, setOpenModal] = useState(false)

    const addCollection = () => {

    }

    return (
        <div className={classes.container}>
            <UnderHeader title={'Collection'}
                         context={
                             <CollectionUnderHeader
                                 openModal={() => setOpenModal(true)}
                                 modalState={openModal}
                             />
                         }
            />
            <div className={classes.collectionWrap}>
                <CollectionItem/>
                <CollectionItem/>
                <CollectionItem/>
                <CollectionItem/>
                <CollectionItem/>
            </div>
            <CModal
                title={'Add Collection'}
                visible={openModal}
                closeModal={() => setOpenModal(false)}
                children={<div>
                    <div className={classes.modalInputWrapp}>
                        <CInput placeholder={'Collection name'}/>
                    </div>
                    <p className={classes.addImageTitle}>Add images</p>
                    <Upload/>
                    <div className={classes.modalBtnWrapp}>
                        <CButton title={'Add'} backgroundColor={colors.blue}/>
                    </div>
                </div>}
            />
        </div>
    );
}

export default Collection;