import React from "react";
import propTypes from "prop-types";
import {useTranslation} from "react-multi-lang";
import {formatNumberWithoutRound} from "src/helpers/format-number";

const WarehouseTableGold = ({dataBody, type}) => {
    const t = useTranslation()
    return (
        <>
            {(dataBody?.categories && dataBody?.categories?.length) ?
                    <>
                        <table className="table" border={1} style={{textAlign:'center'}}>
                            <thead>
                            <tr>
                                <th>{t('table.category')}</th>
                                <th>{t('table.count')}</th>
                                {type === 'gold' && <th>{t('table.initial_weight')}</th>}
                                {(type === 'gold' || type === 'silver') && <th>{t('table.product_weight')}</th>}
                                {(type === 'gold' || type === 'other_with_diamond') &&
                                    <th>{t('table.stone_price')}($)</th>}
                                <th>{t('table.other_costs')}</th>
                                {(type === 'gold' || type === 'silver') &&
                                    <th>{t(`metals.${type}`)} 999.9({t(`metals.gr`)})</th>}
                                {(type === 'gold' || type === 'silver') &&
                                    <th>{type==='gold'? t(`table.price_999_gold`):t(`table.price_999_silver`)}</th>}
                                <th>{t('table.custom_price')}</th>
                                <th>{t('table.cost_warehouse')}</th>
                                <th>{t('table.price')} (֏)</th>
                                <th>{t('table.price')} ($)</th>
                            </tr>
                            </thead>

                            <tr style={{fontSize:14,height:40}}>
                                <th ><p>{t('table.general')}</p></th>
                                <th><p>{dataBody.total_products_count}</p></th>
                                {type === 'gold' &&
                                    <th><p>{dataBody.total_initial_weight}</p></th>}
                                {(type === 'gold' || type === 'silver') &&
                                    <th><p>{dataBody.total_weight}</p></th>}
                                {(type === 'gold' || type === 'other_with_diamond') &&
                                    <th><p>{dataBody.total_stone_price}</p></th>}
                                <th><p>{formatNumberWithoutRound(dataBody.total_oder_costs)}</p></th>
                                {(type === 'gold' || type === 'silver') &&
                                    <th><p>{dataBody.total_weight_999.toFixed(2)}</p></th>}
                                {(type === 'gold' || type === 'silver') &&
                                    <th></th>
                                }

                                <th></th>
                                <th>{formatNumberWithoutRound(dataBody.total_cost)}</th>
                                <th><p>{formatNumberWithoutRound(dataBody.total_price_amd)}</p></th>
                                <th><p>{formatNumberWithoutRound(dataBody.total_price_usd) }</p></th>
                            </tr>
                            <tbody>
                            {
                                dataBody?.categories.map((item, index) => {
                                    return (
                                        <tr key={index.toString()}>
                                            <td>{item.is_default ? t(`type.${item.key}`): item.name}</td>
                                            <td>{item.products_count}</td>
                                            {type === 'gold' && <td>{item.initial_weight}</td>}
                                            {(type === 'gold' || type === 'silver') && <td>{item.weight}</td>}
                                            {(type === 'gold' || type === 'other_with_diamond') &&
                                                <td>{item.stone_price}</td>}
                                            <td>{formatNumberWithoutRound(item.oder_costs)}</td>
                                            {(type === 'gold' || type === 'silver') &&
                                                <td>{item.weight_999.toFixed(2)}</td>}
                                            {(type === 'gold' || type === 'silver') &&
                                                <td>{item.metal_999_rate}</td>}
                                            <td>{`${item.price_coefficient} ${item.price_update_type === 2 ? '%' : '$'}`}</td>
                                            <td>{formatNumberWithoutRound(item.cost)}</td>
                                            <td>{formatNumberWithoutRound(item.price_amd)}</td>
                                            <td>{formatNumberWithoutRound(item.price_usd)}</td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>

                    </> : null
            }

        </>

    )
}

WarehouseTableGold.propTypes = {
    dataBody: propTypes.array,
    type: propTypes.string
};

export default WarehouseTableGold