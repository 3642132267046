import React, {useCallback, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-multi-lang";
import UnderHeader from "src/components/underHeader/underHeader";
import ScrollInfiniteWrapper from "src/components/scroll-wrapper/scroll-wrapper";
import {ajaStyle} from "./aja-style";
import AjaTable from "./aja-table";
import {GlobalContext} from "src/context/global-context";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import CSelect from "src/cors/select/cSelect";
import {CModal} from "src/cors/modal/cModal";
import AjaModal from "./aja-modal";
import AjaDetailsModal from "./aja-details-modal";

function Aja() {
    const classes = ajaStyle();
    const t = useTranslation();
    const {state, setState} = useContext(GlobalContext)
    const [data, setData] = useState([])
    const [status, setStatus] = useState('all')
    const [offset, setOffset] = useState('')
    const [total_count, setTotal_count] = useState(0)
    const [scrolling, setScrolling] = useState(false)
    const [modalState, setModalState] = useState(false)
    const [modalDetails, setModalDetails] = useState(false)
    const [user_id, setUser_id] = useState(null)
    const [userData, setUserData] = useState({})

    const request = useCallback(() => {
        setState({...state, loader: true})
        Axios(`aja/users/${status}?limit=10&offset=0`).then(res => {
            console.log(res, 'ress')
            setData([...data, ...res.data.users])
            setTotal_count(res?.data?.total_count)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        }).finally(() => {
            setState({...state, loader: false})
        })
    }, [status, offset, data])

    const updateView = () => {
        setData([])
        setOffset(prev => prev === 0 ? '' : 0)
    }


    const handleAction = (user_id, action, status) => {
        Axios.put(`aja/action/${action}`, {
            user_id
        }).then(res => {
            updateView()
            toast(t(`notification.${status}`))
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    const showMore = () => {
        setOffset(+offset + 10)
    }


    const handleChangeStatus = (e) => {
        setStatus(e)
        updateView()
    }
    const handleDetailsModal = (data) => {
        setUserData(data)
        setModalDetails(true)
    }


    useEffect(() => {
        request()
    }, [offset])

    useEffect(() => {
        if (scrolling && total_count !== data?.length) {
            showMore()
        }
    }, [scrolling])

    return (
        <ScrollInfiniteWrapper
            scrollUpdate={setScrolling}
            context={<div className={classes.container}>
                <UnderHeader
                    title={t("header.aja")}
                    context={
                        <div className={classes.selectWrap}>
                            <CSelect
                                defaultValue={{label: t("header.all"), value: 'all'}}
                                onChange={(e) => handleChangeStatus(e.value)}
                                options={
                                    [
                                        {label: t("header.all"), value: 'all'},
                                        {label: t("header.created"), value: 'created'},
                                        {label: t("header.approved"), value: 'approved'},
                                        {label: t("header.rejected"), value: 'rejected'}
                                    ]}
                            /></div>
                    }
                />
                <AjaTable
                    setModalState={setModalState}
                    dataBody={data}
                    handleAction={handleAction}
                    setUser_id={setUser_id}
                    handleDetailsModal={handleDetailsModal}

                />
                {modalState && <CModal
                    visible={modalState}
                    closeModal={() => setModalState(false)}
                    title={t("aja.edit_aja")}
                    children={<>
                        <AjaModal
                            user_id={user_id}
                            updateView={updateView}
                            closeModal={() => setModalState(false)}
                        />
                    </>}
                />}
                {modalDetails &&
                    <CModal
                    visible={modalDetails}
                    closeModal={() => setModalDetails(false)}
                    title={t("aja.details")}
                    children={<>
                        <AjaDetailsModal
                            userData={userData}
                            updateView={updateView}
                            closeModal={() => setModalState(false)}
                        />
                    </>}
                />}
            </div>}/>
    );
}

export default Aja;