import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import React, {useEffect, useState} from "react";
import moment from "moment";
import CSelect from "src/cors/select/cSelect";
import CRadio from "src/cors/radio/cRadio";
import CButton from "src/cors/button/cButton";
import {chartStyle} from "./chart-style";
import {colors} from "src/utils/color";
import {checkError} from "src/helpers/check-error";
import {monthDays, months, weekDays, years} from "src/statics/calendar-date";
import {goldTypes} from "src/statics/gold-types-data";
import {Axios} from "src/api/api";
import {useTranslation} from "react-multi-lang";
import {silverTypes} from "src/statics/silver-types-data";
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);


export default function CChart() {
    const t = useTranslation()
    const weekDays = [
        {label: t("seller_rate.day"), value: "day"},
        {label: t("seller_rate.week"), value: "week"},
        {label: t("seller_rate.month"), value: "month"},
        {label: t("seller_rate.year"), value: "year"},
    ]
    const [dataBuy, setDataBuy] = useState([])
    const [dataSell, setDataSell] = useState([])
    const [dataDate, setDataDate] = useState([])
    const [metal, setMetal] = useState('g999')
    const [period, setPeriod] = useState('')
    const [periodError, setPeriodError] = useState('')
    const [fullDate, setFullDate] = useState({
        day: '',
        month: '',
        year: ''
    })

    const [fullDateError, setFullDateError] = useState({})
    const [radios, setRadios] = useState([
        {checked: true, title: t("seller_rate.day"), id: 0, value: 'day'},
        {checked: false, title: t("seller_rate.range"), id: 1, value: 'range'},
    ])
    const classes = chartStyle();
    const options = {
        responsive: true,
        scales: {
            yAxes: {
                ticks: {
                    beginAtZero: false
                }
            }
        },

        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
            },
            // tooltip:{
            //     intersect: false,
            //     mode: "index",
            // },
            tooltips: {
                intersect: false,
                mode: 'index',
                axis: 'x'
            },

            //
            // zoom: {
            //     pan: {
            //         enabled: true,
            //         mode: 'x'
            //     },
            //     zoom: {
            //         pinch: {
            //             enabled: true
            //         },
            //         wheel: {
            //             enabled: true
            //         },
            //         mode: 'x',
            //     }
            // },
            swipeAreaWidth: 200
        },
    };

    const data = {
        labels: dataDate,
        datasets: [
            {
                label: t("seller_rate.buy"),
                data: dataBuy,
                borderColor: '#e50000',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                fill: false
            },
            {
                label: t("seller_rate.sell"),
                data: dataSell,
                borderColor: '#007600',
                backgroundColor: '#7edb7e',
                fill: false
            },
        ],
    };

    const request = (type = 'g999', time = 'period', date = 'year') => {
        Axios(`rate/local_history/${type}?${time}=${date}`).then(res => {
            const buyData = []
            const sellData = []
            const dateData = []
            res.data.length && res.data.slice(0, 40).forEach((item) => {
                buyData.push(item.buy)
                sellData.push(item.sell)
                dateData.push(moment(item.updated_at).format('YYYY MM DD h:mm:ss'))

            })
            const aaa = buyData.filter((item)=>{
                return item !== 1822.04
            })
            setDataBuy(aaa)
            setDataSell(sellData)
            setDataDate(dateData)
        }).catch(err => {
            console.log(err, 'err')
        })
    }

    const handleDate = (e, keyName) => {
        let newDate = {...fullDate}
        newDate[keyName] = e
        setFullDate({...newDate})
        if (e) {
            setFullDateError({...fullDateError, [keyName]: ''})
        }
    }

    useEffect(() => {
        request()
    }, [])

    const handleSearch = () => {
        if (radios.some(item => item.checked && item.value === 'day')) {
            if (fullDate.day && fullDate.month && fullDate.year) {
                const dateStr = `${fullDate.year}-${fullDate.month}-${fullDate.day}:00:00.0000`;
                const date = new Date(dateStr);
                const unixTimestamp = Math.floor(date.getTime() / 1000);
                request(metal, 'day', unixTimestamp)
            } else {
                checkError(fullDate, setFullDateError)
            }
        }
        if (radios.some(item => item.checked && item.value === 'range')) {
            if (period) {
                request(metal, 'period', period)
            } else {
                setPeriodError('check period')
            }
        }
    }

    return (
        <div>
            <div className='chart'>
                <CSelect
                    placeholder={`${t("metals.gold")} 999`}
                    onChange={(e) => setMetal(e.value)}
                    options={[...goldTypes(t("metals.gold")), ...silverTypes(t("metals.silver"))]}
                />
                <div className='chart-radio-wrapper'>
                    <CRadio radioList={radios} onChange={setRadios}/>
                </div>
                {radios.length > 0 && radios.some(item => item.checked && item.value === 'day') ?
                    <>
                        <CSelect
                            onChange={(e) => handleDate(e.value, 'day')}
                            options={monthDays()}
                            placeholder={t("seller_rate.day")}
                            errorMessage={fullDateError.day}
                        />
                        <CSelect
                            onChange={(e) => handleDate(e.value, 'month')}
                            options={months()}
                            placeholder={t("seller_rate.month")}
                            errorMessage={fullDateError.month}
                        />
                        <CSelect
                            onChange={(e) => handleDate(e.value, 'year')}
                            options={years()}
                            placeholder={t("seller_rate.year")}
                            errorMessage={fullDateError.year}
                        />
                    </>
                    :
                    <CSelect
                        placeholder={t("seller_rate.range")}
                        errorMessage={periodError}
                        options={weekDays}
                        onChange={(e) => {
                            setPeriod(e.value)
                            setPeriodError('')
                        }
                        }
                    />
                }
                <div className={classes.btnWrapper}>
                    <CButton
                        onPress={handleSearch}
                        title={t("seller_rate.submit")}
                        backgroundColor={colors.blue}
                    />
                </div>
            </div>
            <div className='chart-wrapper'>
                  <div className='chart-width'>
                      <Line type='line' options={options} data={data}/>
                  </div>
            </div>
        </div>
    );
}
