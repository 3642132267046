import {createStyles, makeStyles} from "@mui/styles";


export const groupStyle = makeStyles(() =>
    createStyles({
        container: {
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto 30px',
            ["@media (max-width:1250px)"]: {
                width: '96%',
            },
        },
        usersBtns:{
            display: 'flex'
        },
        addUserModalSection:{
            marginTop:30
        },
        flex: {
            alignItems: 'center',
            overflow: 'auto',
            display: 'flex'
            // flexWrap: 'wrap',
        },
        group: {
            minWidth: '220px',
            marginRight: '10px',
            background: 'white',
            border: '1px solid rgba(29,56,101,0.1)',
            padding: '10px',
            display: 'flex',
            '&:last-child': {
                marginBottom: 'auto'
            }
        },
        groups: {
            overflow: 'hidden',
            display: 'flex',
            background: 'rgba(29, 56, 101, 0.1)',
            marginBottom: '20px',
            padding: '10px 20px'
        },
        groupsList: {
            justifyContent: 'space-between',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            maxHeight: '230px',
            overflow: 'auto',
            maxWidth: '100vw'
        },
        groupsRight: {
            background: 'white',
            border: '1px solid rgba(29,56,101,0.1)',
            padding: '10px',
            marginLeft: 'auto',
            minWidth: '200px',
            position: 'relative',
            zIndex: '1'
        },
        groupRightEdit: {
            fontSize: '12px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            marginLeft: '10px'
        }
    })
)
