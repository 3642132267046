import React, { useState } from "react";
import propTypes from "prop-types";
import {useTranslation} from "react-multi-lang";
import CButton from "src/cors/button/cButton";
import SmallBtn from "src/cors/smallBtn/smallBtn";
import removeBtn from "src/assets/icons/iconRemove.svg";
import {colors} from "src/utils/color";
import iconCheck from "src/assets/icons/iconCheck.svg";
import deleteIcon from "src/assets/icons/deleteIcon.svg";
import {RenderStatus} from "src/components/render-status/renderStatus";
import moment from "moment";

const BookingTable = ({dataBody}) => {
    const t = useTranslation();
    const [pending, setPending] = useState(false);
    const sortFixing = (data) => data.filter(v => pending ? v.status === 0 : true );

    return (
        <>
            {
                (dataBody && dataBody.length) ?
                    <>
                        <div className='tableWrapper'>
                            <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid rgba(29,56,101,0.1)', paddingBottom: '20px' }}>
                                <h3>Fixes</h3>
                                <div style={{ display: 'flex', marginLeft: 'auto' }}>
                                    <div style={{ marginLeft: '20px', marginRight: '20px', width: '100px' }}>
                                        <CButton
                                            onPress={() => setPending(false)}
                                            title={'All'}
                                            backgroundColor={pending ? colors.blue : colors.gold}
                                        />
                                    </div>
                                    <div style={{ width: '100px' }}>
                                        <CButton
                                            onPress={() => setPending(true)}
                                            title={'Pending'}
                                            backgroundColor={!pending ? colors.blue : colors.gold}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <th>name</th>
                            <th>weight (g)</th>
                            <th>prices $</th>
                            <th>type $</th>
                            <th>discount</th>
                            <th>groups $</th> */}
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>user_id</th>
                                    <th>rate_id</th>
                                    <th>amount</th>
                                    <th>price $</th>
                                    <th>status</th>
                                    <th>place</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    sortFixing(dataBody).map((item, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td>{item.user_id}</td>
                                                <td>{item.rate_id}</td>
                                                <td>{item.amount}</td>
                                                <td>{item.price}$</td>
                                                <td>{item.status}</td>
                                                <td>{item.place}</td>
                                                <td>
                                                    <CButton
                                                        title={'Approve'}
                                                        backgroundColor={colors.blue}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </> : null
            }
        </>

    )
}

BookingTable.propTypes = {
    dataBody: propTypes.array,
    handleBooking: propTypes.func
};

export default BookingTable