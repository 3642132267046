import CSelect from "src/cors/select/cSelect";
import jewelry from "src/assets/images/jewelry.svg";
import SelectedItemTable from "../seller-custom-price-selected-list-table/selected-list-table";
import {sellerCustomPriceStyle} from "../seller-custom-price-style";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import {useTranslation} from "react-multi-lang";
import propTypes from "prop-types";
import {useEffect, useState} from "react";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";

const MoveProductsModal = ({products, moveProducts, type}) => {

    const classes = sellerCustomPriceStyle();
    const t = useTranslation()
    const [checkedList, setCheckedList] = useState(products.filter(item => {
        if (item.checked) {
            return item
        }}))
        const [categories, setCategoriesData] = useState([])
        const [selectValue, setSelectValue] = useState('')

    const deleteItem = (id) => {
        const arr = checkedList.filter(item => {
            if (item.id !== id) {
                return item
            }
        })
        setCheckedList(arr)
    }

    useEffect(()=>{
        Axios(`categories/${type}?limit=10000&offset=0`).then(res => {
            const arr = res.data.categories.map(item=>{
                return {
                    label: item.is_default ? t(`type.${item.key}`) : item.name,
                    value: item.id
                }
            })
            setCategoriesData(arr)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    },[])

    useEffect(()=>{
            const arr = products.map(item=>{
                return {
                    label: item.is_default ? t(`type.${item.key}`) : item.name,
                    value: item.id
                }
            })
            setCategoriesData(arr)
    },[])

    return (
        <div className={classes.moveProductModal}>
            <CSelect
                placeholder={t("categories.select_categories")}
                isSearchable={true}
                options={categories}
                onChange={(e)=>setSelectValue(e.value)}
            />
            <div className={classes.tableTitle}>
                <h1>{t('categories.selected_list')}</h1>
            </div>
            <div className={classes.tableWrapper}>
                <SelectedItemTable deleteItem={deleteItem} dataBody={checkedList}/>
            </div>
            <CButton
                onPress={() => moveProducts(checkedList,selectValue)}
                backgroundColor={colors.blue}
                title={t('project.save')}
            />
        </div>
    )
}
MoveProductsModal.propTypes = {
    products: propTypes.any,
    closeModal: propTypes.func,
    moveProducts: propTypes.func,
    type: propTypes.any,
};
export default MoveProductsModal