import React, {useContext, useEffect, useState} from "react";
import {buyerDetailStyle} from "./buyer-detail-style";
import CInput from "src/cors/input/cInput";
import CButton from "src/cors/button/cButton";
import CTab from "src/cors/tab/cTab";
import BuyerDetailsOrder from "./buyerDetailsTable/buyerDetailsOrder";
import BuyerDetailsOffer from "./buyerDetailsTable/buyerDetailsOffer";
import UnderHeader from "src/components/underHeader/underHeader";
import {useLocation, useNavigate} from "react-router-dom";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import {CModal} from "src/cors/modal/cModal";
import {colors} from "src/utils/color";
import {GlobalContext} from "src/context/global-context";

function BuyerDetail() {
    const navigation = useNavigate()
    const t = useTranslation()
    const location = useLocation();
    const data = location.state
    const {state, setState} = useContext(GlobalContext)
    const [active, setActive] = useState(0)
    const [buyerHistoryData, setBuyerHistoryData] = useState([])
    const [buyerOrderData, setBuyerOrderData] = useState([])
    const classes = buyerDetailStyle();
    const [showResetInput, setShowResetInput] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const [offsetOrder, setOffsetOrder] = useState(0)
    const [offsetOffer, setOffsetOffer] = useState(0)
    const [total_countOrder, setTotal_countOrder] = useState(0)
    const [total_countOffer, setTotal_countOffer] = useState(0)
    const [deleteRequestModal, setDeleteRequestModal] = useState(false)

    useEffect(() => {
        Axios(`admin/buyer_offer/${data?.id}/all?limit=10&offset=0`).then(res => {
            setBuyerHistoryData(res.data.offers)
            setTotal_countOffer(res?.data?.total_count)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err')
        })
    }, [])

    useEffect(() => {
        Axios(`admin/buyer_order/${data?.id}/all?limit=10&offset=0`).then(res => {
            setBuyerOrderData(res.data.orders)
            setTotal_countOrder(res?.data?.total_count)
        }).catch(err => {
            console.log(err)
            toast(errorMessages(err?.response?.data?.code))
        })
    }, [])


    const deactivateUser = () => {
        setState({...state, loader: true})
        Axios.put(`admin/deactivate_user`, {user_id: data?.id}).then(res => {
            toast(t("notification.deactivated"))
            navigation('/buyer')
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err')
        }).finally(()=>{
            setState({...state, loader: false})
            setDeleteRequestModal(false)
        })
    }


    const resetPasswordOpen = () => {
        setShowResetInput(true)
    }

    const showMoreOrder = () => {
        Axios(`admin/buyer_order/${data?.id}/all?limit=10&offset=${offsetOrder + 10}`).then(res => {
            setBuyerOrderData([...buyerOrderData, ...res.data.orders])
            setOffsetOrder(offsetOrder + 10)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    const showMoreOffer = () => {
        Axios(`admin/buyer_offer/${data?.id}/all?limit=10&offset=${offsetOffer + 10}`).then(res => {
            setBuyerHistoryData([...buyerHistoryData, ...res.data.offers])
            setOffsetOffer(offsetOffer + 10)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }


    const resetPassword = () => {
        if (newPassword.length > 6) {
            Axios.put(`admin/reset_password`, {
                    "confirm_password": newPassword,
                    "password": newPassword,
                    "user_id": data.id
                }
            ).then(res => {
                toast(t("notification.password_reset"))
                setNewPassword('')
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
                console.log(err, 'err')
            })
            setShowResetInput(false)
        } else {
            toast(t("notification.length_must_be_more"))
        }
    }

    const passwordValue = (e) => {
        setNewPassword(e)
    }

    return (
        <div className={classes.buyerDetailsContainer}>
            <div className={classes.buyerPersonalInfo}>
                <UnderHeader goBack={() => navigation('/buyer')} title={`${data?.firstname} ${data?.lastname}`}
                             back={true}/>
                <div className={classes.buyerFormWrapper}>
                    <CInput
                        disabled={true}
                        value={data?.firstname}
                        label={t("setting.name")}
                        placeholder={t("setting.name")}
                    />
                    <CInput
                        disabled={true}
                        value={data?.lastname}
                        label={t("setting.surname")}
                        placeholder={t("setting.surname")}
                    />
                    <CInput
                        disabled={true}
                        value={data?.phone_number}
                        type={'phone'}
                        label={t("setting.phone")}
                        placeholder={t("setting.phone")}
                    />
                    <CInput
                        disabled={true}
                        value={t(`setting.${data?.country}`)}
                        label={t("setting.country")}
                        placeholder={t("setting.country")}
                    />
                    <CInput
                        disabled={true}
                        value={data?.email}
                        label={t("setting.email")}
                        placeholder={t("setting.email")}
                    />
                </div>
                <div className={classes.resetWrapper}>
                    {
                        showResetInput ?
                            <div className={classes.resetInputWrapper}>
                                <CInput
                                    onChange={(e) => passwordValue(e.target.value)}
                                    type='password'
                                    label={t("seller.reset_password")}
                                    placeholder={t("setting.password")}
                                />
                                <p onClick={resetPassword} className={classes.applyBtn}>{t("project.apply")}</p>
                            </div>
                            : <div className={classes.buttonWrapper}>
                                <CButton
                                    onPress={resetPasswordOpen}
                                    title={t("seller.reset_password")}
                                    borderWidth={1}
                                />
                            </div>
                    }

                    <p className={classes.deactivate}
                       onClick={() => setDeleteRequestModal(true)}>{t("seller.deactivate_user")}</p>
                </div>
            </div>
            <div className={classes.tabWrapper}>
                <CTab
                    onPress={setActive}
                    active={active}
                    items={[{value: t("seller.order_history"), key: 0},
                        {value: t("seller.offers_history"), key: 1}]}
                />
            </div>
            {active === 0 && <>
                <BuyerDetailsOrder data={data} dataBodyOrderBuyer={buyerOrderData}/>
                {(buyerOrderData.length !== total_countOrder) && <div className={classes.seeMoreBtnWrapper}>
                    <CButton
                        onPress={showMoreOrder}
                        title={t("project.show_more")}
                        borderWidth={1}
                    />
                </div>}
            </>}
            {active === 1 && <div className={classes.buyerDetailsOfferWrapper}>
                <BuyerDetailsOffer dataBodyOfferBuyer={buyerHistoryData}/>
                {(buyerHistoryData.length !== total_countOffer) && <div className={classes.seeMoreBtnWrapper}>
                    <CButton
                        onPress={showMoreOffer}
                        title={t("project.show_more")}
                        borderWidth={1}
                    />
                </div>}
            </div>}
            {deleteRequestModal && <CModal
                visible={deleteRequestModal}
                closeModal={() => setDeleteRequestModal(false)}
                center={true}
                title={t('project.note')}
                children={
                    <div>
                        <p style={{marginTop: 20}}>{t('notifications.if_you_deactivate_user')}</p>
                        <div className={classes.deleteModalBtns}>
                            <div className={classes.deleteModalBtnWrap}>
                                <CButton
                                    onPress={() => setDeleteRequestModal(false)}
                                    borderWidth={1}
                                    title={t('seller_product.no')}
                                />
                            </div>
                            <div className={classes.deleteModalBtnWrap}>
                                <CButton
                                    onPress={deactivateUser}
                                    backgroundColor={colors.red}
                                    title={t('seller_product.yes')}
                                />
                            </div>
                        </div>
                    </div>
                }
            />}
        </div>
    );
}

export default BuyerDetail;