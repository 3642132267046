import React, { useContext, useEffect, useRef, useState } from "react";
import { sellerAddProductStyle } from "src/pages/seller-pages/seller-add-product/seller-add-product-style";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { Axios } from "src/api/api";
import { toast } from "react-toastify";
import { errorMessages } from "src/helpers/error-messages";
import { GlobalContext } from "src/context/global-context";
import CCheckbox from "../../../cors/checkbox/cCheckbox";
import CInput from "../../../cors/input/cInput";
import CButton from "../../../cors/button/cButton";
import { colors } from "../../../utils/color";
import { typeLabels, typeCheckboxes } from '../../../utils/typeValues';

function SellerEditProduct() {
    const navigation = useNavigate();
    const [resetCategory, setResetCategory] = useState(true);
    const [resetType, setResetType] = useState(true);
    const classes = sellerAddProductStyle();
    const t = useTranslation();
    const { state, setState } = useContext(GlobalContext);
    const [subCategoryCheckboxes, setSubCategoryCheckboxes] = useState([false, false, false, false, false]);
    const subCategoryLabels = ["women", "men", "kids", "accessories", "gifts"];
    const [typeCheckboxesState, setTypeCheckboxesState] = useState(typeCheckboxes);

    let { id } = useParams();

    const [ucode, setUcode] = useState('');
    const [forWhom, setForWhom] = useState('');
    const [type, setType] = useState('');
    const [categories, setCategories] = useState({
        sub_category: '',
    });

    const handleSubCategoriesChange = (index, checked) => {
        const newCheckboxes = [...subCategoryCheckboxes];
        newCheckboxes[index] = checked;
        setSubCategoryCheckboxes(newCheckboxes);
    };

    const handleTypeChange = (id, checked) => {
        const newCheckboxes = { ...typeCheckboxesState };
        newCheckboxes[id] = checked;
        setTypeCheckboxesState(newCheckboxes);
    };

    const handleChangeLange = (value) => {
        setUcode(value);
    };

    const refScroll = useRef();

    useEffect(() => {
        if (!resetCategory) {
            setResetCategory(true);
            setCategories({
                sub_category: '',
            });
        }
    }, [resetCategory]);

    useEffect(() => {
        if (!resetType) {
            setResetType(true);
            setType('');
        }
    }, [resetType]);

    const request = () => {
        const checkedSubCategories = subCategoryCheckboxes.map((checked, index) => ({
            label: subCategoryLabels[index],
            checked
        }));

        const checkedTypes = Object.entries(typeCheckboxesState).map(([id, checked]) => ({
            label: typeLabels.find(type => type.id === parseInt(id))?.label,
            checked
        }));

        const finallyData = {
            ucode,
            ProductSubCategory: checkedSubCategories,
            ProductType: checkedTypes,
        };

        Object.keys(finallyData).forEach((elem, index) => {
            if (!finallyData[elem]?.length && typeof finallyData[elem] !== 'number' && typeof finallyData[elem] !== 'object') {
                delete finallyData[elem];
            }
        });

        setState({ ...state, loader: true });
        Axios.post(`product/update-from-admin/${id}`, finallyData).then(res => {
            toast.success('product edited');
        }).catch(err => {
            toast.error(errorMessages(err?.response?.data?.code));
            console.log(err, 'err');
        }).finally(() => {
            setState({ ...state, loader: false });
        });
    };

    const saveProduct = () => {
        request();
    };

    useEffect(() => {
        if (id) {
            Axios(`product/${id}`).then(res => {
                let data = res.data;
                setUcode(data.ucode);  // Assuming the name field corresponds to ucode
                const updatedCheckboxes = [...subCategoryCheckboxes];
                setForWhom(data.for_whom);
                setType(data.product_type);
                data.product_sub_category.forEach(item => {
                    const index = item.subcategoryid - 1; // Calculate the index
                    if (index >= 0 && index < updatedCheckboxes.length) {
                        updatedCheckboxes[index] = true;
                    }
                });
                setSubCategoryCheckboxes(updatedCheckboxes);
                setCategories({
                    sub_category: data.product_sub_category
                });
                const updatedTypeCheckboxes = { ...typeCheckboxesState };
                data.product_type.forEach(item => {
                    console.log(item)
                    updatedTypeCheckboxes[item.type_id] = true; // Update the checkbox state by id
                });
                setTypeCheckboxesState(updatedTypeCheckboxes);
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code));
            });
        } else {
            navigation('/');
        }
    }, [id]);

    return (
        <div className={classes.container} ref={refScroll}>
            <p style={{ marginTop: "15px" }}></p>
            <CInput
                value={ucode}
                onChange={(e) => handleChangeLange(e.target.value, 'ucode')}
                placeholder={t("seller_product.product_code")}
                label={t("seller_product.product_code")}
            />
            <p style={{ marginTop: "15px" }}>{t("category.category")}</p>
            <div className="sub-categories-wrapper">
                {resetCategory && subCategoryCheckboxes.map((checked, index) => (
                    <div key={index}>
                        <CCheckbox
                            checked={checked}
                            onChange={(checked) => handleSubCategoriesChange(index, checked)}
                            label={subCategoryLabels[index]}
                        />
                        <p>{t(`subcategories.${subCategoryLabels[index]}`)}</p>
                    </div>
                ))}
            </div>
            <div className="sub-categories-wrapper">
                {resetType && Object.entries(typeCheckboxesState).map(([id, checked]) => (
                    <div key={id}>
                        <CCheckbox
                            checked={checked}
                            onChange={(checked) => handleTypeChange(id, checked)}
                            label={typeLabels.find(type => type.id === parseInt(id))?.label}
                        />
                        <p>{t(`type.${typeLabels.find(type => type.id === parseInt(id))?.label}`)}</p>
                    </div>
                ))}
            </div>
            <div className={classes.containerPrivacySaveWrap}>
                <CButton
                    onPress={saveProduct}
                    title={t("seller_product.save_product")}
                    backgroundColor={colors.blue}
                />
            </div>
        </div>
    );
}

export default SellerEditProduct;
