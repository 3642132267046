import React from 'react';
import 'react-responsive-modal/styles.css';
import {Modal} from 'react-responsive-modal';
import {cModalStyle} from "./cModal-style";

interface CModalProps {
    visible: boolean
    closeModal: () => void
    center: boolean
    title: string
    children: JSX.Element
}

export const CModal = ({
                           visible,
                           closeModal,
                           center = true,
                           title,
                           children
                       }: CModalProps) => {

    const classes = cModalStyle({center})

    return (
        <div>
            <Modal
                blockScroll={false}
                animationDuration={0}
                classNames={{modal: classes.container}}
                open={visible}
                onClose={closeModal} center={center}
            >
                {title && <p className={classes.title}>{title}</p>}
                {children && children}
            </Modal>
        </div>
    );
};
