import {ReactComponent as IconSearch} from "src/assets/icons/search.svg";
import {ReactComponent as DeleteIcon} from "src/assets/icons/deleteIcon.svg";
import {cSearchInputStyles} from "./searchInput-style";
import {useTranslation} from "react-multi-lang";

interface CSearchInputProps {
    onChange?: any
    handleSearch?: any
    handleClear?: any
    placeholder?: string
    value?: string
}

export default function CSearchInput({
                                         onChange = () => undefined,
                                         placeholder = '',
                                         handleSearch,
                                         value = '',
                                         handleClear
                                     }: CSearchInputProps) {
    const classes = cSearchInputStyles();
    const t = useTranslation()
    return (
        <form
            className={classes.containersearchBox}
            onSubmit={(e) => {
                e.preventDefault()
                handleSearch()
            }}
        >
            <input
                value={value}
                className={classes.searchInput}
                onChange={(e) => onChange(e.target.value)}
                placeholder={placeholder ? placeholder : t("header.search")}
            />
            {value && <div className={classes.searchBtn} onClick={handleClear}>
                <DeleteIcon width={12} height={12}/>
            </div>}
            <div className={classes.searchBtn} onClick={handleSearch}>
                <IconSearch width={17} height={17}/>
            </div>
        </form>
    );
}
