import React, {useEffect, useMemo, useState} from "react";
import UnderHeader from "src/components/underHeader/underHeader";
import {sellerRateStyle} from "./seller-rate-style";
import SellerRateTable from "./seller-rate-table";
import SellerGlobalRateTable from "./seller-global-rate-table";
import CChart from "src/components/chart/chart";
import {Axios} from "src/api/api";
import moment from "moment";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";



let goldData = {
    1: {name: "999 ֊ 24k", order: 1},
    2: {name: "995 ֊ 24k", order: 2},
    3: {name: "958 ֊ 23k", order: 4},
    4: {name: "916 ֊ 22k", order: 5},
    5: {name: "900 ֊ 21.6k", order: 6},
    6: {name: "875 ֊ 21k", order: 7},
    7: {name: "750 ֊ 18k", order: 7},
    8: {name: "585 ֊ 14k", order: 3},
    9: {name: "500 ֊ 12k", order: 9},
    10: {name: "416 ֊ 10k", order: 10},
    11: {name: "375 ֊ 9k", order: 11},
    12: {name: "333 ֊ 8k", order: 12},
    13: {name: "999", order: 13},
    14: {name: "995", order: 14}
}


const SellerRate = () => {
    const classes = sellerRateStyle();
    const t = useTranslation()
    const [dataGold, setDataGold] = useState([])
    const [dataSilver, setDataSilver] = useState([])
    const [dataGlobal, setDataGlobal] = useState([])
    const [status, setStatus] = useState(true)
    let global = [
        {name: t('metals.gold'), id: 1},
        {name: t('metals.silver'), id: 2},
        {name: t('metals.platinum'), id: 3},
        {name: t('metals.palladium'), id: 4},
    ]

    useEffect(() => {
        Axios(`rate/local`).then(res => {
            if (res.data.status === 'open') {
                setStatus(true)
            } else {
                setStatus(false)
            }

            let arr = []
            let arrSilver = []

            res?.data?.data.forEach((item, index) => {
                if (index < 12) {
                    arr.push({...item,  ...goldData[item.id]})
                } else {
                    arrSilver.push({...item, ...goldData[item.id]})
                }
            })
            arr.sort((a, b) => a.order - b.order)
            setDataGold(arr)
            setDataSilver(arrSilver)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }, []);

    useEffect(() => {
        Axios(`rate/global`).then(res => {
            let arr = []
            res?.data.forEach((item, index) => {
                arr.push({...item, name: global[index]?.name})
            })
            setDataGlobal(arr)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }, []);

    const renderChart = useMemo(() => {
        return <CChart/>
    }, [])

    return (
        <div className={classes.rateContainer}>
            <UnderHeader title={t("header.dashboard")}/>
            <div className={classes.rateTableContent}>
                <div className={classes.rateTableWrapper}>
                    <div className={classes.flexContainer}>
                        <p className={classes.rateTableTitle}>{t("seller_rate.local_price")}</p>
                        <p className={classes.rateTableTitle}>{moment().format('L')}</p>
                    </div>
                    <SellerRateTable
                        status={status}
                        dataBody={dataGold}
                        title={t("metals.gold")}
                    />
                    <SellerRateTable
                        status={status}
                        dataBody={dataSilver}
                        title={t("metals.silver")}
                    />
                </div>
                <div className={classes.rateTableWrapper}>
                    <p className={classes.rateTableTitleBottom}>{t("seller_rate.the_world_spot_price")}</p>
                    <SellerGlobalRateTable
                        status={status}
                        dataBody={dataGlobal}
                        title={t("metals.metals")}
                    />
                </div>
            </div>
            <UnderHeader title={t("seller_rate.statistics")}/>
            {renderChart}
        </div>
    )
}

export default SellerRate