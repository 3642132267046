export  function  storage (action, data) {
    let gold = JSON.parse(localStorage.getItem('gold')) ? JSON.parse(localStorage.getItem('gold')) : {}
    if (action === 'set') {
        let _ = JSON.stringify({...gold, ...data})
        localStorage.setItem('gold', _)
    }
    if (action === 'remove') {
        localStorage.removeItem('gold')
    }
    if (action === 'get') {
        return gold
    }
}