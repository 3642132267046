import CInput from "src/cors/input/cInput";
import {sellerCustomPriceStyle} from "../seller-custom-price-style";
import {colors} from "src/utils/color";
import CButton from "src/cors/button/cButton";
import {Axios} from "src/api/api";
import {useState} from "react";
import PropTypes from "prop-types";
import SellerCustomPriceUnderHeader from "../seller-custom-price-under-header";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";

const CreateCategoryModal = ({type, closeModal, request}) => {
    const classes = sellerCustomPriceStyle();
    const t = useTranslation();
    const [value, setValue] = useState('')

    const createCategory = () => {
        if (value) {
            Axios.post(`categories/${type}/create`, {"name": value}).then(res => {
                toast(t('project.created'))
                closeModal(false)
                request()
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
            })
        }
    }

    return (
        <div className={classes.createCategoryModal}>
            <CInput
                onChange={(e) => setValue(e.target.value)}
                value={value}
                placeholder={t('categories.create_category')}
                type='text'
                label={t('categories.create_category')}
            />
            <CButton
                backgroundColor={colors.blue}
                title={t('project.save')}
                onPress={createCategory}
            />
        </div>
    )
}

CreateCategoryModal.propTypes = {
    type: PropTypes.any,
    closeModal:PropTypes.func,
    request:PropTypes.func,
}

export default CreateCategoryModal