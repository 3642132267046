import React, {createContext, useCallback, useReducer} from 'react';
import GlobalContextReducer, {updateState} from './global-context-reducer';
import PropTypes from "prop-types";

export const initialState = {
    morePopUp: false,
    languagePopUp: false,
    burgerLeft: false,
    // userinfo: {},
    loader: false,
};

export const GlobalContext = createContext();

const GlobalContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(GlobalContextReducer, initialState);

    const setState = useCallback(
        (params) => {
            dispatch(updateState(params));
        },
        [dispatch],
    );

    return (
        <GlobalContext.Provider value={{state, setState}}>{children}</GlobalContext.Provider>
    );
};


GlobalContextProvider.propTypes = {
    children: PropTypes.any,
};


export default GlobalContextProvider;
