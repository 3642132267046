import {colors} from "src/utils/color";
import {ReactComponent as IconError} from "src/assets/icons/error.svg";
import {cTextareaStyles} from "./cTextarea-style";

interface CTextareaProps {
    onChange: any
    disabled: boolean
    label: string
    errorMessage: string
    placeholder: string
    value: string
}

export default function CTextarea({
                                      onChange,
                                      disabled,
                                      label,
                                      errorMessage,
                                      placeholder,
                                      value
                                  }: CTextareaProps) {

    const classes = cTextareaStyles({borderColor: errorMessage ? colors.red : colors.blue});
    return (
        <div className={classes.textarea}>
            {label && <p className={`${classes.label} ${errorMessage && classes.labelError} `}>{label}</p>}
            <textarea
                className={`${classes.container} ${errorMessage && classes.containerError} `}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder}
                value={value}

            />
            <div className={classes.errorBlock}>
                {errorMessage && <IconError width={17} height={17}/>}
                <p className={classes.errorText}>{errorMessage}</p>
            </div>
        </div>
    );
}