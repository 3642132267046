import {fakeJewelryStyle} from "./fake-jewelry-style";
import {ReactComponent as IconAccept} from "src/assets/icons/accept.svg";
import {ReactComponent as IconReject} from "src/assets/icons/reject.svg";
import propTypes from "prop-types";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";

export default function FakeJewelryItem({data, admin = false, request = () => undefined}) {
    const classes = fakeJewelryStyle()
    const t = useTranslation()

    const approve = () => {
        Axios.put(`admin/fake_jewelry_approve?id=${data.id}`).then(res => {
            toast(t("notification.approved"))
            request()
        }).catch(err => {
            console.log(err, 'err')
            toast(errorMessages(err.response.data.code))
        })
    }

    const reject = () => {
        Axios.put(`admin/fake_jewelry_reject?id=${data.id}`).then(res => {
            toast(t("notification.rejected"))
            request()
        }).catch(err => {
            console.log(err, 'err')
            toast(errorMessages(err.response.data.code))
        })
    }

    return (
        <div className={classes.fakeJewelryBlock} style={{backgroundImage: `url(${data?.picture})`}}>
            {admin && <div className={classes.fakeJewelryBlockHeader}>
                <div onClick={approve} className={classes.fakeJewelryBlockBtn}>
                    <IconAccept/>
                </div>
                <div onClick={reject} className={`${classes.fakeJewelryBlockBtn} ${classes.fakeJewelryReject}`}>
                    <IconReject/>
                </div>
            </div>}
            {data.seller_company_name && <div className={classes.fakeJewelryBottom}>
                <p className={classes.fakeJewelryBottomText}>{data.seller_company_name}</p>
            </div>}
        </div>
    );
}

FakeJewelryItem.propTypes = {
    data: propTypes.object,
    admin: propTypes.bool,
    request: propTypes.func
}