import React, {useContext, useEffect, useState} from "react";
import {Routes, Route, useLocation, useNavigate} from "react-router-dom";
import {setDefaultLanguage, setTranslations} from "react-multi-lang";
import './App.css';
import './globalStyles.css';
import {storage} from "./utils/storage";
import am from 'src/translations/am.json';
import en from 'src/translations/en.json';
import ru from 'src/translations/ru.json';
import {GlobalContext} from "./context/global-context";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {errorMessages} from "./helpers/error-messages";
import Spinner from "./components/spinner/spinner";
import SellerRole from "./roles/seller-role";
import AdminRole from "./roles/admin-role";
import ServiceRole from "./roles/service-role";
import CScrollToTop from "./cors/scroll-to-top/cScroll-to-top";
import {config} from "./api/config";
import PreorderRole from "./roles/preorder-role";
import MarketRole from "./roles/market-role";

const langConfig = ['en', 'ru', 'am'];
const local = storage('get', 'gold')

function getLang() {
    if (local.language === undefined && local.language !== 0) return 'am'
    return langConfig[storage('get', 'gold').language]
}

setTranslations({am, en, ru});
setDefaultLanguage(getLang());

export default function App() {
    const [role, setRole] = useState(null)
    const [token, setToken] = useState(storage('get').token)
    const {state, setState} = useContext(GlobalContext)
    const {morePopUp, languagePopUp, burgerLeft, loader} = state
    const storageToken = storage('get').token
    const {search, pathname} = useLocation()
    const navigation = useNavigate()

    const onDocument = () => {
        if (morePopUp || languagePopUp || burgerLeft) {
            setState({morePopUp: false, languagePopUp: false, burgerLeft: false})
        }
    }

    useEffect(() => {
        if (!storageToken) {
            storage('set', {
                language: langConfig.indexOf(search.slice(-2)),
                token: search.substring(7, search.length - 8)
            })
            setToken(search.substring(7))
            navigation('/dashboard')
        }
        if ('?token=' === search.slice(0, 7)) {
            storage('remove')
            storage('set', {
                language: langConfig.indexOf(search.slice(-2)),
                token: search.substring(7, search.length - 8)
            })
            setToken(search.substring(7, search.length - 8))
            navigation('/dashboard')
            window.location.reload()
        }
        if (pathname === '/') {
            navigation('/dashboard')
        }
    }, [])

    useEffect(() => {
        axios(`${config.url}auth/validate_token`, {
            headers: {
                'x-access-token': storageToken
            }
        }).then(res => {
            setRole(res.data.role)
            axios(`${config.url}user/user_info`, {
                headers: {
                    'x-access-token': storageToken
                }
            }).then(res => {
                storage('set', res.data)
                setState({...state, userinfo: res.data})
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
                console.log(err, 'error')
            })
        }).catch(err => {
            // window.location = `${config.webUrl}`
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'error')
        })
    }, [token])

    useEffect(() => {
        if (!(!storageToken && search.length)) {
            return;
        }
        window.location.reload()
    }, [])

    return (
        <div onClick={onDocument} style={{minHeight: '100vh', overflow: 'auto'}}>
            <ToastContainer/>
            <CScrollToTop/>
            <Spinner loading={loader}/>
            {token && (
                <div>
                    {(role === 1) && <AdminRole/>}
                    {(role === 2 || role === 3) && <SellerRole/>}
                    {role === 5 && <ServiceRole/>}
                    {role === 6 && <PreorderRole/>}
                    {role === 7 && <MarketRole/>}
                </div>)}
            {<Routes>
                {!role && !token && <Route
                    exact path='*'
                    element={<p style={{fontSize: 40, textAlign: 'center'}}>page not found</p>}/>}
            </Routes>}
        </div>
    );
}

App.propTypes = {};
