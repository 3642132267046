import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const ajaStyle = makeStyles(() =>
    createStyles({
        container: {
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto 30px',
            ["@media (max-width:1250px)"]: {
                width: '96%',
            },
        },
        selectWrap: {
            width: 160,
        },
        modal: {
            paddingTop: 20
        },
        modalSection: {
            backgroundColor: colors.white,
            boxShadow: ' 0px 0px 96px rgba(0, 0, 0, 0.1)',
            padding: 20,
            marginBottom: 30,
        },
        modalTitles: {
            marginBottom: 15,
            color: colors.blue,
            fontWeight: 'bold'
        },
        modalBtn: {
            width: 150,
            marginTop: 10
        },
        ajaDetailsList: {
            display: 'flex',
            justifyContent: "space-between",
            fontSize: 14,
            marginBottom: 10,
            textTransform:"capitalize"
        }
    })
)