import React, {useContext, useEffect, useState} from "react";
import logo from "src/assets/images/logo.png";
import {ReactComponent as IconAvatar} from "src/assets/icons/avatar.svg";
import {ReactComponent as IconSettings} from "src/assets/icons/iconSettings.svg";
import {ReactComponent as IconLogout} from "src/assets/icons/iconLogout.svg";
import {ReactComponent as IconMore} from "src/assets/icons/iconMore.svg";
import {ReactComponent as IconUser} from "src/assets/icons/iconUser.svg";
import {ReactComponent as IconAboutUs} from "src/assets/icons/iconAboutUs.svg";
import {ReactComponent as IconPrivacy} from "src/assets/icons/iconPrivacy.svg";
import {ReactComponent as IconFakeJewelry} from "src/assets/icons/iconFakeJewelry.svg";
import {ReactComponent as IconBurger} from "src/assets/icons/iconBurger.svg";
import IconArrow from "src/assets/icons/iconArrow.svg";

import {headerStyles} from "./header-styles";
import {NavLink} from "react-router-dom";
import LanguageBar from "src/components/languageBar/languageBar";
import {GlobalContext} from "src/context/global-context";
import {getLanguage, useTranslation} from "react-multi-lang";
import {config} from "src/api/config";
import {storage} from "src/utils/storage";


const HeaderMarket = () => {
    const {state, setState} = useContext(GlobalContext)
    const storageToken = storage('get').token
    const socket = new WebSocket(`wss://${config.socketUrl}/ws/notifications/subscribe/online_market?x-access-token=${storageToken}`)
    const {morePopUp, burgerLeft} = state
    const classes = headerStyles()
    const t = useTranslation()
    const left = burgerLeft ? 0 : -1500;

    const logOut = () => {
        window.location = `${config.webUrl}?logout=${storage('get').token}&lang=${getLanguage()}`
        storage('remove')
    }

    return (
        <div className={classes.mainContent}>
            <div className={classes.headerTop}>
                <LanguageBar/>
                <div className={classes.logo}
                     style={{cursor: "pointer"}}
                >
                    <div className={classes.burgerIcon} onClick={() => {
                        setState({burgerLeft: !burgerLeft})
                    }}>
                        <IconBurger/>
                    </div>
                    <div className={classes.logoWrap}
                         onClick={() => window.location = `${config.webUrl}?lang=${getLanguage()}`}
                    >
                        <img className={classes.logoImg} src={logo} alt="logo"/>
                    </div>
                </div>
                <div className={classes.flexContainer}>
                    <p>{`${state?.userinfo?.firstname ? state.userinfo.firstname:""}`} {`${state?.userinfo?.lastname ? state.userinfo.lastname:""}`}</p>
                    <IconAvatar width={16} height={16}/>
                </div>
            </div>
            <div className={classes.headerNavBar}>
                <div className={classes.headerNavBarContent}>
                    <div className={classes.navBarContainer}>
                        <NavLink to='/dashboard'>
                            <p>{t("header.dashboard")}</p>
                        </NavLink>
                        <NavLink to='/pending-orders'>
                            <p>{t("header.pendingOrders")}</p>
                        </NavLink>
                        <NavLink to='/history'>
                            <p>{t("header.history")}</p>
                        </NavLink>
                    </div>
                    <div onClick={logOut} className={classes.logoutContainer}>
                        <IconLogout width={16} height={16}/>
                        <p>{t("header.log_out")}</p>
                    </div>
                </div>
            </div>
            <ul onClick={e => e.stopPropagation()} className={classes.mobileHeader} style={{left: left}}>
                <li>
                    <NavLink to='/dashboard' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.dashboard")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/pending-orders' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.pendingOrders")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/history' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.history")}</span>
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

export default HeaderMarket