import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const sellerOfferStyle = makeStyles(() =>
    createStyles({
        sellerOfferContainer: {
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto 30px',
            "@media (max-width:1250px)": {
                width: '96%',
            },
        },
        sellerOfferCardWrapper: {
            marginTop: 30,
        },
        seeMoreBtnWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 30,
            '& > div': {
                width: 230
            },
            "@media (max-width:500px)": {
                marginBottom: 30
            },
        },
        agreeModalText: {
            color: colors.black,
            fontSize: 16,
            margin: '14px 0 32px 0',
            "@media (max-width:480px)": {
                margin: '15px 0'
            },
        },
        agreeModalBtn: {
            marginBottom: 18,
            "@media (max-width:480px)": {
                marginBottom: 10,
            },
        },
        modalTextarea: {
            height: 150,
            marginBottom: 30,
            "@media (max-width:480px)": {
                marginBottom: 10,
            },
        },
        priceModal: {
            textAlign: 'center',
            marginTop: 25,
            "@media (max-width:480px)": {
                marginTop: 17,
            },
        },
        priceModalInfo: {
            marginBottom: 12,
            color: colors.black,
            fontSize: 13,
            "@media (max-width:480px)": {
                marginBottom: 10,
            },
        },
        priceModalPrice: {
            marginBottom: 33,
            color: colors.black,
            fontSize: '24px',
            "@media (max-width:480px)": {
                fontSize: '18px',
                marginBottom: 21,
            },
        },
        priceModalYouroffer: {
            marginBottom: 24,
            color: colors.black,
            fontSize: '14px',
            "@media (max-width:480px)": {
                marginBottom: 11,
            },
        }
    })
)