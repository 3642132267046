import UnderHeader from "src/components/underHeader/underHeader";
import CInput from "src/cors/input/cInput";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {sellerAddProductStyle} from "./seller-add-product-style";
import {useLocation, useNavigate} from "react-router-dom";
import {ReactComponent as IconWarning} from "src/assets/icons/warning.svg";
import CSelect from "src/cors/select/cSelect";
import CCheckbox from "src/cors/checkbox/cCheckbox";
import Upload from "src/components/upload-image/upload-file";
import CTab from "src/cors/tab/cTab";
import CTextarea from "src/cors/textarea/cTextarea";
import ImageList from "./image-list";
import {checkError} from "src/helpers/check-error";
import {useTranslation} from "react-multi-lang";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {GlobalContext} from "src/context/global-context";
import {errorMessages} from "src/helpers/error-messages";
import UploadVideo from "src/components/uploadVideo/upload-video";
import Cropper from 'react-easy-crop';
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import {CModal} from "src/cors/modal/cModal";
import getCroppedImg, {dataURLtoFile, resizeFile} from "src/components/cropImage/cropImage";

function SellerTemplateSingle() {
    const classes = sellerAddProductStyle()
    const navigation = useNavigate()
    const location = useLocation()
    console.log(location,'log---location')
    const t = useTranslation()
    const {state, setState} = useContext(GlobalContext)
    const userId = state?.userinfo?.role_id
    const [checked, setChecked] = useState(false)
    const [checkedLanguage, setCheckedLanguage] = useState(false)
    const [images, setImages] = useState([]);
    const [video, setVideo] = useState('');
    const [tabLanguage, setTabLanguage] = useState(0)
    const [values, setValues] = useState({
        type: "",
        fineness: '',
        color: '',
        published: '',
        origin: '',
        gemstone: 'no',
        initial_weight: "",
        weight: "",
        product_code: '',
        movement_type: '',
        brand: '',
        bracelet_belt: "",
        country: "",
        condition: 'new'
    })

    const [categories, setCategories] = useState({
        first_category: '',
        metal: '',
        second_category: '',
        state: "ready"
    })
    const [productPrice, setProductPrice] = useState({
        oder_cost: 0,
        cost: '',
        price: 0
    })
    const [stones, setStones] = useState([])
    const [resetStone, setResetStone] = useState(true)
    const [resetAll, setResetAll] = useState(true)
    const [resetCategory, setResetCategory] = useState(true)
    const [resetValues, setResetValues] = useState(true)
    const [resetType, setResetType] = useState(true)
    const [resetBrand, setResetBrand] = useState(true)
    const [newStone, setNewStone] = useState({
        stone: '',
        carat: "",
        // certificate: "",
        certificate_code:"",
        // clarity: '',
        // color: "",
        cut: '',
        diamondPrice: '',
        pcs: ''
    })
    const [en, setEn] = useState({
        name: '',
        description: ''
    })
    const [ru, setRu] = useState({
        name: '',
        description: ''
    })
    const [am, setAm] = useState({
        name: '',
        description: ''
    })

    const [errorValues, setErrorValues] = useState({})
    const [errorStones, setErrorStones] = useState({})
    const [errorEng, setErrorEng] = useState({})
    const [errorRu, setErrorRu] = useState({})
    const [errorAm, setErrorAm] = useState({})
    const [errorLangBorder, setErrorLangBorder] = useState(false)
    const [errorProductPrice, setErrorProductPrice] = useState({})
    const [errorImages, setErrorImages] = useState({message: ''})
    const refScroll = useRef()

    // Cropper States

    const [crop, setCrop] = useState({x: 0, y: 0})
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [cropperModal, setCropperModal] = useState(false);
    const [showImage, setShowImage] = useState({})

    // Cropper functions Start

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, []);
    const cropImage = useCallback(async () => {
        setState({...state, loader: true})
        try {
            const croppedImage = await getCroppedImg(
                showImage?.data_url,
                croppedAreaPixels,
            )
            let myFile = {};
            myFile.file = await resizeFile(dataURLtoFile(croppedImage, showImage?.file?.name));
            myFile.data_url = croppedImage;
            setImages([...images, myFile]);
        } catch (e) {
            console.error(e)
        } finally {
            setState({...state, loader: false})
            setCropperModal(false);
            setZoom(1);
        }
    }, [croppedAreaPixels])

    const handleOpenCropperModal = (imgUrl) => {
        setCropperModal(true);
        setShowImage(imgUrl[0]);
    }

    // Cropper functions End

    const deleteImg = (item) => {
        let arr = []
        for (let i = 0; i < images.length; i++) {
            if (item === images[i].data_url) {
                continue
            } else {
                arr.push(images[i])
            }
        }
        setImages(arr)
    }

    const handleChange = (e, keyName) => {
        if (keyName === 'initial_weight' || keyName === 'weight' || keyName === 'size') {
            if (isNaN(+e)) {
                return
            } else {
                e = +e
            }
        }
        if (keyName === 'type') {
            setResetValues(false)
        }
        if (keyName === 'origin') {
            setResetBrand(false)
        }

        if (keyName === 'product_code') {
            let regex = /^[a-zA-Z0-9_-]*$/

            if (!regex.test(e)) {
                return
            }
        }
        let newValues = {...values}
        newValues[keyName] = e
        setValues({...newValues})
        if (e.length || typeof e === 'number') {
            setErrorValues({...errorValues, [keyName]: ''})
        }
    }
    const handleChangeCategories = (e, keyName) => {
        let newValues = {...categories}
        if (keyName === 'first_category') {
            setResetAll(false)
        }
        if (keyName === 'state') {
            setResetCategory(false)
        }
        if (keyName === 'metal') {
            setResetValues(false)
            setResetType(false)
            setValues({
                type: '',
                fineness: '',
                color: '',
                published: '',
                origin: '',
                gemstone: 'no',
                initial_weight: "",
                weight: "",
                product_code: '',
                movement_type: "",
                brand: '',
                bracelet_belt: "",
                country: "",
                name: "",
                size: "",
                condition: "new"
            })
        }
        if (keyName === 'second_category') {
            setResetType(false)
        }
        if (e === 'souvenir') {
            delete newValues.second_category
        } else {
            newValues.second_category = categories.second_category
        }
        newValues[keyName] = e
        setCategories({...newValues})
    }

    const handleChangeStones = (e, keyName) => {
        let input = e
        if (keyName === 'color' || keyName === 'clarity') {
            if (input.length === 1) {
                input = input[0].value
            } else if (input.length > 1) {
                input = `${input[0].value}-${input[input.length - 1].value}`
            } else {
                input = ''
            }
        }
        if (keyName === 'carat' || keyName === 'pcs' || keyName === 'diamondPrice') {
            if (isNaN(+input)) {
                return
            } else {
                input = +input
            }
        }
        let newValues = {...newStone}
        newValues[keyName] = input
        setNewStone({...newValues})
        if (input.length || typeof input === 'number') {
            setErrorStones({...errorStones, [keyName]: ''})
        }
    }

    const handleChangeProductPrice = (e, keyName) => {
        if (keyName === 'oder_cost' || keyName === 'cost' || keyName === 'price') {
            if (isNaN(+e)) {
                return
            }
        }

        let newValues = {...productPrice}
        newValues[keyName] = e
        setProductPrice({...newValues})
        if (e.length || typeof e === 'number') {
            setErrorProductPrice({...errorProductPrice, [keyName]: ''})
        }
    }

    const handleChangeLange = (e, keyName) => {
        let newValues1 = {...en}
        let newValues2 = {...ru}
        let newValues3 = {...am}
        if (newValues1.name && newValues2.name && newValues3.name) {
            setErrorLangBorder(false)
        }
        if (tabLanguage === 0) {
            newValues1[keyName] = e
            setEn(newValues1)
            if (e.length) {
                setErrorEng({...errorEng, [keyName]: ''})
            }

        } else if (tabLanguage === 1) {
            newValues2[keyName] = e
            setRu(newValues2)
            if (e.length) {
                setErrorRu({...errorRu, [keyName]: ''})
            }
        } else if (tabLanguage === 2) {
            newValues3[keyName] = e
            setAm(newValues3)
            if (e.length) {
                setErrorAm({...errorAm, [keyName]: ''})
            }
        }
    }

    const request = (is_template) => {
        let stonesCheck = [...stones]
        if (images.length > 0) {
            if (newStone.carat && newStone.pcs && newStone.color && newStone.clarity && newStone.cut && newStone.certificate_code && newStone.diamondPrice) {
                let newObj = {
                    stone: newStone.carat,
                    carat: newStone.carat,
                    // certificate: newStone.certificate,
                    certificate_code: newStone.certificate_code,
                    clarity: newStone.clarity,
                    color: newStone.color,
                    cut: newStone.cut,
                    diamondPrice: newStone.diamondPrice,
                    pcs: newStone.pcs
                }
                stonesCheck.push(newObj)
            }
            const finallyData = {
                state: categories.state ? categories.state : 'ready',
                ...values,
                ...categories,
                oder_cost: productPrice.oder_cost ? +productPrice.oder_cost : 0,
                cost: productPrice.cost ? +productPrice.cost : 0,
                price: productPrice.price ? +productPrice.price : 0,
                stones: stonesCheck.length ? stonesCheck : '',
                translation_data: checkedLanguage ?
                    {
                        en: {description: en.description, name: 'auto'},
                        ru: {description: ru.description, name: 'auto',},
                        am: {description: am.description, name: 'auto'}
                    } : {en, ru, am}
            }
            Object.keys(finallyData).forEach((elem, index) => {
                if (!finallyData[elem].length && typeof finallyData[elem] !== 'number' && typeof finallyData[elem] !== 'object') {
                    delete finallyData[elem]
                }
            })
            if (checkedLanguage) {
                finallyData['autogenerate_name'] = true
            }
            const formData = new FormData();
            images.forEach((item, index) => {
                formData.append("files", images[index]?.file);
            })
            if (video) {
                formData.append("files", video);
            }
            formData.append("data", JSON.stringify(finallyData));
            setState({...state, loader: true})
            console.log(finallyData,'finallyData')
            if (is_template){
                Axios.post('product/template/create', formData).then(res => {

                    toast.success(t("notification.product_added"))
                    navigation('/sellerProduct')
                }).catch(err => {
                    toast.error(errorMessages(err?.response?.data?.code))
                    console.log(err, 'err')
                }).finally(() => {
                    setState({...state, loader: false})
                })
            }else {
                Axios.post('product/add', formData).then(res => {
                    toast.success(t("notification.product_added"))
                    navigation('/sellerProduct')
                }).catch(err => {
                    toast.error(errorMessages(err?.response?.data?.code))
                    console.log(err, 'err')
                }).finally(() => {
                    setState({...state, loader: false})
                })
            }

        } else {
            setErrorImages({message: t("please_add_image")})
        }
    }

    const saveProduct = () => {
        if ((en.name && ru.name && am.name) || checkedLanguage) {
            if (categories.first_category === 'jewelry' && categories.metal === 'silver') {
                if (values.type === 'ring' || values.type === 'wed_ring' || values.type === 'eng_ring' || values.type === 'hard_bracelet' || values.type === 'chain_bracelet' || values.type === 'chain') {
                    if (values.type && values.fineness && values.color && values.published && values.origin && values.weight && values.product_code && values.size) {
                        request()
                        return
                    } else {
                        checkError(values, setErrorValues)
                    }
                }
                if (values.type && values.fineness && values.color && values.published && values.origin && values.weight && values.product_code) {
                    request()
                    return

                } else {
                    checkError(values, setErrorValues)
                }
            }
            if (categories.first_category === 'jewelry') {
                if (values.type === 'ring' || values.type === 'wed_ring' || values.type === 'eng_ring' || values.type === 'hard_bracelet' || values.type === 'chain_bracelet' || values.type === 'chain') {
                    if (values.type && values.fineness && values.color && values.published && values.origin && values.initial_weight && values.weight && values.product_code && values.size) {
                        request()
                        return
                    } else {
                        checkError(values, setErrorValues)
                    }
                }
                if (values.type && values.fineness && values.color && values.published && values.origin && values.initial_weight && values.weight && values.product_code) {
                    request()
                    return

                } else {
                    checkError(values, setErrorValues)
                }
            }
            if (categories.first_category === 'watches' && categories.metal === 'gold') {
                if (values.fineness && values.color && values.published && values.origin && values.initial_weight && values.weight && values.brand && values.movement_type && values.bracelet_belt && values.product_code) {
                    request()
                    return

                } else {
                    checkError(values, setErrorValues)
                }
            }

            if (categories.first_category === 'watches' && categories.metal === 'silver') {
                if (values.fineness && values.color && values.published && values.origin && values.weight && values.brand && values.movement_type && values.bracelet_belt && values.product_code) {
                    request()
                    return
                } else {
                    checkError(values, setErrorValues)
                }
            }
            if (categories.first_category === 'watches' && categories.metal === 'other') {
                if (values.color && values.published && values.origin && values.brand && values.movement_type && values.bracelet_belt && values.product_code) {
                    request()
                    return
                } else {
                    checkError(values, setErrorValues)
                }
            }
            if (categories.first_category === 'souvenir') {
                if (categories.metal === 'gold') {
                    if (values.type === 'coin') {
                        if (values.fineness && values.published && values.weight && values.product_code && values.country) {
                            request()
                            return
                        } else {
                            checkError(values, setErrorValues)
                        }
                    } else {
                        checkError(values, setErrorValues)
                    }

                    if (values.type === 'bars') {
                        if (values.fineness && values.published && values.weight && values.product_code && values.name) {
                            request()
                            return
                        } else {
                            checkError(values, setErrorValues)
                        }
                    } else {
                        checkError(values, setErrorValues)
                    }
                    if (values.type === 'antique') {
                        if (values.published && values.product_code) {
                            request()
                            return
                        } else {
                            checkError(values, setErrorValues)
                        }
                    } else {
                        checkError(values, setErrorValues)
                    }

                } else {
                    checkError(values, setErrorValues)
                }
                if (categories.metal === 'silver') {
                    if (values.type === 'coin') {
                        if (values.fineness && values.published && values.weight && values.product_code) {
                            request()
                            return
                        } else {
                            checkError(values, setErrorValues)
                        }
                    } else {
                        checkError(values, setErrorValues)
                    }

                    if (values.type === 'bars') {
                        if (values.fineness && values.published && values.weight && values.product_code && values.name) {
                            request()
                            return
                        } else {
                            checkError(values, setErrorValues)
                        }
                    } else {
                        checkError(values, setErrorValues)
                    }
                    if (values.type === 'antique') {
                        if (values.published && values.product_code) {
                            request()
                            return
                        } else {
                            checkError(values, setErrorValues)
                        }
                    } else {
                        checkError(values, setErrorValues)
                    }
                } else {
                    checkError(values, setErrorValues)
                }
                if (categories.metal === 'other') {
                    if (values.type === 'coin') {
                        if (values.published && values.weight && values.product_code) {
                            request()
                            return
                        } else {
                            checkError(values, setErrorValues)
                        }
                    } else {
                        checkError(values, setErrorValues)
                    }
                    if (values.type === 'antique') {
                        if (values.published && values.product_code) {
                            request()
                            return
                        } else {
                            checkError(values, setErrorValues)
                        }
                    } else {
                        checkError(values, setErrorValues)
                    }

                } else {
                    checkError(values, setErrorValues)
                }
            } else {
                checkError(values, setErrorValues)
            }
        } else {
            if (!checkedLanguage) {
                window.scrollTo(0, 0);
                setErrorLangBorder(true)
                checkError(en, setErrorEng)
                checkError(ru, setErrorRu)
                checkError(am, setErrorAm)
            }
        }
    }

    const addNewStone = () => {
        if (newStone.stone && newStone.carat && newStone.pcs  && newStone.cut  && newStone.diamondPrice) {
            setResetStone(false)
            setErrorStones({})
            let arr = [...stones]
            arr.push(newStone)
            setStones(arr)
        } else {
            checkError(newStone, setErrorStones)
        }
    }

    const deleteStone = (index) => {
        let arr = []
        for (let i = 0; i < stones.length; i++) {
            if (i === index) {
                continue
            } else {
                arr.push(stones[i])
            }
        }
        setStones(arr)
    }

    const handleCalculatePrice = () => {
        setState({...state, loader: true})
        Axios.post(`product/calculate_price`, {
            "fineness": values.fineness,
            "initial_weight": categories.metal === 'silver' || values.type === 'coin' || values.type === 'bars' ? values.weight : values.initial_weight?  +values.initial_weight : 0,
            "metal": categories.metal,
            "oder_cost": +productPrice.oder_cost,
            "stones": !!stones.length,
            "type": values.type
        }).then(res => {
            let allStonesPrices = 0
            stones.forEach(item => {
                if (item.diamondPrice) {
                    allStonesPrices = allStonesPrices + (+item.diamondPrice)
                }
            })
            setProductPrice({...productPrice, cost: res?.data?.amount + (+productPrice.oder_cost + allStonesPrices)})
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setState({...state, loader: false})
        })
    }
    useEffect(() => {
        if (!resetStone) {
            setResetStone(true)
            setNewStone({
                stone: '',
                carat: "",
                // certificate: "",
                certificate_code: "",
                clarity: '',
                color: "",
                cut: '',
                diamondPrice: '',
                pcs: ''
            })
        }
    }, [resetStone])

    useEffect(() => {
        if (!resetBrand) {
            setResetBrand(true)
            setValues({
                ...values,
                brand: ''
            })
        }
    }, [resetBrand])

    useEffect(() => {
        if (!resetCategory) {
            setResetCategory(true)
            setCategories({
                first_category: '',
                second_category: '',
                metal: '',
                state: categories.state
            })
        }
    }, [resetCategory])

    useEffect(() => {
        if (!resetType) {
            setResetType(true)
            setValues({
                type: '',
                fineness: '',
                color: '',
                published: '',
                origin: '',
                gemstone: 'no',
                initial_weight: "",
                weight: "",
                product_code: '',
                movement_type: "",
                brand: '',
                bracelet_belt: "",
                country: "",
                name: '',
                size: "",
                condition: 'new'
            })
        }
    }, [resetType])

    useEffect(() => {
        if (!resetValues) {
            setResetValues(true)
            setValues({
                type: values.type,
                fineness: '',
                color: '',
                published: '',
                origin: '',
                initial_weight: "",
                weight: "",
                product_code: '',
                gemstone: 'no',
                movement_type: "",
                brand: '',
                bracelet_belt: "",
                country: '',
                name: "",
                size: "",
                condition: 'new'
            })
        }
    }, [resetValues])

    useEffect(() => {
        if (!resetAll) {
            setResetAll(true)
            setValues({
                type: "",
                fineness: '',
                color: '',
                published: '',
                origin: '',
                initial_weight: "",
                weight: "",
                product_code: '',
                gemstone: 'no',
                movement_type: "",
                brand: '',
                bracelet_belt: "",
                country: "",
                condition: 'new'
            })
            setCategories({
                first_category: categories.first_category,
                metal: '',
                second_category: '',
                state: categories.state
            })
        }
    }, [resetAll])

    useEffect(()=>{

    },[])

    return (
        <div className={classes.container} ref={refScroll}>
            <UnderHeader
                goBack={() => navigation('/sellerProduct')}
                back={true}
                title={t("seller_product.add_product")}
            />
            <div className={classes.addProductContainer}>
                <div style={errorLangBorder ? {border: `1px solid ${colors.red}`} : {}}
                     className={classes.addProductContainerBlock}>
                    <CTab
                        onPress={setTabLanguage}
                        active={tabLanguage}
                        items={[{value: 'ENG', key: 0}, {value: 'Рус', key: 1}, {value: 'Հայ', key: 2}]}
                    />
                    <div className={classes.checkBoxUndertabWrapp}>
                        <CCheckbox onChange={() => {
                            setErrorLangBorder(false)
                            setErrorAm({name: '', description: ""})
                            setErrorEng({name: '', description: ""})
                            setErrorRu({name: '', description: ""})
                            setEn({name: '', description: ""})
                            setRu({name: '', description: ""})
                            setAm({name: '', description: ""})
                            setCheckedLanguage(!checkedLanguage)
                        }} checked={checkedLanguage}
                        />
                        <p>{t("seller_product.auto_generate_name")}</p>
                    </div>
                    {tabLanguage === 0 && <>
                        <CInput
                            errorMessage={errorEng.name}
                            disabled={checkedLanguage}
                            value={en.name}
                            onChange={(e) => handleChangeLange(e.target.value, 'name')}
                            placeholder={t("seller_product.product_name")}
                            label={t("seller_product.product_name")}
                        />
                        <div className={classes.textAreaWrapp}>
                            <CTextarea
                                disabled={checkedLanguage}
                                value={en.description}
                                onChange={(e) => handleChangeLange(e.target.value, 'description')}
                                placeholder={t("seller_product.product_description")}
                                label={t("seller_product.product_description")}
                            />
                        </div>
                    </>}
                    {tabLanguage === 1 && <>
                        <CInput
                            errorMessage={errorRu.name}
                            disabled={checkedLanguage}
                            value={ru.name}
                            onChange={(e) => handleChangeLange(e.target.value, 'name')}
                            placeholder={t("seller_product.product_name")}
                            label={t("seller_product.product_name")}
                        />
                        <div className={classes.textAreaWrapp}>
                            <CTextarea
                                disabled={checkedLanguage}
                                value={ru.description}
                                onChange={(e) => handleChangeLange(e.target.value, 'description')}
                                placeholder={t("seller_product.product_description")}
                                label={t("seller_product.product_description")}
                            />
                        </div>
                    </>}
                    {tabLanguage === 2 && <>
                        <CInput
                            errorMessage={errorAm.name}
                            disabled={checkedLanguage}
                            value={am.name}
                            onChange={(e) => handleChangeLange(e.target.value, 'name')}
                            placeholder={t("seller_product.product_name")}
                            label={t("seller_product.product_name")}
                        />
                        <div className={classes.textAreaWrapp}>
                            <CTextarea
                                disabled={checkedLanguage}
                                value={am.description}
                                onChange={(e) => handleChangeLange(e.target.value, 'description')}
                                placeholder={t("seller_product.product_description")}
                                label={t("seller_product.product_description")}
                            />
                        </div>
                    </>}

                </div>
                <div className={classes.addProductContainerBlock}>
                    <CTab items={[{value: t("seller_product.select_product_category"), key: 0}]}
                        onPress={() => console.log('Select product category')} />
                    <div className={classes.clearBothUnderTab}/>
                    {userId === 3 &&
                        <CSelect
                            defaultValue={{label: t("seller_product.ready"), value: "ready"}}
                            options={
                                [
                                    {label: t("seller_product.ready"), value: "ready"},
                                    {label: t("seller_product.mounting"), value: "not_ready"},
                                ]
                            }
                            onChange={(e) => handleChangeCategories(e.value, 'state')}
                            label={t("seller_product.state")}
                            placeholder={t("seller_product.state")}
                        />}
                    {resetCategory &&
                        <CSelect
                            value={categories.first_category}
                            options={
                                categories.state === 'not_ready' ? [{
                                    label: t("category.jewelery"),
                                    value: "jewelry"
                                },] : [
                                    {label: t("category.jewelery"), value: "jewelry"},
                                    {label: t("category.watches"), value: "watches"},
                                    {label: t("category.souvenir"), value: "souvenir"}
                                ]}
                            onChange={(e) => handleChangeCategories(e.value, 'first_category')}
                            label={t("category.category")}
                            placeholder={t("category.category")}
                        />}
                    {resetAll && <>
                        {categories.first_category !== 'souvenir' &&
                            <CSelect
                                options={
                                    categories.first_category === 'watches' || categories.state === 'not_ready' ? [
                                        {label: t("for_whom.woman"), value: "woman"},
                                        {label: t("for_whom.man"), value: "man"},
                                    ] : [
                                        {label: t("for_whom.woman"), value: "woman"},
                                        {label: t("for_whom.man"), value: "man"},
                                        {label: t("for_whom.boy"), value: "boy"},
                                        {label: t("for_whom.girl"), value: "girl"}
                                    ]
                                }
                                onChange={(e) => handleChangeCategories(e.value, 'second_category')}
                                label={t("for_whom.for_whom")}
                                placeholder={t("for_whom.for_whom")}
                            />}
                        {categories.state === 'not_ready' ? <CSelect
                                options={[{label: t("metals.gold"), value: "gold"}]}
                                onChange={(e) => handleChangeCategories(e.value, 'metal')}
                                label={t("metals.metals")}
                                placeholder={t("metals.metals")}
                            /> :
                            <CSelect
                                options={
                                    categories.first_category === 'watches' || categories.first_category === 'souvenir' ? [
                                        {label: t("metals.gold"), value: "gold"},
                                        {label: t("metals.silver"), value: "silver"},
                                        {label: t("metals.other"), value: 'other'},
                                    ] : [{label: t("metals.gold"), value: "gold"},
                                        {label: t("metals.silver"), value: "silver"},
                                    ]
                                }
                                onChange={(e) => handleChangeCategories(e.value, 'metal')}
                                label={t("metals.metals")}
                                placeholder={t("metals.metals")}
                            />}

                    </>}
                </div>
            </div>
            {categories.first_category && categories.metal ?
                <div className={classes.containerSettings}>
                    <p className={classes.title}>{t("seller_product.add_product_details")}</p>
                    <div className={classes.inputBlock}>
                        {(categories.state === 'not_ready') &&
                            <div className={classes.selectWrapp}>
                                {resetType &&
                                    <CSelect
                                        errorMessage={errorValues.type}
                                        onChange={(e) => handleChange(e.value, 'type')}
                                        label={t("type.type")}
                                        placeholder={t("type.type")}
                                        options={[
                                            {label: t("type.ring"), value: "ring"}, // all
                                            {label: t("type.eng_ring"), value: "eng_ring"}, // woman
                                            {label: t("type.necklace"), value: "necklace"}, // woman
                                            {label: t("type.cross"), value: "cross"}, // woman man
                                            {label: t("type.earring"), value: "earring"}, // woman , girl
                                            {label: t("type.pendant"), value: "pendant"}, // all
                                            {label: t("type.hard_bracelet"), value: "hard_bracelet"}, // woman
                                            {label: t("type.chain_bracelet"), value: "chain_bracelet"}, // man woman
                                            {label: t("type.other"), value: "other"}, //woman
                                        ]}
                                    />}
                            </div>}
                        {(categories.state === 'not_ready') || (categories.first_category === 'watches') || (categories.first_category === 'souvenir') ? null :
                            <div className={classes.selectWrapp}>
                                {resetType &&
                                    <CSelect
                                        errorMessage={errorValues.type}
                                        options={
                                            categories.metal === 'silver' ?
                                                categories.second_category === 'man' ? [
                                                    {label: t("type.ring"), value: "ring"},
                                                    {label: t("type.cross"), value: "cross"},
                                                    {label: t("type.pendant"), value: "pendant"},
                                                    {label: t("type.chain"), value: "chain"},
                                                    {label: t("type.chain_bracelet"), value: "chain_bracelet"},
                                                    {label: t("type.hard_bracelet"), value: "hard_bracelet"},
                                                    {label: t("type.other"), value: "other"},
                                                ] : categories.second_category === 'girl' ? [
                                                    {label: t("type.ring"), value: "ring"},
                                                    {label: t("type.earring"), value: "earring"},
                                                    {label: t("type.pendant"), value: "pendant"},
                                                    {label: t("type.chain_bracelet"), value: "chain_bracelet"},
                                                    {label: t("type.hard_bracelet"), value: "hard_bracelet"},
                                                    {label: t("type.other"), value: "other"},
                                                ] : categories.second_category === 'boy' ?
                                                    [
                                                        {label: t("type.ring"), value: "ring"},
                                                        {label: t("type.pendant"), value: "pendant"},
                                                        {label: t("type.chain_bracelet"), value: "chain_bracelet"},
                                                        {label: t("type.hard_bracelet"), value: "hard_bracelet"},
                                                        {label: t("type.other"), value: "other"},
                                                    ] : [
                                                        {label: t("type.ring"), value: "ring"}, // all
                                                        {label: t("type.necklace"), value: "necklace"}, // woman
                                                        {label: t("type.cross"), value: "cross"}, // woman man
                                                        {label: t("type.chain"), value: "chain"},
                                                        {label: t("type.earring"), value: "earring"}, // woman , girl
                                                        {label: t("type.pendant"), value: "pendant"}, // all
                                                        {label: t("type.hard_bracelet"), value: "hard_bracelet"}, // woman
                                                        {label: t("type.chain_bracelet"), value: "chain_bracelet"}, // man woman
                                                        {label: t("type.other"), value: "other"}, //woman
                                                    ] : categories.second_category === 'man' ? [
                                                    {label: t("type.ring"), value: "ring"},
                                                    {label: t("type.wed_ring"), value: "wed_ring"},
                                                    {label: t("type.cross"), value: "cross"},
                                                    {label: t("type.pendant"), value: "pendant"},
                                                    {label: t("type.chain"), value: "chain"},
                                                    {label: t("type.chain_bracelet"), value: "chain_bracelet"},
                                                    {label: t("type.hard_bracelet"), value: "hard_bracelet"},
                                                    {label: t("type.other"), value: "other"},
                                                ] : categories.second_category === 'girl' ? [
                                                    {label: t("type.ring"), value: "ring"},
                                                    {label: t("type.earring"), value: "earring"},
                                                    {label: t("type.pendant"), value: "pendant"},
                                                    {label: t("type.chain_bracelet"), value: "chain_bracelet"},
                                                    {label: t("type.hard_bracelet"), value: "hard_bracelet"},
                                                    {label: t("type.other"), value: "other"},
                                                ] : categories.second_category === 'boy' ?
                                                    [
                                                        {label: t("type.ring"), value: "ring"},
                                                        {label: t("type.pendant"), value: "pendant"},
                                                        {label: t("type.chain_bracelet"), value: "chain_bracelet"},
                                                        {label: t("type.hard_bracelet"), value: "hard_bracelet"},
                                                        {label: t("type.other"), value: "other"},
                                                    ] : [
                                                        {label: t("type.ring"), value: "ring"}, // all
                                                        {label: t("type.wed_ring"), value: "wed_ring"}, // woman , man
                                                        {label: t("type.eng_ring"), value: "eng_ring"}, // woman
                                                        {label: t("type.necklace"), value: "necklace"}, // woman
                                                        {label: t("type.cross"), value: "cross"}, // woman man
                                                        {label: t("type.chain"), value: "chain"},
                                                        {label: t("type.earring"), value: "earring"}, // woman , girl
                                                        {label: t("type.pendant"), value: "pendant"}, // all
                                                        {label: t("type.hard_bracelet"), value: "hard_bracelet"}, // woman
                                                        {label: t("type.chain_bracelet"), value: "chain_bracelet"}, // man woman
                                                        {label: t("type.other"), value: "other"}, //woman
                                                    ]
                                        }
                                        onChange={(e) => handleChange(e.value, 'type')}
                                        label={t("type.type")}
                                        placeholder={t("type.type")}
                                    />}
                            </div>}
                        {categories.first_category === 'souvenir' &&
                            <div className={classes.selectWrapp}>
                                {resetType &&
                                    <CSelect
                                        errorMessage={errorValues.type}
                                        options={
                                            categories.metal === 'gold' ? [
                                                {label: t("type.coin"), value: "coin"},
                                                {label: t("type.bars"), value: "bars"},
                                                {label: t("type.antique"), value: "antique"},
                                            ] : categories.metal === 'silver' ? [
                                                {label: t("type.coin"), value: "coin"},
                                                {label: t("type.antique"), value: "antique"},
                                            ] : [
                                                {label: t("type.antique"), value: "antique"},
                                            ]
                                        }
                                        onChange={(e) => handleChange(e.value, 'type')}
                                        label={t("type.type")}
                                        placeholder={t("type.type")}
                                    />}
                            </div>}
                        {resetValues && <>
                            {(categories.first_category === 'watches' && categories.metal === 'other') || categories.first_category === 'souvenir' ? null :
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        errorMessage={errorValues.fineness}
                                        options={
                                            categories.metal === 'gold' ? (categories.first_category === 'watches') || (categories.state === 'not_ready') ?
                                                [
                                                    {label: '750', value: "g750"},
                                                    {label: '585', value: "g585"},
                                                ] : [
                                                    {label: '999.9', value: "g999.9"},
                                                    {label: '958', value: "g958"},
                                                    {label: '916', value: "g916"},
                                                    {label: '875', value: "g875"},
                                                    {label: '750', value: "g750"},
                                                    {label: '585', value: "g585"},
                                                ] : categories.metal === 'silver' ? categories.first_category === 'watches' ?
                                                [{label: '925', value: "s925"}] :
                                                [
                                                    {label: '925', value: "s925"}] : null
                                        } onChange={(e) => handleChange(e.value, 'fineness')}
                                        label={t("seller_product.fineness")}
                                        placeholder={t("seller_product.fineness")}
                                    />
                                </div>}

                            {values.type !== 'bars' && categories.first_category === 'souvenir' && categories.metal !== 'other' &&
                                values.type !== 'antique' && <div className={classes.selectWrapp}>
                                    <CSelect
                                        errorMessage={errorValues.fineness}
                                        options={
                                            categories.metal === 'gold' ? [
                                                {label: '999.9', value: "g999.9"},
                                                {label: '986', value: "g986"},
                                                {label: '916', value: "g916"},
                                                {label: '900', value: "g900"},
                                                {label: '875', value: "g875"},
                                                {label: '750', value: "g750"},
                                                {label: '585', value: "g585"},
                                            ] : categories.metal === 'silver' ?
                                                [
                                                    {label: '999', value: "s999"},
                                                    {label: '925', value: "s925"},
                                                    {label: '900', value: "s900"},
                                                ] : null

                                        } onChange={(e) => handleChange(e.value, 'fineness')}
                                        label={t("seller_product.fineness")}
                                        placeholder={t("seller_product.fineness")}/>
                                </div>}

                            {values.type === 'bars' &&
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        errorMessage={errorValues.fineness}
                                        options={
                                            [
                                                {label: '999', value: "g999"},
                                                {label: '999.9', value: "g999.9"},
                                            ]
                                        } onChange={(e) => handleChange(e.value, 'fineness')}
                                        label={t("seller_product.fineness")}
                                        placeholder={t("seller_product.fineness")}/>
                                </div>}

                            {categories.metal === 'gold' && values.type === 'coin' &&
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        errorMessage={errorValues.country}
                                        options={
                                            [
                                                {label: t("country.armenian"), value: "armenian"},
                                                {label: t("country.russian"), value: "russian"},
                                                {label: t("country.english"), value: "english"},
                                                {label: t("country.french"), value: "french"},
                                                {label: t("country.lebanon"), value: "lebanon"},
                                                {label: t("country.american"), value: "american"},
                                                {label: t("country.other"), value: "other"},
                                            ]
                                        }
                                        onChange={(e) => handleChange(e.value, 'country')}
                                        label={t("seller_product.country")}
                                        placeholder={t("seller_product.country")}
                                    />
                                </div>}
                            {categories.first_category !== 'souvenir' && <div className={classes.selectWrapp}>
                                <CSelect
                                    errorMessage={errorValues.color}
                                    options={
                                        categories.metal === 'silver' ? [
                                            {label: t('colors.silver'), value: "silver"},
                                            {label: t('colors.gold_plated'), value: "gold_plated"},
                                            {label: t('colors.rosegold_plated'), value: "rosegold_plated"},
                                            {label: t('colors.rhodium_plated'), value: "rhodium_plated"},
                                            {label: t('colors.two_tone'), value: "two_tone"},
                                            {label: t('colors.three_tone'), value: "three_tone"},
                                            {label: t('colors.other'), value: "other"},
                                        ] : [
                                            {label: t('colors.yellow'), value: "yellow"},
                                            {label: t('colors.white'), value: "white"},
                                            {label: t('colors.red'), value: "red"},
                                            {label: t('colors.pink'), value: "pink"},
                                            {label: t('colors.two_tone'), value: "two_tone"},
                                            {label: t('colors.three_tone'), value: "three_tone"},
                                            {label: t('colors.other'), value: "other"},
                                        ]
                                    } onChange={(e) => handleChange(e.value, 'color')}
                                    label={t("seller_product.color")}
                                    placeholder={t("seller_product.color")}/>
                            </div>}
                            <div className={classes.selectWrapp}>
                                <CSelect
                                    errorMessage={errorValues.published}
                                    options={
                                        [
                                            {label: t("seller_product.publish"), value: "publish"},
                                            {label: t("seller_product.no_publish"), value: "no_publish"},
                                            {label: t("seller_product.by_order"), value: "by_order"},
                                        ]
                                    }
                                    onChange={(e) => handleChange(e.value, 'published')}
                                    label={t("seller_product.published")}
                                    placeholder={t("seller_product.published")}
                                />
                            </div>
                            {categories.first_category !== 'souvenir' && <div className={classes.selectWrapp}>
                                <CSelect
                                    errorMessage={errorValues.origin}
                                    options={
                                        [
                                            {label: t("seller_product.armenian"), value: "armenian"},
                                            {label: t("seller_product.imported"), value: "imported"},
                                        ]
                                    }
                                    onChange={(e) => handleChange(e.value, 'origin')}
                                    label={t("seller_product.origin")}
                                    placeholder={t("seller_product.origin")}
                                />
                            </div>}
                            {(categories.first_category === 'watches' || categories.first_category === 'souvenir' ) && <div className={classes.selectWrapp}>
                                <CSelect

                                    options={
                                        [
                                            {label: t("seller_product.new"), value: "new"},
                                            {label: t("seller_product.used"), value: "used"},
                                        ]
                                    }
                                    onChange={(e) => handleChange(e.value, 'condition')}
                                    label={t("seller_product.condition")}
                                    placeholder={t("seller_product.condition")}
                                />
                            </div>}

                            {(categories.metal === 'silver') || (categories.first_category === 'watches' && categories.metal === 'other') || (categories.first_category === 'souvenir') ? null :
                                <div className={classes.selectWrapp}>
                                    <CInput
                                        type={'number'}
                                        errorMessage={errorValues.initial_weight}
                                        onChange={(e) => handleChange(e.target.value, 'initial_weight')}
                                        label={t("seller_product.initial_weight")}
                                        placeholder={t("seller_product.initial_weight")}
                                    />
                                </div>}
                            {categories.first_category === 'watches' && categories.metal === 'other' ? null :
                                values.type !== 'bars' && values.type !== 'antique' &&
                                <div className={classes.selectWrapp}>
                                    <CInput
                                        type={'number'}
                                        errorMessage={errorValues.weight}
                                        onChange={(e) => handleChange(e.target.value, 'weight')}
                                        label={t("seller_product.weight")}
                                        placeholder={t("seller_product.weight")}
                                    />
                                </div>}
                            {values.type === 'bars' &&
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        errorMessage={errorValues.weight}
                                        options={
                                            [
                                                {label: '1', value: "1"},
                                                {label: '2', value: "2"},
                                                {label: '2.5', value: "2.5"},
                                                {label: '5', value: "5"},
                                                {label: '10', value: "10"},
                                                {label: '15.55', value: "15.55"},
                                                {label: '20', value: "20"},
                                                {label: '31.1', value: "31.1"},
                                                {label: '50', value: "50"},
                                                {label: '62.2', value: "62.2"},
                                                {label: '100', value: "100"},
                                                {label: 'other', value: "other"},
                                            ]
                                        }
                                        onChange={(e) => handleChange(e.value, 'weight')}
                                        label={t("seller_product.weight")}
                                        placeholder={t("seller_product.weight")}/>
                                </div>}

                            {values.type === 'bars' &&
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        errorMessage={errorValues.name}
                                        options={
                                            [
                                                {label: 'P.A.M.P', value: "P.A.M.P"},
                                                {label: 'Heraeus', value: "heraeus"},
                                                {label: 'Munze Osterich', value: "munze_osterich"},
                                                {label: 'Argor Heraeus', value: "argor_heraeus"},
                                                {label: 'Perth Mint', value: "perth_mint"},
                                                {label: 'Valcambi', value: "valcambi"},
                                                {label: 'UBS', value: "ubs"},
                                                {label: 'Russia', value: "russia"},
                                                {label: 'Commerzbank', value: "commerzbank"},
                                                {label: 'Credit Suisse', value: "credit_suisse"},
                                                {label: 'other', value: "other"},
                                            ]
                                        }
                                        onChange={(e) => handleChange(e.value, 'name')}
                                        label={t("seller_product.name")}
                                        placeholder={t("seller_product.name")}
                                    />
                                </div>}

                            {(values.type === 'chain_bracelet' || values.type === 'chain' || values.type === 'hard_bracelet') &&
                                <div className={classes.selectWrapp}>
                                    <CInput
                                        errorMessage={errorValues.size}
                                        onChange={(e) => handleChange(e.target.value, 'size')}
                                        type={'number'}
                                        label={`${t("seller_product.size")} (${t('seller_product.cm')})`}
                                        placeholder={`${t("seller_product.size")} (${t('seller_product.cm')})`}
                                    />
                                </div>}
                            {(values.type === 'ring' || values.type === 'wed_ring' || values.type === 'eng_ring') &&
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        errorMessage={errorValues.size}
                                        options={
                                            [
                                                {label: '8', value: 8},
                                                {label: '8.5', value: 8.5},
                                                {label: '9', value: 9},
                                                {label: '9.5', value: 9.5},
                                                {label: '10', value: 10},
                                                {label: '10.5', value: 10.5},
                                                {label: '11', value: 11},
                                                {label: '11.5', value: 11.5},
                                                {label: '12', value: 12},
                                                {label: '12.5', value: 12.5},
                                                {label: '13', value: 13},
                                                {label: '13.5', value: 13.5},
                                                {label: '14', value: 14},
                                                {label: '14.5', value: 14.5},
                                                {label: '15', value: 15},
                                                {label: '15.5', value: 15.5},
                                                {label: '16', value: 16},
                                                {label: '16.5', value: 16.5},
                                                {label: '17', value: 17},
                                                {label: '17.5', value: 17.5},
                                                {label: '18', value: 18},
                                                {label: '18.5', value: 18.5},
                                                {label: '19', value: 19},
                                                {label: '19.5', value: 19.5},
                                                {label: '20', value: 20},
                                                {label: '20.5', value: 20.5},
                                                {label: '21', value: 21},
                                                {label: '21.5', value: 21.5},
                                                {label: '22', value: 22},
                                                {label: '22.5', value: 22.5},
                                                {label: '23', value: 23},
                                                {label: '23.5', value: 23.5},
                                                {label: '24', value: 24},
                                                {label: '24.5', value: 24.5},
                                                {label: '25', value: 25},
                                                {label: '25.5', value: 25.5},
                                            ]
                                        }
                                        onChange={(e) => handleChange(e.value, 'size')}
                                        label={`${t("seller_product.size")} (${t('seller_product.dm')})`}
                                        placeholder={`${t("seller_product.size")} (${t('seller_product.dm')})`}
                                    />
                                </div>}
                            {(categories.first_category === 'watches') &&
                                <div className={classes.selectWrapp}>
                                    {resetBrand &&
                                        <CSelect
                                            isDisabled={!values.origin}
                                            errorMessage={errorValues.brand}
                                            options={
                                                values.origin === 'armenian' ? [
                                                    {label: 'Gnomon', value: 'Gnomon'},
                                                    {label: 'Nairi', value: 'Nairi'},
                                                    {label: 'Nari', value: 'Nari'},
                                                    {label: 'AWI', value: 'AWI'},
                                                    {label: t('seller_product.other'), value: 'other'},
                                                ] : [
                                                    {label: 'Montblanc', value: 'Montblanc'},
                                                    {label: 'Van Cleef & Arpels', value: 'Van Cleef & Arpels'},
                                                    {label: 'Jaeger-LeCoultre', value: 'Jaeger-LeCoultre'},
                                                    {label: 'Audemars Piguet', value: 'Audemars Piguet'},
                                                    {label: 'Frédérique Constant', value: 'Frédérique Constant'},
                                                    {label: 'Piaget', value: 'Piaget'},
                                                    {label: 'Cartier', value: 'Cartier'},
                                                    {label: 'Harry Winston', value: 'Harry Winston'},
                                                    {label: 'Blancpain', value: 'Blancpain'},
                                                    {label: 'Longines', value: 'Longines'},
                                                    {label: 'Zenith', value: 'Zenith'},
                                                    {label: 'Tudor', value: 'Tudor'},
                                                    {label: 'Omega', value: 'Omega'},
                                                    {label: 'Rolex', value: 'Rolex'},
                                                    {label: 'Patek Philippe', value: 'Patek Philippe'},
                                                    {label: 'Vacheron Constantin', value: 'Vacheron Constantin'},
                                                    {label: 'Tag Heuer', value: 'Tag Heuer'},
                                                    {label: 'Panerai', value: 'Panerai'},
                                                    {label: 'Hublot', value: 'Hublot'},
                                                    {label: 'Breitling', value: 'Breitling'},
                                                    {label: 'Chopard', value: 'Chopard'},
                                                    {label: 'MeisterSinger', value: 'MeisterSinger'},
                                                    {label: 'Bulgari', value: 'MeisterSinger'},
                                                    {label: 'Breguet', value: 'Breguet'},
                                                    {label: 'Verdure Watches', value: 'Verdure Watches'},
                                                    {label: 'Nika', value: 'Nika'},
                                                    {label: t('seller_product.other'), value: 'other'},
                                                ]
                                            }
                                            onChange={(e) => handleChange(e.value, 'brand')}
                                            label={t("seller_product.brand")}
                                            placeholder={t("seller_product.brand")}
                                        />}
                                </div>}
                            {(categories.first_category === 'watches') &&
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        errorMessage={errorValues.movement_type}
                                        options={
                                            [
                                                {label: t('seller_product.mechanical'), value: "mechanical"},
                                                {label:  t('seller_product.automatic'), value: "automatic"},
                                                {label:  t('seller_product.other'), value: "other"},
                                            ]
                                        }
                                        onChange={(e) => handleChange(e.value, 'movement_type')}
                                        label={t("seller_product.movement_type")}
                                        placeholder={t("seller_product.movement_type")}
                                    />
                                </div>}
                            {(categories.first_category === 'watches') &&
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        errorMessage={errorValues.bracelet_belt}
                                        options={
                                            [
                                                {label: t("seller_product.chain"), value: "chain"},
                                                {label: t("seller_product.belt"), value: "belt"},
                                                {label: t("seller_product.other"), value: "other"},
                                            ]
                                        }
                                        onChange={(e) => handleChange(e.value, 'bracelet_belt')}
                                        label={t("seller_product.bracelet_belt")}
                                        placeholder={t("seller_product.bracelet_belt")}
                                    />
                                </div>}

                            {(categories.state === 'not_ready') || (categories.first_category === 'souvenir') || (categories.first_category === 'watches' && categories.metal === 'other') || (categories.first_category === 'watches' && categories.metal === 'silver') ? null :
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        errorMessage={errorValues.gemstone}
                                        options={
                                            [
                                                {label: t("seller_product.yes"), value: "yes"},
                                                {label: t("seller_product.no"), value: "no"},
                                            ]
                                        }
                                        onChange={(e) => handleChange(e.value, 'gemstone')}
                                        label={t("seller_product.gemstone")}
                                        placeholder={t("seller_product.gemstone")}
                                    />
                                </div>}
                            <div className={classes.selectWrapp}>
                                <CInput
                                    value={values.product_code}
                                    errorMessage={errorValues.product_code}
                                    onChange={(e) => handleChange(e.target.value, 'product_code')}
                                    label={t("seller_product.product_code")}
                                    // placeholder={t("seller_product.product_code")}
                                    placeholder={'a-z, 0-9 , -, _'}
                                />
                            </div>
                        </>}
                    </div>
                    {categories.state !== 'not_ready' && categories.metal !== 'silver' && categories.first_category !== 'souvenir' &&
                        <>
                            <div className={classes.addStonesAttributesTitleBlock}>
                                <p className={classes.addStonesAttributesTitleBlockTitle}>{t("seller_product.stones_attributes")}</p>
                                <div className={classes.checkBoxWrapp}>
                                    <CCheckbox onChange={() => setChecked(!checked)} checked={checked}/>
                                    <p>{t("seller_product.no_stone")}</p>
                                </div>
                            </div>

                            {!checked && resetStone && <>
                                {stones.map((item, index) => {
                                    return (
                                        <div className={classes.inputBlock} key={index}>
                                            <div className={classes.selectWrapp}>
                                                <p>{t("stone.stone")}</p>
                                                <p className={classes.selectedWrapText}>{t(`stone.${item.stone}`)}</p>
                                            </div>
                                            <div className={classes.selectedWrapp}>
                                                <p>{t("stone.carat")}</p>
                                                <p className={classes.selectedWrapText}>{item.carat}</p>
                                            </div>
                                            <div className={classes.selectedWrapp}>
                                                <p>{t("stone.pcs")}</p>
                                                <p className={classes.selectedWrapText}>{item.pcs}</p>
                                            </div>
                                            <div className={classes.selectedWrapp}>
                                                <p>{t("stone.color")}</p>
                                                <p className={classes.selectedWrapText}>{item.color ? item.color : 'N/A'}</p>
                                            </div>
                                            <div className={classes.selectedWrapp}>
                                                <p>{t("stone.clarity")}</p>
                                                <p className={classes.selectedWrapText}>{item.clarity ? item.clarity : 'N/A'}</p>
                                            </div>
                                            <div className={classes.selectedWrapp}>
                                                <p>{t("stone.cut")}</p>
                                                <p className={classes.selectedWrapText}>{item.cut}</p>
                                            </div>
                                            <div className={classes.selectedWrapp}>
                                                <p>{t("stone.certificate_code")}</p>
                                                <p className={classes.selectedWrapText}>{item.certificate_code ?item.certificate_code: 'N/A'}</p>
                                            </div>
                                            <div className={classes.selectedWrapp}>
                                                <p>{t("stone.diamondPrice")}</p>
                                                <p className={classes.selectedWrapText}>{item.diamondPrice}</p>
                                            </div>
                                            <div style={{margin: '20px 0 50px 0', width: '39%'}}>
                                                <CButton
                                                    onPress={() => deleteStone(index)}
                                                    title={t("project.delete")}
                                                    backgroundColor={colors.red}/>
                                            </div>
                                        </div>
                                    )
                                })}

                                <div className={classes.inputBlock}>
                                    <div className={classes.selectWrapp}>
                                        <CSelect
                                            errorMessage={errorStones.stone}
                                            value={newStone.stone}
                                            label={t("stone.stone")}
                                            onChange={(e) => handleChangeStones(e.value, 'stone')}
                                            placeholder={t("stone.stone")}
                                            options={
                                                [
                                                    {label: t("stone.diamond"), value: "diamond"},
                                                ]
                                            }
                                        />
                                    </div>
                                    <div className={classes.selectWrapp}>
                                        <CInput
                                            type={'number'}
                                            errorMessage={errorStones.carat}
                                            onChange={(e) => handleChangeStones(e.target.value, 'carat')}
                                            label={t("stone.carat")}
                                            placeholder={t("stone.carat")}
                                        />
                                    </div>
                                    <div className={classes.selectWrapp}>
                                        <CInput
                                            type={'number'}
                                            errorMessage={errorStones.pcs}
                                            onChange={(e) => handleChangeStones(e.target.value, 'pcs')}
                                            label={t("stone.pcs")}
                                            placeholder={t("stone.pcs")}
                                        />
                                    </div>
                                    <div className={classes.selectWrapp}>
                                        <CSelect
                                            errorMessage={errorStones.color}
                                            value={newStone.color}
                                            isMulti={true}
                                            options={
                                                [
                                                    {label: 'D', value: "d"},
                                                    {label: 'E', value: "e"},
                                                    {label: 'F', value: "f"},
                                                    {label: 'G', value: "g"},
                                                    {label: 'H', value: "h"},
                                                    {label: 'I', value: "i"},
                                                    {label: 'j', value: "j"},
                                                    {label: 'K', value: "k"},
                                                    {label: 'L', value: "l"},
                                                    {label: 'M', value: "m"},
                                                    {label: 'N', value: "n"},
                                                    {label: 'O', value: "o"},
                                                    {label: 'P', value: "p"},
                                                    {label: 'Q', value: "q"},
                                                    {label: 'R', value: "r"},
                                                    {label: 'S', value: "s"},
                                                    {label: 'T', value: "t"},
                                                    {label: 'U', value: "u"},
                                                    {label: 'V', value: "v"},
                                                    {label: 'W', value: "w"},
                                                    {label: 'X', value: "x"},
                                                    {label: 'Y', value: "y"},
                                                    {label: 'Z', value: "z"},
                                                    {label: 'Fancy light', value: "Fancy light"},
                                                    {label: 'Fancy', value: "Fancy"},
                                                    {label: 'Fancy dark', value: "Fancy dark"},
                                                    {label: 'Fancy intense', value: "Fancy intense"},
                                                    {label: 'Fancy vivid', value: "Fancy vivid"},
                                                    {label: 'Fancy deep', value: "Fancy deep"},
                                                ]
                                            }
                                            onChange={(e) => handleChangeStones(e, 'color')}
                                            label={t("stone.color")}
                                            placeholder={t("stone.color")}
                                        />
                                    </div>
                                    <div className={classes.selectWrapp}>
                                        <CSelect
                                            errorMessage={errorStones.clarity}
                                            isMulti={true}
                                            options={
                                                [
                                                    {label: 'FL', value: "fl"},
                                                    {label: 'IF', value: "if"},
                                                    {label: 'VVS1', value: "vvs1"},
                                                    {label: 'VVS2', value: "vvs2"},
                                                    {label: 'VS1', value: "vs1"},
                                                    {label: 'VS2', value: "vs2"},
                                                    {label: 'Si1', value: "si1"},
                                                    {label: 'Si2', value: "si2"},
                                                    {label: 'I1', value: "i1"},
                                                    {label: 'I2', value: "i2"},
                                                    {label: 'I3', value: "i3"},
                                                ]
                                            }
                                            onChange={(e) => handleChangeStones(e, 'clarity')}
                                            label={t("stone.clarity")}
                                            placeholder={t("stone.clarity")}
                                        />
                                    </div>
                                    <div className={classes.selectWrapp}>
                                        <CSelect
                                            errorMessage={errorStones.cut}
                                            options={
                                                [
                                                    {label: 'ROUND', value: "round"},
                                                    {label: 'PRINCESS', value: "princess"},
                                                    {label: 'ASSCHER', value: "asscher"},
                                                    {label: 'TRILLIANT', value: "trilliant"},
                                                    {label: 'RADIANT', value: "radiant"},
                                                    {label: 'CUSHION', value: "cushion"},
                                                    {label: 'BAGUETTE', value: "baguette"},
                                                    {label: 'EMERALD', value: "emerald"},
                                                    {label: 'PEAR', value: "pear"},
                                                    {label: 'MARQUISE', value: "marquise"},
                                                    {label: 'HEART', value: "heart"},
                                                    {label: 'OVAL', value: "oval"},
                                                    {label: 'OTHER', value: "other"},
                                                ]
                                            }
                                            onChange={(e) => handleChangeStones(e.value, 'cut')}
                                            label={t("stone.cut")}
                                            placeholder={t("stone.cut")}
                                        />
                                    </div>
                                    <div className={classes.selectWrapp}>
                                        <CInput
                                            errorMessage={errorStones.certificate_code}
                                            onChange={(e) => handleChangeStones(e.target.value, 'certificate_code')}
                                            label={t("stone.certificate_code")}
                                            placeholder={t("stone.certificate_code")}
                                        />
                                    </div>
                                    <div className={classes.selectWrapp}>
                                        <CInput
                                            type={'number'}
                                            errorMessage={errorStones.diamondPrice}
                                            value={newStone.diamondPrice}
                                            onChange={(e) => handleChangeStones(e.target.value, 'diamondPrice')}
                                            label={t("stone.diamondPrice")}
                                            placeholder={t("stone.diamondPrice")}
                                        />
                                    </div>
                                </div>
                                <div className={classes.containerPrivacyBtns}>
                                    <div className={classes.containerPrivacySaveWrap}>
                                        <CButton
                                            onPress={addNewStone}
                                            title={t("stone.add_stones_attribute")}
                                            borderWidth={1}
                                        />
                                    </div>
                                </div>
                            </>}
                        </>}

                    <div className={classes.addStonesAttributesTitleBlock}>
                        <p className={classes.addStonesAttributesTitleBlockTitle}>{t("seller_product.add_product_price")}</p>
                    </div>
                    <div className={classes.inputBlock}>
                        <div className={classes.selectWrapp}>
                            <CInput
                                errorMessage={errorProductPrice.oder_cost}
                                onChange={(e) => handleChangeProductPrice(e.target.value, 'oder_cost')}
                                label={t("seller_product.oder_cost")}
                                placeholder={0}
                                type={'number'}
                            />
                        </div>
                        <div className={classes.selectWrapp}>
                            <CInput
                                errorMessage={errorProductPrice.cost}
                                onChange={(e) => handleChangeProductPrice(e.target.value, 'cost')}
                                label={t("seller_product.cost")}
                                placeholder={0}
                                type={'number'}
                                value={productPrice.cost}
                                disabled={(values.type && values.fineness && (categories.metal === 'silver' || values.type === 'coin' || values.type === 'bars' ? values.weight : values.initial_weight) && categories.metal)}
                            />
                        </div>
                        <div className={classes.selectWrapp}>
                            <CInput
                                disabled={true}
                                type={'number'}
                                errorMessage={errorProductPrice.price}
                                onChange={(e) => handleChangeProductPrice(e.target.value, 'price')}
                                label={t("seller_product.price")}
                                placeholder={0}
                            />
                        </div>
                    </div>
                    {(values.type && values.fineness && (categories.metal === 'silver' || values.type === 'coin' || values.type === 'bars' ? values.weight : values.initial_weight) && categories.metal) ? (
                        <div className={classes.calcBlock}>
                            <div style={{width: 180}}>
                                <CButton
                                    title={t('seller_product.calculate')}
                                    backgroundColor={colors.blue}
                                    onPress={handleCalculatePrice}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className={classes.addStonesAttributesTitleBlock}>
                        <p className={classes.addStonesAttributesTitleBlockTitle}>{t("seller_product.add_image")}</p>
                    </div>
                    <ImageList images={images} setImages={setImages} deleteImg={deleteImg}/>
                    <Upload
                        errorMessage={errorImages}
                        multiple={false}
                        list={images}
                        onChange={(imgUrl) => handleOpenCropperModal(imgUrl)}
                        hideImage={false}
                        setError={setErrorImages}
                    />
                    <div className={classes.liner}/>
                    <div className={classes.addStonesAttributesTitleBlock}>
                        <p className={classes.addStonesAttributesTitleBlockTitle}>{t("seller_product.add_video_link")}</p>
                    </div>
                    <div className={classes.selectWrapp}>
                        <UploadVideo setVideo={setVideo} video={video}/>
                    </div>
                    <div className={classes.liner}/>
                    <div className={classes.containerPrivacyBtns}>
                        <div className={classes.containerPrivacySaveWrap}>
                            <CButton
                                onPress={()=>saveProduct('template')}
                                title={t("seller_product.save_product_as_template")}
                                borderWidth={1}
                            />
                        </div>
                        <div className={classes.containerPrivacySaveWrap}>
                            <CButton
                                onPress={saveProduct}
                                title={t("seller_product.save_product")}
                                backgroundColor={colors.blue}
                            />
                        </div>
                    </div>
                </div> :
                <div className={classes.containerSaveWrap}>
                    <CButton
                        title={t("seller_product.Select_category_continue")}
                        borderWidth={1}
                        leftIcon={<IconWarning/>}
                    />
                </div>}
            {
                cropperModal && <CModal
                    center={false}
                    title={t("seller_product.resize_image")}
                    closeModal={() => setCropperModal(false)}
                    visible={cropperModal}
                    children={<div>
                        <div className={classes.cropperWrapper}>
                            <Cropper
                                image={showImage?.data_url}
                                crop={crop}
                                zoom={zoom}
                                aspect={1}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            />
                        </div>
                        <div className={classes.sliderContainer}>
                            <Typography
                                variant="overline"
                                classes={{root: classes.sliderLabel}}
                            >
                                Zoom
                            </Typography>
                            <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e, zoom) => setZoom(zoom)}
                            />
                        </div>
                        <div className={classes.cropperButtonWrapper}>
                            <CButton
                                onPress={cropImage}
                                title={t("project.save")}
                                borderWidth={1}
                            />
                        </div>
                    </div>
                    }
                />
            }
        </div>
    );
}

export default SellerTemplateSingle;
