import React from "react";
import propTypes from "prop-types";
import {useTranslation} from "react-multi-lang";
import SmallBtn from "src/cors/smallBtn/smallBtn";
import removeBtn from "src/assets/icons/iconRemove.svg";
import {colors} from "src/utils/color";
import iconCheck from "src/assets/icons/iconCheck.svg";
import editIcon from "src/assets/icons/iconEdit.svg";
import {RenderStatus} from "../../../components/render-status/renderStatus";
import CButton from "../../../cors/button/cButton";


const AjaTable = ({dataBody, handleAction, setModalState, setUser_id,handleDetailsModal}) => {
    const t = useTranslation()

    return (
        <>
            {
                (dataBody && dataBody.length) ?
                    <>
                        <div className='tableWrapper'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>{t("table.photo")}</th>
                                    <th>{t("table.name")}</th>
                                    <th>{t("table.status")}</th>
                                    <th>{t("table.action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dataBody.map((tdItem, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td>
                                                    {tdItem?.image && <img className='productImage'
                                                                           src={`${tdItem?.image}`}
                                                                           alt="img"
                                                    />}
                                                </td>
                                                <td>{`${tdItem.name}`}</td>

                                                <td>{<RenderStatus status={tdItem?.status}/>}</td>
                                                <td>
                                                    <div className='tableButtonWrapper flexRow'>
                                                        <SmallBtn
                                                            onPress={() => {
                                                                setUser_id(tdItem.id)
                                                                setModalState(true)
                                                            }}
                                                            icon={editIcon}
                                                            borderColor={colors.black}
                                                        />
                                                        <SmallBtn
                                                            onPress={() => handleAction(tdItem.id, 'reject', 'rejected')}
                                                            icon={removeBtn}
                                                            borderColor={colors.softRed}
                                                        />
                                                        <SmallBtn
                                                            onPress={() => handleAction(tdItem.id, 'approve', 'approved')}
                                                            icon={iconCheck}
                                                            borderColor={colors.blue}
                                                        />
                                                        <div style={{width: 200}}>
                                                            <CButton onPress={()=>handleDetailsModal(tdItem)} title={t('project.view_details')} borderWidth={1}/>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            dataBody.map((item, index) => {
                                return (
                                    <div key={index.toString()} className='cardContainer'>
                                        <ul className='cardContent'>
                                            <li className='cardItem'>
                                                {item?.image && <img
                                                    src={`${item?.image}`}
                                                    alt="avatar"
                                                    style={{width: 80, height: 80}}
                                                />}
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.name")}</p>
                                                <p className='cardValue'>{`${item.name}`}</p>
                                            </li>


                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.status")}</p>
                                                <p className='cardValue'>{<RenderStatus status={item?.status}/>}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <div style={{marginTop: 15}} className='cardButtonWrapper flexEnd'>
                                                    <SmallBtn
                                                        onPress={() => {
                                                            setUser_id(item.id)
                                                            setModalState(true)
                                                        }}
                                                        icon={editIcon}
                                                        borderColor={colors.black}
                                                    />
                                                    <SmallBtn
                                                        onPress={() => handleAction(item.id, 'reject', 'rejected')}
                                                        icon={removeBtn}
                                                        borderColor={colors.softRed}
                                                    />
                                                    <SmallBtn
                                                        onPress={() => handleAction(item.id, 'approve', 'approved')}
                                                        icon={iconCheck}
                                                        borderColor={colors.blue}
                                                    />
                                                </div>
                                            </li>
                                            <li className='cardItem'>
                                                <CButton
                                                    title={t('project.view_details')}
                                                    borderWidth={1}
                                                    onPress={()=>handleDetailsModal(item)}
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </> : null
            }
        </>

    )
}

AjaTable.propTypes = {
    dataBody: propTypes.array,
    handleAction: propTypes.func,
    setModalState: propTypes.func,
    setUser_id: propTypes.func,
};

export default AjaTable