import {buyerStyle} from "./buyer-style";
import CTab from "src/cors/tab/cTab";
import React, {useContext, useEffect, useState} from "react";
import BuyerTable from "./buyerTable/buyerTable";
import {Axios} from "src/api/api";
import CButton from "src/cors/button/cButton";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import UnderHeader from "src/components/underHeader/underHeader";
import CSearchInput from "src/cors/searchInput/searchInput";
import {GlobalContext} from "src/context/global-context";

const Buyer = () => {
    const classes = buyerStyle()
    const t = useTranslation()
    const [active, setActive] = useState(0);
    const {state, setState} = useContext(GlobalContext)
    const [activeBuyerData, setActiveBuyerData] = useState([]);
    const [inactiveBuyerData, setInctiveBuyerData] = useState([]);
    const [offsetActive, setOffsetActive] = useState(0)
    const [offsetInActive, setOffsetInActive] = useState(0)
    const [total_countActive, setTotal_countActive] = useState(0)
    const [total_countInActive, setTotal_countInActive] = useState(0)
    const [searchValue, setSearchValue] = useState('')


    const requestInActive = () => {
        Axios(`admin/inactive_buyers?limit=10&offset=0`).then(res => {
            setInctiveBuyerData(res?.data?.buyers)
            setTotal_countInActive(res?.data?.total_count)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    const requestActive = () => {
        Axios(`admin/active_buyers?limit=10&offset=0`).then(res => {
            setActiveBuyerData(res.data.buyers)
            setTotal_countActive(res.data.total_count)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    const search = () => {
        if (searchValue) {
            setState({...state, loader: true})
            if (active === 0) {
                Axios(`admin/search_active_buyers?key=${searchValue}&limit=1000&offset=0`).then(res => {
                    setActiveBuyerData(res.data.buyers)
                    setTotal_countActive(res?.data?.total_count)
                }).catch(err => {
                    console.log(err)
                }).finally(() => {
                    setState({...state, loader: false})
                })
            } else if (active === 1) {
                Axios(`admin/search_inactive_buyers?key=${searchValue}&limit=1000&offset=0`).then(res => {
                    setInctiveBuyerData(res.data.buyers)
                    setTotal_countInActive(res?.data?.total_count)
                }).catch(err => {
                    console.log(err)
                }).finally(() => {
                    setState({...state, loader: false})
                })
            }
        } else if (searchValue === '') {
            if (active === 0) {
                requestActive()
            } else if (active === 1) {
                requestInActive()
            }
        }
    }

    const handleClear = () => {
        setSearchValue('')
        requestActive()
        requestInActive()
    }

    const showMoreActive = () => {
        Axios(`admin/active_buyers?limit=10&offset=${offsetActive + 10}`).then(res => {
            setActiveBuyerData([...activeBuyerData, ...res?.data.buyers])
            setOffsetActive(offsetActive + 10)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    const showMoreInActive = () => {
        Axios(`admin/inactive_buyers?limit=10&offset=${offsetInActive + 10}`).then(res => {
            setInctiveBuyerData([...inactiveBuyerData, ...res?.data.buyers])
            setOffsetInActive(offsetInActive + 10)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    useEffect(() => {
        requestActive()
    }, [])


    useEffect(() => {
        requestInActive()
    }, [])

    return (
        <div className={classes.buyerContainer}>
            <UnderHeader
                title={t("header.buyers")}
                context={<div className={classes.flexContainer}>
                    <div className={classes.searchInputWrap}>
                        <CSearchInput
                            handleSearch={search}
                            onChange={setSearchValue}
                            value={searchValue}
                            handleClear={handleClear}
                        />
                    </div>
                </div>}
            />
            <div className={classes.tabWrapper}>
                <CTab
                    onPress={setActive}
                    active={active}
                    items={[{value: t("seller.active_buyer"), key: 0}, {value: t("seller.archive_buyer"), key: 1}]}/>
            </div>
            {active === 0 && <>
                <BuyerTable dataBody={activeBuyerData}/>
                {(total_countActive !== activeBuyerData.length) && <div className={classes.seeMoreBtnWrapper}>
                    <CButton
                        onPress={showMoreActive}
                        title={t("project.show_more")}
                        borderWidth={1}
                    />
                </div>}
            </>}
            {active === 1 && <>
                <BuyerTable dataBody={inactiveBuyerData}/>
                {(total_countInActive !== inactiveBuyerData.length) && <div className={classes.seeMoreBtnWrapper}>
                    <CButton
                        onPress={showMoreInActive}
                        title={t("project.show_more")}
                        borderWidth={1}
                    />
                </div>}
            </>}
        </div>
    );
}

export default Buyer;