import React, {useContext, useEffect, useState} from 'react';
import {sellerStyle} from './seller-style';
import CTab from "src/cors/tab/cTab";
import ActiveSellerTable from "./sellerTable/activeSellerTable";
import RequestSellerTable from "./sellerTable/requestSellerTable";
import {Axios} from "src/api/api";
import {toast} from 'react-toastify';
import CButton from "src/cors/button/cButton";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import UnderHeader from "src/components/underHeader/underHeader";
import CSearchInput from "src/cors/searchInput/searchInput";
import {GlobalContext} from "src/context/global-context";
import {CModal} from "src/cors/modal/cModal";
import {colors} from "src/utils/color";

const Seller = () => {
    const classes = sellerStyle()
    const t = useTranslation()
    const [active, setActive] = useState(0);
    const {state, setState} = useContext(GlobalContext)
    const [activeSellerData, setActiveSellerData] = useState([]);
    const [requestsSellerData, setRequestsSellerData] = useState([]);
    const [offsetActive, setOffsetActive] = useState(0)
    const [total_countActive, setTotal_countActive] = useState(0)
    const [approved_products_count, setApproved_products_count] = useState(0)
    const [offsetRequest, setOffsetRequest] = useState(0)
    const [total_countRequest, setTotal_countRequest] = useState(0)
    const [searchValue, setSearchValue] = useState('')
    const [deleteRequestModal, setDeleteRequestModal] = useState(false)
    const [sellerID, setSellerID] = useState('')

    const search = () => {
        if (searchValue) {
            setState({...state, loader: true})
            if (active === 0) {
                Axios(`admin/search_active/seller?key=${searchValue}&limit=1000&offset=0`).then(res => {
                    setActiveSellerData(res.data.users)
                    setTotal_countActive(res?.data?.total_count)
                }).catch(err => {
                    console.log(err)
                }).finally(() => {
                    setState({...state, loader: false})
                })
            } else if (active === 1) {
                Axios(`admin/seller/search_requests?key=${searchValue}&limit=1000&offset=0`).then(res => {
                    setRequestsSellerData(res.data.users)
                    setTotal_countRequest(res?.data?.total_count)
                }).catch(err => {
                    console.log(err)
                }).finally(() => {
                    setState({...state, loader: false})
                })
            }
        } else if (searchValue === '') {
            if (active === 0) {
                requestSellerActives()
            } else if (active === 1) {
                requestSellerRequests()
            }
        }
    }

    const requestSellerRequests = () => {
        Axios(`admin/seller/requests?limit=10&offset=0`).then(res => {
            setRequestsSellerData(res.data.users)
            setTotal_countRequest(res.data.total_count)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    const requestSellerActives = () => {
        Axios(`admin/active/seller?limit=10&offset=0`).then(res => {
            setActiveSellerData(res.data.users)
            setTotal_countActive(res.data.total_count)
            setApproved_products_count(res?.data?.approved_products_count)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    const showMoreActives = () => {
        Axios(`admin/active/seller?limit=10&offset=${offsetActive + 10}`).then(res => {
            setActiveSellerData([...activeSellerData, ...res?.data?.users])
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
        setOffsetActive(offsetActive + 10)
    }

    const showMoreRequest = () => {
        Axios(`admin/seller/requests?limit=10&offset=${offsetRequest + 10}`).then(res => {
            setRequestsSellerData([...requestsSellerData, ...res?.data?.users])
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
        setOffsetRequest(offsetRequest + 10)
    }

    const approveSeller = (id) => {
        Axios.put(`admin/approve/${id}`).then(res => {
            requestSellerRequests()
            requestSellerActives()
            toast(t("notification.approved"))
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    const deleteRequest = (id) => {
        setDeleteRequestModal(true)
        setSellerID(id)
    }

    const deleteSeller = () => {
        setState({...state, loader: true})
        Axios.put(`admin/deactivate_user`, {"user_id": sellerID}).then(res => {
            requestSellerRequests()
            requestSellerActives()
            toast(t("notification.deactivated"))
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        }).finally(()=>{
            setState({...state, loader: false})
            setDeleteRequestModal(false)
        })
    }

    const handleClear = () => {
        setSearchValue('')
        requestSellerActives()
        requestSellerRequests()
    }

    useEffect(() => {
        requestSellerActives()

    }, [])

    useEffect(() => {
        requestSellerRequests()
    }, [])

    return (
        <div className={classes.sellerContainer}>
            <UnderHeader
                title={t("header.sellers")}
                context={<div className={classes.flexContainer}>
                    <div className={classes.searchInputWrap}>
                        <CSearchInput
                            value={searchValue}
                            handleSearch={search}
                            onChange={setSearchValue}
                            handleClear={handleClear}
                        />
                    </div>
                </div>}
            />
            <div className={classes.tabWrapper}>
                <CTab
                    onPress={setActive}
                    active={active}
                    items={[
                        {value: t("seller.active_seller"), key: 0},
                        {value: t("seller.sellers_requests"), key: 1}
                    ]}
                />
            </div>
            {active===0 &&  <>
                <p className={classes.total_count}>{`${t('seller_product.total_count')}: ${total_countActive ? total_countActive : 0}`}</p>
                <p className={classes.total_count}>{`${t('seller_product.approved_products_count')}: ${approved_products_count ? approved_products_count : 0}`}</p>
            </>}
            {active===1 &&  <p className={classes.total_count}>{`${t('seller_product.total_count')}: ${total_countRequest ? total_countRequest : 0}`}</p>}
            {active === 1 && <RequestSellerTable
                deleteRequest={deleteRequest}
                aproveSeller={approveSeller}
                dataBodyRequestSeller={requestsSellerData}
            />}
            {active === 0 &&
                <ActiveSellerTable
                    deleteRequest={deleteRequest}
                    dataBodyActiveSeller={activeSellerData}
                />}
            {(total_countActive !== activeSellerData?.length) && (active === 0) &&
                <div className={classes.seeMoreBtnWrapper}>
                    <CButton
                        onPress={showMoreActives}
                        title={t("project.show_more")}
                        borderWidth={1}
                    />
                </div>}
            {(total_countRequest !== requestsSellerData?.length) && (active === 1) &&
                <div className={classes.seeMoreBtnWrapper}>
                    <CButton
                        onPress={showMoreRequest}
                        title={t("project.show_more")}
                        borderWidth={1}
                    />
                </div>}
            {
                deleteRequestModal && <CModal
                    visible={deleteRequestModal}
                    closeModal={() => setDeleteRequestModal(false)}
                    center={true}
                    title={t('project.note')}
                    children={
                        <div>
                            <p style={{marginTop: 20}}>{t('notifications.if_you_deactivate_user')}</p>
                            <div className={classes.deleteModalBtns}>
                                <div className={classes.deleteModalBtnWrap}>
                                    <CButton
                                        onPress={() => setDeleteRequestModal(false)}
                                        borderWidth={1}
                                        title={t('seller_product.no')}
                                    />
                                </div>
                                <div className={classes.deleteModalBtnWrap}>
                                    <CButton
                                        onPress={deleteSeller}
                                        backgroundColor={colors.red}
                                        title={t('seller_product.yes')}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                />
            }
        </div>
    );
}

export default Seller;
