import React from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {ReactComponent as IconDelete} from "src/assets/icons/delete.svg";
import {sellerAddProductStyle} from "./seller-add-product-style";
import {Axios} from 'src/api/api';
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";

const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    display: 'flex',
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};


export default function ImageListEdit({
                                          images,
                                          setImages,
                                          deleteImg,
                                          url,
                                          isDeletable = true,
                                          onItem = () => undefined
                                      }) {
    const classes = sellerAddProductStyle()
    const t = useTranslation()
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            images,
            result.source.index,
            result.destination.index
        );

        setImages(items)
        Axios.put(url, {"pictures": items}).then((res) => {
            setImages(res.data.pictures)
            toast(t("project.changes_were_successfully_saved"))
        }).catch(err => {
            console.log(err, 'err ')
            toast(errorMessages(err?.response?.data?.code))
        })
    }


    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={{...getListStyle(snapshot.isDraggingOver), flexWrap: 'wrap'}}
                        {...provided.droppableProps}
                    >
                        {images.map((item, index) => {
                            if (!item.is_video) {
                                return (
                                    <Draggable
                                        key={`${item.data_url}${index}`}
                                        draggableId={`${item.data_url}${index}`}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <div
                                                    key={index}
                                                    className={classes.imageListItem}
                                                    onClick={()=>onItem(index)}>
                                                    {isDeletable &&
                                                        <div onClick={() => deleteImg(item.number)}
                                                             className={classes.deleteImg}>
                                                            <IconDelete/>
                                                        </div>}
                                                    <img
                                                        src={`${item["path"]}`}
                                                        className={classes.uploadImg}
                                                        alt="img"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            }
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}


