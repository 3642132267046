import React from 'react';


import SellerOffer from "./seller-offers";
import SellerOfferContextProvider from "src/context/seller-offer-context";


export const SellerOfferContextWrapper = (props) => {
    return (
        <SellerOfferContextProvider>
            <SellerOffer {...props} />
        </SellerOfferContextProvider>

    );
};

export default SellerOfferContextWrapper;



