import SellerCustomPriceEditUnderHeader from "./seller-custom-price-edit-under-header";
import {sellerCustomPriceStyle} from "./seller-custom-price-style";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {CModal} from "src/cors/modal/cModal";
import MoveProductsModal from "./modals/moveProductsModal";
import SellerCustomPriceEditSilverOtherTable
    from "./seller-custom-price-edit-table/seller-custom-price-edit-silver-other-table";
import {useLocation} from "react-router-dom";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import CButton from "src/cors/button/cButton";
import {useTranslation} from "react-multi-lang";
import UnderHeader from "src/components/underHeader/underHeader";
import moment from "moment";
import {GlobalContext} from "src/context/global-context";
import UpdateRateModal from "./modals/updateRateModal";
import ScrollInfiniteWrapper from "src/components/scroll-wrapper/scroll-wrapper";



const SellerCustomPriceEdit = () => {
    const {state, setState} = useContext(GlobalContext)
    const classes = sellerCustomPriceStyle();
    const location = useLocation()
    const {id, type, edit} = location.state.data
    const t = useTranslation()
    const [offset, setOffset] = useState('0')
    const [total_count, setTotal_count] = useState(0)
    const [searchValue, setSearchValue] = useState('')
    const [products, setProducts] = useState([])
    const [period, setPeriod] = useState('')
    const [updateRate, setUpdateRate] = useState(false);
    const [nextUpdate, setNextUpdate] = useState('')
    const [scrolling, setScrolling] = useState(false)



    const request = useCallback(() => {
        setState({...state, loader: true})
        Axios(`category/${id}?key=${searchValue}&limit=10&offset=${offset}`).then(res => {
            setProducts([...products, ...res.data.products])
            setTotal_count(res?.data?.total_count)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        }).finally(() => {
            setState({...state, loader: false})
            setScrolling(false)
        })
    }, [offset, searchValue])

    const updateView = () => {
        setProducts([])
        setOffset(prev => prev === 0 ? '' : 0)
    }

    const search = () => {
        if (searchValue) {
            updateView()
        }
    }

    const showMore = () => {
        setOffset(+offset + 10)
    }

    const updateByRate = (event) => {
        setState({...state, loader: true})
        Axios.put(`category/period?category_id=${id}&period=${event.value}`).then(res => {
           updateView()
            toast(t("project.saved"))
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err)
        }).finally(() => {
            setState({...state, loader: false})
        })
    }

    const onCheck = (value, id) => {
        const arr = products.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    checked: value
                }
            } else {
                return item
            }
        })
        setProducts(arr)
    }

    const moveProducts = (list, selectValue) => {
        if (list.length && selectValue) {
            setState({...state, loader: true})
            const arr = list.map(item => {
                return item.id
            })
            Axios.put(`category/move`, {
                "from_category_id": id,
                "products": arr,
                "to_category_id": selectValue
            }).then(res => {
                updateView()
                setMoveCategoryModal(false)
                toast(t('project.moved'))

            }).catch(err=>{
                toast(errorMessages(err?.response?.data?.code))
                console.log(err)
            }).finally(() => {
                setState({...state, loader: false})
            })
        }
    }

    const handleClear = () => {
        setSearchValue('')
        updateView()
    }


    useEffect(() => {
        request()
    }, [offset])

    useEffect(() => {
        Axios(`category/period/${id}`).then(res => {
            setNextUpdate(res.data.next_update)
            setPeriod(res.data.period)
        })
    }, [])

    useEffect(() => {
        if (scrolling && total_count !== products?.length) {
            showMore()
        }
    }, [scrolling])

    const [moveCategoryModal, setMoveCategoryModal] = useState(false);
    return (
     <ScrollInfiniteWrapper
         scrollUpdate={setScrolling}
         context={   <div className={classes.sellerCustomPriceContainer}>
         <UnderHeader
             title={`${location?.state?.data?.name} `}
             context={<SellerCustomPriceEditUnderHeader
                 openMoveModal={() => setMoveCategoryModal(true)}
                 period={period}
                 updateByRate={updateByRate}
                 openUpdateModal={() => setUpdateRate(true)}
                 moveButtonState={products.some(item => item.checked)}
                 search={search}
                 onChange={setSearchValue}
                 handleClear={handleClear}
                 searchValue={searchValue}
                 nextUpdate={nextUpdate}
             />}
         />
         <div className={classes.customPriceRateDate}>
             <p>{`1$ - ${location?.state.data.usd_amd_rate}֏ |`}</p>
             <p>{`${t('table.last_update')} ${moment(location.state.data.last_update).format('DD MM YYYY')}`}</p>
         </div>
         <SellerCustomPriceEditSilverOtherTable
             dataBody={products}
             onCheck={onCheck}
             nav={location.state.data}
         />
         <CModal
             center={false}
             title={t('categories.move_products_to_category')}
             closeModal={() => setMoveCategoryModal(false)}
             visible={moveCategoryModal}
             children={<MoveProductsModal
                 products={products}
                 closeModal={setMoveCategoryModal}
                 moveProducts={moveProducts}
                 type={type}
             />}
         />
         <CModal
             center={false}
             title={t("categories.update_rate")}
             closeModal={() => setUpdateRate(false)}
             visible={updateRate}
             children={updateRate && (<UpdateRateModal
                 closeModal={setUpdateRate}
                 type={type}
                 edit={edit}
                 id={id}
                 requestGetAll={updateView}
             />)}
         />
     </div>} />
    )
}

export default SellerCustomPriceEdit