import React, {useEffect, useState} from "react";
import {orderItemStyle} from "./order-item-style";
import UnderHeader from "src/components/underHeader/underHeader";
import CardInfo from "src/components/cardInfo/cardInfo";
import ProductItem from "src/components/productItem/productItem";
import {useLocation, useNavigate} from "react-router-dom";
import {Axios} from "src/api/api";
import moment from "moment";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";

function OrderItem() {
    const classes = orderItemStyle()
    const t = useTranslation()
    const navigation = useNavigate()
    const location = useLocation()
    const [orderDetails, setOrderDetails] = useState({})

    const goBack = () => {
        if (location?.state?.prefix === 'order') return navigation('/order')
        if (location?.state?.prefix === 'buyer') return navigation('/buyer/buyerDetail', {state:  location.state.data})
        if (location?.state?.prefix === 'seller') return navigation('/seller/sellerDetail')
    }

    useEffect(() => {
        if (location?.state?.id) {
            Axios(`order/${location.state.id}`).then(res => {
                setOrderDetails(res.data);
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
                console.log(err, 'err');
            })
        } else {
            navigation('/order')
        }
    }, [])

    return (
        <div className={classes.orderDetailsContainer}>
            <UnderHeader goBack={goBack} title={orderDetails?.order_number} back={true}/>
            <h1 className={classes.title}>{t("order.order_info")}</h1>
            <div className={classes.flexBetweenContainer}>
                <CardInfo title={t("order.time_placed")} info={moment(orderDetails?.time_placed).format('lll')}/>
                <CardInfo title={t("order.order_number")} info={orderDetails?.order_number}/>
                <CardInfo title={t("order.total")} info={orderDetails?.total_price && orderDetails?.total_item ?`AMD ${orderDetails?.total_price} (${orderDetails?.total_item} ITEM)` : 'EMPTY'}/>
                <CardInfo title={t("order.sold_by")} info={orderDetails?.sold_by}/>
            </div>
            <h1 className={classes.title}>{t("order.tracking_details")}</h1>
            <CardInfo title={t("order.shipping_service")} info={orderDetails?.tracking_details?.sipping_service}/>
            <CardInfo title={t("order.tracking_number")} info={orderDetails?.tracking_details?.tracking_number}/>
            <h1 className={classes.title}>{t("order.shipping_address")}</h1>
            <div className={classes.flexBetweenContainer}>
                <CardInfo title={t("order.full_name")}
                          info={(orderDetails?.payment_info?.contact_name || orderDetails?.payment_info?.contact_surname) ? `${orderDetails?.payment_info?.contact_name} ${orderDetails?.payment_info?.contact_surname}` : 'EMPTY'}/>
                <CardInfo title={t("order.address")}
                          info={orderDetails?.address?.street_address ? `${orderDetails?.address?.street_address}${orderDetails?.address?.phone_number ? `, Tel: ${orderDetails?.address?.phone_number}` : ''}${orderDetails?.address?.name ? `, ${orderDetails?.address?.name + ' ' + orderDetails?.address?.surname || ''}` : ''}` : 'EMPTY'}/>
            </div>
            <h1 className={classes.title}>{t("order.payment_info")}</h1>
            <div className='payment-info_container'>
                <div className={'payment-info-item'}>
                    <p>{t("order.status")}</p>
                    <span>....................................................................................................................................................................................................................................</span>
                    <p>{orderDetails?.payment_info?.status}</p>
                </div>
                <div className={'payment-info-item'}>
                    <p>{t("order.full_name")}</p>
                    <span>....................................................................................................................................................................................................................................</span>
                    <p>{orderDetails?.payment_info?.contact_name ? `${orderDetails?.payment_info?.contact_name} ${orderDetails?.payment_info?.contact_surname}` : ''}</p>
                </div>
                <div className={'payment-info-item'}>
                    <p>{t("order.date_and_time")}</p>
                    <span>....................................................................................................................................................................................................................................</span>
                    <p>{moment(orderDetails?.payment_info?.date).format('lll')}</p>
                </div>
                <div className={'payment-info-item'}>
                    <p>{`${orderDetails?.payment_info?.total_items ? orderDetails?.payment_info?.total_items : ''} ${t('order.item')}`}</p>
                    <span>....................................................................................................................................................................................................................................</span>
                    <p></p>
                </div>
                <div className={'payment-info-item'}>
                    <p>{t("order.shipping")}</p>
                    <span>....................................................................................................................................................................................................................................</span>
                    <p></p>
                </div>
                <div className={'payment-info-item'}>
                    <p>{t("order.order_total")}</p>
                    <span>....................................................................................................................................................................................................................................</span>
                    <p>{orderDetails?.payment_info?.order_total_price ? `${orderDetails?.payment_info?.order_total_price} AMD` : ''}</p>
                </div>
            </div>
            <h1 className={classes.title}>{`${t("order.products")} (${orderDetails.products?.length})`}</h1>
            <div className={classes.productItemWrapper}>
                {
                    orderDetails && orderDetails.products ?
                        orderDetails.products.map((productItem, index)=>{
                            return (
                                <ProductItem key={productItem.id} index={index} productItem={productItem} />
                            )
                        }) : null
                }
            </div>
        </div>
    );
}

export default OrderItem;