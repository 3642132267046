import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";


export const underHeaderStyles = makeStyles(() =>
    createStyles({
        container: {
            width: "100%",
            borderBottom: `1px solid ${colors.greyLiner}`,
            padding: '30px 0 12px 0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: "center",
            "@media (max-width:768px)": {
                padding: '17px 0 16px 0',
            },
            "@media (max-width:480px)": {
                // padding: '10px 0 10px 0',
                flexDirection: 'column-reverse',
                alignItems: "flex-start",
            },
        },
        containerLeft: {
            display: 'flex',
            alignItems: 'center',
        },
        containerRight: {
            display: 'flex',
            alignItems: "center",
            "@media (max-width:480px)": {
                 // flexDirection: 'column',
                width: '100%'
            },
        },
        containerTitle: {
            textTransform: 'capitalize',
            fontSize: 24,
            color: colors.black,
            fontFamily: 'montserratRegular',
            "@media (max-width:480px)": {
                fontSize: 18,
            },
        },
        containerTitleSort: {
            fontSize: 24,
            color: colors.black,
            "@media (max-width:480px)": {
                fontSize: 18,
                display: 'none'
            },
        },
        containerTitleMobile: {
            display: 'none',
            fontSize: 24,
            color: colors.black,
            "@media (max-width:480px)": {
                fontSize: 18,
                display: 'block',
            },
        },
        containerGoBack: {
            width: 38,
            height: 38,
            borderRadius: '50%',
            backgroundColor: colors.lightGray,
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
            marginRight: 16,
            cursor: "pointer",
            '&:hover': {
                opacity: .6
            },
            "@media (max-width:480px)": {
                width: 30,
                height: 30,
            },
        },
        inputWrap: {
            width: 285,
            "@media (max-width:767px)": {
                width: 200
            },
            "@media (max-width:480px)": {
                width: '100%'
            },
        },
        sortWrapp: {
            "@media (max-width:480px)": {
                width: '100%',
                // display: "flex",
                // justifyContent: 'space-between',
                // alignItems: "center",
                marginTop: 11
            },
        },
        selectWrap:{
          width: 50,
            marginRight: 20
        },
        sortTitle:{
            margin: '0 10px'
        }
    })
);
