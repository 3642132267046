import React from "react";
import propTypes from "prop-types";
import {Link, useNavigate} from "react-router-dom";
import CButton from "src/cors/button/cButton";
import moment from "moment";
import {useTranslation} from "react-multi-lang";
import SmallBtn from "../../../../cors/smallBtn/smallBtn";
import {colors} from "../../../../utils/color";
import removeIcon from "../../../../assets/icons/iconRemove.svg";

const SellerTemplatesTable = ({dataBody}) => {

    const t = useTranslation()
    const navigation = useNavigate()
    return (
        <>
            {
                (dataBody && dataBody.length) ?
                    <>
                        <div className='tableWrapper'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>{t("table.id")}</th>
                                    <th>{t("table.name")}</th>
                                    <th>{t("table.action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dataBody.map((tdItem, index) => {
                                        console.log(tdItem,'tdItem,333')
                                        return (
                                            <tr key={index.toString()}>
                                                <td>
                                                    <Link to='#' className='link'>{tdItem.id}</Link>
                                                </td>
                                                <td>{`${tdItem.name} `}</td>
                                                <td>
                                                    <div style={{display:"flex"}} className='tableButtonWrapper'>
                                                        <CButton
                                                            onPress={() => navigation('/seller-template-single', {
                                                                state: {
                                                                    template: tdItem.template
                                                                }
                                                            })}
                                                            title={t("project.view_details")}
                                                            borderWidth={1}
                                                        />
                                                        <SmallBtn
                                                            icon={removeIcon}
                                                            borderColor={colors.softRed}
                                                            // onPress={() => navigation('/sellerProduct/sellerViewProduct', {state: {id: item.id}})}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            dataBody.map((item, index) => {
                                return (
                                    <div key={index.toString()} className='cardContainer'>
                                        <ul className='cardContent'>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.id")}</p>
                                                <p className='cardValue'>
                                                    <Link className='link' to='#'>{item.id}</Link>
                                                </p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.name")}</p>
                                                <p className='cardValue'>{`${item.name}`}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <div className='cardButtonWrapper' style={{display:"flex"}}>
                                                    <CButton
                                                        onPress={() => navigation('/sellerProduct/sellerEditProduct', {
                                                            state: {
                                                                template: item.template
                                                            }
                                                        })}
                                                        title={t("project.view_details")}
                                                        borderWidth={1}
                                                    />
                                                    <SmallBtn
                                                        icon={removeIcon}
                                                        borderColor={colors.softRed}
                                                        // onPress={() => navigation('/sellerProduct/sellerViewProduct', {state: {id: item.id}})}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </> : null
            }
        </>

    )
}

SellerTemplatesTable.propTypes = {
    dataBody: propTypes.array
};

export default SellerTemplatesTable