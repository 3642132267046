import CButton from "src/cors/button/cButton";
import CSearchInput from "src/cors/searchInput/searchInput";
import PropTypes from "prop-types";
import {sellerProductStyle} from "./seller-product-style";
import {colors} from "src/utils/color";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-multi-lang";
import CSelect from "src/cors/select/cSelect";

export default function SellerProductUnderHeader({setSelectValue, search, setSearchValue, sort, searchValue, handleClear,setProductItem}) {
    const t = useTranslation()
    const navigation = useNavigate()
    const classes = sellerProductStyle()

    return (
        <div className={classes.sellerProductUnderHeader}>
            <div className={classes.buttonWrapp}>
                <CButton
                    onPress={() => navigation('/sellerProduct/sellerAddProduct')}
                    title={t("seller_product.add_product")}
                    backgroundColor={colors.blue}
                />
            </div>
            <div className={classes.buttonWrapp}>
                <CSelect
                    onChange={(e) => {
                        setProductItem({})
                        setSelectValue(e.value)
                    }}
                    placeholder={t("seller_product.create_set")}
                    backgroundColor={colors.blue}
                    options={
                        [
                            {label: t("seller_product.wedding"), value: "wedding"},
                            {label: t("seller_product.set"), value: "set"}
                        ]
                    }
                />
            </div>
            <div className={classes.selectWrapp}>
                <CSelect
                    handleSearch={search}
                    onChange={(e) => sort(e)}
                    showErrorMessage={false}
                    placeholder={t('header.sort_by')}
                    options={[
                        {label: t("header.all"), value: 'all'},
                        {label: t("status.pending_approval"), value: 'pending_approval'},
                        {label: t("status.approved"), value: 'approved'},
                        {label: t("status.rejected"), value: 'rejected'},
                        {label: t("status.updated_pending_approval"), value: 'updated_pending_approval'},
                    ]}
                />
            </div>
            <div className={classes.inputWrapp}>
                <CSearchInput
                    handleSearch={search}
                    onChange={(e) => setSearchValue(e)}
                    handleClear={handleClear}
                    value={searchValue}
                />
            </div>

        </div>
    );
}

SellerProductUnderHeader.propTypes = {
    setSelectValue: PropTypes.func,
    search: PropTypes.func,
    setSearchValue: PropTypes.func,
    sort: PropTypes.func,
    handleClear: PropTypes.func,
    searchValue: PropTypes.string,
}