import React from "react";
import propTypes from "prop-types";
import SmallBtn from "src/cors/smallBtn/smallBtn";
import editIcon from 'src/assets/icons/iconEdit.svg';
import {colors} from "src/utils/color";
import {useTranslation} from "react-multi-lang";
import {useNavigate} from "react-router-dom";

const SellerCustomPriceEditSilverOtherTable = ({dataBody, onCheck, nav}) => {
    const t = useTranslation()
    const navigation = useNavigate()
    return (
        <>
            {
                (dataBody && dataBody.length) ?
                    <>
                        <div className='tableWrapper'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>{t("table.code")}</th>
                                    <th>{t('table.cost')}</th>
                                    <th>{t('table.diamond_price')}</th>
                                    <th>{`${t("table.total_price")}($/֏)`}</th>
                                    <th>{`${t("table.updated_price")}($/֏)`}</th>
                                    <th>{`${t("table.price_deference")}($/֏)`}</th>
                                    <th>{t('table.action')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dataBody.map((tdItem, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) => onCheck(e.target.checked, tdItem.id)}
                                                        checked={tdItem.checked}
                                                    />
                                                </td>
                                                <td>
                                                    <img
                                                        className='productImage customPriceEditProductImage'
                                                        src={`${tdItem?.main_picture?.path}`}
                                                        alt="img"
                                                    />
                                                    <p>{tdItem.product_code}</p>
                                                </td>
                                                <td>{tdItem.cost}</td>
                                                <td>{tdItem.diamond_price}</td>
                                                <td>{`${tdItem.total_price_usd}/${tdItem.total_price_amd}`}</td>
                                                <td>{`${tdItem.updated_price_usd}/${tdItem.updated_price_amd}`}</td>
                                                <td>{`${tdItem.price_difference_usd}/${tdItem.price_difference_amd}`}</td>
                                                <td>
                                                    <div className='tableButtonWrapper flexRow'>
                                                        <SmallBtn
                                                            onPress={() => navigation(`/customPrice/customPriceEdit/sellerEditProduct/${tdItem.id}`, {
                                                                state: {
                                                                    id: tdItem.id,
                                                                    prefix: 'customPriceEdit',
                                                                    nav
                                                                }
                                                            })}
                                                            icon={editIcon}
                                                            borderColor={colors.black}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        <div>
                            {
                                dataBody.map((item, index) => {
                                    return (
                                        <div key={index.toString()} className='cardContainer'>
                                            <ul className='cardContent'>
                                                <li className='cardItem'>
                                                    <div className='customPriceImage'>
                                                        <input
                                                            onChange={(e) => onCheck(e.target.checked, item.id)}
                                                            type="checkbox"
                                                        />
                                                        <img
                                                            src={`${item?.main_picture?.path}`}
                                                            alt="photo"
                                                        />
                                                        <p>{item.product_code}</p>
                                                    </div>
                                                </li>
                                                <li className='cardItem'>
                                                    <p className='cardKey'>{t("table.cost")}</p>
                                                    <p className='cardValue'>{item.cost}</p>
                                                </li>
                                                <li className='cardItem'>
                                                    <p className='cardKey'>{t("table.diamond_price")}</p>
                                                    <p className='cardValue'>{item.diamond_price}</p>
                                                </li>
                                                <li className='cardItem'>
                                                    <p className='cardKey'>{`${t("table.total_price")}($/֏)`}</p>
                                                    <p className='cardValue'>{`${item.total_price_usd}/${item.total_price_amd}`}</p>
                                                </li>
                                                <li className='cardItem'>
                                                    <p className='cardKey'>{`${t("table.updated_price")}($/֏)`}</p>
                                                    <p className='cardValue'>{`${item.updated_price_usd}/${item.updated_price_amd}`}</p>
                                                </li>
                                                <li className='cardItem'>
                                                    <p className='cardKey'>{`${t("table.price_deference")}($/֏)`}</p>
                                                    <p className='cardValue'>{`${item.price_difference_usd}/${item.price_difference_amd}`}</p>
                                                </li>
                                                <li className='cardItem'>
                                                    <div className='cardButtonWrapper flexEnd' >
                                                        <SmallBtn
                                                            icon={editIcon}
                                                            borderColor={colors.black}
                                                            onPress={() => navigation(`/customPrice/customPriceEdit/sellerEditProduct/${item.id}`, {
                                                                state: {
                                                                    id: item.id,
                                                                    prefix: 'customPriceEdit',
                                                                    nav
                                                                }
                                                            })}
                                                        />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </> : null
            }

        </>

    )
}

SellerCustomPriceEditSilverOtherTable.propTypes = {
    dataBody: propTypes.array,
    onCheck: propTypes.func
};

export default SellerCustomPriceEditSilverOtherTable