import React, {useCallback, useContext, useEffect, useState} from "react";
import {sellerOrderStyle} from "./seller-order-style";
import SellerOrderTable from "./sellerOrderTable/sellerOrderTable";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import UnderHeader from "src/components/underHeader/underHeader";
import CSearchInput from "src/cors/searchInput/searchInput";
import {GlobalContext} from "src/context/global-context";
import CSelect from "src/cors/select/cSelect";
import ScrollInfiniteWrapper from "src/components/scroll-wrapper/scroll-wrapper";

function SellerOrder() {
    const t = useTranslation()
    const classes = sellerOrderStyle();
    const {state,setState} = useContext(GlobalContext)
    const [orders, setOrders] = useState([])
    const [offset, setOffset] = useState('')
    const [total_count, setTotal_count] = useState(0)
    const [searchValue, setSearchValue] = useState('')
    const [status, setStatus] = useState('all')
    const [scrolling, setScrolling] = useState(false)

    const sortOptions = [
        {label: t("header.all"), value: 'all'},
        {label: t("header.started"), value: 'started'},
        {label: t("header.payment_deposited"), value: 'payment_deposited'},
        {label: t("header.payment_declined"), value: 'payment_declined'}
    ]


    const request = useCallback(() => {
        setState({...state, loader: true})
        Axios(`/order/seller/${status}?key=${searchValue}&limit=10&offset=${offset}`).then(res => {
            setOrders([...orders, ...res?.data?.orders])
            setTotal_count(res?.data?.total_count)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err');
        }).finally(() => {
            setState({...state, loader: false})
            setScrolling(false)
        })
    }, [status, searchValue, offset, orders])


    const showMore = () => {
        setOffset(+offset + 10)
    }

    const updateView = () => {
        setOrders([])
        setOffset(prev => prev === 0 ? '' : 0)
    }

    const handleChangeStatus = (e: any) => {
        setStatus(e)
        updateView()
    }

    const search = () => {
        if (searchValue) {
            updateView()
        }
    }

    const handleClear = () => {
        setSearchValue('')
        updateView()
    }

    useEffect(() => {
        request()
    }, [offset, status])

    useEffect(() => {
        if (scrolling && total_count !== orders?.length) {
            showMore()
        }
    }, [scrolling])

    return (
        <ScrollInfiniteWrapper
            scrollUpdate={setScrolling}
            context={<div className={classes.orderContainer}>
                <UnderHeader
                    title={t("header.orders")}
                    context={<div className={classes.flexContainer}>
                        <div className={classes.selectWrap}>
                            <CSelect
                                defaultValue={{label: t("header.all"), value: 'all'}}
                                onChange={(e: any) => handleChangeStatus(e.value)}
                                options={sortOptions}
                            />
                        </div>
                        <div className={classes.searchInputWrap}>
                            <CSearchInput
                                handleClear={handleClear}
                                handleSearch={search}
                                onChange={setSearchValue}
                                value={searchValue}
                            />
                        </div>
                    </div>}
                />
                <SellerOrderTable dataBody={orders}/>
            </div>}/>

    );
}

export default SellerOrder;