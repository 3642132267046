import React, { useState } from 'react';
import { Axios } from "../../../api/api";
import { useNavigate } from 'react-router-dom';
import {toast} from "react-toastify";
import {useTranslation} from "react-multi-lang";

const form = {
    margin: 0,
    maxWidth: '100%',
    background: 'none',
    boxShadow: 'none'
}
const MarketForm = () => {
    const t = useTranslation()
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        password: '',
        email: '',
        company_name: '',
        location: '',
        working_hours: {},
        working_days: []
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleDaysChange = (e) => {
        const { value, checked } = e.target;
        setFormData(prevState => {
            const working_days = checked
                ? [...prevState.working_days, value]  // Add day to array
                : prevState.working_days.filter(day => day !== value);  // Remove day from array

            // Initialize working_hours for the newly selected day
            const working_hours = { ...prevState.working_hours };
            if (checked) {
                working_hours[value] = [];  // Initialize with an empty array for new days
            } else {
                delete working_hours[value];  // Remove hours for unselected days
            }

            return { ...prevState, working_days, working_hours };
        });
    };

    const handleHoursChange = (day, e) => {
        const { value, checked } = e.target;
        setFormData(prevState => {
            const working_hours = { ...prevState.working_hours };
            if (checked) {
                working_hours[day] = [...(working_hours[day] || []), value];
            } else {
                working_hours[day] = (working_hours[day] || []).filter(hour => hour !== value);
            }

            return { ...prevState, working_hours };
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await Axios.post('/preorder/admin/locations', formData).then((res) => {
                if(res.status === 200) {
                    toast.success(t('fix.market_created_successfully'))
                    navigate('/markets');
                } else {
                    toast.error('Internal server error')
                }
            });
        } catch (error) {
            console.error("Error creating market:", error);
        }
    };

    // Define hours options (e.g., every hour from 9 AM to 9 PM)
    const hoursOptions = [
        "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00",
        "16:00", "17:00", "18:00", "19:00", "20:00", "21:00"
    ];

    // Define days options
    const daysOptions = [
        t('fix.monday'), t('fix.tuesday'), t('fix.wednesday'), t('fix.thursday'), t('fix.friday'), t('fix.saturday'), t('fix.sunday')
    ];

    const sortedWorkingDays = formData.working_days.slice().sort((a, b) => daysOptions.indexOf(a) - daysOptions.indexOf(b));

    return (
        <div>
            <h1>{t('fix.create_market')}</h1>
            <form onSubmit={handleSubmit} style={form}>
                <label>
                    {t('fix.firstname')}:
                    <input
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t('fix.last_name')}:
                    <input
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t('fix.phone_number')}:
                    <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t('fix.password')}:
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t('fix.email')}:
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t('fix.location_name')}:
                    <input
                        type="text"
                        name="company_name"
                        value={formData.company_name}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t('fix.address')}:
                    <input
                        type="text"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t('fix.working_days')}:
                    <div className="flexRow" style={{marginTop: 10}}>
                        {daysOptions.map(day => (
                            <label key={day} style={{marginLeft: 15}}>
                                <input
                                    type="checkbox"
                                    value={day}
                                    checked={formData.working_days?.includes(day)}
                                    onChange={handleDaysChange}
                                />
                                {day}
                            </label>
                        ))}
                    </div>
                </label>
                {sortedWorkingDays.length > 0 && (
                    <div>
                        {sortedWorkingDays.map(day => (
                            <div key={day}>
                                <h3>{day} {t('fix.working_hours')}</h3>
                                <div style={{marginTop: 10}} className="flexRow">
                                    {hoursOptions.map(hour => (
                                        <label key={hour} style={{marginLeft: 10}}>
                                            <input
                                                type="checkbox"
                                                value={hour}
                                                checked={formData.working_hours[day]?.includes(hour) || false}
                                                onChange={(e) => handleHoursChange(day, e)}
                                            />
                                            {hour}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <button type="submit">{t('fix.create')}</button>
            </form>
        </div>
    );
};

export default MarketForm;
