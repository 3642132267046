import React from "react";
import propTypes from "prop-types";
import SmallBtn from "src/cors/smallBtn/smallBtn";
import removeBtn from 'src/assets/icons/iconRemove.svg';
import eyeIcon from 'src/assets/icons/iconEye.svg';
import {colors} from "src/utils/color";
import CRating from "src/cors/rating/rating";
import moment from "moment";
import {Axios} from "src/api/api";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-multi-lang";
import editIcon from "../../../../assets/icons/iconEdit.svg";

const ActiveSellerTable = ({dataBodyActiveSeller, deleteRequest, type}) => {
    const navigation = useNavigate()
    const t = useTranslation()

    const goToDetail = (id, status) => {
        if (type === 'service') {
            navigation('/services/serviceDetail', {state: {id, status}})
        } else {
            navigation('/seller/sellerDetail', {state: {id, status}})
        }
    }

    const editRequest = (id, status) => {
        //
        if (type === 'service') {
            navigation('/services/editServiceDetails', {state: {id, status}})
        } else {
            navigation('/seller/editSellerDetails', {state: {id, status}})
        }
    }

    return (
        <>
            {
                (dataBodyActiveSeller && dataBodyActiveSeller.length) ?
                    <>
                        <div className='tableWrapper'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>{t("table.username")}</th>
                                    <th>{t("table.phone_number")}</th>
                                    <th>{t("table.country")}</th>
                                    <th>{t("table.email")}</th>
                                    <th>{t("table.code")}</th>
                                    <th>{t("table.last_visit")}</th>
                                    <th>{t("table.approved_count")}</th>
                                    <th>{t("table.pending_approval_count")}</th>
                                    <th>{t("table.rating")}</th>
                                    <th>{t("table.action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dataBodyActiveSeller.map((tdItem, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td>{`${tdItem.firstname} ${tdItem.lastname}`}</td>
                                                <td>{tdItem.phone_number}</td>
                                                <td className='titleUppercase'>{t(`setting.${tdItem.country}`)}</td>
                                                <td>{tdItem.email}</td>
                                                <td>{tdItem.ucode}</td>
                                                <td>{moment(tdItem.last_visit).format('YYYY MM DD')}</td>
                                                <td>{tdItem.approved_count}</td>
                                                <td>{tdItem.pending_approval_count}</td>
                                                <td style={{display:"flex"}}>
                                                    <CRating id={tdItem.id} rate={tdItem.stars}/>
                                                    <p>{`(${tdItem.stars_insert_count})`}</p>
                                                </td>
                                                <td>
                                                    <div className='tableButtonWrapper flexRow'>
                                                        <SmallBtn
                                                            onPress={() => deleteRequest(tdItem.id)}
                                                            icon={removeBtn}
                                                            borderColor={colors.softRed}
                                                        />
                                                        <SmallBtn
                                                            onPress={() => {
                                                                editRequest(tdItem.id)
                                                            }}
                                                            icon={editIcon}
                                                            borderColor={colors.black}
                                                        />
                                                        <SmallBtn
                                                            onPress={() => goToDetail(tdItem.id, tdItem.status)}
                                                            icon={eyeIcon}
                                                            borderColor={colors.blue}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            dataBodyActiveSeller.map((item, index) => {
                                return (
                                    <div key={index.toString()} className='cardContainer'>
                                        <ul className='cardContent'>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.username")}</p>
                                                <p className='cardValue'>{`${item.firstname} ${item.lastname}`}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.phone_number")}</p>
                                                <p className='cardValue'>{item.phone_number}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.country")}</p>
                                                <p className='cardValue titleUppercase'>{item.country}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.email")}</p>
                                                <p className='cardValue'>{item.email}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.last_visit")}</p>
                                                <p className='cardValue'>{moment(item.last_visit).format('YYYY MM DD')}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.approved_count")}</p>
                                                <p className='cardValue'>{item.approved_count}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.pending_approval_count")}</p>
                                                <p className='cardValue'>{item.pending_approval_count}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.rate")}</p>
                                                <CRating id={item.id} rate={item.stars}/>
                                            </li>
                                            <li className='cardItem'>
                                                <div className='cardButtonWrapper flexEnd'>
                                                    <SmallBtn
                                                        onPress={() => deleteRequest(item.id)}
                                                        icon={removeBtn}
                                                        borderColor={colors.softRed}
                                                    />
                                                    <SmallBtn
                                                        onPress={() => goToDetail(item.id, item.status)}
                                                        icon={eyeIcon}
                                                        borderColor={colors.blue}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </> : null
            }

        </>

    )
}

ActiveSellerTable.propTypes = {
    dataBody: propTypes.array
};

export default ActiveSellerTable