import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const settingsStyle = makeStyles(() =>
    createStyles({
        container: {
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto 30px',
            "@media (max-width:1250px)": {
                width: '96%',
            },
        },

        containerSettings: {
            backgroundColor: colors.white,
            boxShadow: ' 0px 0px 96px rgba(0, 0, 0, 0.1)',
            padding: 40,
            marginTop: 30,
            "@media (max-width:768px)": {
                padding: 20,
                marginTop: 27,
            },
            "@media (max-width:480px)": {
                padding: 7,
                marginTop: 9,
            },
        },
        inputBlock: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        title: {
            fontSize: 14,
            color: colors.black,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            marginBottom: 27,
            "@media (max-width:480px)": {
                marginBottom: 9,
            },
        },
        inputWrapp: {
            width: 359,
            margin: '0 7px',
            "@media (max-width:1250px)": {
                width: '48%',
            },
            "@media (max-width:830px)": {
                width: '47%',
            },
            "@media (max-width:550px)": {
                width: '100%',
                margin: '0 0',
            },
        },
        inputPassword: {
            width: '48%',
            "@media (max-width:830px)": {
                width: '47%',
            },
            "@media (max-width:550px)": {
                width: '100%',
                margin: '0 0',
            },
        },
        containerPrivacyBtns: {
            marginTop: 40,
            display: 'flex',
            justifyContent: 'flex-end',
            "@media (max-width:768px)": {
                marginTop: 30,
            },
            "@media (max-width:480px)": {
                marginTop: 9,
                flexDirection: 'column-reverse'
            },
        },
        containerPrivacyBtnsBtnWrap: {
            width: 236,
            "@media (max-width:480px)": {
                width: '100%',
            },
        },
        containerPrivacySaveWrap: {
            width: 236,
            marginLeft: 10,
            "@media (max-width:480px)": {
                width: '100%',
                marginLeft: 0,
                marginBottom: 10

            },
        }
    })
)