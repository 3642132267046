import {servicesStyle} from './services-style';
import React, {useEffect, useState} from "react";
import {ReactComponent as IconCheck} from "src/assets/icons/iconCheck.svg";
import {cCheckboxStyle} from "src/cors/checkbox/cCheckbox-style";
import {useTranslation} from "react-multi-lang";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";

const Services = () => {
    const classes = servicesStyle();
    const checkboxStyles = cCheckboxStyle();
    const [providers, setProviders] = useState([]);
    const t = useTranslation();

    const getProviders = () => {
        Axios(`service/get-providers`).then(res => {
            setProviders(res.data?.service_providers)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, ' err');
        })
    }

    const changeActive = (id, val) => {
        Axios.put(`service/set-provider`, {id, value: !val}).then(res => {
            if (res.data.success) {
                getProviders();
            }
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err');
        })
    }

    useEffect(() => {
        getProviders();
    }, [])

    return (
        <div className={classes.serviceContainer}>
            {
                 providers.length > 0 ? providers.map((provider) => {
                    return (
                        <div key={provider.id} className={classes.checkBoxWrap}>
                            <div
                                className={`${checkboxStyles.container} ${provider.value && checkboxStyles.active}`}
                                onClick={() => changeActive(provider.id, provider.value)}
                            >
                                <IconCheck width={10} height={8}/>
                            </div>
                            <p onClick={() => changeActive(provider.id, provider.value)}>{t(`service_providers.${provider.code}`)}</p>
                        </div>
                    )
                }) : null
            }
        </div>
    )
}

export default Services