import React from "react";
import propTypes from "prop-types";
import {Link, useNavigate} from "react-router-dom";
import CButton from "src/cors/button/cButton";
import moment from "moment";
import {useTranslation} from "react-multi-lang";

const ProductTable = ({dataBody}) => {
    const t = useTranslation()
    const navigation = useNavigate()
    return (
        <>
            {
                (dataBody && dataBody.length) ?
                    <>
                        <div className='tableWrapper'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>{t("table.id")}</th>
                                    <th>{t("table.username")}</th>
                                    <th>{t("table.seller")}</th>
                                    <th>{t("table.code")}</th>
                                    <th>{t("table.date")}</th>
                                    <th>{t("table.quantity")}</th>
                                    <th>{t("table.action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dataBody.map((tdItem, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td>
                                                    <Link to='#' className='link'>{tdItem.seller_id}</Link>
                                                </td>
                                                <td>{`${tdItem.firstname} ${tdItem.lastname}`}</td>
                                                <td>{tdItem.company_name}</td>
                                                <td>{tdItem.pcode}</td>
                                                <td>{moment(tdItem.date).format('YYYY MM DD')}</td>
                                                <td>{tdItem.quantity}</td>
                                                <td>

                                                    <div className='tableButtonWrapper'>
                                                        <CButton
                                                            onPress={() => navigation('/product/productRequests', {state: {seller_id: tdItem.seller_id}})}
                                                            title={t("table.view_all")}
                                                            borderWidth={1}
                                                        />
                                                    </div>

                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            dataBody.map((item, index) => {
                                return (
                                    <div key={index.toString()} className='cardContainer'>
                                        <ul className='cardContent'>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.id")}</p>
                                                <p className='cardValue'>
                                                    <Link className='link' to='#'>{item.seller_id}</Link>
                                                </p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.username")}</p>
                                                <p className='cardValue'>{`${item.firstname} ${item.lastname}`}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.seller")}</p>
                                                <p className='cardValue'>{item.company_name}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.date")}</p>
                                                <p className='cardValue'>{moment(item.date).format('YYYY MM DD')}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.quantity")}</p>
                                                <p className='cardValue'>{item.quantity}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <div className='cardButtonWrapper'>
                                                    <CButton
                                                        onPress={() => navigation('/product/productRequests', {state: {seller_id: item.seller_id}})}
                                                        title={t("table.view_all")}
                                                        borderWidth={1}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </> : null
            }
        </>

    )
}

ProductTable.propTypes = {
    dataBody: propTypes.array
};

export default ProductTable