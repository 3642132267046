import React from "react";
import {cCheckboxStyle} from "./cCheckbox-style";
import {ReactComponent as IconCheck} from "src/assets/icons/iconCheck.svg";

interface CCheckboxProps {
    checked: boolean
    onChange: (b: boolean) => void
}

export default function CCheckbox({onChange, checked}: CCheckboxProps) {
    const classes = cCheckboxStyle();

    return (
        <div className={`${classes.container} ${checked && classes.active}`}
             onClick={() => onChange(!checked)}
        >
            <IconCheck width={10} height={8}/>
        </div>

    );
}

