import React, {useContext} from "react";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import {ReactComponent as IconDelete} from "src/assets/icons/delete.svg";
import {uploadVideoStyles} from "./upload-video-style";
import {useTranslation} from "react-multi-lang";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {GlobalContext} from "src/context/global-context";

export default function VideoInput({height, setVideo, video, deleteImg, edit, url, onChange, view}) {
    const inputRef = React.useRef();
    const classes = uploadVideoStyles()
    const t = useTranslation()
    const [source, setSource] = React.useState(video?.path ? video?.path : null);
    const {state, setState} = useContext(GlobalContext)

    const handleFileChange = (event) => {
        if (edit) {
            const formData = new FormData();
            formData.append("files", event.target.files[0]);
            setState({...state, loader: true})
            Axios.put(url, formData).then(res => {
                res.data?.pictures.forEach(item => {
                    if (item.is_video) {
                        const file = event.target.files[0];
                        setVideo(item)
                        const url = URL.createObjectURL(file);
                        setSource(url);
                    }
                })
                onChange(res.data.pictures)
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
                console.log(err, 'err')
            }).finally(() => {
                setState({...state, loader: false})
            })
        } else {
            const file = event.target.files[0];
            setVideo(file)
            const url = URL.createObjectURL(file);
            setSource(url);
        }
    };

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    const handleRemoveButton = () => {
        setSource(null);
        inputRef.current.value = '';
        setVideo(null)
        if (video.number) {
            deleteImg(video.number)
        }

    }

    return (
        <div>
            <label htmlFor={'btn'}>
                <input
                    ref={inputRef}
                    style={{display: 'none'}}
                    type="file"
                    onChange={handleFileChange}
                    accept=".mov,.mp4"
                />
            </label>
            {!source && <CButton
                backgroundColor={colors.blue}
                onPress={handleChoose}
                title={t("seller_product.add_video_link")}
            />}
            {source && (
                <div className={classes.videoInput}>
                    {!view && <div
                        onClick={() => handleRemoveButton()}
                        className={classes.deleteImg}>
                        <IconDelete/>
                    </div>}
                    <video
                        className="VideoInput_video"
                        width="100%"
                        height={height}
                        controls
                        src={source}
                    />
                </div>
            )}
        </div>
    );
}
