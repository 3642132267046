import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const sellerRateStyle = makeStyles(() =>
    createStyles({
        rateContainer: {
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto 30px',
            "@media (max-width:1250px)": {
                width: '96%',
            },
        },
        rateTableContent: {
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: '20px',
            marginTop: '30px',
            "@media (max-width:768px)": {
                flexDirection: 'column',
            },
            '& .tableWrapper': {
                margin: 'unset'
            },
            '& .table thead tr th': {
                padding: '11px',
            },
            '& .table tbody tr td': {
                padding: '11px',
            }
        },
        rateTableWrapper: {
            width: '100%',
            marginRight: '20px',
            "@media (max-width:768px)": {
                marginRight: 0,
                '&:last-child': {
                    marginTop: 25
                },
            },
        },
        rateTableTitle: {
            fontSize: 12,
            color: colors.black,
            textTransform: 'uppercase',
            fontWeight: 600,
            lineHeight: '14px',
            paddingBottom: 12,
        },
        rateTableTitleBottom: {
            fontSize: 12,
            color: colors.black,
            textTransform: 'uppercase',
            fontWeight: 600,
            lineHeight: '14px',
            borderBottom: '1px solid rgba(29,56,101,.1)',
            paddingBottom: 12,
        },
        flexContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid rgba(29,56,101,.1)',
        },
        btnWrapper: {
            width: 149
        },
        switchBox: {
            display : 'flex',
            alignItems: 'center',
        },
        switchBoxClose: {
            fontSize: 12,
            color: colors.black,
            marginRight : 10
        },
        switchBoxOpen: {
            fontSize: 12,
            color: colors.black,
            marginLeft : 10
        }
    })
)