import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const sellerAddProductStyle = makeStyles(() =>
    createStyles({
        container: {
            maxWidth: 1200,
            width: '100%',
            margin: 'auto',
            "@media (max-width:1250px)": {
                width: '96%',
            },
        },
        containerSettings: {
            backgroundColor: colors.white,
            boxShadow: ' 0px 0px 96px rgba(0, 0, 0, 0.1)',
            padding: 40,
            marginTop: 30,
            "@media (max-width:768px)": {
                padding: 20,
                marginTop: 27,
            },
            "@media (max-width:480px)": {
                padding: 7,
                marginTop: 9,
            },
        },
        inputBlock: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        title: {
            fontSize: 14,
            color: colors.black,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            marginBottom: 27,
            "@media (max-width:480px)": {
                marginBottom: 9,
            },
        },
        selectWrapp: {
            width: 359,
            margin: '0 7px',
            "@media (max-width:1250px)": {
                width: '48%',
            },
            "@media (max-width:830px)": {
                width: '47%',
            },
            "@media (max-width:550px)": {
                width: '100%',
                margin: '0 0',
            },
        },

        inputPassword: {
            width: '48%',
            "@media (max-width:830px)": {
                width: '47%',
            },
            "@media (max-width:550px)": {
                width: '100%',
                margin: '0 0',
            },
        },
        containerPrivacyBtns: {
            marginTop: 40,
            display: 'flex',
            justifyContent: 'flex-end',
            "@media (max-width:768px)": {
                marginTop: 30,
            },
            "@media (max-width:480px)": {
                marginTop: 9,
                flexDirection: 'column-reverse'
            },
        },
        containerPrivacyBtnsBtnWrap: {
            width: 236,
            "@media (max-width:480px)": {
                width: '100%',
            },
        },
        containerPrivacySaveWrap: {
            width: 236,
            marginLeft: 10,
            "@media (max-width:480px)": {
                width: '100%',
                marginLeft: 0,
                marginBottom: 10

            },
        },
        uploadBlock: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 20,
            "@media (max-width:767px)": {
                flexDirection: 'column'
            },
        },
        uploadBlockWrapp: {
            width: '30%',
            "@media (max-width:767px)": {
                width: '100%',
                marginBottom: 10
            },
        },


        addProductContainer: {
            display: "flex",
            justifyContent: 'space-between',
            "@media (max-width:768px)": {
                flexDirection: "column"
            },
        },
        addProductContainerBlock: {
            width: '49%',
            height: 'auto',
            backgroundColor: colors.white,
            boxShadow: ' 0px 0px 96px rgba(0, 0, 0, 0.1)',
            padding: 40,
            marginTop: 30,
            "@media (max-width:768px)": {
                padding: 20,
                marginTop: 27,
                width: '100%',
            },
            "@media (max-width:480px)": {
                padding: 7,
                marginTop: 9,
            },
        },
        addStonesAttributesTitleBlock: {
            display: "flex",
            alignItems: 'center',
            marginTop: 60,
            marginBottom: 27,
            "@media (max-width:480px)": {
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginTop: 30,
            },
        },
        addStonesAttributesTitleBlockTitle: {
            fontSize: 14,
            color: colors.black,
            fontWeight: 'bold',
            textTransform: 'uppercase',
        },
        checkBoxWrapp: {
            display: "flex",
            alignItems: 'center',
            marginLeft: 20,
            '& p': {
                color: colors.black,
                fontSize: 16,
                marginLeft: 9,
                "@media (max-width:480px)": {
                    fontSize: 14,
                },
            },
            "@media (max-width:480px)": {
                marginLeft: 0,
                marginTop: 11
            },
        },
        liner: {
            width: '100%',
            backgroundColor: colors.grey,
            height: 1,
            marginTop: 60
        },
        imageList: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        imageListItem: {
            position: 'relative',
            marginRight: 14,
            marginBottom: 14,
            "@media (max-width:480px)": {
                width: 125,
                height: 125,
                marginRight: 8,
                marginBottom: 8,
            },
        },
        deleteImg: {
            position: 'absolute',
            backgroundColor: colors.red,
            top: 6,
            right: 19,
            width: 26,
            height: 26,
            borderRadius: "50%",
            display: "flex",
            alignItems: 'center',
            justifyContent: "center",
            cursor: "pointer",
            zIndex:0
        },
        uploadImg: {
            width: 125,
            height: 125,
            objectFit: 'cover',
            border: `1px solid ${colors.grey}`,
            "@media (max-width:480px)": {
                marginRight: 8,
                width: '100%',
                height: 125,
            },
        },
        containerSaveWrap: {
            marginTop: 49,
            marginBottom: 30,
            "@media (max-width:768px)": {
                marginTop: 32,
            },
            "@media (max-width:480px)": {
                marginTop: 24,
            },
        },
        checkBoxUndertabWrapp: {
            display: 'flex',
            alignItems: 'center',
            marginTop: 28,
            marginBottom: 28,
            '& p': {
                color: colors.black,
                fontSize: 16,
                marginLeft: 9,
                "@media (max-width:480px)": {
                    fontSize: 14,
                },
            },
        },
        textAreaWrapp: {
            height: 220,
            marginBottom: 20,
            "@media (max-width:768px)": {
                marginBottom: 20,
            },
            "@media (max-width:480px)": {
                height: 168,
                marginBottom: 30,
            },
        },
        clearBothUnderTab: {
            marginTop: 40,
            "@media (max-width:480px)": {
                marginTop: 10,
            },
        },
        selectedWrapp: {
            width: 359,
            margin: '0 7px',
            "@media (max-width:1250px)": {
                width: '48%',
            },
            "@media (max-width:830px)": {
                width: '47%',
            },
            "@media (max-width:550px)": {
                width: '100%',
                margin: '0 0',
            },
        },
        selectedWrapText: {
            margin: '10px 0 20px 0',
            paddingBottom: 10,
            fontSize: 14,
            color: colors.blue,
            fontWeight: "bold",
            borderBottom: '1px solid #ccc'
        },
        viewImageList: {
            display : "flex",
            flexWrap: "wrap",
            "& img" : {
                width: 150,
                height: 150,
                objectFit : "cover",
                margin : 5
            }
        },
        videoBlock:{
            position:'relative'
        },
        cropperWrapper: {
            marginTop: 20,
            position: 'relative',
            width: '100%',
            height: 400,
            background: '#333'
        },
        cropperButtonWrapper: {
            marginTop: 15,
        },
        sliderContainer: {
            display: 'flex',
            flex: '1',
            alignItems: 'center',
        },
        sliderLabel: {
            marginRight: 20,
        },
        calcBlock:{
            display : 'flex',
            alignItems: "center"
        },
        productOrderSection:{
            paddingTop:30
        },
        productOrder:{
            display:"flex",
            alignItems:'center',
            margin:'40px 0'
        },
        productOrderInput:{
            width:170
        },
        productOrderButton:{
            width:130,
            marginLeft:5
        }
    })
)
