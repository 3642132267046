import CSearchInput from "src/cors/searchInput/searchInput";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-multi-lang";
import CSelect from "src/cors/select/cSelect";
import {sellerDetailStyle} from "./edit-seller-details-style";

export default function EditSellerDetailsUnderHeader(
    {
        search,
        onChange,
        updateByRate,
        handleClear,
        searchValue
    }
) {
    const classes = sellerDetailStyle()
    const t = useTranslation()

    return (
        <div className={classes.sellerDetailsUnderHeader}>
            <div className={classes.inputWrap}>
                <CSearchInput
                    handleSearch={search}
                    onChange={onChange}
                    handleClear={handleClear}
                    value={searchValue}
                />
            </div>
            <div className={classes.selectWrapperUnderHeader}>
                <CSelect
                    onChange={updateByRate}
                    showErrorMessage={false}
                    defaultValue={{
                        label: t(`header.sort_by`) ,
                        value: 'all'
                    }}
                    options={[
                        {label: t("header.all"), value: 'all'},
                        {label: t("status.pending_approval"), value: 'pending_approval'},
                        {label: t("status.approved"), value: 'approved'},
                        {label: t("status.rejected"), value: 'rejected'},
                        {label: t("status.updated_pending_approval"), value: 'updated_pending_approval'},
                    ]}
                />
            </div>
        </div>
    );
}

EditSellerDetailsUnderHeader.propTypes = {
    search: PropTypes.func,
    onChange: PropTypes.func,
    updateByRate: PropTypes.func,
    handleClear: PropTypes.func,
    searchValue: PropTypes.func
}