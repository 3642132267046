import React, { useEffect, useState } from "react";
import { Axios } from "../../../../api/api";
import { toast } from "react-toastify";
import { errorMessages } from "../../../../helpers/error-messages";
import { useTranslation } from "react-multi-lang";

export default function History() {
    const [orders, setOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage] = useState(7);
    const [loading, setLoading] = useState(false);
    const t = useTranslation();
    const [pending, setPending] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'desc' });
    const [totalGrams, setTotalGrams] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [status, setStatus] = useState();

    const headers = [
        { key: 'order_number', label: t('fix.number') },
        { key: 'created_at', label: t('fix.date') },
        { key: 'firstname', label: t('fix.name') },
        { key: 'carat', label: t('fix.carat') },
        { key: 'grams', label: t('fix.grams') },
        { key: 'rate', label: t('fix.rate') },
        { key: 'price', label: t('fix.amount') },
        { key: 'location', label: t('fix.location') },
        { key: 'arrive_date', label: t('fix.appointment_date') },
        {key: 'status', label: t('fix.status')}
    ];

    const getOrders = (page) => {
        setLoading(true);
        Axios.get(`preorder/orders/completed`, {
            params: {
                page,
                limit: itemsPerPage,
                search: searchTerm, // Include search term in the request
                sortKey: sortConfig.key,
                sortDirection: sortConfig.direction,
                status
            }
        })
            .then(res => {
                setOrders(res.data.orders);
                setTotalPages(res.data.totalPages); // Ensure your API returns totalPages
                setLoading(false);
            })
            .catch(err => {
                toast(errorMessages(err?.response?.data?.code));
                setLoading(false);
            });
    };

    useEffect(() => {
        const { totalGrams, totalPrice } = orders.reduce((acc, item) => {
            acc.totalGrams += item.grams;
            acc.totalPrice += item.price;
            return acc;
        }, { totalGrams: 0, totalPrice: 0 });

        setTotalGrams(totalGrams);
        setTotalPrice(totalPrice);
    }, [orders]);

    const sortFixing = (data) => {
        const { key, direction } = sortConfig;
        return [...data].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        }).filter(v => pending ? v.status === 0 : true);
    };

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortArrowSvg = (direction) => (
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            {direction === 'desc' ?
                <path
                    d="M7.00023 5.17266L11.9502 0.222656L13.3642 1.63666L7.00023 8.00066L0.63623 1.63666L2.05023 0.222656L7.00023 5.17266Z"
                    fill="#09121F"
                />
                : <path d="M7 2L11.95 6.05L10.536 7.464L7 3.928L3.464 7.464L2.05 6.05L7 2Z" fill="#09121F"/>
            }
        </svg>
    );

    useEffect(() => {
        getOrders(currentPage);
    }, [currentPage, searchTerm, sortConfig, status]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderPageNumbers = () => {
        let pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
                    <button onClick={() => handlePageChange(i)} className="page-link">
                        {i}
                    </button>
                </li>
            );
        }
        return pageNumbers;
    };

    return (
        <>
            {loading && <p>Loading...</p>}
            <div className='tableWrapper pre-order-table'>
                <div style={{marginBottom: '20px'}}>
                    <input
                        type="text"
                        placeholder={t('fix.search')}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div style={{marginBottom: '20px'}}>
                    <label>Sort by status</label>
                    <select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <option value="">{t('fix.all_statuses')}</option>
                        <option value="completed">{t('fix.completed')}</option>
                        <option value="failed">{t('fix.failed')}</option>
                        <option value="canceled">{t('fix.canceled')}</option>
                    </select>
                </div>
                {orders && orders.length ? (
                        <>
                        <table className="table">
                            <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>{t('fix.total')}</th>
                                <th><span style={{fontWeight: 'bold'}}>{totalGrams.toFixed(2)}</span> {t('fix.grams')}</th>
                                <th></th>
                                <th>$<span style={{fontWeight: 'bold'}}>{totalPrice.toFixed(2)}</span></th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                {headers.map(({key, label}) => (
                                    <th key={key} onClick={() => requestSort(key)} style={{cursor: "pointer"}}>
                                        {label} {sortConfig.key === key && sortArrowSvg(sortConfig.direction)}
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {sortFixing(orders).map((item, index) => (
                                <tr key={index.toString()} style={{
                                    backgroundColor: item.status === 'canceled' ? '#f8d7da' : 'transparent', // set the desired color for 'canceled'
                                }}>
                                    <td><span>{t('fix.number')}: </span>{item.order_number}</td>
                                    <td>
                                        <span>{t('fix.date')}: </span>{new Date(item.created_at).toLocaleDateString()} {new Date(item.created_at).toLocaleTimeString()}
                                    </td>
                                    <td><span>{t('fix.firstname')}: </span>{item.firstname}</td>
                                    <td><span>{t('fix.carat')}: </span>{Number(item.carat) === 995 ? '995' : '999.9'}
                                    </td>
                                    <td><span>{t('fix.grams')}: </span>{item.grams.toFixed(2)}</td>
                                    <td><span>{t('fix.rate')}: </span>{(item.price / item.grams).toFixed(2)}</td>
                                    <td><span>{t('fix.amount')}: </span>$ {item.price.toFixed(2)}</td>
                                    <td><span>{t('fix.location')}: </span>{item.location}</td>
                                    <td>
                                        <span>{t('fix.appointment_date')}: </span>{item.arrive_date && new Date(item.arrive_date).getFullYear() > 2023 ? new Date(item.arrive_date).toLocaleDateString() : ''} {item.arrive_date && new Date(item.arrive_date).getFullYear() > 2023 ? new Date(item.arrive_date).toLocaleTimeString('en-US', {
                                        timeZone: 'UTC',
                                        hour12: false
                                    }) : ''}</td>
                                    <td>
                                        <span>{t('fix.status')}: </span>
                                        {item.status === 'completed' ? (
                                            <p style={{color: 'green', fontWeight:'400'}}>{item.status}</p>
                                        ) : (
                                            <p style={{color: 'red', fontWeight:'400'}}>{item.status}</p>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    <nav>
                        <ul className="pagination">
                            {renderPageNumbers()}
                        </ul>
                    </nav>
                    </>
                    ) : <p>No orders found</p>}
            </div>
            </>
            );
            }
