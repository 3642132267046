import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const productSingleStyle = makeStyles(() =>
    createStyles({
        productSingleContainer: {
            maxWidth: 1200,
            width: '100%',
            margin: 'auto',
            "@media (max-width:1250px)": {
                width: '96%',
            },
        },
        title: {
            fontSize: 32,
            lineHeight: '32px',
            fontWeight: 400,
            color: colors.blue,
            margin: '30px 0',
        },
        imageContainer: {
            display: 'flex',
            alignItems: 'flex-end',
        },
        productImage: {
            width: 100,
            height: 100,
            objectFit:'cover',
            cursor: 'pointer',
            '&:not(:last-child)': {
                marginRight: 24,
            },
            "@media (max-width:600px)": {
                width: 55,
                height: 55,
                border: `1px solid transparent`,
            },
        },
        productActiveImage: {
            width: 140,
            height: 140,
            border: `1px solid ${colors.blue}`,
            "@media (max-width:600px)": {
                width: 55,
                height: 55,
            },
        },
        modalImg: {
            width: '100%',
            height: '100%',
        },
        productTitle: {
            fontSize: 20,
            lineHeight: '31px',
            fontWeight: 400,
            margin: '24px 0 6px',
            "@media (max-width:600px)": {
                fontSize: 18,
                margin: '12px 0 0',
            },
        },
        productDetails: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            '& > div': {
                width: 307,
                '&': {
                    "@media (max-width:1050px)": {
                        width: '30%'
                    },
                    "@media (max-width:600px)": {
                        width: '100%'
                    },
                },
            },
        },
        descriptionContainer: {
            margin: '20px 0 30px'
        },
        descriptionTitle: {
            fontSize: 12,
            lineHeight: '14px',
            fontWeight: 400,
            paddingBottom: 10,
            textTransform: 'uppercase',
        },
        descriptionText: {
            fontSize: 14,
            lineHeight: '19px',
            fontWeight: 400,
            maxWidth: 645,
        },
        buttonsContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginBottom: 30,
            '& > div': {
                width: 208,
                '&:first-child': {
                    marginRight: 6
                }
            }
        },
        selectReasonModal: {
            marginTop: 20,
            '& textarea' :{
                height: 180,
            }
        },
        video:{
            width:300,
            height:300
        }
    })
)