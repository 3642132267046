import React, {useEffect, useState} from "react";
import {dashboardStyle} from "./dashboard-style";
import ActivityCard from "src/components/activityCard/activityCard";
import {colors} from "src/utils/color";
import DashboardTable from "./dashboardTable/dashboardTable";
import UnderHeader from "src/components/underHeader/underHeader";
import {Axios} from "src/api/api";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";


function Dashboard() {
    const classes = dashboardStyle()
    const [orders, setOrders] = useState([])
    const [activity, setActivity] = useState({})
    const t = useTranslation()

    const activityCardData = [
        {
            title: t("dashboard.today_new_users"),
            quantity: activity?.today_new_users,
            topColor: colors.blue
        },
        {
            title: t("dashboard.today_new_sellers"),
            quantity: activity.today_new_sellers,
            topColor: colors.green
        },
        {
            title: t("dashboard.today_new_orders"),
            quantity: activity.today_new_orders,
            topColor: colors.grayishBlue
        },
    ]

    useEffect(() => {
        Axios(`admin/dashboard?limit=3&offset=0`).then(res => {
            setOrders(res.data.orders)
            setActivity(res.data.activity)
            console.log(activity)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }, [])

    return (
        <div className={classes.dashboardContainer}>
            <UnderHeader title={t("header.dashboard")} />
            <div className={classes.activitySection}>
                <div className='space-between-container'>
                    <p className='mainTitle'>{t("dashboard.activity")}</p>
                </div>
                <div className={classes.wrapper}>
                    <ActivityCard activityCardData={activityCardData}/>
                </div>
            </div>
            {orders.length > 0 && <div className={classes.tableSection}>
                <div className='space-between-container'>
                    <p className='mainTitle'>{t("dashboard.order_list")}</p>
                </div>
                <DashboardTable dataBody={orders}/>
            </div>}
        </div>
    );
}

export default Dashboard;