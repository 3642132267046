import UnderHeader from "src/components/underHeader/underHeader";
import {sellerDetailStyle} from "./seller-details-style";
import CTab from "src/cors/tab/cTab";
import React, {useContext, useEffect, useState} from "react";
import CInput from "src/cors/input/cInput";
import SellerDetailsOfferTable from "./sellerDetailsTable/sellerDetailsOfferTable";
import SellerDetailsOrderTable from "./sellerDetailsTable/sellerDetailsOrderTable";
import CButton from "src/cors/button/cButton";
import CTextarea from "src/cors/textarea/cTextarea";
import SellerDetailsProductTable from "./sellerDetailsTable/sellerDetailsProductTable";
import {useLocation, useNavigate} from "react-router-dom";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import SellerDetailsUnderHeader from "./seller-details-under-header";
import {GlobalContext} from "src/context/global-context";
import CRadio from "src/cors/radio/cRadio";


const markets = [
    {label: 'Gold Center', value: 'gold_center'},
    {label: 'Dalma Gold Center', value: 'dalma_gold_center'},
    {label: 'Tashir Gold Center', value: 'tashir_gold_center'},
    {label: 'Malatia Gold Center', value: 'malatia_gold_center'},
]


function SellerDetail() {
    const classes = sellerDetailStyle()
    const t = useTranslation()
    const navigation = useNavigate()
    const location = useLocation()
    const {state, setState} = useContext(GlobalContext)
    const [status, setStatus] = useState('all')
    const [searchValue, setSearchValue] = useState('')
    const [active, setActive] = useState(0)
    const [productActive, setProductActive] = useState(0);
    const [data, setData] = useState([]);
    const [showResetInput, setShowResetInput] = useState(false);
    const [sellerProducts, setSellerProducts] = useState([])
    const [sellerOfferData, setSellerOfferData] = useState([])
    const [sellerOrderData, setSellerOrderData] = useState([])
    const [newPassword, setNewPassword] = useState('')
    const [offsetProducts, setOffsetProducts] = useState(0)
    const [offsetOrder, setOffsetOrder] = useState(0)
    const [offsetOffer, setOffsetOffer] = useState(0)
    const [total_countOrder, setTotal_countOrder] = useState(0)
    const [total_countOffer, setTotal_countOffer] = useState(0)
    const [total_countProducts, setTotal_countProducts] = useState(0)
    const [userOrdering, setUserOrdering] = useState('')
    const [radios, setRadios] = useState([
        {checked: false, title: t("seller.seller"), role_id: 2, value: 'seller'},
        {checked: false, title: t("seller.manufacturer"), role_id: 3, value: 'manufacturer'},
    ])
    const getMarket = () => {
        let market = ''
        markets.forEach(item => {
            if (item.value === data.market) {
                market = item.label
            }
        })
        return market
    }

    const requestProducts = () => {
        Axios(`catalog/admin/${location?.state?.id}/${status}?limit=10&offset=0`).then(res => {
            setSellerProducts(res.data.catalog)
            setTotal_countProducts(res?.data?.total_count)
            setOffsetProducts(0)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    const handleChangeStatus = (e) => {
        setStatus(e.value)
        setOffsetProducts(0)
        setSearchValue('')

    }

    const showMoreProducts = () => {
        Axios(`catalog/admin/${location.state.id}/${status}?key=${searchValue}&limit=10&offset=${offsetProducts + 10}`).then(res => {
            setSellerProducts([...sellerProducts, ...res.data.catalog])
            setOffsetProducts(offsetProducts + 10)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    const showMoreOrder = () => {
        Axios(`catalog/admin/${location.state.id}?limit=10&offset=${offsetOrder + 10}`).then(res => {
            setSellerOrderData([...sellerOrderData, ...res.data.catalog])
            setOffsetOrder(offsetOrder + 10)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    const showMoreOffer = () => {
        Axios(`admin/seller_offer/${location.state.id}/all?limit=10&offset=${offsetOffer}`).then(res => {
            setSellerOfferData([...sellerOfferData, ...res.data.catalog])
            setOffsetOffer(offsetOffer + 10)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    const deleteRequest = () => {
        Axios.put(`admin/deactivate_user`, {"user_id": location.state.id}).then(res => {
            toast(t("notification.deleted"))
            navigation('/seller')
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    const resetPasswordOpen = () => {
        setShowResetInput(true)
    }

    const resetPassword = () => {
        if (newPassword.length > 6) {
            Axios.put(`admin/reset_password`, {
                    "confirm_password": newPassword,
                    "password": newPassword,
                    "user_id": location.state.id
                }
            ).then(res => {
                toast(t("notification.password_reset"))
                setNewPassword('')
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
                console.log(err, 'err')
            })
            setShowResetInput(false)
        } else {
            toast(t("notification.length_must_be_more"))
        }
    }

    const passwordValue = (e) => {
        setNewPassword(e)
    }

    const changeUserType = (item) => {
        Axios.put(`admin/change_user_type`, {
            "user_id": location.state.id,
            "user_type": item.value
        }).then(res => {
            toast(t("notification.changed"))
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err')
        })
    }

    const search = () => {
        if (searchValue) {
            setState({...state, loader: true})
            Axios(`catalog/admin/${location?.state?.id}/${status}?key=${searchValue}&limit=10&offset=0`).then(res => {
                setSellerProducts(res.data.catalog)
                setTotal_countProducts(res?.data?.total_count)
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
            }).finally(() => {
                setState({...state, loader: false})
                setOffsetProducts(0)
            })
        } else if (searchValue === '') {
            requestProducts()
        }
    }

    const handleClear = () => {
        setSearchValue('')
        requestProducts()
    }

    const handleOrderingValue = (e) => {
        if (isNaN(+e)) {
            return
        }
        setUserOrdering(e)
    }

    const handleSetOrdering = () => {
        if (userOrdering) {
            setState({...state, loader: true})
            Axios.put(`admin/store_sort_value`, {
                "sort_value": +userOrdering,
                "store_id": location?.state?.id
            }).then(res => {
                toast(t("notification.changed"))
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
            }).finally(() => {
                setState({...state, loader: false})
            })
        }
    }

    useEffect(() => {
        Axios(`user/info/${location?.state?.id}`).then(res => {
            setUserOrdering(res?.data?.sort_value)
            let newRadios = [...radios]
            newRadios.forEach((item) => {
                if (item.role_id === res?.data?.role_id) {
                    item.checked = true
                } else {
                    item.checked = false
                }
            })
            setRadios(newRadios)
            let address = ''
            if (res?.data?.address) {
                address = JSON.parse(res.data.address)?.properties?.name
            }
            setData({...res.data, address})
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }, [])

    useEffect(() => {
        requestProducts()
    }, [status])

    useEffect(() => {
        Axios(`admin/seller_offer/${location.state.id}/all?limit=10&offset=0`).then(res => {
            setSellerOfferData(res.data.offers)
            setTotal_countOffer(res?.data?.total_count)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err')
        })
    }, [])

    useEffect(() => {
        Axios(`admin/seller_order/${location.state.id}/all?limit=10000&offset=0`).then(res => {
            setSellerOrderData(res.data.orders)
            setTotal_countOrder(res.data.total_count)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err')
        })
    }, [])

    return (
        <div>
            <div className={classes.container}>
                <UnderHeader
                    goBack={() => navigation('/seller')}
                    title={(data.firstname || data.lastname) && `${data.firstname} ${data.lastname}`}
                    back={true}
                    context={productActive === 1 ?
                        <SellerDetailsUnderHeader
                            updateByRate={handleChangeStatus}
                            search={search}
                            onChange={setSearchValue}
                            handleClear={handleClear}
                            searchValue={searchValue}

                        />
                        : null}
                />
                <div className={classes.containerTabWrapp}>
                    <CTab items={[{value: t("seller.personal_information"), key: 0}, {
                        value: t("seller.products"),
                        key: 1
                    }]}
                          active={productActive}
                          onPress={setProductActive}
                    />
                </div>
            </div>
            {productActive === 0 ?
                <div className={classes.personalInfoContainer}>
                    {data.main_banner && <div className={classes.sellerDetailBlock}
                                              style={{backgroundImage: `url(${data.main_banner})`}}>
                    </div>}

                    <div className={classes.container}>
                        <div className={classes.sellerDetailsLogoWrapper}>
                            {data.logo && <img src={`${data.logo}`} alt="logo"/>}
                        </div>
                        <div className={classes.inputBlock}>
                            <div className={classes.inputWrapp}>
                                <CInput
                                    value={data.firstname}
                                    disabled={true}
                                    label={t("setting.name")}
                                    placeholder={t("setting.name")}
                                />
                            </div>
                            <div className={classes.inputWrapp}>
                                <CInput
                                    value={data.lastname}
                                    disabled={true}
                                    label={t("setting.surname")}
                                    placeholder={t("setting.surname")}
                                />
                            </div>
                            <div className={classes.inputWrapp}>
                                <CInput
                                    value={data.company_name}
                                    disabled={true}
                                    label={t("setting.company_name")}
                                    placeholder={t("setting.company_name")}
                                />
                            </div>
                            <div className={classes.inputWrapp}>
                                <CInput
                                    value={data.phone}
                                    disabled={true}
                                    type={'phone'}
                                    label={t("setting.phone")}
                                    placeholder={t("setting.phone")}
                                />
                            </div>
                            <div className={classes.inputWrapp}>
                                <CInput
                                    value={data.viber}
                                    disabled={true}
                                    label={t("setting.viber")}
                                    placeholder={t("setting.viber")}
                                />
                            </div>
                            <div className={classes.inputWrapp}>
                                <CInput
                                    value={data.whatsapp}
                                    disabled={true}
                                    label={t("setting.whats_app")}
                                    placeholder={t("setting.whats_app")}
                                />
                            </div>

                            <div className={classes.inputWrapp}>
                                <CInput
                                    value={t(`setting.${data.country}`)}
                                    disabled={true}
                                    label={t("setting.country")}
                                    placeholder={t("setting.country")}
                                />
                            </div>
                            <div className={classes.inputWrapp}>
                                <CInput
                                    value={t(`region.${data.region}`)}
                                    disabled={true}
                                    label={t("setting.region")}
                                    placeholder={t("setting.region")}
                                />
                            </div>
                            <div className={classes.inputWrapp}>
                                <CInput
                                    value={getMarket()}
                                    disabled={true}
                                    label={t("setting.market")}
                                    placeholder={t("setting.market")}
                                />
                            </div>
                            <div className={`${classes.inputWrapp} ${classes.inputWrappAddress}`}>
                                <CInput
                                    value={data.address}
                                    disabled={true}
                                    label={t("setting.address")}
                                    placeholder={t("setting.address")}
                                />
                            </div>
                            <div className={classes.inputWrapp}>
                                <CInput
                                    value={data.floor}
                                    disabled={true}
                                    label={t("setting.floor")}
                                    placeholder={t("setting.floor")}
                                />
                            </div>
                            <div className={classes.inputWrapp}>
                                <CInput
                                    value={data.shop_number}
                                    disabled={true}
                                    label={t("setting.shop")}
                                    placeholder={t("setting.shop")}
                                />
                            </div>
                            <div className={classes.inputWrapp}>
                                <CInput
                                    value={data.email}
                                    disabled={true}
                                    label={t("setting.email")}
                                    placeholder={t("setting.email")}
                                />
                            </div>
                        </div>
                        <div className={classes.textAreaWrapp}>
                            <CTextarea
                                value={data.description}
                                disabled={true}
                                label={t("setting.description")}
                                placeholder={t("setting.description")}
                            />
                        </div>
                        <div className={classes.resetWrapper}>
                            {showResetInput ?
                                <div className={classes.resetInputWrapper}>
                                    <CInput
                                        onChange={(e) => passwordValue(e.target.value)}
                                        type='password'
                                        label={t("seller.reset_password")}
                                        placeholder={t("setting.password")}
                                    />
                                    <p onClick={resetPassword}
                                       className={classes.applyBtn}>{t("project.apply")}</p>
                                </div>
                                : <div className={classes.buttonWrapper}>
                                    <CButton
                                        onPress={resetPasswordOpen}
                                        title={t("seller.reset_password")}
                                        borderWidth={1}
                                    />
                                </div>}
                            <div>
                                <p onClick={deleteRequest}
                                   className={classes.deactivate}>{t("seller.deactivate_user")}</p>
                            </div>
                        </div>
                        <div className={classes.selectUserType}>
                            <div className={classes.radio_wrapper}>
                                <CRadio radioList={radios} onChange={setRadios} onClick={changeUserType}/>
                            </div>
                        </div>

                        <div className={classes.resetInputWrapper}>
                            <CInput
                                onChange={(e) => handleOrderingValue(e.target.value)}
                                label={`${t("seller.set_ordering")} (0-20)`}
                                placeholder={t("seller.set_ordering")}
                                value={userOrdering}
                            />
                            <p onClick={handleSetOrdering}
                               className={classes.applyBtn}>{t("project.apply")}</p>
                        </div>

                    </div>
                    <div className={classes.tabWrapper}>
                        <CTab
                            onPress={setActive}
                            active={active}
                            items={[{value: t("seller.order_history"), key: 0}, {
                                value: t("seller.offers_history"), key: 1
                            }]}
                        />
                    </div>
                    {active === 0 ? <>
                        <SellerDetailsOrderTable dataBodyOrderSeller={sellerOrderData}/>
                        {(sellerOrderData.length !== total_countOrder) && <div className={classes.seeMoreBtnWrapper}>
                            <CButton
                                onPress={showMoreOrder}
                                title={t("project.show_more")}
                                borderWidth={1}
                            />
                        </div>}
                    </> : null}
                    {active === 1 ? <>
                        <SellerDetailsOfferTable dataBodyOfferSeller={sellerOfferData}/>
                        {(sellerOfferData.length !== total_countOffer) && <div className={classes.seeMoreBtnWrapper}>
                            <CButton
                                onPress={showMoreOffer}
                                title={t("project.show_more")}
                                borderWidth={1}
                            />
                        </div>}
                    </> : null}
                </div> : null}
            {productActive === 1 ?
                <div className={classes.productTableWrapper}>
                    <SellerDetailsProductTable dataBody={sellerProducts} sellerId={location?.state?.id}/>
                    {(sellerProducts.length !== total_countProducts) && <div className={classes.seeMoreBtnWrapper}>
                        <CButton
                            onPress={showMoreProducts}
                            title={t("project.show_more")}
                            borderWidth={1}
                        />
                    </div>}
                </div> : null}
        </div>
    );
}

export default SellerDetail;