import {sellerCustomPriceStyle} from "./seller-custom-price-style";
import SellerCustomPriceUnderHeader from "./seller-custom-price-under-header";
import React, {useCallback, useContext, useEffect, useState} from "react";
import CRadio from "src/cors/radio/cRadio";
import SellerCustomPriceTable from "./seller-custom-price-table/seller-custom-price-table";
import {CModal} from "src/cors/modal/cModal";
import UpdateRateModal from "./modals/updateRateModal";
import CreateCategoryModal from "./modals/createCategoryModal";
import {Axios} from "src/api/api";
import {GlobalContext} from "src/context/global-context";
import {useTranslation} from "react-multi-lang";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import UnderHeader from "src/components/underHeader/underHeader";
import ScrollInfiniteWrapper from "src/components/scroll-wrapper/scroll-wrapper";


const SellerCustomPrice = () => {
    const classes = sellerCustomPriceStyle();
    const {state, setState} = useContext(GlobalContext)
    const {custom_price_active: active = 'gold'} = state
    const t = useTranslation()
    const [radios, setRadios] = useState([
        {checked: true, title: t('metals.gold'), id: 0, value: "gold"},
        {checked: false, title: t('metals.silver'), id: 1, value: "silver"},
        {checked: false, title: t('metals.other'), id: 2, value: 'other'},
        {checked: false, title: t('metals.other_with_diamond'), id: 3, value: 'other_with_diamond'},
    ]);
    const [updateRate, setUpdateRate] = useState(false);
    const [category, setCategory] = useState(false);
    const [categoriesData, setCategoriesData] = useState([]);
    const [offset, setOffset] = useState('')
    const [total_count, setTotal_count] = useState(0)
    const [searchValue, setSearchValue] = useState('')
    const [period, setPeriod] = useState('')
    const [nextUpdate, setNextUpdate] = useState('')
    const [scrolling, setScrolling] = useState(false)
    const [deleteModalState, setDeleteModalState] = useState(false)

    const request = useCallback(() => {
        setState({...state, loader: true})
        Axios(`categories/${active}?key=${searchValue}&limit=10&offset=${offset}`).then(res => {
            setCategoriesData([...categoriesData, ...res.data.categories])
            setTotal_count(res?.data?.total_count)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        }).finally(() => {
            setState({...state, loader: false})
            setScrolling(false)
        })
    }, [offset, searchValue])

    const updateView = () => {
        setCategoriesData([])
        setOffset(prev => prev === 0 ? '' : 0)
    }

    const deleteCategory = (id) => {
        setState({...state, loader: true})
        Axios.put(`categories/${active}/delete?category_id=${id}`).then(res => {
            updateView()
            setSearchValue('')
            setDeleteModalState(false)
            toast(t('project.deleted'))
        }).catch(err => {
            console.log(err, 'err')
            toast(errorMessages(err?.response?.data?.code))
        }).finally(() => {
            setState({...state, loader: false})
        })
    }

    const search = () => {
        if (searchValue) {
            updateView()
        }
    }

    const showMore = () => {
        setOffset(+offset + 10)
    }

    const changeRadios = (arr) => {
        setRadios(arr)
        const activeRadio = radios.find(item => item.checked).value
        setState({...state, custom_price_active: activeRadio})
        updateView()
    }

    const updateByRate = (event) => {
        setState({...state, loader: true})
        Axios.put(`categories/${active}/all/period?period=${event.value}`).then(res => {
            updateView()
            toast(t("project.saved"))
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err)
        }).finally(() => {
            setState({...state, loader: false})
        })
    }

    const handleClear = () => {
        setSearchValue('')
        updateView()
    }

    useEffect(() => {
        let arr = radios.map(item => {
            if (item.value === active) {
                return {...item, checked: true}
            } else {
                return {...item, checked: false}
            }
        })
        setRadios(arr)
    }, [state.custom_price_active])

    useEffect(() => {
        request()
    }, [offset])

    useEffect(() => {
        Axios(`categories/${active}/all/period`).then(res => {
            setNextUpdate(res.data.next_update)
            setPeriod(res.data.period)
        })
    }, [])

    useEffect(() => {
        if (scrolling && total_count !== categoriesData?.length) {
            showMore()
        }
    }, [scrolling])

    return (
        <ScrollInfiniteWrapper
            scrollUpdate={setScrolling}
            context={<div className={classes.sellerCustomPriceContainer}>
                <UnderHeader
                    title={t('header.categories')}
                    context={<SellerCustomPriceUnderHeader
                        openCategoryModal={() => setCategory(true)}
                        openModal={() => setUpdateRate(true)}
                        search={search}
                        onChange={setSearchValue}
                        updateByRate={updateByRate}
                        period={period}
                        handleClear={handleClear}
                        searchValue={searchValue}
                        nextUpdate={nextUpdate}
                    />}
                />
                <div className={classes.radioGroup}>
                    <CRadio
                        radioList={radios}
                        onChange={changeRadios}
                    />
                </div>
                <p className={classes.total_count}>{`${t('seller_product.total_count')}: ${total_count ? total_count : 0}`}</p>
                <SellerCustomPriceTable
                    dataBody={categoriesData}
                    deleteCategory={deleteCategory}
                    type={active}
                    updateView={updateView}
                    deleteModalState={deleteModalState}
                    setDeleteModalState={setDeleteModalState}
                />
                <CModal
                    center={false}
                    title={t("categories.update_rate")}
                    closeModal={() => setUpdateRate(false)}
                    visible={updateRate}
                    children={updateRate &&
                        (<UpdateRateModal
                                closeModal={setUpdateRate}
                                type={radios.find(item => item.checked).value}
                                requestGetAll={updateView}
                            />
                        )}
                />
                {category && <CModal
                    center={false}
                    title={t('categories.create_category')}
                    closeModal={() => setCategory(false)}
                    visible={category}
                    children={<CreateCategoryModal
                        closeModal={setCategory}
                        type={radios.find(item => item.checked).value}
                        request={updateView}
                        setOffset={setOffset}
                    />}
                />}
            </div>}
        />
    )
}

export default SellerCustomPrice