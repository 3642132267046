import UnderHeader from "src/components/underHeader/underHeader";
import CInput from "src/cors/input/cInput";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import React, {useContext, useState} from "react";
import {Axios} from "src/api/api";
import {checkError} from "src/helpers/check-error";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import {translationPageStyle} from "./translation-page-style";
import CSearchInput from "src/cors/searchInput/searchInput";
import {GlobalContext} from "src/context/global-context";

function TranslationPageSection() {
    const t = useTranslation()
    const classes = translationPageStyle()
    const {state, setState} = useContext(GlobalContext)
    const [searchValue, setSearchValue] = useState('')
    const [newTranslation, setNewTranslation] = useState('')
    const [data, setData] = useState({
        am: '',
        en: '',
        ru: '',
    })

    const [errorData, setErrorData] = useState({
        am: '',
        en: '',
        ru: '',
    })
    const search = () => {
        if (searchValue) {
            setData({
                am: '',
                en: '',
                ru: '',
            })
            Axios(`translation/${searchValue}`).then(res => {

                if (res.data.am || res.data.en || res.data.ru){
                    setNewTranslation(searchValue)
                }else {
                    setNewTranslation('')
                }
                setData(res.data)
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
                console.log(err, 'err')
            })
        }
    }


    const handleChangeInfo = (value, input) => {
        if (value.length) {
            setErrorData({...errorData, [input]: ''})
        }
        let info = {...data}
        info[input] = value
        setData(info)
    }


    const saveTranslation = () => {
        if (newTranslation && data.am && data.en && data.ru) {
            Axios.put(`translation/${newTranslation}`, data).then(res => {
                toast(t("notification.saved"))
                setNewTranslation('')
                setData({
                    am: '',
                    en: '',
                    ru: '',
                })
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
            }).finally(() => {
                setState({...state, loader: false})

            })
        } else {
            checkError(data, setErrorData)
        }
    }

    const handleClear = () => {
        setData({
            am: '',
            en: '',
            ru: '',
        })
        setSearchValue('')
        setNewTranslation('')
    }

    return (
        <div >
            <UnderHeader
                title={t("header.translations")}
                context={<div className={classes.flexContainer}>
                    <div className={classes.searchInputWrap}>
                        <CSearchInput
                            value={searchValue}
                            handleSearch={search}
                            onChange={setSearchValue}
                            handleClear={handleClear}
                        />
                    </div>
                </div>}
            />
            <div className={classes.containerSettings}>
                <p className={classes.title}>{t("language.languages")}</p>
                <div className={classes.inputWrapp}>
                    <CInput
                        value={newTranslation}
                        onChange={(e) => setNewTranslation(e.target.value)}
                        label={t("language.new_translation")}
                        placeholder={t("language.new_translation")}
                        errorMessage={errorData.am}
                    />
                </div>
                <div className={classes.inputBlock}>
                    <div className={classes.inputWrapp}>
                        <CInput
                            value={data.am}
                            onChange={(e) => handleChangeInfo(e.target.value, 'am')}
                            label={t("language.am")}
                            placeholder={t("language.am")}
                            errorMessage={errorData.am}
                        />
                    </div>
                    <div className={classes.inputWrapp}>
                        <CInput
                            value={data.en}
                            onChange={(e) => handleChangeInfo(e.target.value, 'en')}
                            errorMessage={errorData.en}
                            label={t("language.en")}
                            placeholder={t("language.en")}
                        />
                    </div>
                    <div className={classes.inputWrapp}>
                        <CInput
                            value={data.ru}
                            errorMessage={errorData.ru}
                            onChange={(e) => handleChangeInfo(e.target.value, 'ru')}
                            label={t("language.ru")}
                            placeholder={t("language.ru")}
                        />
                    </div>
                </div>
                <div className={classes.containerPrivacyBtns}>
                    <div className={classes.containerPrivacySaveWrap}>
                        <CButton
                            onPress={saveTranslation}
                            title={t("project.save")}
                            backgroundColor={colors.blue}
                        />
                    </div>
                </div>
            </div>


        </div>
    );
}

export default TranslationPageSection;



