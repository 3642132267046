export const errorMessages =
    (type = '') => {
        switch (type) {
            case "internal-0001" :
            case "internal-0002" :
            case "internal-0003" :
            case "internal-0004" :
            case "entity-00012" :
                return 'Internal Server Error: The server encountered an internal error or misconfiguration and was unable to complete your request.';
            case 'auth-0001':
            case 'auth-0002':
            case 'auth-0003':
            case 'auth-0004':
            case 'auth-0005':
            case 'auth-0006':
            case 'auth-0007':
            case 'auth-0008':
                return 'Authorization error: User credentials are invalid or user is denied access.';
            case 'entity-0001':
            case 'entity-0002':
            case 'entity-00010':
            case 'entity-00011':
            case 'entity-00014':
            case 'entity-00019':
            case 'entity-00020':
            case 'entity-00021':
            case 'entity-00022':
            case 'entity-00023':
            case 'entity-00027':
            case 'entity-00028':
            case 'entity-00029':
                return 'Bad Request: a request failed due to providing bad input.';
            case 'entity-0005' :
            case 'entity-0006' :
            case 'entity-0007' :
                return 'Parameter is not valid.';
            case 'entity-0008' :
                return 'Passwords do not match.';
            case 'entity-00013' :
                return 'Unable to delete address selected as a default one.';
            case 'entity-00015' :
                return 'You already have active offer.'
            case 'entity-00016' :
                return 'The product price is determined to be final.';
            case 'entity-00017':
                return 'The offer limit is over.'
            case 'entity-00018':
                return 'The cart is empty.'
            case 'entity-00024':
                return 'A cart can only contain products from one store.'
            case 'entity-00025':
                return 'Invalid phone number.'
            case "entity-00026" :
                return 'Photo cannot be deleted being a main one.'
            case "entity-00030" :
                return 'Invalid fineness out of (0-999.9] range.'
            case "info-0001" :
                return 'Wait for admin confirmation, please.'
            case "entity-00036":
                return "duplicate product code"
            case "entity-00037":
                return "product code cannot contain characters other than - _"
            case "ipay-0000" :
            case "ipay-0001" :
            case "ipay-0002" :
            case "ipay-0003" :
            case "ipay-0004" :
            case "ipay-0005" :
            case "ipay-0006" :
            case "ipay-0007" :
                return 'Unable to process payment. Please, contact admin for details.'
            default :
                return 'something went wrong'
        }
    }