import CButton from "src/cors/button/cButton";
import CSearchInput from "src/cors/searchInput/searchInput";
import {sellerCustomPriceStyle} from "./seller-custom-price-style";
import {colors} from "src/utils/color";
import React from "react";
import settingsIcon from "src/assets/icons/settingsIcon.svg";
import {useTranslation} from "react-multi-lang";
import CSelect from "src/cors/select/cSelect";
import propTypes from "prop-types";

export default function SellerCustomPriceEditUnderHeader({
                                                             openMoveModal,
                                                             period,
                                                             updateByRate,
                                                             openUpdateModal,
                                                             moveButtonState,
                                                             search,
                                                             onChange,
                                                             handleClear,
                                                             searchValue,
                                                             nextUpdate
                                                         }) {

    const classes = sellerCustomPriceStyle()
    const t = useTranslation()

    let diffTime = Math.abs(new Date().valueOf() - new Date(nextUpdate).valueOf());
    let days = diffTime / (24*60*60*1000);
    let hours = (days % 1) * 24;
    let minutes = (hours % 1) * 60;
    let secs = (minutes % 1) * 60;
    [days, hours, minutes, secs] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(secs)]


    return (
        <div className={classes.sellerCustomPriceUnderHeader}>
            <div className={classes.inputWrap}>
                <CSearchInput
                    handleSearch={search}
                    onChange={onChange}
                    value={searchValue}
                    handleClear={handleClear}
                />
            </div>
            <div className={classes.buttonWrap}>
                <CButton
                    onPress={openMoveModal}
                    title={t('categories.move')}
                    backgroundColor={colors.blue}
                    disable={!moveButtonState}
                />
            </div>
            <div className={classes.sellerCustomPriceOptions}>
                <div onClick={openUpdateModal} className={classes.settingsIconContainer}>
                    <img src={settingsIcon} alt="settings icon"/>
                </div>
                <div className={classes.sellerCustomSelect}>
                    {period &&
                        <CSelect
                            onChange={updateByRate}
                            showErrorMessage={false}
                            defaultValue={{
                                label: nextUpdate  ? `${t('categories.next_update')}: ${days}d, ${hours}h, ${minutes}m, ${secs}s`: t(`categories.update_all_by_rate`) ,
                                value: period
                            }}
                            options={[
                                {label: t("categories.now"), value: 'now'},
                                {label: t("categories.every_1"), value: 'every_1'},
                                {label: t("categories.every_15"), value: 'every_15'},
                                {label: t("categories.every_30"), value: 'every_30'}
                            ]}
                        />}
                </div>
            </div>
        </div>
    );
}

SellerCustomPriceEditUnderHeader.propTypes = {
    openMoveModal: propTypes.func,
    openUpdateModal: propTypes.func,
    updateByRate: propTypes.func,
    period: propTypes.any,
    moveButtonState: propTypes.any,
    onChange: propTypes.func,
    search: propTypes.func,
    handleClear: propTypes.func,
    searchValue: propTypes.string,
}