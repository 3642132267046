import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";


export const cCheckboxStyle = makeStyles(() =>
    createStyles({
        container: {
            width: 16,
            height: 16,
            outline: 'none',
            border: `1px solid ${colors.mainGrey}`,
            borderRadius: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: 'pointer',
            '&  svg': {
                fill: colors.white,
            },
        },
        active: {
            backgroundColor: colors.blue,
            border: `1px solid ${colors.blue}`,
        },
        disable: {
            backgroundColor: colors.blue,
            border: `1px solid ${colors.blue}`,
            opacity: .3
        }

    })
);
