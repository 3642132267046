import CButton from "src/cors/button/cButton";
import CSearchInput from "src/cors/searchInput/searchInput";
import PropTypes from "prop-types";
import {sellerCustomPriceStyle} from "./seller-custom-price-style";
import {colors} from "src/utils/color";
import React from "react";
import settingsIcon from "src/assets/icons/settingsIcon.svg";
import {useTranslation} from "react-multi-lang";
import CSelect from "src/cors/select/cSelect";

export default function SellerCustomPriceUnderHeader({
                                                         openModal,
                                                         openCategoryModal,
                                                         search,
                                                         onChange,
                                                         updateByRate,
                                                         period,
                                                         searchValue,
                                                         handleClear,
                                                         nextUpdate
                                                     }) {
    const classes = sellerCustomPriceStyle()
    let diffTime = Math.abs(new Date().valueOf() - new Date(nextUpdate).valueOf());
    let days = diffTime / (24 * 60 * 60 * 1000);
    let hours = (days % 1) * 24;
    let minutes = (hours % 1) * 60;
    let secs = (minutes % 1) * 60;
    [days, hours, minutes, secs] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(secs)]

    const t = useTranslation()
    return (
        <div className={classes.sellerCustomPriceUnderHeader}>
            <div className={classes.inputWrap}>
                <CSearchInput
                    handleSearch={search}
                    onChange={onChange}
                    value={searchValue}
                    handleClear={handleClear}
                />
            </div>
            <div className={classes.buttonWrap}>
                <CButton
                    onPress={openCategoryModal}
                    title={`+ ${t('categories.create_category')}`}
                    backgroundColor={colors.blue}
                />
            </div>
            <div className={classes.sellerCustomPriceOptions}>
                <div onClick={openModal} className={classes.settingsIconContainer}>
                    <img src={settingsIcon} alt="settings icon"/>
                </div>
                <div className={classes.sellerCustomSelect}>
                    {period && <CSelect
                        onChange={updateByRate}
                        showErrorMessage={false}

                        defaultValue={{
                            label: nextUpdate ? `${t('categories.next_update')}: ${days}${t('categories.day')}, ${hours}${t('categories.hours')}, ${minutes}${t('categories.minute')}, ${secs}${t('categories.seconds')}` :
                                t(`categories.update_all_by_rate`),
                            value: period
                        }}
                        options={[
                            {label: t("categories.now"), value: 'now'},
                            {label: t("categories.every_1"), value: 'every_1'},
                            {label: t("categories.every_15"), value: 'every_15'},
                            {label: t("categories.every_30"), value: 'every_30'}
                        ]}
                    />}
                </div>
            </div>
        </div>
    );
}

SellerCustomPriceUnderHeader.propTypes = {
    openModal: PropTypes.func,
    openCategoryModal: PropTypes.func,
    search: PropTypes.func,
    onChange: PropTypes.func,
    updateByRate: PropTypes.func,
    handleClear: PropTypes.func,
    period: PropTypes.any,
    searchValue: PropTypes.string,
}