import React, {useContext, useEffect, useState} from 'react';
import {sellerStyle} from 'src/pages/admin-pages/seller/seller-style';
import CTab from "src/cors/tab/cTab";
import ActiveSellerTable from "src/pages/admin-pages/seller/sellerTable/activeSellerTable";
import RequestSellerTable from "src/pages/admin-pages/seller/sellerTable/requestSellerTable";
import {Axios} from "src/api/api";
import {toast} from 'react-toastify';
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import UnderHeader from "src/components/underHeader/underHeader";
import CSearchInput from "src/cors/searchInput/searchInput";
import {GlobalContext} from "src/context/global-context";
import {CModal} from "src/cors/modal/cModal";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";

const ServicesAdmin = () => {
    const classes = sellerStyle()
    const t = useTranslation()
    const {state, setState} = useContext(GlobalContext)
    const [active, setActive] = useState(0);
    const [activeServiceData, setActiveServiceData] = useState([]);
    const [requestsServiceData, setRequestsServiceData] = useState([]);
    const [searchValue, setSearchValue] = useState('')
    const [deleteRequestModal, setDeleteRequestModal] = useState(false)
    const [serviceID, setServiceID] = useState('')

    const search = () => {
        if (searchValue) {
            setState({...state, loader: true})
            if (active === 0) {
                Axios(`admin/search_active/service?key=${searchValue}&limit=1000&offset=0`).then(res => {
                    setActiveServiceData(res.data.users)
                }).catch(err => {
                    console.log(err)
                }).finally(() => {
                    setState({...state, loader: false})
                })
            } else if (active === 1) {
                Axios(`search/service/search_requests?key=${searchValue}&limit=1000&offset=0`).then(res => {
                    setRequestsServiceData(res.data.users)
                }).catch(err => {
                    console.log(err)
                }).finally(() => {
                    setState({...state, loader: false})
                })
            }
        } else if (searchValue === '') {
            if (active === 0) {
                requestServiceActives()
            } else if (active === 1) {
                requestServiceRequests()
            }
        }
    }

    const requestServiceRequests = () => {
        Axios(`admin/service/requests?limit=1000&offset=0`).then(res => {
            setRequestsServiceData(res.data.users)
        }).catch(err => {
            // toast(errorMessages(err?.response?.data?.code))
        })
    }
    const requestServiceActives = () => {
        Axios(`admin/active/service?limit=1000&offset=0`).then(res => {
            setActiveServiceData(res.data.users)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    const approveService = (id) => {
        Axios.put(`admin/approve/${id}`).then(res => {
            requestServiceRequests()
            requestServiceActives()
            toast(t("notification.approved"))
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    const deleteRequest = (id) => {
        setDeleteRequestModal(true)
        setServiceID(id)
    }

    const deleteService = () => {
        setState({...state, loader: true})
        Axios.put(`admin/deactivate_user`, {"user_id": serviceID}).then(res => {
            requestServiceRequests()
            requestServiceActives()
            toast(t("notification.deactivated"))
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        }).finally(()=>{
            setState({...state, loader: false})
            setDeleteRequestModal(false)
        })
    }

    const handleClear = () => {
        setSearchValue('')
        requestServiceActives()
        requestServiceRequests()
    }

    useEffect(() => {
        requestServiceActives()

    }, [])

    useEffect(() => {
        requestServiceRequests()
    }, [])


    return (
        <div className={classes.sellerContainer}>
            <UnderHeader
                title={t("header.services")}
                context={<div className={classes.flexContainer}>
                    <div className={classes.searchInputWrap}>
                        <CSearchInput
                            handleSearch={search}
                            onChange={setSearchValue}
                            handleClear={handleClear}
                            value={searchValue}
                        />
                    </div>
                </div>}
            />
            <div className={classes.tabWrapper}>
                <CTab
                    onPress={setActive}
                    active={active}
                    items={[{value: t('seller.active_service'), key: 0}, {
                        value: t('seller.service_requests'),
                        key: 1
                    }]}/>
            </div>

            {
                active === 1 && <RequestSellerTable
                    type={'service'}
                    deleteRequest={deleteRequest}
                    aproveSeller={approveService}
                    dataBodyRequestSeller={requestsServiceData}
                />
            }

            {
                active === 0 && <ActiveSellerTable
                    type={'service'}
                    deleteRequest={deleteRequest}
                    dataBodyActiveSeller={activeServiceData}
                />
            }
            {
                deleteRequestModal &&  <CModal
                    visible={deleteRequestModal}
                    closeModal={() => setDeleteRequestModal(false)}
                    center={true}
                    title={t('project.note')}
                    children={
                        <div>
                            <p style={{marginTop: 20}}>{t('notifications.if_you_deactivate_user')}</p>
                            <div className={classes.deleteModalBtns}>
                                <div className={classes.deleteModalBtnWrap}>
                                    <CButton
                                        onPress={() => setDeleteRequestModal(false)}
                                        borderWidth={1}
                                        title={t('seller_product.no')}
                                    />
                                </div>
                                <div className={classes.deleteModalBtnWrap}>
                                    <CButton
                                        onPress={deleteService}
                                        backgroundColor={colors.red}
                                        title={t('seller_product.yes')}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                />
            }
        </div>
    );
}

export default ServicesAdmin;
