import React, {useEffect, useState} from 'react';
import {soldStyle} from "./sold-style";
import {colors} from "src/utils/color";
import UnderHeader from "src/components/underHeader/underHeader";
import ActivityCard from "src/components/activityCard/activityCard";
import {Axios} from "src/api/api";
import SoldTable from "./soldTable/soldTable";
import CButton from "src/cors/button/cButton";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";


function Sold() {
    const classes = soldStyle()
    const t = useTranslation()
    const navigation = useNavigate()
    const [orders, setOrders] = useState([])
    const [sold, setSold] = useState({})

    useEffect(() => {
        Axios(`/sold`).then(res => {
            setSold(res.data.sold)
            let arr = []
            for (let i = 0; i < res.data.orders.length; i++) {
                if (i > 2) {
                    break;
                } else {
                    arr.push(res.data.orders[i])
                }
            }
            setOrders(arr)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }, [])

    const activityCardData = [
        {
            title: t("sold.total"),
            quantity: sold?.total_orders,
            topColor: colors.blue
        },
        {
            title: t("sold.completed"),
            quantity: sold?.completed_orders,
            topColor: colors.green
        },
        {
            title: t("sold.pending"),
            quantity: sold?.pending_orders,
            topColor: colors.grayishBlue
        },
        {
            title: t("sold.processing"),
            quantity: sold?.processing_orders,
            topColor: colors.strongBlue
        },
        {
            title: t("sold.canceled"),
            quantity: sold?.canceled_orders,
            topColor: colors.brightRed
        },
        {
            title: t("sold.refunded"),
            quantity: sold?.refunded_orders,
            topColor: colors.gold
        },
    ]

    return (
        <div className={classes.soldContainer}>
            <UnderHeader title={t("header.sold")} />
            <div className={classes.wrapper}>
                <ActivityCard activityCardData={activityCardData}/>
            </div>
            <div className={classes.tableWrapper}>
                <SoldTable dataBody={orders}/>
            </div>
            {orders.length > 2 && <div className={classes.seeMoreBtnWrapper}>
                <CButton
                    onPress={() => navigation('/order')}
                    title={t("project.show_more")}
                    borderWidth={1}
                />
            </div>}
        </div>
    );
}

export default Sold;