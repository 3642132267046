import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const sellerProductStyle = makeStyles(() =>
    createStyles({
        sellerProductContainer: {
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto 30px',
            "@media (max-width:1250px)": {
                width: '96%',
            },
        },
        selectWrapper: {
            marginTop: 28,
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${colors.greyLiner}`,
            paddingBottom: 20,
            '& > div': {
                width: 183,
            },
            '& > div:not(:last-child)': {
                marginRight: 20,
            },
            "@media (max-width:500px)": {
                flexDirection:"column",
                '& > div': {
                    width: '100%',
                    marginBottom:10
                },
                '& > div:not(:last-child)': {
                    marginRight: 0,
                },
            },
        },
        seeMoreBtnWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 30,
            '& > div': {
                width: 230
            },
            "@media (max-width:500px)": {
                marginBottom: 30
            },
        },
        sellerProductUnderHeader: {
            display: 'flex',
            marginLeft: 20,
            "@media (max-width:1100px)": {
                flexDirection: 'column-reverse',
                width: '100%',
            },
            "@media (max-width:480px)": {
                marginLeft: 0,
            },

        },
        buttonWrapp: {
            width: 208,
            marginRight: 20,
            "@media (max-width:768px)": {
                width: 137,
            },
            "@media (max-width:480px)": {
                width: '100%',
                marginBottom: 11,
            },
        },
        inputWrapp: {
            width: 211,
            "@media (max-width:768px)": {
                width: 137,
                marginBottom: 10,
            },
            "@media (max-width:480px)": {
                width: '100%',
            },
        },
        deleteModalBtns: {
            display: "flex",
            justifyContent: 'space-between',
            marginTop: 40,
            width: '100%',
        },
        addStonesAttributesTitleBlockTitle: {
            fontSize: 12,
            color: colors.black,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            marginBottom: 10
        },
        selectWrapp: {
            marginRight: 20,
            width: 211,
            "@media (max-width:768px)": {
                width: 137,
                marginBottom: 10,
            },
            "@media (max-width:480px)": {
                width: '100%',
                marginBottom: 11,
            },
        },
        product_code: {
            margin: '10px 0 30px',
            fontWeight: 'bold',
            fontSize: 20
        },
        cropperWrapper: {
            marginTop: 20,
            position: 'relative',
            width: '100%',
            height: 400,
            background: '#333'
        },
        cropperButtonWrapper: {
            marginTop: 15,
        },
        sliderContainer: {
            display: 'flex',
            flex: '1',
            alignItems: 'center',
        },
        sliderLabel: {
            marginRight: 20,
        },
        productCode: {
            fontWeight: 'bold',
            color: colors.blue,
            display: 'inline-block'
        },
        productImg: {
            height: 50,
            objectFit: "contein",
            border: '1px solid #ccc',
            marginRight: 15
        },
        total_count:{
            textAlign:"right",
            fontWeight:'bold',
            marginTop:10,
            fontSize:14
        }
    })
)
