import UnderHeader from "src/components/underHeader/underHeader";
import {sellerDetailStyle} from "src/pages/admin-pages/seller-details/seller-details-style";
import CTab from "src/cors/tab/cTab";
import React, {useEffect, useState} from "react";
import CInput from "src/cors/input/cInput";
import CButton from "src/cors/button/cButton";
import CTextarea from "src/cors/textarea/cTextarea";
import {useLocation, useNavigate} from "react-router-dom";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";

const markets = [
    {label: 'Gold Center', value: 'gold_center'},
    {label: 'Dalma Gold Center', value: 'dalma_gold_center'},
    {label: 'Tashir Gold Center', value: 'tashir_gold_center'},
    {label: 'Malatia Gold Center', value: 'malatia_gold_center'},
]

function ServiceDetail({}) {
    const navigation = useNavigate()
    const classes = sellerDetailStyle()
    const t = useTranslation()
    const location = useLocation()
    const [productActive, setProductActive] = useState(0);
    const [data, setData] = useState([]);
    const [showResetInput, setShowResetInput] = useState(false);
    const [newPassword, setNewPassword] = useState('')

    useEffect(() => {
        Axios(`user/info/${location.state.id}`).then(res => {
            setData(res.data)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }, [])

    const deleteRequest = () => {
        Axios.put(`admin/deactivate_user`, {"user_id": location.state.id}).then(res => {
            toast(t("notification.deleted"))
            navigation('/seller')
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    const resetPasswordOpen = () => {
        setShowResetInput(true)
    }

    const getMarket = () => {
        let market = ''
        markets.forEach(item => {
            if (item.value === data.market) {
                market = item.label
            }
        })
        return market
    }

    const resetPassword = () => {
        if (newPassword.length > 6) {
            Axios.put(`admin/reset_password`, {
                    "confirm_password": newPassword,
                    "password": newPassword,
                    "user_id": location.state.id
                }
            ).then(res => {
                toast(t("notification.password_reset"))
                setNewPassword('')
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
                console.log(err, 'err')
            })
            setShowResetInput(false)
        } else {
            toast(t("notification.length_must_be_more"))
        }
    }

    const passwordValue = (e) => {
        setNewPassword(e)
    }

    return (
        <div>
            <div className={classes.container}>
                <UnderHeader
                    goBack={() => navigation('/services')}
                    title={(data.firstname || data.lastname) && `${data.firstname} ${data.lastname}`}
                    back={true}
                />
                <div className={classes.containerTabWrapp}>
                    <CTab items={[{value: t("seller.personal_information"), key: 0}]}
                          active={productActive}
                          onPress={setProductActive}/>
                </div>
            </div>
            <div className={classes.personalInfoContainer}>
                {data.main_banner &&
                    <div className={classes.sellerDetailBlock} style={{backgroundImage: `url(${data.main_banner})`}}>
                    </div>}
                <div className={classes.sellerDetailsLogoWrapper}>
                    {data.logo && <img src={`${data.logo}`} alt="logo"/>}
                </div>
                <div className={classes.container}>
                    <div className={classes.inputBlock}>
                        <div className={classes.inputWrapp}>
                            <CInput
                                value={data.firstname}
                                disabled={true}
                                label={t("setting.name")}
                                placeholder={t("setting.name")}
                            />
                        </div>
                        <div className={classes.inputWrapp}>
                            <CInput
                                value={data.lastname}
                                disabled={true}
                                label={t("setting.surname")}
                                placeholder={t("setting.surname")}
                            />
                        </div>
                        <div className={classes.inputWrapp}>
                            <CInput
                                value={data.company_name}
                                disabled={true}
                                label={t("setting.company_name")}
                                placeholder={t("setting.company_name")}
                            />
                        </div>
                        <div className={classes.inputWrapp}>
                            <CInput
                                value={data.phone}
                                disabled={true}
                                label={t("setting.phone")}
                                placeholder={t("setting.phone")}
                            />
                        </div>
                        <div className={classes.inputWrapp}>
                            <CInput
                                value={data.viber}
                                disabled={true}
                                label={t("setting.viber")}
                                placeholder={t("setting.viber")}
                            />
                        </div>
                        <div className={classes.inputWrapp}>
                            <CInput
                                value={data.whatsapp}
                                disabled={true}
                                label={t("setting.whats_app")}
                                placeholder={t("setting.whats_app")}
                            />
                        </div>

                        <div className={classes.inputWrapp}>
                            <CInput
                                value={t(`setting.${data?.country}`)}
                                disabled={true}
                                label={t("setting.country")}
                                placeholder={t("setting.country")}
                            />
                        </div>
                        <div className={classes.inputWrapp}>
                            <CInput
                                value={t(`region.${data.region}`)}
                                disabled={true}
                                label={t("setting.region")}
                                placeholder={t("setting.region")}
                            />
                        </div>
                        <div className={classes.inputWrapp}>
                            <CInput
                                value={getMarket()}
                                disabled={true}
                                label={t("setting.market")}
                                placeholder={t("setting.market")}
                            />
                        </div>
                        <div className={`${classes.inputWrapp} ${classes.inputWrappAddress}`}>
                            <CInput
                                value={data.address}
                                disabled={true}
                                label={t("setting.address")}
                                placeholder={t("setting.address")}
                            />
                        </div>
                        <div className={classes.inputWrapp}>
                            <CInput
                                value={data.floor}
                                disabled={true}
                                label={t("setting.floor")}
                                placeholder={t("setting.floor")}
                            />
                        </div>
                        <div className={classes.inputWrapp}>
                            <CInput
                                value={data.shop_number}
                                disabled={true}
                                label={t("setting.shop")}
                                placeholder={t("setting.shop")}
                            />
                        </div>
                        <div className={classes.inputWrapp}>
                            <CInput
                                value={data.email}
                                disabled={true}
                                label={t("setting.email")}
                                placeholder={t("setting.email")}
                            />
                        </div>
                    </div>
                    <div className={classes.textAreaWrapp}>
                        <CTextarea
                            value={data.description}
                            disabled={true}
                            label={t("setting.description")}
                            placeholder={t("setting.description")}
                        />
                    </div>
                    <div className={classes.resetWrapper}>
                        {showResetInput ?
                            <div className={classes.resetInputWrapper}>
                                <CInput
                                    onChange={(e) => passwordValue(e.target.value)}
                                    type='text'
                                    label={t("seller.reset_password")}
                                    placeholder={t("setting.password")}
                                />
                                <p onClick={resetPassword}
                                   className={classes.applyBtn}>{t("project.apply")}</p>
                            </div>
                            : <div className={classes.buttonWrapper}>
                                <CButton
                                    onPress={resetPasswordOpen}
                                    title={t("seller.reset_password")}
                                    borderWidth={1}
                                />
                            </div>
                        }

                        <div>
                            {location.state.status !== 'active' && <p className={classes.activate}>{t("project.approve")}</p>}
                            <p onClick={deleteRequest} className={classes.deactivate}>{t("seller.deactivate_user")}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ServiceDetail;