import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const sellerVWarehouseStyle = makeStyles(() =>
    createStyles({
        container: {
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto 30px',
            ["@media (max-width:1250px)"]: {
                width: '96%',
            },
        },
        radioGroup: {
            display: 'flex',
            alignItems: 'center',
            marginTop: 20,
            paddingBottom: 20,
            borderBottom: `1px solid ${colors.greyLiner}`,
            '& p:not(:last-child)': {
                marginRight: 48,
                "@media (max-width:500px)": {
                    marginRight: 8,
                },
            },
        },
    })
)