import React from "react";
import {cRadioStyle} from "./cRadio-style";

interface CRadioProps {
    onChange: (a: Array<any>) => Array<any>
    radioList: Array<any>
    onClick: any
}


export default function CRadio({onChange, radioList, onClick = () => undefined}: CRadioProps) {
    const classes = cRadioStyle();

    return (
        <>
            {radioList?.length > 0 && radioList.map((item: any, index: number) => {
                return (
                    <React.Fragment key={index}>
                        <div
                            className={`${classes.container} ${item.checked && classes.active}`}
                            onClick={() => {
                                if (!item.checked) {
                                    const arr: Array<any> = [...radioList];
                                    arr.forEach((i) => {
                                        if (i.checked) i.checked = false;
                                    })
                                    arr[index].checked = true;
                                    onChange(arr)
                                    onClick(item)
                                }
                            }}
                        >
                            {item.checked && <div className={classes.radio}></div>}
                        </div>
                        {item.title && <p className={classes.title}>{item.title}</p>}
                    </React.Fragment>
                )
            })}
        </>
    );
}