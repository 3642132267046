export const monthDays = ()=>{
    const days = []
    for (let i = 1; i<32; i++){
        if (i<10){
            days.push( {label: `0${i}`, value:`0${i}`})
        }else {
            days.push( {label: i, value: i})
        }
    }
    return days
}

export const months = ()=>{
    const month = []
    for (let i = 1; i<13; i++){
        if (i<10){
            month.push( {label: `0${i}`, value:`0${i}`})
        }else {
            month.push( {label: i, value: i})
        }
    }
    return month
}

export const years = ()=>{
    const currentYear = new Date().getFullYear()
    const year = []
    for (let i = currentYear -2; i<= currentYear; i++){
        if (i<10){
            year.push( {label: `0${i}`, value:`0${i}`})
        }else {
            year.push( {label: i, value: i})
        }
    }
    return year.reverse()
}

export const weekDays = [
    {label: "Day", value: "day"},
    {label: "Week", value: "week"},
    {label: "Month", value: "month"},
    {label: "Year", value: "year"},
]