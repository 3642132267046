import {createStyles, makeStyles} from "@mui/styles";

export const dashboardStyle = makeStyles(() =>
    createStyles({
        dashboardContainer: {
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto 30px',
            "@media (max-width:1250px)": {
                width: '96%',
            },
        },
        activitySection: {
            marginTop: 20
        },
        wrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            margin: -10,
            "@media (max-width:500px)": {
                flexDirection: 'column',
                margin: 0
            },
        },
        tableSection: {
            marginTop: 50
        },
        messageSection: {
            marginTop: 60
        },
        tabWrapper: {
            marginBottom: 30,
            width: '100%',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            '-ms-overflow-style': 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            "@media (max-width:500px)": {
                marginTop: 20
            },

        }
    })
)