import {useCallback, useContext, useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import {useTranslation} from "react-multi-lang";
import propTypes from "prop-types";
import UnderHeader from "src/components/underHeader/underHeader";
import {Axios} from "src/api/api";
import {errorMessages} from "src/helpers/error-messages";
import SmallBtn from "src/cors/smallBtn/smallBtn";
import iconCheck from "src/assets/icons/iconCheck.svg";
import {colors} from "src/utils/color";
import removeBtn from "src/assets/icons/iconRemove.svg";
import CButton from "src/cors/button/cButton";
import {groupStyle} from "./fixing-style";


const FixingGroupUsers = () => {
    const navigation = useNavigate()
    const t = useTranslation()
    const location = useLocation()
    const [active, setActive] = useState(null)
    const [users, setUsers] = useState([])
    const classes = groupStyle()
    const setAddUserModal = (v) => console.log(v)
    const getLocalGroupUsers = (v) => {
        console.log(v)
        alert(1)
    }
    const getAllUsers = (v) => {
        console.log(v)
        alert(1)
    }
    const getGroupUsers = (group_id) => {
        Axios.get(`fixing/groups/${group_id}/users`).then(res => {
            setActive(group_id)
            console.log(res.data.users[0])
            setUsers(res.data.users)
        })
    }

    useEffect(() => {
        if (location?.state?.id) {
            getGroupUsers(location?.state?.id)
        } else {
            navigation('/order')
        }
    }, [])

    return (
        <div className={classes.container}>
            <UnderHeader
                title={'Users (' + users.length + ' in group' + (active ? ' ' + active : '') + ')'}
                context={users.length ? <div className={classes.usersBtns}>
                    <div style={{minWidth: '70px'}}>
                        <CButton
                            onPress={() => setAddUserModal(true)}
                            title={'All'}
                            backgroundColor={colors.blue}
                        />
                    </div>
                    <div style={{margin: '0 10px', minWidth: '60px'}}>
                        <CButton
                            onPress={() => setAddUserModal(true)}
                            title={'New'}
                            backgroundColor={colors.blue}
                        />
                    </div>
                    <div style={{minWidth: '80px'}}>
                        <CButton
                            onPress={() => setAddUserModal(true)}
                            title={'Pending'}
                            backgroundColor={colors.blue}
                        />
                    </div>
                </div> : ''}
            />
            {
                (users && users.length) ?
                    <>
                        <div className='tableWrapper'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>{t("table.name")}</th>
                                    <th>{t("group")}</th>
                                    <th>{t("table.phone_number")}</th>
                                    <th>{t("table.email")}</th>
                                    <th></th>
                                    {/* <th>{t("table.confirming")}</th>
                                    <th>{t("table.coefficient")}</th> */}
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    users.map((tdItem, index) => {

                                        return (
                                            <tr key={index.toString()}>
                                                <td>{tdItem.firstname}</td>
                                                <td>{tdItem.group}</td>
                                                <td>{tdItem.phone}</td>
                                                <td>{tdItem.email}</td>
                                                <td>
                                                    <CButton
                                                        onPress={() => getLocalGroupUsers(tdItem.id)}
                                                        title={'Approve'}
                                                        backgroundColor={colors.blue}
                                                    />
                                                </td>
                                                {/* <td>
                                                    {tdItem.confirming ? <SmallBtn
                                                        onPress={() => handleBooking(tdItem.id, !tdItem.confirming, 'approved')}
                                                        icon={iconCheck}
                                                        borderColor={colors.blue}
                                                        title={"approve"}
                                                    /> : <SmallBtn
                                                        onPress={() => handleBooking(tdItem.id, !tdItem.confirming, 'rejected')}
                                                        icon={removeBtn}
                                                        borderColor={colors.softRed}
                                                        title={'reject'}
                                                    />}</td>
                                                <td>
                                                    <CButton
                                                        title={t('table.coefficient')}
                                                        borderWidth={1}
                                                        onPress={()=>onCoefficient(tdItem)}
                                                    />
                                                </td> */}
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            users.map((item, index) => {
                                return (
                                    <div key={index.toString()} className='cardContainer'>
                                        <ul className='cardContent'>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.company")}</p>
                                                <p className='cardValue'>{item.company_name}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.phone_number")}</p>
                                                <p className='cardValue'>{item.phone}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.email")}</p>
                                                <p className='cardValue'>{item.email}</p>
                                            </li>
                                            {/* <li className='cardItem'>
                                                <p className='cardKey'>{t("table.confirming")}</p>
                                                {item.confirming ? <SmallBtn
                                                    onPress={() => handleBooking(item.id, !item.confirming, 'approved')}
                                                    icon={iconCheck}
                                                    borderColor={colors.blue}
                                                    title={"approve"}
                                                /> : <SmallBtn
                                                    onPress={() => handleBooking(item.id, !item.confirming, 'rejected')}
                                                    icon={removeBtn}
                                                    borderColor={colors.softRed}
                                                    title={'reject'}
                                                />}
                                            </li>
                                            <li className='cardItem'>
                                                <CButton
                                                    title={t('table.coefficient')}
                                                    borderWidth={1}
                                                    onPress={()=>onCoefficient(item)}
                                                />
                                            </li> */}
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </> : null
            }
            <br />
        </div>
    )
}

export default FixingGroupUsers