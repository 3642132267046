import {Route, Routes} from "react-router-dom";
import HeaderService from "../components/header/headerService";
import SellerRate from "../pages/seller-pages/seller-rate/seller-rate";
import FakeJewelrySeller from "../pages/admin-pages/fake-jewelry/seller-fake-jewelry";
import SellerSettings from "../pages/seller-pages/seller-settings/seller-settings";
import SellerTools from "../pages/seller-pages/seller-tools/seller-tools";
import Services from "../pages/service-pages/services/services";

export default function ServiceRole() {
    return (
        <>
            <HeaderService/>
            <Routes>
                <Route path='/services/service' element={<Services/>}/>
                <Route path='/dashboard' element={<SellerRate/>}/>
                <Route path='/services/fake' element={<FakeJewelrySeller/>}/>
                <Route path='/sellerSettings' element={<SellerSettings/>}/>
                <Route path='/sellerTools' element={<SellerTools/>}/>
            </Routes>
        </>
    );
}


