import React from "react";
import propTypes from "prop-types";
import {Link} from "react-router-dom";
import moment from "moment";
import {useTranslation} from "react-multi-lang";

const SoldTable = ({dataBody}) => {
    const t = useTranslation()
    return (
        <>
            {
                (dataBody && dataBody.length) ?
                    <>
                        <div className='tableWrapper'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>{t("table.id")}</th>
                                    <th>{t("table.username")}</th>
                                    <th>{t("table.sold_by")}</th>
                                    <th>{t("table.order_number")}</th>
                                    <th>{t("table.date")}</th>
                                    <th>{t("table.quantity")}</th>
                                    <th>{t("table.total_price")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dataBody.length && dataBody.map((tdItem, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td>
                                                    <Link to='#' className='link'>{tdItem.id}</Link>
                                                </td>
                                                <td>{`${tdItem.buyer_firstname} ${tdItem.buyer_lastname}`}</td>
                                                <td>{tdItem.seller_company_name}</td>
                                                <td>{tdItem.order_number}</td>
                                                <td>{moment(tdItem.created_at).format('YYYY MM DD')}</td>
                                                <td>{tdItem.quantity}</td>
                                                <td>{tdItem.total_price}</td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            dataBody.map((item, index) => {
                                return (
                                    <div key={index.toString()} className='cardContainer'>
                                        <ul className='cardContent'>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.id")}</p>
                                                <p className='cardValue'>
                                                    <Link className='link' to='#'>{item.id}</Link>
                                                </p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.username")}</p>
                                                <p className='cardValue'>{`${item.buyer_firstname} ${item.buyer_lastname}`}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.sold_by")}</p>
                                                <p className='cardValue'>{item.seller_company_name}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.order_number")}</p>
                                                <p className='cardValue'>{item.order_number}</p>
                                            </li>
                                            {/*<li className='cardItem'>*/}
                                            {/*    <p className='cardKey'>product</p>*/}
                                            {/*    <p className='cardValue'>*/}
                                            {/*        <Link className='link' to='#'>{item.productName}</Link>*/}
                                            {/*    </p>*/}
                                            {/*</li>*/}
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.date")}</p>
                                                <p className='cardValue'>{moment(item.created_at).format('YYYY MM DD')}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.quantity")}</p>
                                                <p className='cardValue'>{item.quantity}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.total_price")}</p>
                                                <p className='cardValue'>
                                                    <span className='bold'>{item.total_price}</span>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </> : null
            }
        </>
    )
}

SoldTable.propTypes = {
    dataBody: propTypes.array
};

export default SoldTable