import React, {createContext, useCallback, useReducer} from 'react';
import SellerOfferContextReducer, {updateState} from './seller-offer-context-reducer';
import PropTypes from "prop-types";

export const initialState = {

};

export const SellerOfferContext = createContext();

const SellerOfferContextProvider = ({children}) => {
    const [local, dispatch] = useReducer(SellerOfferContextReducer, initialState);

    const setLocal = useCallback(
        params => {
            dispatch(updateState(params));
        },
        [dispatch],
    );


    return (
        <SellerOfferContext.Provider value={{local, setLocal}}>{children}</SellerOfferContext.Provider>
    );
};


SellerOfferContextProvider.propTypes = {
    children: PropTypes.any,
};


export default SellerOfferContextProvider;
