import {useTranslation} from "react-multi-lang";
import {colors} from "src/utils/color";

interface RenderStatusCardProps {
    translation: string,
    type?: string
}

export const RenderStatusCard = ({translation, type}: RenderStatusCardProps) => {
    const t = useTranslation()
    const getColor = () => {
        if (type === 'pending') return colors.vividOrange
        if (type === 'completed') return colors.limeGreen
        if (type === 'rejected') return colors.brightRed
    }

    return (
        <p style={{
            color: getColor(),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 14,
            lineHeight: '16px',
            fontWeight: 500,
            border: `1px solid ${getColor()}`,
            borderRadius: 5,
            padding: 6,
        }}>{t(translation)}</p>
    )
}