import React from "react";
import propTypes from "prop-types";
import SmallBtn from "src/cors/smallBtn/smallBtn";
import removeIcon from 'src/assets/icons/iconRemove.svg';
import editIcon from 'src/assets/icons/iconEdit.svg';
import eyeIcon from 'src/assets/icons/iconEye.svg';
import {colors} from "src/utils/color";
import {RenderStatus} from "src/components/render-status/renderStatus";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-multi-lang";
import {config} from "src/api/config";

const SellerProductTable = ({
                                dataBody,
                                setDeleteModal,
                                setProductId,
                                setSelectValue,
                                setImages,
                                setProductItem,
                                setVideo,
                                handleEditSet
                            }) => {
    const t = useTranslation()
    const navigation = useNavigate()
    return (
        <>
            {(dataBody && dataBody?.length) ?
                <>
                    <div className='tableWrapper'>
                        <table className="table">
                            <thead>
                            <tr>
                                <th>{t("table.photo")}</th>
                                <th>{t("table.name")}</th>
                                <th>{t("table.category")}</th>
                                <th>{t("table.code")}</th>
                                <th>{t("table.price")}</th>
                                <th>{t("table.status")}</th>
                                <th>{t("table.action")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {dataBody.map((tdItem, index) => {
                                return (
                                    <tr key={index.toString()}>
                                        <td>
                                            {tdItem?.pictures?.length && <img className='productImage'
                                                                              src={`${tdItem?.pictures[0]?.path}`}
                                                                              alt="img"
                                            />}
                                        </td>
                                        <td style={{maxWidth: 200}}>{tdItem.name}</td>
                                        <td>{t(`type.${tdItem.type}`)}</td>
                                        <td>{tdItem?.product_code}</td>
                                        <td>{tdItem?.price}</td>
                                        <td>{<RenderStatus status={tdItem?.status}/>}</td>
                                        <td>
                                            <div className='tableButtonWrapper flexRow'>
                                                {tdItem?.type !== 'set' &&
                                                    <a style={{display: "block", marginRight: 8}}
                                                       href={`${config.adminUrl}/sellerProduct/sellerViewProduct/${tdItem.id}`}
                                                       target={'_blank'}
                                                    >
                                                        <SmallBtn
                                                            icon={eyeIcon}
                                                            borderColor={colors.blue}
                                                            // onPress={() => {
                                                            //     navigation('/sellerProduct/sellerViewProduct', {state: {id: tdItem?.id}})
                                                            // }}
                                                        /></a>
                                                }
                                                {tdItem.type === 'set' ?
                                                    <SmallBtn
                                                        icon={editIcon}
                                                        borderColor={colors.black}
                                                        onPress={() => {
                                                            handleEditSet(tdItem)
                                                        }}
                                                    />
                                                    :
                                                    <a style={{display: "block", marginRight: 8}}
                                                       href={`${config.adminUrl}/sellerProduct/sellerEditProduct/${tdItem.id}`}
                                                       target={'_blank'}
                                                    >
                                                        <SmallBtn
                                                            icon={editIcon}
                                                            borderColor={colors.black}
                                                            // onPress={() => {
                                                            //     if (tdItem.type === 'set') {
                                                            //         setSelectValue(tdItem.type)
                                                            //         setImages(tdItem.pictures)
                                                            //         setProductItem(tdItem)
                                                            //     } else {
                                                            //         navigation(`/sellerProduct/sellerEditProduct/${tdItem.id}`, {
                                                            //             state: {
                                                            //                 id: tdItem.id,
                                                            //                 prefix: 'sellerProducts'
                                                            //             }
                                                            //         })
                                                            //     }
                                                            // }}
                                                        />

                                                    </a>
                                                }
                                                <SmallBtn
                                                    icon={removeIcon}
                                                    borderColor={colors.softRed}
                                                    onPress={() => {
                                                        setDeleteModal(true)
                                                        setProductId(tdItem.id)
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                            </tbody>
                        </table>
                    </div>
                    {
                        dataBody.map((item, index) => {
                            return (
                                <div key={index.toString()} className='cardContainer sellerProductCard'>
                                    {item?.pictures?.length && <img
                                        src={`${item?.pictures[0]?.path}`}
                                        alt="avatar"
                                    />}
                                    <ul className='cardContent sellerProductContent'>
                                        <li className='cardItem'>
                                            <p className='cardKey'>{t("table.name")}</p>
                                            <p className='cardValue'>{item.name}</p>
                                        </li>
                                        <li className='cardItem'>
                                            <p className='cardKey'>{t("table.category")}</p>
                                            <p className='cardValue'>{t(`type.${item.type}`)}</p>
                                        </li>
                                        <li className='cardItem'>
                                            <p className='cardKey'>{t("table.code")}</p>
                                            <p className='cardValue'>{item.product_code}</p>
                                        </li>
                                        <li className='cardItem'>
                                            <p className='cardKey'>{t("table.price")}</p>
                                            <p className='cardValue'>{item.price}</p>
                                        </li>
                                        <li className='cardItem'>
                                            <p className='cardKey'>{t("table.status")}</p>
                                            {<RenderStatus status={item.status}/>}
                                        </li>
                                        <li className='cardItem'>
                                            <div className='cardButtonWrapper flexEnd flexStart'>
                                                {item?.type !== 'set' &&
                                                    <a
                                                        style={{marginRight: 8, width: 28}}
                                                        href={`${config.adminUrl}/sellerProduct/sellerEditProduct/${item.id}`}
                                                        target={'_blank'}
                                                    >
                                                        <SmallBtn
                                                            icon={eyeIcon}
                                                            borderColor={colors.blue}
                                                            // onPress={() => navigation('/sellerProduct/sellerViewProduct', {state: {id: item.id}})}
                                                        />
                                                    </a>}

                                                {item.type !== 'set' ?
                                                    <a
                                                        style={{marginRight: 8, width: 28}}
                                                        href={`${config.adminUrl}/sellerProduct/sellerEditProduct/${item.id}`}
                                                        target={'_blank'}
                                                    >
                                                        <SmallBtn
                                                            icon={editIcon}
                                                            borderColor={colors.black}
                                                        />
                                                    </a> :
                                                    <SmallBtn
                                                        onPress={() => {
                                                            item.pictures.forEach((item) => {
                                                                if (item.is_video) {
                                                                    setVideo(item)
                                                                }
                                                            })
                                                            setSelectValue(item.type)
                                                            setImages(item.pictures)
                                                            setProductItem(item)
                                                        }}
                                                        icon={editIcon}
                                                        borderColor={colors.black}
                                                    />
                                                }
                                                <SmallBtn
                                                    onPress={() => {
                                                        setDeleteModal(true)
                                                        setProductId(item.id)
                                                    }}
                                                    icon={removeIcon}
                                                    borderColor={colors.softRed}
                                                />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            )
                        })
                    }
                </> : null
            }

        </>

    )
}

SellerProductTable.propTypes = {
    dataBody: propTypes.array,
    setDeleteModal: propTypes.func,
    setProductId: propTypes.func
};

export default SellerProductTable
