import UnderHeader from "src/components/underHeader/underHeader";
import CInput from "src/cors/input/cInput";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import {privacyPolicyStyle} from "./privacy-policy-style";
import CTextarea from "src/cors/textarea/cTextarea";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {checkError} from "src/helpers/check-error";
import {errorMessages} from "src/helpers/error-messages";
import {Axios} from "src/api/api";
import {useTranslation} from "react-multi-lang";


function PrivacyPolicy() {
    const classes = privacyPolicyStyle()
    const t = useTranslation()
    const [inputValues, setInputValues] = useState({
        title: '',
        description: '',
    })

    const [inputValuesError, setInputValuesError] = useState({
        title: '',
        description: '',
    })


    const requestGet = ()=>{
        Axios(`privacy_policy`).then(res => {
            setInputValues({
                title: res.data.title,
                description: res.data.description
            })
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err');
        })
    }

    useEffect(() => {
        requestGet()
    }, [])

    const handleCancel = () =>{
        requestGet()
    }

    const saveAboutUs = () => {
        if (inputValues.title && inputValues.description) {
            Axios.put(`admin/privacy_policy`, {
                "description": inputValues.description,
                "title": inputValues.title
            }).then(res => {
                toast(t("notification.saved"))
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
                console.log(err,'err')
            })
        } else {
            checkError(inputValues, setInputValuesError)
        }
    }

    const handleChangeInfo = (value, input) => {
        if (value.length) {
            setInputValuesError({...inputValuesError, [input]: ''})
        }
        let info = {...inputValues}
        info[input] = value
        setInputValues(info)
    }

    return (
        <div className={classes.container}>
            <UnderHeader title={t("header.privacy_policy")}/>
            <div className={classes.containerPrivacy}>
                <div className={classes.inputWrapp}>
                    <CInput
                        label={t("privacy_policy.title")}
                        placeholder={t("privacy_policy.title")}
                        value={inputValues.title}
                        errorMessage={inputValuesError.title}
                        onChange={(e) => handleChangeInfo(e.target.value, 'title')}
                    />
                </div>
                <div className={classes.textAreaWrapp}>
                    <CTextarea
                        value={inputValues.description}
                        errorMessage={inputValuesError.description}
                        onChange={(e) => handleChangeInfo(e.target.value, 'description')}
                        label={t("privacy_policy.description")}
                        placeholder={t("privacy_policy.description")}
                    />
                </div>
                <div className={classes.containerPrivacyBtns}>
                    <div className={classes.containerPrivacyBtnsBtnWrap}>
                        <CButton onPress={handleCancel} title={t("project.cancel")} borderWidth={1}/>
                    </div>
                    <div className={classes.containerPrivacySaveWrap}>
                        <CButton onPress={saveAboutUs} title={t("project.save")} backgroundColor={colors.blue}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;