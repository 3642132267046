import React from "react";
import propTypes from "prop-types";
import CButton from "src/cors/button/cButton";
import {Link, useNavigate} from "react-router-dom";
import moment from "moment";
import {useTranslation} from "react-multi-lang";

const BuyerDetailsOrder = ({dataBodyOrderBuyer, data}) => {
    const navigation = useNavigate()
    const t = useTranslation()
    return (
        <>
            {
                (dataBodyOrderBuyer && dataBodyOrderBuyer.length) ?
                    <>
                        <div className='tableWrapper'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>{t("table.time_placed")}</th>
                                    <th>{t("table.order_number")}</th>
                                    <th>{t("table.sold_by")}</th>
                                    <th>{t("table.total_price")}</th>
                                    <th>{t("table.action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dataBodyOrderBuyer.map((tdItem, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td>{moment(tdItem.last_update).format('YYYY MM DD')}</td>
                                                <td>{tdItem.order_number}</td>
                                                <td>{tdItem.seller_company_name}</td>
                                                <td>{tdItem.total_price}</td>
                                                <td>
                                                    <div className='tableButtonWrapper'>
                                                        <CButton
                                                            onPress={() => navigation('/buyer/orderItem', {
                                                                state: {
                                                                    prefix: 'buyer',
                                                                    id: tdItem.id,
                                                                    data
                                                                }
                                                            })}
                                                            title={t("project.view_details")}
                                                            borderWidth={1}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            dataBodyOrderBuyer.map((item, index) => {
                                return (
                                    <div key={index.toString()} className='cardContainer'>
                                        <ul className='cardContent'>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.time_placed")}</p>
                                                <p className='cardValue'>{moment(item.last_update).format('YYYY MM DD')}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.order_number")}</p>
                                                <p className='cardValue'>{item.order_number}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.sold_by")}</p>
                                                <p className='cardValue'>{item.seller_company_name}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.total_price")}</p>
                                                <p className='cardValue'>{item.total_price}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <Link to={'/order'}>
                                                    <div className='cardButtonWrapper'>
                                                        <CButton
                                                            title={t("project.view_details")}
                                                            borderWidth={1}
                                                        />
                                                    </div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </> : null
            }

        </>

    )
}

BuyerDetailsOrder.propTypes = {
    dataBody: propTypes.array
};

export default BuyerDetailsOrder