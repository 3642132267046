import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const cRadioStyle = makeStyles(() =>
    createStyles({
        container: {
            width: 16,
            height: 16,
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            border: `1px solid ${colors.mainGrey}`,
            cursor: 'pointer',
            flex: '0 0 16px',
        },
        active: {
            border: `1px solid ${colors.blue}`,
        },
        radio: {
            width: 10,
            height: 10,
            backgroundColor: colors.blue,
            borderRadius: '50%',
            flex: '0 0 10px',
        },
        title: {
            margin: '0 5px',
            fontSize: '12px',
        }

    })
);
