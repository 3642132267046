import axios from 'axios';
import {storage} from "../utils/storage";
import {API_ROOT} from "./env-vars";
import {config} from "./config";


const storageToken: string = storage('get').token
console.log('storageToken', storageToken)
const headers = {
    'x-access-token': storageToken,
    "Content-Type": "application/json",
    "accept": "application/json",
    // "Access-Control-Allow-Origin": "*",
}


export const Axios = axios.create({
    baseURL: config.url,
    headers
});

