import React, {useEffect, useState} from "react";
import {sellerToolsStyle} from "./seller-tools-style";
import UnderHeader from "src/components/underHeader/underHeader";
import CInput from "src/cors/input/cInput";
import CButton from "src/cors/button/cButton";
import {Axios} from "src/api/api";
import {colors} from "src/utils/color";
import CSelect from "src/cors/select/cSelect";
import deleteIcon from 'src/assets/icons/deleteIcon.svg'
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";

function SellerTools() {
    const t = useTranslation();
    const classes = sellerToolsStyle();
    const [index, setIndex] = useState(0)
    const [checkList, setCheckList] = useState([])
    const [checkListFineness, setCheckListFineness] = useState([])
    const [showCalcButton, setShowCalcButton] = useState(false)
    const [showCalcButtonFineness, setShowCalcButtonFineness] = useState(false)
    const [currencyList, setCurrencyList] = useState({})
    const [currency, setCurrency] = useState('usd')
    const [checkValues, setCheckValues] = useState({
        fineness: "",
        weight: ""
    })
    const [checkValuesFineness, setCheckValuesFineness] = useState({
        from_fineness: '',
        initial_weight: '',
        to_fineness: ''
    })

    const checkInputsValuesFineness = (e, keyName) => {
        let reg = /-?[0-9]*\.?[0-9]+/g
        if (isNaN(+e) || !reg) {
            return
        }
        if (keyName === 'from_fineness' && e > 999.9) {
            return;
        }
        let newValues = {...checkValuesFineness}
        newValues[keyName] = e
        setCheckValuesFineness({...newValues})
        if (newValues.from_fineness && newValues.initial_weight && newValues.to_fineness) {
            setShowCalcButtonFineness(true)
        } else {
            setShowCalcButtonFineness(false)
        }
    }

    const checkInputsValues = (e, keyName) => {
        let reg = /-?[0-9]*\.?[0-9]+/g
        if (isNaN(+e) || !reg) {
            return
        }
        if (keyName === 'fineness' && e > 999.9) {
            return;
        }
        let newValues = {...checkValues}
        newValues[keyName] = e
        setCheckValues({...newValues})
        if (newValues.fineness && newValues.weight) {
            setShowCalcButton(true)
        } else {
            setShowCalcButton(false)
        }
    }

    const handleAddCheck = () => {
        setIndex(index + 1)
        if (checkValues.fineness && checkValues.weight) {
            let arr = [...checkList]
            arr.push({
                id: index,
                fineness: +checkValues.fineness,
                weight: +checkValues.weight,
                total_price: +checkValues.total_price,
                per_gram_price: +checkValues.per_gram_price
            })
            setCheckList(arr)
            setCheckValues({fineness: "", weight: "", per_gram_price: '', total_price: ''})
            setShowCalcButton(false)
        }
    }

    const handleAddCheckFineness = () => {
        setIndex(index + 1)
        if (checkValuesFineness.from_fineness && checkValuesFineness.initial_weight && checkValuesFineness.to_fineness) {
            let arr = [...checkListFineness]
            arr.push({
                id: index,
                from_fineness: +checkValuesFineness.from_fineness,
                initial_weight: +checkValuesFineness.initial_weight,
                to_fineness: +checkValuesFineness.to_fineness,
                required_weight: +checkValuesFineness.required_weight
            })
            setCheckListFineness(arr)
            setCheckValuesFineness({from_fineness: "", initial_weight: "", to_fineness: '', required_weight: ''})
            setShowCalcButtonFineness(false)
        }
    }


    const exchange = (value, currency) => {
        switch (currency) {
            case 'usd' :
                return value ? value.toFixed(2) : '';
            case 'amd' :
                return value ? (value * currencyList?.usd).toFixed(1) : "";
            case 'eur' :
                return value ? (value * currencyList?.usd / currencyList?.eur).toFixed(2) : "";
            case 'rub' :
                return value ? (value * currencyList?.usd / currencyList?.rub).toFixed(2) : '';
            default :
                return ''
        }
    }

    const handleCalc = () => {
        setIndex(index + 1)
        let checkPlus = [...checkList]
        if (checkValues.fineness && checkValues.weight) {
            checkPlus.push({
                fineness: +checkValues.fineness,
                weight: +checkValues.weight,
                id: +index
            })
        }
        Axios.post(`tools/check_gold_price`, {
            "array": [...checkPlus]
        }).then(res => {
            let resData = Object.entries(res.data.response)
            let arr = []
            checkPlus.forEach(item => {
                resData.forEach(elem => {
                    if (+elem[0] === +item.id) {
                        arr.push({
                            id: +item.id,
                            fineness: +elem[1].fineness,
                            weight: +elem[1].weight,
                            per_gram_price: elem[1].per_gram_price,
                            total_price: elem[1].total_price
                        })
                    }
                })
            })
            let calcItem = {}
            let arrWithoutCalcItem = []
            arr.forEach((item => {
                if (+item.id !== +index) {
                    arrWithoutCalcItem.push(item)
                } else {
                    calcItem = item
                }
            }))
            setCheckList(arrWithoutCalcItem)
            setCheckValues(calcItem)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err')
        })
    }

    const handleCalcFineness = () => {
        setIndex(index + 1)
        let checkPlus = [...checkListFineness]
        if (checkValuesFineness.from_fineness && checkValuesFineness.initial_weight && checkValuesFineness.to_fineness) {
            checkPlus.push({
                from_fineness: +checkValuesFineness.from_fineness,
                initial_weight: +checkValuesFineness.initial_weight,
                to_fineness: +checkValuesFineness.to_fineness,
                id: +index
            })
        }
        Axios.post(`tools/calculate_fineness`, {
            "fineness": [...checkPlus]
        }).then(res => {
            let resData = Object.entries(res.data.fineness)
            let arr = []
            checkPlus.forEach(item => {
                resData.forEach(elem => {
                    if (+elem[0] === +item.id) {
                        arr.push({
                            id: +item.id,
                            from_fineness: +elem[1].from_fineness,
                            initial_weight: +elem[1].initial_weight,
                            to_fineness: +elem[1].to_fineness,
                            required_weight: +elem[1].required_weight,
                        })
                    }
                })
            })
            let calcItem = {}
            let arrWithoutCalcItem = []
            arr.forEach((item => {
                if (+item.id !== +index) {
                    arrWithoutCalcItem.push(item)
                } else {
                    calcItem = item
                }
            }))
            setCheckListFineness(arrWithoutCalcItem)
            setCheckValuesFineness(calcItem)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err')
        })
    }

    const deleteListItem = (indexItem, list, onChange) => {
        let arr = []
        list.forEach((item, index) => {
            if (index !== indexItem) {
                arr.push(item)
            }
        })
        onChange(arr)
    }

    const handleChangeCurrency = (value) => {
        setCurrency(value)
    }

    const calcTotal = () => {
        if (checkList.length > 0) {
            return checkList.reduce((prev, current) => {
                return +prev + current.total_price + +checkValues.total_price
            }, 0)
        } else {
            return checkValues.total_price
        }
    }

    const calcTotalFineness = () => {
        if (checkListFineness.length > 0) {
            return checkListFineness.reduce((prev, current) => {
                return +prev + current.required_weight + +checkValuesFineness.required_weight
            }, 0)
        } else {
            return checkValuesFineness.required_weight
        }
    }

    useEffect(() => {
        Axios('rate/cba').then(res => {
            setCurrencyList(res.data)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }, [])

    return (
        <div className={classes.sellerToolsContainer}>
            <UnderHeader title={t("header.tools")}/>
            <p className={classes.subTitle}>{t("tools.check_your_gold_price")}</p>
            <div style={{width: 100, marginTop: 20}}>
                <CSelect
                    defaultValue={{label: 'USD', value: 'usd'}}
                    onChange={(e) => handleChangeCurrency(e.value)}
                    label={t("tools.currency")}
                    placeholder={t("tools.currency")}
                    options={[
                        {label: 'USD', value: 'usd'},
                        {label: 'AMD', value: 'amd'},
                        {label: 'EUR', value: 'eur'},
                        {label: 'RUB', value: 'rub'},
                    ]}
                />
            </div>
            <div className={classes.boxShadowContainer}>
                {checkList.length > 0 && checkList.map((item, index) => {
                    return (<div className={classes.inputsRow} key={index}>
                        <CInput
                            disabled={true}
                            value={item.fineness}
                            type='number'
                            placeholder={t("tools.fineness")}
                            label={t("tools.fineness")}
                        />
                        <CInput
                            disabled={true}
                            value={item.weight}
                            type='number'
                            placeholder={t("tools.weight")}
                            label={t("tools.weight")}
                        />
                        <CInput
                            disabled={true}
                            value={exchange(item.per_gram_price, currency) ? `${exchange(item.per_gram_price, currency)} ${currency.toUpperCase()}` : ''}
                            type='text'
                            placeholder={t("tools.per_gram_price")}
                            label={t("tools.per_gram_price")}
                        />
                        <CInput
                            disabled={true}
                            value={exchange(item.total_price, currency) ? `${exchange(item.total_price, currency)} ${currency.toUpperCase()}` : ""}
                            type='text'
                            placeholder={t("tools.total_price")}
                            label={t("tools.total_price")}
                        />
                        <img
                            className={classes.deleteIcon}
                            onClick={() => deleteListItem(index, checkList, setCheckList)}
                            width='20'
                            height='20'
                            src={deleteIcon}
                            alt="icon"
                        />
                        <div className={classes.deleteButtonWrapper}>
                            <CButton
                                title={t("project.delete")}
                                backgroundColor={colors.red}
                                onPress={() => deleteListItem(index, checkList, setCheckList)}
                            />
                        </div>
                    </div>)
                })}
                <div className={classes.inputsRow}>
                    <CInput
                        value={checkValues.fineness}
                        onChange={(e) => checkInputsValues(e.target.value, 'fineness')}
                        // type='number'
                        placeholder={t("tools.fineness")}
                        label={t("tools.fineness")}
                    />
                    <CInput
                        value={checkValues.weight}
                        onChange={(e) => checkInputsValues(e.target.value, 'weight')}
                        // type='number'
                        placeholder={t("tools.weight")}
                        label={t("tools.weight")}
                    />
                    <CInput
                        value={checkValues.per_gram_price ? `${exchange(checkValues.per_gram_price, currency)} ${currency.toUpperCase()}` : ''}
                        disabled={true}
                        type='text'
                        placeholder={t("tools.per_gram_price")}
                        label={t("tools.per_gram_price")}
                    />
                    <CInput
                        value={checkValues.total_price ? `${exchange(checkValues.total_price, currency)} ${currency.toUpperCase()}` : ''}
                        disabled={true}
                        type='text'
                        placeholder={t("tools.total_price")}
                        label={t("tools.total_price")}
                    />

                </div>
                <div className={classes.flexEnd}>
                    {showCalcButton &&
                        <CButton
                            onPress={handleCalc}
                            title={t("tools.calc")}
                            borderWidth={1}
                        />}
                    <div style={{marginLeft: 10}}>
                        <CButton
                            onPress={handleAddCheck}
                            title={t("project.add")}
                            borderWidth={1}
                        />
                    </div>
                </div>
                <div className={classes.priceContainer}>
                    <p>{`${t("tools.total")}: ${exchange(calcTotal(checkList), currency) ? exchange(calcTotal(checkList), currency) : 0} ${currency.toUpperCase()}`}</p>
                </div>
            </div>
            <div style={{marginTop: 40}}>
                <p className={classes.subTitle}>{t("tools.turn_gold_into_normal_fineness")}</p>
            </div>

            <div className={classes.boxShadowContainer}>
                {checkListFineness.length > 0 && checkListFineness.map((item, index) => {
                    return (<div className={classes.inputsRow} key={index}>
                        <CInput
                            disabled={true}
                            value={item.from_fineness}
                            type='number'
                            placeholder={t("tools.from_fineness")}
                            label={t("tools.from_fineness")}
                        />
                        <CInput
                            disabled={true}
                            value={item.to_fineness}
                            type='number'
                            placeholder={t("tools.to_fineness")}
                            label={t("tools.to_fineness")}
                        />
                        <CInput
                            disabled={true}
                            value={item.initial_weight}
                            type='number'
                            placeholder={t("tools.initial_weight")}
                            label={t("tools.initial_weight")}
                        />
                        <CInput
                            disabled={true}
                            value={item.required_weight}
                            type='number'
                            placeholder={t("tools.required_weight")}
                            label={t("tools.required_weight")}
                        />
                        <img
                            className={classes.deleteIcon}
                            onClick={() => deleteListItem(index, checkListFineness, setCheckListFineness)}
                            width='20'
                            height='20'
                            src={deleteIcon}
                            alt="del icon"
                        />
                        <div className={classes.deleteButtonWrapper}>
                            <CButton
                                onPress={() => deleteListItem(index, checkListFineness, setCheckListFineness)}
                                title={t("project.delete")}
                                backgroundColor={colors.red}
                            />
                        </div>
                    </div>)
                })}
                <div className={classes.inputsRow}>
                    <CInput
                        value={checkValuesFineness.from_fineness}
                        onChange={(e) => checkInputsValuesFineness(e.target.value, 'from_fineness')}
                        // type='number'
                        placeholder={t("tools.from_fineness")}
                        label={t("tools.from_fineness")}
                    />
                    <CInput
                        value={checkValuesFineness.to_fineness}
                        onChange={(e) => checkInputsValuesFineness(e.target.value, 'to_fineness')}
                        // type='number'
                        placeholder={t("tools.to_fineness")}
                        label={t("tools.to_fineness")}
                    />
                    <CInput
                        value={checkValuesFineness.initial_weight}
                        onChange={(e) => checkInputsValuesFineness(e.target.value, 'initial_weight')}
                        placeholder={t("tools.initial_weight")}
                        // type='number'
                        label={t("tools.initial_weight")}
                    />
                    <CInput
                        value={checkValuesFineness.required_weight}
                        disabled={true}
                        type='text'
                        placeholder={t("tools.required_weight")}
                        label={t("tools.required_weight")}
                    />
                </div>

                <div className={classes.flexEnd}>
                    {showCalcButtonFineness && <CButton
                        onPress={handleCalcFineness}
                        title={t("tools.calc")}
                        borderWidth={1}
                    />}
                    <div style={{marginLeft: 10}}>
                        <CButton
                            onPress={handleAddCheckFineness}
                            title={t("project.add")}
                            borderWidth={1}
                        />
                    </div>
                </div>
                <div className={classes.priceContainer}>
                    <p>{`${t("tools.total_weight")}: ${calcTotalFineness() ? calcTotalFineness() : 0}`}</p>
                </div>
            </div>
        </div>
    );
}

export default SellerTools;
