import React from "react";

import {useTranslation} from "react-multi-lang";
import {ajaStyle} from "./aja-style";
import propTypes from "prop-types";

const AjaDetailsModal = ({ userData}) => {
    console.log(userData, 'userData')
    const t = useTranslation()
    const classes = ajaStyle();


    return (
        <div className={classes.modal}>
            <div className={classes.modalSection}>
                <p className={classes.modalTitles}>{t("aja.information")}</p>
                <div className={classes.ajaDetailsList} >
                    <p>{t("aja.name")}</p>
                    <p>{userData.name}</p>
                </div>
                <div className={classes.ajaDetailsList} >
                    <p>{t("aja.age_group")}</p>
                    <p>{userData.age_group}</p>
                </div>
                <div className={classes.ajaDetailsList} >
                    <p>{t("aja.also_carrying")}</p>
                    <p>{userData.also_carrying}</p>
                </div>
                <div className={classes.ajaDetailsList} >
                    <p>{t("aja.business_type")}</p>
                    <p>{userData.business_type}</p>
                </div>
                <div className={classes.ajaDetailsList} >
                    <p>{t("aja.city")}</p>
                    <p>{userData.city}</p>
                </div>
                <div className={classes.ajaDetailsList} >
                    <p>{t("aja.company_name")}</p>
                    <p>{userData.company_name}</p>
                </div>
                <div className={classes.ajaDetailsList} >
                    <p>{t("aja.country")}</p>
                    <p>{userData.country}</p>
                </div>
                <div className={classes.ajaDetailsList} >
                    <p>{t("aja.email")}</p>
                    <p>{userData.email}</p>
                </div>
                <div className={classes.ajaDetailsList} >
                    <p>{t("aja.i_am_a")}</p>
                    <p>{userData.i_am_a}</p>
                </div>
                <div className={classes.ajaDetailsList} >
                    <p>{t("aja.member_state")}</p>
                    <p>{userData.member_state}</p>
                </div>
                <div className={classes.ajaDetailsList} >
                    <p>{t("aja.number_of_employees")}</p>
                    <p>{userData.number_of_employees}</p>
                </div>
                <div className={classes.ajaDetailsList} >
                    <p>{t("aja.phone")}</p>
                    <p>{userData.phone}</p>
                </div>
                <div className={classes.ajaDetailsList} >
                    <p>{t("aja.position")}</p>
                    <p>{userData.position}</p>
                </div>
                <div className={classes.ajaDetailsList} >
                    <p>{t("aja.primary_speciality")}</p>
                    <p>{userData.primary_speciality}</p>
                </div>
                <div className={classes.ajaDetailsList} >
                    <p>{t("aja.retail")}</p>
                    <p>{userData.retail}</p>
                </div>
                <div className={classes.ajaDetailsList} >
                    <p>{t("aja.work_duration")}</p>
                    <p>{userData.work_duration}</p>
                </div>
            </div>
        </div>

    )
}

AjaDetailsModal.propTypes = {
    closeModal: propTypes.func,
    updateView: propTypes.func,
};

export default AjaDetailsModal