import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const messagesStyle = makeStyles(() =>
    createStyles({
        container: {
            maxWidth: 1200,
            width: '100%',
            margin: 'auto',
            "@media (max-width:1250px)": {
                width: '96%',
            },
        },
        containerMessages: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 11
        },
        usersBlock: {
            width: 393,
            "@media (max-width:768px)": {
                width: '100%',
            },
        },
        usersBox: {
            padding: '18px 29px',
            borderBottom: `1px solid ${colors.grey}`,
            cursor: 'pointer',
            "@media (max-width:480px)": {
                padding: '10px 10px',
            },
        },
        usersBoxHeader: {
            display: 'flex',
            justifyContent: "space-between",
            alignItems: 'center',
            marginBottom: 7
        },
        usersBoxHeaderName: {
            fontWeight: "bold",
            color: colors.black,
            fontSize: 16,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: 150,
            "@media (max-width:480px)": {
                fontSize: 14,
            },
        },
        usersBoxHeaderDate: {
            color: colors.mainGreyDark,
            fontSize: 12
        },
        shortMessage: {
            color: colors.mainGreyDark,
            fontSize: 12,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: 300,
        },
        chat: {
            backgroundColor: colors.veryLightGray,
            width: 770,
            padding: 40,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 8,
            "@media (max-width:768px)": {
                width: '100%',
                padding: '20px 30px',
            },
            "@media (max-width:480px)": {
                width: '100%',
                padding: '20px 10px',
            },
        },
        messageItem: {
            padding: 20,
            border: `1px solid ${colors.mainGreyDark}`,
            borderRadius: 5,
            backgroundColor: colors.gold,
            marginBottom: 20,
            "@media (max-width:480px)": {
                padding: '13px 10px',
                marginBottom: 14,
            },
        },
        messageText: {
            fontSize: 14,
            color: colors.white,
            "@media (max-width:480px)": {
                marginBottom: 12,
            },
        },
        searchInputWrapp: {
            width: 285,
            "@media (max-width:767px)": {
                width: 200,
            },
            "@media (max-width:480px)": {
                width: '100%',
                marginBottom: 11
            },
        }

    })
)