import React, {useEffect, useState} from "react";
import {productSingleStyle} from "./product-single-style";
import UnderHeader from "src/components/underHeader/underHeader";
import CardInfo from "src/components/cardInfo/cardInfo";
import CButton from "src/cors/button/cButton";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {CModal} from "src/cors/modal/cModal";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {colors} from "src/utils/color";
import CSelect from "src/cors/select/cSelect";
import CTextarea from "src/cors/textarea/cTextarea";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import ImageListEdit from "../../seller-pages/seller-add-product/image-list-edit";

function ProductSingle() {
    const location = useLocation()
    const params = useParams()
    const id = location?.state?.productId || params.product_id
    const classes = productSingleStyle()
    const t = useTranslation()
    const navigation = useNavigate()
    const [active, setActive] = useState(0)
    const [openModal, setOpenModal] = useState(false)
    const [openRejectModal, setOpenRejectModal] = useState(false)
    const [data, setData] = useState([])
    const [video, setVideo] = useState()
    const [images, setImages] = useState([])
    const [inputValues, setInputValues] = useState({
        "message": "",
        "template_id": ''
    })
    const handleChangeInfo = (value, input) => {
        let info = {...inputValues}
        info[input] = value
        setInputValues(info)
    }

    const changeActive = (index) => {
        setActive(index);
    }

    const goBack = () => {
        if (location?.state?.prefix === 'product') return navigation('/product/productRequests', {state: {seller_id: location.state.seller_id}})
    }


    const approveProduct = () => {
        Axios.put(`product/approve`, {id: data.id}
        ).then(res => {
            toast(t("notification.approved"))
            if (location?.state?.prefix === 'product'){
                goBack('product')
            }else {
                window.close()
            }

        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }


    const rejectProduct = () => {
        Axios.put(`product/reject`,
            {
                id: data.id,
                "message": inputValues.message,
                "template_id": inputValues.template_id
            }
        ).then(res => {
            toast(t('notification.rejected'))
            goBack('product')
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    const onImageItem = (index) => {
        setOpenModal(true)
        changeActive(index)
    }

    useEffect(() => {
        if (id) {
            Axios(`product/${id}`).then(res => {
                let arr = []
                res.data?.pictures.forEach(item => {
                    if (item.is_video) {
                        setVideo(item)
                    } else {
                        arr.push(item)
                    }

                    setImages([...arr])
                })
                setData(res.data)
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
            })
        } else {
            navigation('/product')
        }
    }, [])



    return (
        <div className={classes.productSingleContainer}>
            {!!location?.state?.productId &&  <UnderHeader goBack={goBack} title={data.code} back={true}/>}
            <h1 className={classes.title}>{data.code}</h1>
            <div className={classes.imageContainer}>
                <ImageListEdit
                    url={`product/admin/update_pictures/${data.seller_id}/${data.id}`}
                    images={images}
                    setImages={setImages}
                    isDeletable={false}
                    onItem={onImageItem}
                />
            </div>

            {video &&
                <video
                    src={video.path}
                    controls={true}
                    className={classes.video}
                />}
            <h1 className={classes.productTitle}>{t("seller_product.product_details")}</h1>
            <div className={classes.productDetails}>
                <CardInfo title={t("category.category")} info={data.category && t(`category.${data.category}`)}/>
                <CardInfo title={t("seller_product.name")} info={data.name}/>
                <CardInfo title={t('type.type')} info={data.type && t(`type.${data.type}`)}/>
                <CardInfo title={t('seller_product.state')} info={data.state && t(`seller_product.${data.state}`)}/>
                <CardInfo title={t("seller_product.color")} info={data.color && t(`colors.${data.color}`)}/>
                <CardInfo title={t("seller_product.cost")} info={data.cost}/>
                <CardInfo title={t("seller_product.oder_cost")} info={data.oder_costs}/>
                <CardInfo title={t("seller_product.code")} info={data.code}/>
                <CardInfo title={t("seller_product.gemstone")}
                          info={data.gemstone && t(`seller_product.${data.gemstone}`)}/>
                <CardInfo title={t("seller_product.price")} info={data.price}/>
                <CardInfo title={t("seller_product.weight")} info={data.weight}/>
                <CardInfo title={t("seller_product.initial_weight")} info={data.initial_weight}/>
                <CardInfo title={t("metals.metal")} info={data.metal && t(`metals.${data.metal}`)}/>
                <CardInfo title={t("seller_product.movement_type")} info={data.movement_type}/>
                <CardInfo title={t("seller_product.fineness")} info={data.fineness}/>
                <CardInfo title={t("seller_product.published")}
                          info={data.published && t(`seller_product.${data.published}`)}/>
                <CardInfo title={t("seller_product.size")} info={data.size}/>
                <CardInfo title={t("seller_product.stars")} info={data.stars}/>
                <CardInfo title={t("stone.carat")} info={data.total_carat}/>
                <CardInfo title={t("stone.pcs")} info={data.total_pcs}/>
                <CardInfo title={t("seller_product.origin")} info={data.origin && t(`seller_product.${data.origin}`)}/>
                <CardInfo title={t('for_whom.for_whom')} info={data.for_whom && t(`for_whom.${data.for_whom}`)}/>
                <CardInfo title={t('seller_product.videos')} info={data.videos}/>
                <CardInfo title={t('seller_product.viewed')} info={data.click_count}/>
            </div>
            <div className={classes.descriptionContainer}>
                <p className={classes.descriptionTitle}>{t("seller_product.description")}</p>
                <p className={classes.descriptionText}>{data.description}</p>
            </div>
            <div className={classes.buttonsContainer}>
                <CButton
                    onPress={() => setOpenRejectModal(true)}
                    title={t("project.reject")}
                    backgroundColor={colors.red}
                />
                <CButton
                    onPress={approveProduct}
                    title={t("project.approve")}
                    backgroundColor={colors.blue}
                />
            </div>
            {data?.pictures?.length > 0 && <CModal
                visible={openModal}
                closeModal={() => setOpenModal(false)}
                center={true}
                children={<div>
                    <img onClick={() => {
                        setOpenModal(false)
                    }}
                         className={classes.modalImg}
                         src={`${data?.pictures[active]?.path}`}
                         alt="jewelry"
                    />
                </div>}
            />}
            <CModal
                closeModal={() => setOpenRejectModal(false)}
                title={t("seller_product.rejection_reason")}
                children={
                    <div className={classes.selectReasonModal}>
                        <CSelect
                            label={t("seller_product.select_reason")}
                            options={[
                                {label:t('seller_product.incorrect_data'), value: 1},
                                {label:t('seller_product.incorrect_image_video'), value: 2},
                            ]}
                            placeholder={t("seller_product.select_reason")}
                            onChange={(e) => handleChangeInfo(e.value, 'template_id')}
                        />
                        <CTextarea
                            label={t("seller_product.message")}
                            placeholder={t("seller_product.message")}
                            onChange={(e) => handleChangeInfo(e.target.value, 'message')}
                        />
                        <CButton
                            onPress={rejectProduct}
                            backgroundColor={colors.blue}
                            title={t("project.reject")}
                        />
                    </div>
                }
                visible={openRejectModal} center={true}
            />
        </div>
    );
}

export default ProductSingle;
