export const goldTypes = (lang)=>{
    return [
        {label: `${lang} 999`, value: "g999"},
        {label: `${lang} 958`, value: "g958"},
        {label: `${lang} 916`, value: "g916"},
        {label: `${lang} 900`, value: "g900"},
        {label: `${lang} 875`, value: "g875"},
        {label: `${lang} 585`, value: "g585"},
        {label: `${lang} 500`, value: "g500"},
        {label: `${lang} 416`, value: "g416"},
        {label: `${lang} 375`, value: "g375"},
        {label: `${lang} 333`, value: "g333"},
    ]
}