import React, {useMemo, useState, useCallback, useEffect} from "react";
import { dashboardStyle } from "./dashboard-style";
import ActivityCard from "src/components/activityCard/activityCard";
import { colors } from "src/utils/color";
import DashboardTable from "./dashboardTable/dashboardTable";
import UnderHeader from "src/components/underHeader/underHeader";
import { Axios } from "src/api/api";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { errorMessages } from "src/helpers/error-messages";
import { useTranslation } from "react-multi-lang";
import ConfirmationModal from "../../../components/confimationModal/confirmationModal";
import './dashboard.css';
import { useQuery } from "@tanstack/react-query";  // Import QueryClient and QueryClientProvider

function Dashboard() {
    const classes = dashboardStyle();
    const t = useTranslation();

    // Initialize QueryClient directly inside the Dashboard component

    // React Query for data fetching with error handling
    const { data: dashboardData, isLoading, error } = useQuery({
        queryKey: ['dashboardData'], // Change 'dashboardData' to an array
        queryFn: async () => {
            try {
                const [preordersRes, maxGoldLimitRes, allorders] = await Promise.all([
                    await Axios.get('preorder/admin/preorders'),
                    await Axios.get('preorder/get-max-gold-limit'),
                    await Axios.get(`preorder/admin/allorders`)
                ]);
                const totalSoldGrams = allorders?.data?.orders
                    ? allorders.data.orders.reduce((sum, order) => sum + (order.grams || 0), 0)
                    : 0;
                return {
                    orders: preordersRes.data.orders,
                    activity: preordersRes.data,
                    maxGoldLimit: maxGoldLimitRes.data.quantity,
                    totalSoldGrams: totalSoldGrams
                };
            } catch (err) {
                toast.error(errorMessages(err?.response?.data?.code));
                return null;
            }
        }
    });

    // Derived states with useMemo for optimized re-renders
    const totalGrams = useMemo(() => {
        return dashboardData?.orders?.reduce((sum, order) => sum + (order.grams || 0), 0) || 0;
    }, [dashboardData]);

    const totalPrice = useMemo(() => (
        dashboardData?.orders?.reduce((sum, order) => sum + (order.price || 0), 0) || 0
    ), [dashboardData]);

    const activityCardData = useMemo(() => [
        {
            title: t("dashboard.today_new_orders"),
            quantity: dashboardData?.activity?.todayOrdersCount || 0,
            topColor: colors.grayishBlue,
            preorderactivityCardContainer: 1
        }
    ], [dashboardData, t]);

    const [isEditing, setIsEditing] = useState(false);
    const [newMaxGoldLimit, setNewMaxGoldLimit] = useState();
    const [isEditingDiscount, setIsEditingDiscount] = useState(false);
    const [globalDiscount, setGlobalDiscount] = useState(0);

    const handleSave = useCallback(() => {
        const floatMaxGoldLimit = parseFloat(newMaxGoldLimit);
        Axios.put(`/preorder/update-max-gold-limit`, { quantity: floatMaxGoldLimit })
            .then(() => {
                setNewMaxGoldLimit(0);
                setIsEditing(false);
                toast.success(t("dashboard.limit_updated"));
            })
            .catch(err => {
                toast.error(errorMessages(err?.response?.data?.code));
            });
    }, [newMaxGoldLimit, t]);

    const handleDiscountSave = useCallback(() => {
        const floatGlobalDiscount = parseFloat(globalDiscount);
        Axios.put(`/preorder/update-discount`, { discount: floatGlobalDiscount })
            .then(() => {
                setGlobalDiscount(0);
                setIsEditingDiscount(false);
                toast.success(t("dashboard.limit_updated"));
            })
            .catch(err => {
                toast.error(errorMessages(err?.response?.data?.code));
            });
    }, [globalDiscount, t]);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading data</div>;

    const headers = [
        { key: 'order_number', label: t('fix.number') },
        { key: 'created_at', label: t('fix.date') },
        { key: 'firstname', label: t('fix.name') },
        { key: 'carat', label: t('fix.carat') },
        { key: 'grams', label: t('fix.grams') },
        { key: 'rate', label: t('fix.rate') },
        { key: 'price', label: t('fix.amount') },
        { key: 'location', label: t('fix.location') },
        { key: 'arrive_date', label: t('fix.appointment_date') },
    ];

    return (
            <div className={classes.dashboardContainer}>
                <UnderHeader title={t("header.dashboard")} />
                <div className={classes.activitySection}>
                    <div className="space-between-container">
                        <p className="mainTitle">{t("dashboard.activity")}</p>
                    </div>
                    <div className={classes.wrapper}>
                        <ActivityCard activityCardData={activityCardData} style={{ width: '100%' }} />
                        <div className={classes.limitSection}>
                            <p>{t('fix.gold_max_limit')}</p>
                            <input
                                type="text"
                                value={newMaxGoldLimit || ""}
                                onChange={(e) => setNewMaxGoldLimit(e.target.value)}
                                className={classes.editableInput}
                                style={{ border: 'none', background: 'transparent', fontSize: '32px' }}
                            />
                            <button onClick={() => setIsEditing(true)} className={classes.editButton}>
                                {t('fix.edit')}
                            </button>
                        </div>
                        <div className={classes.limitSection}>
                            <p>{t('fix.master_rate')}</p>
                            <input
                                type="text"
                                value={globalDiscount || ""}
                                onChange={(e) => setGlobalDiscount(e.target.value)}
                                className={classes.editableInput}
                                style={{ border: 'none', background: 'transparent', fontSize: '32px' }}
                            />
                            <button onClick={() => setIsEditingDiscount(true)} className={classes.editButton}>
                                {t('fix.edit')}
                            </button>
                        </div>
                        <div className={classes.limitSection} style={{ borderTopColor: '#c6a909f7' }}>
                            <p>{t('fix.gold_max_limit')}</p>
                            <p>{t('fix.start')}: <span>{(dashboardData?.maxGoldLimit || 0) + (dashboardData?.totalSoldGrams || 0)}</span></p>
                            <p>{t('fix.balance')}: <span>{dashboardData?.maxGoldLimit || 0}</span></p>
                            <p>{t('fix.sales')}: <span>{dashboardData?.totalSoldGrams || 0}</span></p>
                        </div>
                    </div>
                </div>
                <DashboardTable headers={headers} orders={dashboardData?.orders} totalGrams={totalGrams} totalPrice={totalPrice} />
                {isEditing && <ConfirmationModal isOpen={isEditing} onConfirm={handleSave} onCancel={() => setIsEditing(false)} title="Confirm Update" />}
                {isEditingDiscount && <ConfirmationModal isOpen={isEditingDiscount} onConfirm={handleDiscountSave} onCancel={() => setIsEditingDiscount(false)} title="Confirm Discount Update" />}
            </div>
    );
}

export default Dashboard;
