import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from "react-router-dom";
import GlobalContextWrapper from "./globalContextWrapper";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Router>
        <GlobalContextWrapper/>
    </Router>
)
