import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";


export const fakeJewelryStyle = makeStyles(() =>
    createStyles({
        container: {
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto 30px',
            "@media (max-width:1250px)": {
                width: '96%',
            },
        },
        fakeJewelryWrap: {
            display: 'flex',
            flexWrap: 'wrap',
            margin: '32px -10px 0',
            transition: '.3s',
            "@media (max-width:480px)": {
                margin: '17px -5px 0'
            },
        },
        fakeJewelryBlock: {
            width: 285,
            height: 305,
            backgroundColor: colors.grey,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: "center",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '12px 0 0 0',
            margin: '10px',
            "@media (max-width:1250px)": {
                width: '30%',
            },
            "@media (max-width:768px)": {
                height: 234,
            },
            "@media (max-width:650px)": {
                width: '46%',
                height: 220,
                margin: '5px',
            },
            "@media (max-width:450px)": {
                width: '46%',
                height: 168,
                margin: '5px',
            },
        },
        fakeJewelryBlockHeader: {
            display: 'flex',
            justifyContent: 'flex-end',

        },
        fakeJewelryBlockBtn: {
            width: 40,
            height: 40,
            backgroundColor: colors.white,
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
            border: `1px solid ${colors.green}`,
            cursor: 'pointer',
            '&:hover': {
                opacity: .8
            },
            "@media (max-width:480px)": {
                width: 25,
                height: 25,
            },
        },
        fakeJewelryReject: {
            border: `1px solid ${colors.red}`,
            marginLeft: 10,
            marginRight: 12,
            "@media (max-width:480px)": {
                marginLeft: 4,
                marginRight: 6,
            },
        },
        fakeJewelryBottom: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            width: '100%',
            padding: "17px 16px",

            "@media (max-width:768px)": {
                padding: "15px 16px",
            },
            "@media (max-width:480px)": {
                padding: 12,
            },
        },
        fakeJewelryBottomText: {
            fontSize: 14,
            color: colors.white,
            textTransform: 'uppercase',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        buttonWrapp: {
            width: 137,
            "@media (max-width:480px)": {
                width: '100%',
                marginBottom: 10,
            },
        },
        seeMoreBtnWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 30,
            '& > div': {
                width: 230
            },
            "@media (max-width:500px)": {
                marginBottom: 30
            },
        },
        btnsWrap:{
            display:"flex",
            justifyContent:"space-between",
            marginTop:30
        },
        cropperWrapper: {
            marginTop: 20,
            position: 'relative',
            width: '100%',
            height: 400,
            background: '#333'
        },
        cropperButtonWrapper: {
            marginTop: 15,
        },
        sliderContainer: {
            display: 'flex',
            flex: '1',
            alignItems: 'center',
        },
        sliderLabel: {
            marginRight: 20,
        },
        productCode: {
            fontWeight: 'bold',
            color: colors.blue,
            display: 'inline-block'
        },
        productImg: {
            height: 50,
            objectFit: "contein",
            border: '1px solid #ccc',
            marginRight: 15
        },
        selectWrap:{
            width: 160,
        },
    })
)