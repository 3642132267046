import {fakeJewelryStyle} from "./fake-jewelry-style";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import propTypes from "prop-types";
import {useTranslation} from "react-multi-lang";
import CSelect from "src/cors/select/cSelect";
import React from "react";

export default function FakeJewelryUnderHeader({openModal, user, handleChangeStatus}) {
    const classes = fakeJewelryStyle()
    const t = useTranslation()

    return (
        <div className={classes.buttonWrapp}>
            {user === 'admin' ?
                <div className={classes.selectWrap}>
                <CSelect
                    defaultValue={{label: t("header.created"), value: 'created'}}
                    onChange={(e) => handleChangeStatus(e.value)}
                    options={
                        [{label: t("header.created"), value: 'created'},
                            {label: t("header.approved"), value: 'approved'},
                            {label: t("header.rejected"), value: 'rejected'}
                        ]}
                /> </div> :
                <CButton
                    backgroundColor={colors.blue}
                    title={`+ ${t("fake.add_new")}`}
                    onPress={openModal}
                />}
        </div>
    );
}

FakeJewelryUnderHeader.propTypes = {
    handleChangeStatus: propTypes.func,
    openModal: propTypes.func,
    user: propTypes.string
}