import {fakeJewelryStyle} from "./fake-jewelry-style";
import FakeJewelryItem from "./fake-jewelry-item";
import UnderHeader from "src/components/underHeader/underHeader";
import FakeJewelryUnderHeader from "./fake-jewelry-underHeader";
import {useTranslation} from "react-multi-lang";
import React, {Fragment, useCallback, useContext, useEffect, useState} from "react";
import {Axios} from "src/api/api";
import CButton from "src/cors/button/cButton";
import {CModal} from "src/cors/modal/cModal";
import {colors} from "src/utils/color";
import Upload from "src/components/upload-image/upload-file";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {GlobalContext} from "src/context/global-context";
import ScrollInfiniteWrapper from "src/components/scroll-wrapper/scroll-wrapper";
import getCroppedImg, {dataURLtoFile, resizeFile} from "../../../components/cropImage/cropImage";
import Cropper from "react-easy-crop";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

function FakeJewelrySeller() {
    const classes = fakeJewelryStyle()
    const t = useTranslation()
    const {state, setState} = useContext(GlobalContext)
    const [fakeJewelry, setFakeJewelry] = useState([])
    const [offset, setOffset] = useState('')
    const [total_count, setTotal_count] = useState(0)
    const [modal, setModal] = useState(false)
    const [scrolling, setScrolling] = useState(false)
    const [images, setImages] = useState([]);
    const [error, setError] = useState({
        message: ""
    })


    const [crop, setCrop] = useState({x: 0, y: 0})
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [cropperModal, setCropperModal] = useState(false);
    const [showImage, setShowImage] = useState({})

    // Cropper functions Start

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, []);

    const cropImage = useCallback(async () => {
        setState({...state, loader: true})
        try {
            const croppedImage = await getCroppedImg(
                showImage?.data_url,
                croppedAreaPixels,
            )
            let myFile = {};
            myFile.file = await resizeFile(dataURLtoFile(croppedImage, showImage?.file?.name));
            myFile.data_url = croppedImage;


            setImages([...images, myFile]);


        } catch (e) {
            console.error(e)
        } finally {
            setState({...state, loader: false})
            setCropperModal(false);
            setZoom(1);
        }
    }, [croppedAreaPixels])

    const handleOpenCropperModal = (imgUrl) => {
        setCropperModal(true);
        setShowImage(imgUrl[0]);
    }


    const request = useCallback(() => {
        setState({...state, loader: true})
        Axios(`fake_jewelry?limit=10&offset=${offset}`).then(res => {
            setFakeJewelry([...fakeJewelry, ...res.data.fake_jewelry])
            setTotal_count(res?.data?.total_count)
        }).catch(err => {
            console.log(err, 'err')
            toast(errorMessages(err.response.data.code))
        }).finally(() => {
            setState({...state, loader: false})
            setScrolling(false)
        })
    }, [offset])

    const updateView = () => {
        setImages([])
        setFakeJewelry([])
        setOffset(prev => prev === 0 ? '' : 0)
    }

    useEffect(() => {
        request()
    }, [offset])

    useEffect(() => {
        if (scrolling && total_count !== fakeJewelry?.length) {
            showMore()
        }
    }, [scrolling])

    const showMore = () => {
        setOffset(+offset + 10)
    }

    const closeModal = () => {
        setModal(false)
        setImages([])
    }

    const addFake = () => {
        if (images.length) {
            const formData = new FormData();
            formData.append("picture", images[0].file);
            Axios.put(`fake_jewelry/add`, formData).then(res => {
                toast('pending approval')
                closeModal()
                updateView()
            }).catch(err => {
                console.log(err, 'err')
                toast(errorMessages(err.response.data.code))
            })
        } else {
            setError({message: "please add image"})
        }
    }

    return (
        <ScrollInfiniteWrapper
            scrollUpdate={setScrolling}
            context={
                <div className={classes.container}>
                    <UnderHeader
                        title={t("header.fake_jewelry")}
                        context={<FakeJewelryUnderHeader openModal={() => setModal(true)}/>}
                    />
                    <div className={classes.fakeJewelryWrap}>
                        {fakeJewelry.length > 0 && fakeJewelry.map((item, index) => {
                            return (
                                <Fragment key={index}>
                                    <FakeJewelryItem data={item}/>
                                </Fragment>
                            )
                        })}
                    </div>
                    {modal && <CModal
                        visible={modal}
                        title={t("fake.add_new")}
                        closeModal={closeModal}
                        children={<>
                            <div style={{marginTop: 30}}>
                                <Upload
                                    onChange={(imgUrl) => handleOpenCropperModal(imgUrl)}
                                    hideImage={true}
                                    multiple={false}
                                    list={images}
                                    errorMessage={error}
                                    setError={setError}
                                />
                            </div>
                            <div className={classes.btnsWrap}>
                                <div style={{width: "48%"}}>
                                    <CButton
                                        borderWidth={1}
                                        title={t("project.cancel")}
                                        onPress={closeModal}
                                    />
                                </div>
                                <div style={{width: "48%"}}>
                                    <CButton
                                        backgroundColor={colors.blue}
                                        title={t("project.add")}
                                        onPress={addFake}
                                    />
                                </div>
                            </div>
                        </>}
                    />}
                    {
                        cropperModal && <CModal
                            center={false}
                            title={t("seller_product.resize_image")}
                            closeModal={() => setCropperModal(false)}
                            visible={cropperModal}
                            children={<div>
                                <div className={classes.cropperWrapper}>
                                    <Cropper
                                        image={showImage?.data_url}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={1}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                    />
                                </div>
                                <div className={classes.sliderContainer}>
                                    <Typography
                                        variant="overline"
                                        classes={{root: classes.sliderLabel}}
                                    >
                                        Zoom
                                    </Typography>
                                    <Slider
                                        value={zoom}
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        aria-labelledby="Zoom"
                                        onChange={(e, zoom) => setZoom(zoom)}
                                    />
                                </div>
                                <div className={classes.cropperButtonWrapper}>
                                    <CButton
                                        onPress={cropImage}
                                        title={t("project.save")}
                                        borderWidth={1}
                                    />
                                </div>
                            </div>
                            }
                        />
                    }
                </div>}/>
    );
}

export default FakeJewelrySeller;