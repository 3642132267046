import React from "react";
import {smallBtnStyle} from "./smallBtn-style";

interface SmallBtnProps {
    onPress: any
    icon: any
    borderColor: string
    title: string
}

export default function SmallBtn({
                                     title = '',
                                     onPress,
                                     icon,
                                     borderColor
                                 }: SmallBtnProps) {
    const classes = smallBtnStyle()
    return (
        <div
            onClick={onPress}
            style={{border: `1px solid ${borderColor}`}}
            className={classes.smallBtnContainer}
        >
            <img
                className={classes.icon}
                src={icon}
                alt="icon"
                title={title}
            />
        </div>
    )
}