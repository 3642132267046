import React from "react";
import propTypes from "prop-types";
import {useTranslation} from "react-multi-lang";
import SmallBtn from "src/cors/smallBtn/smallBtn";
import removeBtn from "src/assets/icons/iconRemove.svg";
import {colors} from "src/utils/color";
import iconCheck from "src/assets/icons/iconCheck.svg";


const OnlineShopRequestTable = ({dataBody, handleAction}) => {
    const t = useTranslation()

    return (
        <>
            {
                (dataBody && dataBody.length) ?
                    <>
                        <div className='tableWrapper'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>{t("table.company")}</th>
                                    <th>{t("table.name")}</th>
                                    <th>{t("table.country")}</th>
                                    <th>{t("table.email")}</th>
                                    <th>{t("table.phone_number")}</th>
                                    <th>{t("table.action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dataBody.map((tdItem, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td>{tdItem.company_name}</td>
                                                <td>{`${tdItem.firstname} ${tdItem.lastname}`}</td>
                                                <td>{t(`country.${tdItem.country}`)}</td>
                                                <td>{tdItem.email}</td>
                                                <td>{tdItem?.phone_number}</td>
                                                <td>
                                                    <div className='tableButtonWrapper flexRow'>
                                                        <SmallBtn
                                                            onPress={() => handleAction(tdItem.id, 'reject','rejected')}
                                                            icon={removeBtn}
                                                            borderColor={colors.softRed}
                                                        />
                                                        <SmallBtn
                                                            onPress={() => handleAction(tdItem.id, 'approve','approved')}
                                                            icon={iconCheck}
                                                            borderColor={colors.blue}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            dataBody.map((item, index) => {
                                return (
                                    <div key={index.toString()} className='cardContainer'>
                                        <ul className='cardContent'>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.company")}</p>
                                                <p className='cardValue'>{item.company_name}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.name")}</p>
                                                <p className='cardValue'>{`${item.firstname} ${item.lastname}`}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.country")}</p>
                                                <p className='cardValue'>{t(`country.${item.country}`)}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.email")}</p>
                                                <p className='cardValue'>{item.email}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.phone_number")}</p>
                                                <p className='cardValue'>{item.phone_number}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <div className='cardButtonWrapper flexEnd'>
                                                    <SmallBtn
                                                        onPress={() => handleAction(item.id, 'reject','rejected')}
                                                        icon={removeBtn}
                                                        borderColor={colors.softRed}
                                                    />
                                                    <SmallBtn
                                                        onPress={() => handleAction(item.id, 'approve', 'approved')}
                                                        icon={iconCheck}
                                                        borderColor={colors.blue}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </> : null
            }
        </>

    )
}

OnlineShopRequestTable.propTypes = {
    dataBody: propTypes.array,
    handleAction: propTypes.func
};

export default OnlineShopRequestTable