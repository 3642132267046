import React from 'react';
import {underHeaderStyles} from "./underHeader-style";
import {ReactComponent as IconArrowBack} from "src/assets/icons/arrowBack.svg";

interface UnderHeaderProps {
    title: string
    back?: boolean
    goBack?: () => void
    context: JSX.Element
}

export default function UnderHeader({
                                        title,
                                        back = false,
                                        goBack,
                                        context
                                    }: UnderHeaderProps) {
    const classes = underHeaderStyles();
    return (
        <div className={classes.container}>
            <div className={classes.containerLeft}>
                {back && <div onClick={goBack} className={classes.containerGoBack}>
                    <IconArrowBack/>
                </div>}
                {title && <p className={classes.containerTitle}>{title}</p>}
            </div>
            {context}
        </div>
    )
}

