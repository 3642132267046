import {cTabStyles} from "./cTab-style.js";

interface CTabProps {
    onPress: any
    items: any
    active: any
}

export default function CTab({onPress, items, active}: CTabProps) {
    const classes = cTabStyles();

    return (
        <div className={classes.container}>
            {items.length > 0 && items.map((item: any, index: any) => (
                <div className={`${classes.tab} ${active === index && classes.tabActive}`}
                     onClick={() => onPress(index)} key={item.key}>
                    <p className={`${classes.tabText} ${active === index && classes.tabTextActive}`}>{item.value}</p>
                </div>
            ))}
        </div>
    );
}
