import React from "react";
import propTypes from "prop-types";
import {useTranslation} from "react-multi-lang";
import SmallBtn from "src/cors/smallBtn/smallBtn";
import removeBtn from "src/assets/icons/iconRemove.svg";
import {colors} from "src/utils/color";
import iconCheck from "src/assets/icons/iconCheck.svg";
import deleteIcon from "src/assets/icons/deleteIcon.svg";
import {RenderStatus} from "src/components/render-status/renderStatus";
import moment from "moment";

const SellerBookingTable = ({dataBody}) => {
    const t = useTranslation()
    const fineness = {
        sell_999: 999,
        sell_995: 995
    }
    console.log(dataBody)
    return (
        <>
            {
                (dataBody && dataBody.length) ?
                    <>
                        <div className='tableWrapper'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>{t("table.total_amount")}</th>
                                    <th>{t("table.taking_time")}</th>
                                    <th>{t("table.weight")}</th>
                                    <th>{t("table.market")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dataBody.map((tdItem, index) => {

                                        return (
                                            <tr key={index.toString()}>
                                                <td>{tdItem.total_amount}</td>
                                                <td>{tdItem.taking_time}</td>
                                                <td>{tdItem.per_gram_price}</td>
                                                <td>{tdItem.weight}</td>
                                                <td>{fineness[tdItem?.market]}</td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            dataBody.map((item, index) => {
                                return (
                                    <div key={index.toString()} className='cardContainer'>
                                        <ul className='cardContent'>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.total_amount")}</p>
                                                <p className='cardValue'>{item.total_amount}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.taking_time")}</p>
                                                <p className='cardValue'>{item.taking_time}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.weight")}</p>
                                                <p className='cardValue'>{item.weight}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.market")}</p>
                                                <p className='cardValue'>{item.market}</p>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </> : null
            }
        </>

    )
}

SellerBookingTable.propTypes = {
    dataBody: propTypes.array,
};

export default SellerBookingTable