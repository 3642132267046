import React, {useCallback, useContext, useEffect, useState} from "react";
import {sellerTemplatesStyle} from "./seller-templates-style";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import UnderHeader from "src/components/underHeader/underHeader";
import {GlobalContext} from "src/context/global-context";
import SellerTemplatesTable from "./SellerTemplatesTable/sellerTemplatesTable";


function SellerTemplates() {
    const t = useTranslation()
    const classes = sellerTemplatesStyle();
    const {state, setState} = useContext(GlobalContext)
    const [templates, setTemplates] = useState([])

    const request = useCallback(() => {
        setState({...state, loader: true})
        Axios(`product/template`).then(res => {

            setTemplates(res?.data?.templates)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err');
        }).finally(() => {
            setState({...state, loader: false})
        })
    }, [])

    const updateView = () => {
        setTemplates([])
    }


    useEffect(() => {
        request()
    }, [])


    return (
        <div className={classes.orderContainer}>
            <UnderHeader
                title={t("header.templates")}
            />
            <SellerTemplatesTable dataBody={templates}/>
        </div>

    );
}

export default SellerTemplates;