import React, {useContext, useEffect, useState} from "react";
import {headerStyles} from "./header-styles";
import {NavLink} from "react-router-dom";
import LanguageBar from "src/components/languageBar/languageBar";
import logo from "src/assets/images/logo.png";
import {ReactComponent as IconAvatar} from "src/assets/icons/avatar.svg";
import {ReactComponent as IconBurger} from "src/assets/icons/iconBurger.svg";
import {ReactComponent as IconLogout} from "src/assets/icons/iconLogout.svg";
import {getLanguage, useTranslation} from "react-multi-lang";
import {GlobalContext} from "src/context/global-context";
import {storage} from "src/utils/storage";
import {config} from "src/api/config";
import {ReactComponent as IconMore} from "src/assets/icons/iconMore.svg";


const HeaderSeller = () => {
    const {state, setState} = useContext(GlobalContext)
    const storageToken = storage('get').token
     const socket = new WebSocket(`wss://${config.socketUrl}/ws/notifications/subscribe/new_offer?x-access-token=${storageToken}`)
    const {morePopUp, burgerLeft} = state
    const t = useTranslation()
    const left = burgerLeft ? 0 : -1500;
    const classes = headerStyles()
    const [newOffer, setNewOffer] = useState({})
    const logOut = () => {
        window.location = `${config.webUrl}?logout=${storage('get').token}&lang=${getLanguage()}`
        storage('remove')
    }

    useEffect(() => {
          socket.onopen = function (e) {
              socket.send(JSON.stringify({"event": "get_notifications", "limit": 1000}));
          };
          socket.onmessage = function (event) {
              setNewOffer(JSON.parse(event.data))
              console.log(JSON.parse(event.data),'121212123')
          };
        return  ()=>{
              // socket.onclose((e)=>{
              //     console.log(e)
              // })
          }
    }, [])

    const onSellerOffer = () => {
        socket.onmessage = function (event) {
            setNewOffer(JSON.parse(event.data))
            socket.send(JSON.stringify({"event": "read_notification", "notification_id": 1}));
        };
    }

    return (
        <div className={classes.mainContent} onClick={(e) => e.stopPropagation()}>
            <div className={classes.headerTop}>
                <LanguageBar/>
                <div className={classes.logo} style={{cursor: "pointer"}}>
                    <div className={classes.burgerIcon}
                         onClick={() => {
                             setState({burgerLeft: !burgerLeft})
                         }}>
                        <IconBurger/>
                    </div>
                    <div className={classes.logoWrap}
                         onClick={() => window.location = `${config.webUrl}?lang=${getLanguage()}`}
                    >
                        <img className={classes.logoImg} src={logo} alt="logo"/>
                    </div>
                </div>
                <div className={classes.flexContainer}>
                    <p>{`${state?.userinfo?.firstname ? state.userinfo.firstname:""}`} {`${state?.userinfo?.lastname ? state.userinfo.lastname:""}`}</p>
                    <IconAvatar width={16} height={16}/>
                </div>
            </div>
            <div className={`${classes.headerNavBar} ${classes.headerNavBarSeller}`}>
                <div className={classes.headerNavBarContent}>
                    <div className={classes.navBarContainer}>
                        <NavLink to='/dashboard'>
                            <p>{t("header.dashboard")}</p>
                        </NavLink>
                        <NavLink to='/sellerProduct'>
                            <p>{t("header.product")}</p>
                        </NavLink>
                        <NavLink to='/sellerOrder'>
                            <p>{t("header.orders")}</p>
                        </NavLink>
                        <NavLink to='/sellerOrder'>
                            <p>{t("header.orders")}</p>
                        </NavLink>
                        <NavLink to='/sellerOffer' onClick={onSellerOffer}>
                            <p>{t("header.offers")}
                                {newOffer.unread_notifications > 0 && <div className={classes.indicator}>
                                    <span className={classes.indicatorTitle}>{newOffer.unread_notifications}</span>
                                </div>}
                            </p>

                        </NavLink>
                        <NavLink to='/customPrice'>
                            <p>{t("header.customPrice")}</p>
                        </NavLink>
                        <div className={classes.moreBtn}>
                            <div onClick={() => {
                                setState({morePopUp: !morePopUp})
                            }}>
                                <IconMore width={18} height={18}/>
                                <p>{t("header.more")}</p>
                            </div>
                            {morePopUp && <ul onClick={(e) => e.stopPropagation()}>
                                <li>
                                    <NavLink
                                        onClick={() => setState({morePopUp: false})}
                                        to='sellerSold'>
                                        <p>{t("header.sold")}</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        onClick={() => setState({morePopUp: false})}
                                        to='/seller/fakeJewelry'>
                                        <p>{t("header.fake_jewelry")}</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        onClick={() => setState({morePopUp: false})}
                                        to='/sellerSettings'>
                                        <p>{t("header.settings")}</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        onClick={() => setState({morePopUp: false})}
                                        to='/sellerTools'>
                                        <p>{t("header.tools")}</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        onClick={() => setState({morePopUp: false})}
                                        to='/seller-warehouse'>
                                        <p>{t("header.seller_warehouse")}</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        onClick={() => setState({morePopUp: false})}
                                        to='/seller-booking'>
                                        <p>{t("header.booking")}</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        onClick={() => setState({morePopUp: false})}
                                        to='/seller-templates'>
                                        <p>{t("header.templates")}</p>
                                    </NavLink>
                                </li>
                            </ul>
                            }
                        </div>
                    </div>
                    <div onClick={logOut} className={classes.logoutContainer}>
                        <IconLogout width={16} height={16}/>
                        <p>{t("header.log_out")}</p>
                    </div>
                </div>
            </div>
            <ul className={classes.mobileHeader} style={{left: left}}>
                <li onClick={() => {
                    setState({burgerLeft: !burgerLeft})
                }}>
                    <NavLink to='/dashboard'>
                        <span>{t("header.dashboard")}</span>
                    </NavLink>
                </li>
                <li onClick={() => {
                    setState({burgerLeft: !burgerLeft})
                }}>
                    <NavLink to='/sellerProduct'>
                        <span>{t("header.product")}</span>
                    </NavLink>
                </li>
                <li onClick={() => {
                    setState({burgerLeft: !burgerLeft})
                }}>
                    <NavLink to='/sellerOrder'>
                        <span>{t("header.orders")}</span>
                    </NavLink>
                </li>
                <li onClick={() => {
                    setState({burgerLeft: !burgerLeft})
                }}>
                    <NavLink to='/sellerOffer'>
                        <span>{t("header.offers")}</span>
                        {newOffer.unread_notifications > 0 && <div className={classes.indicatorMobile}>
                            <p className={classes.indicatorTitle}>{newOffer.unread_notifications}</p>
                        </div>}
                    </NavLink>
                </li>
                <li onClick={() => {
                    setState({burgerLeft: !burgerLeft})
                }}>
                    <NavLink to='/customPrice'>
                        <span>{t("header.customPrice")}</span>
                    </NavLink>
                </li>
                <li onClick={() => {
                    setState({burgerLeft: !burgerLeft})
                }}>
                    <NavLink to='/sellerSold'>
                        <span>{t("header.sold")}</span>
                    </NavLink>
                </li>
                <li onClick={() => {
                    setState({burgerLeft: !burgerLeft})
                }}>
                    <NavLink to='/seller/fakeJewelry'>
                        <span>{t("header.fake_jewelry")}</span>
                    </NavLink>
                </li>
                <li onClick={() => {
                    setState({burgerLeft: !burgerLeft})
                }}>
                    <NavLink to='/sellerSettings'>
                        <span>{t("header.settings")}</span>
                    </NavLink>
                </li>
                <li onClick={() => {
                    setState({burgerLeft: !burgerLeft})
                }}>
                    <NavLink to='/sellerTools'>
                        <span>{t("header.tools")}</span>
                    </NavLink>
                </li>
                <li onClick={() => {
                    setState({burgerLeft: !burgerLeft})
                }}>
                    <NavLink to='/seller-warehouse'>
                        <span>{t("header.seller_warehouse")}</span>
                    </NavLink>
                </li>
                <li onClick={() => {
                    setState({burgerLeft: !burgerLeft})
                }}>
                    <NavLink to='/seller-booking'>
                        <span>{t("header.booking")}</span>
                    </NavLink>
                </li>
                <li onClick={() => {
                    setState({burgerLeft: !burgerLeft})
                }}>
                    <NavLink to='/seller-templates'>
                        <span>{t("header.templates")}</span>
                    </NavLink>
                </li>
                <li onClick={() => {
                    setState({burgerLeft: !burgerLeft})
                }}>
                    <NavLink to='/' onClick={logOut}>
                        <span>{t("header.log_out")}</span>
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

export default HeaderSeller
