import UnderHeader from "src/components/underHeader/underHeader";
import {useTranslation} from "react-multi-lang";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {Axios} from "src/api/api";
import {GlobalContext} from "src/context/global-context";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {onlineShopRequestStyle} from "./online-shop-requests-stye";
import OnlineShopRequestTable from "./online-shop-requests-table";
import CSelect from "src/cors/select/cSelect";
import ScrollInfiniteWrapper from "../../../components/scroll-wrapper/scroll-wrapper";


function OnlineShopRequest() {
    const classes = onlineShopRequestStyle()
    const t = useTranslation()
    const {state, setState} = useContext(GlobalContext)
    const [data, setData] = useState([])
    const [status, setStatus] = useState('created')
    const [offset, setOffset] = useState('')
    const [total_count, setTotal_count] = useState(0)
    const [scrolling, setScrolling] = useState(false)

    const request = useCallback(() => {
        setState({...state, loader: true})
        Axios(`admin/online_market_requests?status=${status}&limit=10&offset=${offset}`).then(res => {
            setData([...data, ...res.data.users])
            setTotal_count(res?.data?.total_count)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        }).finally(() => {
            setState({...state, loader: false})
        })
    }, [status,offset,data])

    const updateView = () => {
        setData([])
        setOffset(prev => prev === 0 ? '' : 0)
    }


    const handleAction = (market_id, action, status) => {
        Axios.put(`admin/online_market`, {
            action,
            market_id
        }).then(res => {
            updateView()
            toast(t(`notification.${status}`))
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    const showMore = () => {
        setOffset(+offset + 10)
    }


    const handleChangeStatus = (e) => {
        setStatus(e)
        updateView()
    }


    useEffect(() => {
        request()
    }, [offset])

    useEffect(() => {
        if (scrolling && total_count !== data?.length) {
            showMore()
        }
    }, [scrolling])


    return (
        <ScrollInfiniteWrapper
            scrollUpdate={setScrolling}
            context={<div className={classes.container}>
                <UnderHeader title={t("header.onlineShopRequest")}
                             context={
                                 <div className={classes.selectWrap}>
                                     <CSelect
                                         defaultValue={{label: t("header.created"), value: 'created'}}
                                         onChange={(e) => handleChangeStatus(e.value)}
                                         options={
                                             [{label: t("header.created"), value: 'created'},
                                                 {label: t("header.approved"), value: 'approved'},
                                                 {label: t("header.rejected"), value: 'rejected'}
                                             ]}
                                     /></div>
                             }
                />
                <OnlineShopRequestTable dataBody={data} handleAction={handleAction}
                />
            </div>}
        />
    );
}

export default OnlineShopRequest;