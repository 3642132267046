import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const cButtonStyles = makeStyles(() =>
    createStyles({
        container: {
            textAlign:"center",
            backgroundColor: (props) =>
                props.backgroundColor ? props.backgroundColor : "unset",
            borderWidth: (props) => (props.borderWidth ? props.borderWidth : "unset"),
            width: "100%",
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            borderColor: colors.blue,
            transform:'rotate(0deg) !important',
            opacity: (props) =>
                props.disable ? .5 : "unset",
            borderStyle: (props) => (props.borderWidth ? "solid" : "unset"),
            transition: '0.5s',
            "&:hover": {
                opacity: .7
            },

        },
        btnText: {
            color: (props) => (props.backgroundColor ? colors.white : colors.blue),
            fontSize: 14,
            textTransform: 'uppercase',
        },
        leftIcon: {
            marginRight: 5,
            '& svg':{
                fill: (props) => (props.backgroundColor ? colors.white : colors.blue),
            },
        },
        rightIcon: {
            marginLeft: 5,
            '& svg':{
                fill: (props) => (props.backgroundColor ? colors.white : colors.blue),
            },
        },
    })
);
