import {createStyles, makeStyles} from "@mui/styles";

export const buyerStyle = makeStyles(() =>
    createStyles({
        buyerContainer: {
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto 30px',
            "@media (max-width:1250px)": {
                width: '96%',
            },
        },
        tabWrapper: {
            marginTop: 30
        },
        flexContainer: {
            display: "flex",
            "@media (max-width:480px)": {
                width: '100%',
            },
        },
        selectWrap:{
            // width: 150,

        },
        searchInputWrap: {
            marginLeft: 20,
            width: 285,
            "@media (max-width:767px)": {
                width: 200,
            },
            "@media (max-width:480px)": {
                width: '100%',
                marginBottom: 11,
                marginLeft: 0,
            },
        },
        seeMoreBtnWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 30,
            '& > div': {
                width: 230
            },
            "@media (max-width:500px)": {
                marginBottom: 30
            },
        },
    })
)