import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const cSelectStyles = makeStyles(() =>
    createStyles({
        container: {},
        containerError: {
            borderColor: colors.red,
            borderWidth: 1,
            borderStyle: 'ridge'
        },

        label: {
            fontSize: 12,
            marginBottom: 12,
            color: colors.mainGreyDark,
            textTransform: 'uppercase'
        },
        labelError: {
            color: colors.red,
        },
        errorBlock: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: 6,
            height: 17,
        },
        errorText: {
            color: colors.red,
            fontSize: 12,
            marginLeft: 5,
            verticalAlign: 'center'
        }
    })
);
