import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const cSearchInputStyles = makeStyles(() =>
    createStyles({
        containersearchBox: {
            width: '100%',
            height: 40,
            backgroundColor: colors.grey,
            borderColor: colors.mainGrey,
            borderWidth: 1,
            borderStyle: 'ridge',
            padding: '0 0 0 19px',
            display: "flex",
            alignItems: 'center',
            justifyContent: "center",
            "@media (max-width:768px)": {
                height: 40,
            },
        },
        searchInput: {
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent'
        },
        searchBtn: {
            width: 30,
            flex: '0 0 30px',
            cursor: "pointer",
            height: '100%',
            display: "flex",
            alignItems: 'center',
            justifyContent: "center",
        }
    })
);
