import React from "react";
import propTypes from "prop-types";
import {Link, useNavigate} from "react-router-dom";
import CButton from "src/cors/button/cButton";
import moment from "moment";
import {useTranslation} from "react-multi-lang";
import SmallBtn from "../../../../cors/smallBtn/smallBtn";
import editIcon from "../../../../assets/icons/iconEdit.svg";
import {colors} from "../../../../utils/color";

const ProductRequestTable = ({dataBody}) => {

    const t = useTranslation()
    const navigation = useNavigate()

    function editRequest(id) {
        
    }

    return (
        <>
            {
                (dataBody && dataBody.length) ?
                    <>
                        <div className='tableWrapper'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>{t("table.photo")}</th>
                                    <th>{t("table.code")}</th>
                                    <th>{t("table.id")}</th>
                                    <th>{t("table.seller")}</th>
                                    <th>{t("table.name")}</th>
                                    <th>{t("table.date")}</th>
                                    <th>{t("table.price")}</th>
                                    <th>{t("table.code")}</th>
                                    <th>{t("table.action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dataBody.map((tdItem, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td>
                                                    {tdItem?.main_picture && <img className='productImage'
                                                                                      src={`${tdItem?.main_picture}`}
                                                                                      alt="img"
                                                    />}
                                                </td>
                                                <td>{tdItem.code}</td>
                                                <td>
                                                    <Link to='/product/productSingle' className='link'>{tdItem.id}</Link>
                                                </td>
                                                <td>{tdItem.company_name}</td>
                                                <td>
                                                    <Link to='/product/productSingle' className='link'>{tdItem.name}</Link>
                                                </td>
                                                <td>{moment(tdItem.date).format('YYYY MM DD')}</td>
                                                <td>{tdItem.price}</td>
                                                <td>{tdItem.pcode}</td>
                                                <td>
                                                        <div className='cardButtonWrapper' style={{display: "flex"}}>
                                                            <SmallBtn
                                                                onPress={() => {
                                                                    editRequest(tdItem.id)
                                                                }}
                                                                icon={editIcon}
                                                                borderColor={colors.black}
                                                            />
                                                            <CButton
                                                                onPress={()=>navigation('/product/productSingle', {state: {prefix: 'product', productId : tdItem.id, seller_id: tdItem.seller_id}})}
                                                                title={t("project.view_details")}
                                                                borderWidth={1}
                                                                style={{marginLeft: 8}}
                                                            />
                                                        </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            dataBody.map((item, index) => {
                                return (
                                    <div key={index.toString()} className='cardContainer'>
                                        <ul className='cardContent'>
                                            <li className='cardItem'>
                                                {item?.main_picture && <img
                                                    src={`${item?.main_picture}`}
                                                    alt="avatar"
                                                    style={{width:80,height:80}}
                                                />}
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.code")}</p>
                                                <p className='cardValue'>
                                                    {item.code}
                                                </p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.id")}</p>
                                                <p className='cardValue'>
                                                    <Link className='link' to='/product/productSingle'>{item.id}</Link>
                                                </p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.seller")}</p>
                                                <p className='cardValue'>{item.company_name}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.name")}</p>
                                                <p className='cardValue'>
                                                    <Link className='link' to='/product/productSingle'>{item.name}</Link>
                                                </p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.date")}</p>
                                                <p className='cardValue'>{moment(item.date).format('YYYY MM DD')}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.price")}</p>
                                                <p className='cardValue'>{item.price}</p>
                                            </li>
                                            <li className='cardItem'>
                                                   <div className='cardButtonWrapper'>
                                                       <CButton
                                                          onPress={()=>navigation('/product/productSingle', {state: {prefix: 'product', productId : item.id, seller_id: item.seller_id}})}
                                                           title={t("project.view_details")}
                                                           borderWidth={1}
                                                       />
                                                   </div>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </> : null
            }
        </>

    )
}

ProductRequestTable.propTypes = {
    dataBody: propTypes.array
};

export default ProductRequestTable
