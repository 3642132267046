import React, {useCallback, useContext, useEffect, useState} from "react";
import {orderStyle} from "./order-style";
import OrderTable from "./orderTable/orderTable";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import {GlobalContext} from "src/context/global-context";
import UnderHeader from "src/components/underHeader/underHeader";
import CSelect from "src/cors/select/cSelect";
import CSearchInput from "src/cors/searchInput/searchInput";
import ScrollInfiniteWrapper from "src/components/scroll-wrapper/scroll-wrapper";

function Order() {
    const classes = orderStyle();
    const t = useTranslation();
    const {state, setState} = useContext(GlobalContext)
    const [orders, setOrders] = useState([])
    const [offset, setOffset] = useState('0')
    const [status, setStatus] = useState('all')
    const [total_count, setTotal_count] = useState(0)
    const [searchValue, setSearchValue] = useState('')
    const [searched, setSearched] = useState(false)
    const [scrolling, setScrolling] = useState(false)

    const sortOptions = [
        {label: t("header.all"), value: 'all'},
        {label: t("header.started"), value: 'started'},
        {label: t("header.payment_deposited"), value: 'payment_deposited'},
        {label: t("header.payment_declined"), value: 'payment_declined'}
    ]


    const request = useCallback(() => {
        setState({...state, loader: true})
        Axios(`admin/${searched ? 'search_orders' : 'orders'}/${status}?key=${searchValue}&limit=10&offset=${offset}`).then(res => {
            setOrders([...orders, ...res.data.orders])
            setTotal_count(res?.data?.total_count)

        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        }).finally(() => {
            setState({...state, loader: false})
            setScrolling(false)
        })
    }, [offset, status])

    const updateView = () => {
        setOrders([])
        setOffset(prev => prev === 0 ? '' : 0)
    }

    const search = () => {
        updateView()
        setSearched(true)
    }


    const handleClear = () => {
        setSearchValue('')
        updateView()
        setSearched(false)
    }

    const handleChangeStatus = (e) => {
        setStatus(e)
        updateView()
    }

    const showMore = () => {
        setOffset(+offset + 10)
    }

    useEffect(() => {
            request()
    }, [offset])

    useEffect(() => {
        if (scrolling && total_count !== orders?.length) {
            showMore()
        }
    }, [scrolling])

    return (
        <ScrollInfiniteWrapper
            scrollUpdate={setScrolling}
            context={<div className={classes.orderContainer}>
                <UnderHeader
                    title={t("header.orders")}
                    context={<div className={classes.flexContainer}>
                        <div className={classes.selectWrap}>
                            <CSelect
                                defaultValue={{label: t("header.all"), value: 'all'}}
                                onChange={(e) => handleChangeStatus(e.value)}
                                options={sortOptions}
                            />
                        </div>
                        <div className={classes.searchInputWrap}>
                            <CSearchInput
                                handleSearch={search}
                                onChange={setSearchValue}
                                value={searchValue}
                                handleClear={handleClear}
                            />
                        </div>
                    </div>}/>

                <OrderTable dataBody={orders}/>
            </div>}/>
    );
}

export default Order;