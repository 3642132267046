import React from "react";
import propTypes from "prop-types";
import moment from "moment";
import {useTranslation} from "react-multi-lang";

const SellerRateTable = ({dataBody, title, status}) => {
    const t = useTranslation()

    return (
        <>
            {
                (dataBody && dataBody.length) ?
                    <>
                        <div className='tableWrapper rateTable equalWidthTable'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>{title}</th>
                                    <th>{t("seller_rate.buy")}</th>
                                    <th>{t("seller_rate.sell")}</th>
                                    <th>+ / -</th>
                                    <th>{t("seller_rate.time")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dataBody.map((tdItem, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td className='bold'>
                                                    {tdItem.name}
                                                </td>
                                                <td className='bold'>{tdItem.buy}</td>
                                                <td>{tdItem.sell}</td>
                                                <td className={tdItem.sell - tdItem.yesterday_sell < 0 ? 'redTitle' : 'greenTitle'}>
                                                    {(tdItem.sell - tdItem.yesterday_sell).toFixed(2)}</td>
                                                <td>{status ? moment(tdItem.updated_at).format('HH:mm:ss') : t('seller_rate.is_close')}</td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </> : null
            }
        </>

    )
}

SellerRateTable.propTypes = {
    dataBody: propTypes.array,
    status: propTypes.bool,
    title: propTypes.any,
};

export default SellerRateTable