import {ReactComponent as IconEdit} from "src/assets/icons/edit.svg";
import {ReactComponent as IconReject} from "src/assets/icons/reject.svg";
import {collectionStyle} from "./collection-style";
import jewelry from 'src/assets/images/collection.svg';

function CollectionItem() {

    const classes = collectionStyle()

    return (
   <div className={classes.collectionBox}>
       <div className={classes.collectionBlock}
            style={{backgroundImage: `url(${jewelry})`}}>
       </div>
       <div className={classes.collectionBottom}>
           <p className={classes.collectionBottomText}>Ringssdfsdfsdfsdf</p>
           <div className={classes.collectionBottomRight}>
               <div className={classes.collectionBlockBtn}>
                   <IconEdit />
               </div>
               <div className={`${classes.collectionBlockBtn} ${classes.collectionReject}`}>
                   <IconReject />
               </div>
           </div>
       </div>
   </div>
    );
}

export default CollectionItem;