import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const sellerDetailStyle = makeStyles(() =>
    createStyles({
        container: {
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto 30px',
            "@media (max-width:1250px)": {
                width: '96%',
            },
        },
        containerTabWrapp: {
            margin: '36px 0 27px 0',
        },
        sellerDetailBlock: {
            width: '100%',
            maxWidth: 1440,
            height: 365,
            backgroundColor: colors.grey,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: "center",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '12px 0 0 0',
            margin: '0 auto',
            "@media (max-width:768px)": {
                height: 234,
            },
            "@media (max-width:650px)": {
                height: 220,
            },
            "@media (max-width:450px)": {
                height: 183,
            },

        },
        sellerDetailHeader: {
            display: 'flex',
            justifyContent: 'flex-end',

        },
        sellerDetailBlockBtn: {
            width: 40,
            height: 40,
            backgroundColor: colors.white,
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
            border: `1px solid ${colors.green}`,
            cursor: 'pointer',
            '&:hover': {
                opacity: .8
            },
            "@media (max-width:480px)": {
                width: 25,
                height: 25,
            },
        },
        sellerDetailReject: {
            border: `1px solid ${colors.red}`,
            marginLeft: 10,
            marginRight: 12,
            "@media (max-width:480px)": {
                marginLeft: 4,
                marginRight: 6,
            },
        },
        inputBlock: {
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: 30
        },
        title: {
            fontSize: 14,
            color: colors.black,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            marginBottom: 27,
            "@media (max-width:480px)": {
                marginBottom: 9,
            },
        },
        inputWrapp: {
            width: '32.1%',
            margin: '0 7px',
            "@media (max-width:1250px)": {
                width: '48%',
            },
            "@media (max-width:830px)": {
                width: '47%',
            },
            "@media (max-width:550px)": {
                width: '100%',
                margin: '0 0',
            },
        },
        inputWrappAddress: {
            width: '65.5%',
            "@media (max-width:1250px)": {
                width: '48%',
            },
            "@media (max-width:830px)": {
                width: '47%',
            },
            "@media (max-width:550px)": {
                width: '100%',
                margin: '0 0',
            },
        },
        textAreaWrapp: {
            width: '99.5%',
            marginBottom: 60,
            height: 220,
            "@media (max-width:1250px)": {
                width: '98%',
            },
            "@media (max-width:830px)": {
                width: '98%',
                marginBottom: 60,
            },
            "@media (max-width:550px)": {
                width: '100%',
                marginBottom: 60,
                height: 146,
            },
        },
        tabWrapper: {
            margin: '30px auto 10px',
            maxWidth: 1200,
            width: '100%',
            "@media (max-width:1250px)": {
                width: '98%',
            },
        },
        resetWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            "@media (max-width:500px)": {
                flexDirection: 'column',
            },
        },
        deactivate: {
            fontSize: 14,
            lineHeight: '16px',
            fontWeight: 400,
            color: colors.brightRed,
            cursor: 'pointer',
            textTransform: 'uppercase',
        },
        activate: {
            fontSize: 14,
            lineHeight: '16px',
            fontWeight: 400,
            color: colors.green,
            cursor: 'pointer',
            textTransform: 'uppercase',
        },
        personalInfoContainer: {
            marginBottom: 30
        },
        productTableWrapper: {
            marginBottom: 30
        },
        seeMoreBtnWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 30,
            '& > div': {
                width: 230
            },
            "@media (max-width:500px)": {
                marginBottom: 30
            },
        },
        buttonWrapper: {
            width: 209,
            "@media (max-width:500px)": {
                marginBottom: 20
            },
        },
        resetInputWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        applyBtn: {
            fontSize: 14,
            color: colors.blue,
            fontWeight: 600,
            marginLeft: 15,
            cursor: 'pointer',
        },
        sellerDetailsLogoWrapper: {
            marginTop: 20,
            '& img': {
                width: 140,
                header: 60,
            }
        },
        sellerDetailsUnderHeader: {
            display: "flex",
            "@media (max-width:768px)": {
                flexDirection: "column",
                width:'100%',
            },
        },
        selectWrapperUnderHeader: {
            marginLeft: 20,
            width:211,
            "@media (max-width:768px)": {
                marginLeft: 0,
                marginTop: 20,
                marginBottom: 20,
                width:'100%',
            },
        },
        radio_wrapper:{
            display:"flex",
            marginTop:50,
            marginBottom:60
        }
    })
)