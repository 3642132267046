import React from "react";
import {Routes, Route} from "react-router-dom";
import FakeJewelry from "../pages/admin-pages/fake-jewelry/fake-jewelry";
import Collection from "../pages/admin-pages/collection/collection";
import Buyer from "../pages/admin-pages/buyer/buyer";
import Order from "../pages/admin-pages/order/order";
import OrderItem from "../pages/admin-pages/order-item/order-item";
import Seller from "../pages/admin-pages/seller/seller";
import SellerDetail from "../pages/admin-pages/seller-details/seller-details";
import ProductSingle from "../pages/admin-pages/product-single/product-single";
import AboutUs from "../pages/admin-pages/about-us/about-us";
import Rate from "../pages/admin-pages/rate/rate";
import PrivacyPolicy from "../pages/admin-pages/privacy-policy/privacy-policy";
import BuyerDetail from "../pages/admin-pages/buyer-detail/buyer-detail";
import Sold from "../pages/admin-pages/sold/sold";
import Product from "../pages/admin-pages/product/product";
import ProductRequests from "../pages/admin-pages/product-reqests/product-reqests";
import ServicesAdmin from "../pages/admin-pages/services/services";
import ServiceDetail from "../pages/admin-pages/service-detail/service-detail";
import Messages from "../pages/admin-pages/messages/messages";
import Dashboard from "../pages/admin-pages/dashboard/dashboard";
import Settings from "../pages/admin-pages/settings/settings";
import HeaderAdmin from "../components/header/headerAdmin";
import Fixing from "../pages/admin-pages/fixing/fixing";
import FixingGroupUsers from "../pages/admin-pages/fixing/fixing-users-table";
import OnlineShopRequest from "../pages/admin-pages/online-shop-requests/online-shop-requests";
import Aja from "../pages/admin-pages/aja/aja";
import TranslationPage from "../pages/admin-pages/translation-page";
import EditSellerDetails from "../pages/admin-pages/edit-seller-details/edit-seller-details";
import EditServiceDetail from "../pages/admin-pages/edit-service-detail/edit-service-detail"
import SellerEditProduct from "../pages/admin-pages/seller-details/edit-seller-product";

export default function AdminRole() {
    return (
        <>
            <HeaderAdmin/>
            <Routes>
                <Route path='/dashboard' element={<Dashboard/>}/>
                <Route path='/fakeJewelry' element={<FakeJewelry/>}/>
                <Route path='/more/collection' element={<Collection/>}/>
                <Route path='/buyer' element={<Buyer/>}/>
                <Route path='/order' element={<Order/>}/>
                <Route path='/order/orderItem' element={<OrderItem/>}/>
                <Route path='/buyer/orderItem' element={<OrderItem/>}/>
                <Route path='/seller/orderItem' element={<OrderItem/>}/>
                <Route path='/seller' element={<Seller/>}/>
                <Route path='/seller/sellerDetail' element={<SellerDetail/>}/>
                <Route path='/seller/editSellerDetails' element={<EditSellerDetails/>}/>
                <Route path='/services/editServiceDetail' element={<EditServiceDetail/>}/>
                <Route path='/seller/:id' element={<ProductSingle/>}/>
                <Route path='/aboutUs' element={<AboutUs/>}/>
                <Route path='/fixing' element={<Fixing/>}/>
                <Route path='/fixing/fixingGroup' element={<FixingGroupUsers />}/>
                <Route path='/onlineShopRequest' element={<OnlineShopRequest/>}/>
                <Route path='/rate' element={<Rate/>}/>
                <Route path='/privacyPolicy' element={<PrivacyPolicy/>}/>
                <Route path='/settings' element={<Settings/>}/>
                <Route path='/buyer/buyerDetail' element={<BuyerDetail/>}/>
                <Route path='/sold' element={<Sold/>}/>
                <Route path='/product' element={<Product/>}/>
                <Route path='/product/productRequests' element={<ProductRequests/>}/>
                <Route path='/product/productSingle' element={<ProductSingle/>}/>
                <Route path='/seller/productSingle/:product_id' element={<ProductSingle/>}/>
                <Route path='/seller/editProduct/:id' element={<SellerEditProduct/>}/>
                <Route path='/messages' element={<Messages/>}/>
                <Route path='/services' element={<ServicesAdmin/>}/>
                <Route path='/services/serviceDetail' element={<ServiceDetail/>}/>
                <Route path='/aja' element={<Aja/>}/>
                <Route path='/translations' element={<TranslationPage />}/>
            </Routes>
        </>
    );
}

