import React, {useContext} from "react";
import {ReactComponent as IconLogo} from "src/assets/logo.svg";
import logo from "src/assets/images/logo.png";
import {ReactComponent as IconAvatar} from "src/assets/icons/avatar.svg";
import {ReactComponent as IconDashboard} from "src/assets/icons/iconDashboard.svg";
import {ReactComponent as IconProduct} from "src/assets/icons/iconProduct.svg";
import {ReactComponent as IconLogout} from "src/assets/icons/iconLogout.svg";
import {ReactComponent as IconFakeJewelry} from "src/assets/icons/iconFakeJewelry.svg";
import {ReactComponent as IconBurger} from "src/assets/icons/iconBurger.svg";
import {ReactComponent as IconSettings} from "src/assets/icons/iconSettings.svg";
import {headerStyles} from "./header-styles";
import {NavLink} from "react-router-dom";
import LanguageBar from "src/components/languageBar/languageBar";
import {GlobalContext} from "src/context/global-context";
import {getLanguage, useTranslation} from "react-multi-lang";
import {config} from "src/api/config";
import {storage} from "src/utils/storage";



const HeaderService = () => {
    const {state, setState} = useContext(GlobalContext)
    const {burgerLeft} = state
    const classes = headerStyles()
    const t = useTranslation()
    const left = burgerLeft ? 0 : -1500;

    const logOut = () => {
        window.location = `${config.webUrl}?logout=${storage('get').token}&lang=${getLanguage()}`
        storage('remove')
    }

    return (
        <div className={classes.mainContent}>
            <div className={classes.headerTop}>
                <LanguageBar/>
                <div className={classes.logo}
                     style={{cursor: "pointer"}}
                     onClick={() => {
                         setState({burgerLeft: !burgerLeft})
                     }}>
                    <div className={classes.burgerIcon}>
                        <IconBurger/>
                    </div>
                    <div className={classes.logoWrap}
                         onClick={() => window.location = `${config.webUrl}?lang=${getLanguage()}`}
                    >
                        <img className={classes.logoImg} src={logo} alt="logo" />
                    </div>
                </div>
                <div className={classes.flexContainer}>
                    <p>{`${state?.userinfo?.firstname} ${state?.userinfo?.lastname}`}</p>
                    <IconAvatar width={16} height={16}/>
                </div>
            </div>
            <div className={classes.headerNavBar}>
                <div className={classes.headerNavBarContent}>
                    <div className={classes.navBarContainer}>
                        <NavLink to='/dashboard'>
                            <IconDashboard width={16} height={16}/>
                            <p>{t("header.dashboard")}</p>
                        </NavLink>
                        <NavLink to='/services/fake'>
                            <IconFakeJewelry width={16} height={16}/>
                            <p>{t("header.fake_jewelry")}</p>
                        </NavLink>
                        <NavLink to='/services/service'>
                            <IconProduct width={16} height={16}/>
                            <p>{t("header.services")}</p>
                        </NavLink>
                        <NavLink to='/sellerSettings'>
                            <IconSettings width={16} height={16}/>
                            <p>{t("header.settings")}</p>
                        </NavLink>
                        <NavLink to='/sellerTools'>
                            <IconSettings width={16} height={16}/>
                            <p>{t("header.tools")}</p>
                        </NavLink>
                    </div>
                    <div onClick={logOut} className={classes.logoutContainer}>
                        <IconLogout width={16} height={16}/>
                        <p>{t("header.log_out")}</p>
                    </div>
                </div>
            </div>
            <ul onClick={(e) => e.stopPropagation()} className={classes.mobileHeader} style={{left: left}}>
                <li>
                    <NavLink to='/dashboard' onClick={() => setState({burgerLeft: false})}>
                        <IconDashboard width={16} height={16}/>
                        <span>{t("header.dashboard")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/services/fake' onClick={() => setState({burgerLeft: false})}>
                        <IconFakeJewelry width={16} height={16}/>
                        <span>{t("header.fake_jewelry")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/services/service' onClick={() => setState({burgerLeft: false})}>
                        <IconProduct width={16} height={16}/>
                        <span>{t("header.services")}</span>
                    </NavLink>
                </li>
                <li onClick={() => {
                    setState({burgerLeft: !burgerLeft})
                }}>
                    <NavLink to='/sellerSettings'>
                        <IconSettings width={16} height={16}/>
                        <span>{t("header.settings")}</span>
                    </NavLink>
                </li>
                <li onClick={() => {
                    setState({burgerLeft: !burgerLeft})
                }}>
                    <NavLink to='/sellerTools'>
                        <IconSettings width={16} height={16}/>
                        <span>{t("header.tools")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={''} onClick={logOut}>
                        <IconLogout width={16} height={16}/>
                        <span>{t("header.log_out")}</span>
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

export default HeaderService