import React, {useEffect, useState} from "react";
import {Axios} from "../../../../api/api";
import {toast} from "react-toastify";
import {errorMessages} from "../../../../helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import CButton from "../../../../cors/button/cButton";
import {useNavigate} from "react-router-dom";
import deleteIcon from "src/assets/icons/fixingdeleteIcon.svg";
import iconCheck from "src/assets/icons/fixingiconCheck.svg";
import ConfirmationModal from "../../../../components/confimationModal/confirmationModal"; // Assuming you have this component
import "../orders.css";


export default function PendingOrders() {
    const [orders, setOrders] = useState([])
    const [pending, setPending] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const t = useTranslation();
    const navigation = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage] = useState(7);
    const [loading, setLoading] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'desc' });
    const [totalGrams, setTotalGrams] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    const sortArrowSvg = (direction) => (
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            {direction === 'desc' ?
                <path
                    d="M7.00023 5.17266L11.9502 0.222656L13.3642 1.63666L7.00023 8.00066L0.63623 1.63666L2.05023 0.222656L7.00023 5.17266Z"
                    fill="#09121F"
                />
                : <path d="M7 2L11.95 6.05L10.536 7.464L7 3.928L3.464 7.464L2.05 6.05L7 2Z" fill="#09121F"/>
            }
        </svg>
    );
    const sortFixing = (data) => {
        const { key, direction } = sortConfig;
        return [...data].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        }).filter(v => pending ? v.status === 0 : true);
    };

    const getOrders = (page) => {
        setLoading(true);
        Axios.get(`preorder/orders/pending`, {
            params: {
                page,
                limit: itemsPerPage,
                search: searchTerm, // Include search term in the request
                sortKey: sortConfig.key,
                sortDirection: sortConfig.direction
            }
        })
            .then(res => {
                setOrders(res.data.orders);
                setTotalPages(res.data.total_pages);  // Ensure your API returns totalPages
                setLoading(false);
            })
            .catch(err => {
                toast(errorMessages(err?.response?.data?.code));
                setLoading(false);
            });
    };

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        getOrders(currentPage);
    }, [currentPage, searchTerm, sortConfig]);

    useEffect(() => {
        const { totalGrams, totalPrice } = orders.reduce((acc, item) => {
            acc.totalGrams += item.grams;
            acc.totalPrice += item.price;
            return acc;
        }, { totalGrams: 0, totalPrice: 0 });

        setTotalGrams(totalGrams);
        setTotalPrice(totalPrice);
    }, [orders]);

    const headers = [
        { key: 'order_number', label: t('fix.number') },
        { key: 'created_at', label: t('fix.date') },
        { key: 'firstname', label: t('fix.name') },
        { key: 'carat', label: t('fix.carat') },
        { key: 'grams', label: t('fix.grams') },
        { key: 'rate', label: t('fix.rate') },
        { key: 'price', label: t('fix.amount') },
        { key: 'location', label: t('fix.location') },
        { key: 'arrive_date', label: t('fix.appointment_date') },
    ];

    const handleApprove = (id) => {
        Axios.put(`preorder/order/${id}/completed`)
            .then((res) => {
                toast.success(t('fix.order_approved_successfully'));
                getOrders();
            })
            .catch((err) => {
                toast.error(errorMessages(err?.response?.data?.code));
            });
    };

    const handleCancel = (id) => {
        Axios.put(`preorder/order/${id}/canceled`)
            .then((res) => {
                toast.success(t('fix.order_canceled_successfully'));
                getOrders();
            })
            .catch((err) => {
                toast.error(errorMessages(err?.response?.data?.code));
            });
    };

    const handleDeleteClick = (id) => {
        setSelectedOrderId(id);
        setModalOpen(true);
    };

    const handleModalConfirm = () => {
        handleCancel(selectedOrderId);
        setModalOpen(false);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderPageNumbers = () => {
        let pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
                    <button onClick={() => handlePageChange(i)} className="page-link">
                        {i}
                    </button>
                </li>
            );
        }
        return pageNumbers;
    };

    return (
        <>
            {loading && <p>Loading...</p>}
            <div style={{ marginBottom: '20px' }}>
                <input
                    type="text"
                    placeholder={t('fix.search')}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            {
                (orders && orders.length) ?
                    <>
                        <div className='tableWrapper pre-order-table' style={{marginTop: '30px'}}>
                            <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid rgba(29,56,101,0.1)', paddingBottom: '20px' }}>
                                <h3>{t('fix.pending_orders')}</h3>
                            </div>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>{t('fix.total')}</th>
                                    <th><span style={{fontWeight:'bold'}}>{totalGrams.toFixed(2)}</span> {t('fix.grams')}</th>
                                    <th></th>
                                    <th>$<span style={{fontWeight:'bold'}}>{totalPrice.toFixed(2)}</span></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                                <tr>
                                    {headers.map(({ key, label }) => (
                                        <th key={key} onClick={() => requestSort(key)} style={{cursor:"pointer"}}>
                                            {label} {sortConfig.key === key && sortArrowSvg(sortConfig.direction)}
                                        </th>
                                    ))}
                                    <th>{t('fix.actions')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    sortFixing(orders).map((item, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td><span>{t('fix.number')}: </span>{item.order_number}</td>
                                                <td>
                                                    <span>{t('fix.date')}: </span>{new Date(item.created_at).toLocaleDateString()} {new Date(item.created_at).toLocaleTimeString()}
                                                </td>
                                                <td>{item.firstname}</td>
                                                <td>{Number(item.carat) === 995 ? '995' : '999.9'}</td>
                                                <td>{item.grams.toFixed(2)}</td>
                                                <td>{(item.price / item.grams).toFixed(2)}</td>
                                                <td>$ {item.price.toFixed(2)}</td>
                                                <td>{item.location}</td>
                                                <td>
                                                    <span>{t('fix.appointment_date')}: </span>{item.arrive_date && new Date(item.arrive_date).getFullYear() > 2023 ? new Date(item.arrive_date).toLocaleDateString() : ''} {item.arrive_date && new Date(item.arrive_date).getFullYear() > 2023 ? new Date(item.arrive_date).toLocaleTimeString('en-US', {
                                                    timeZone: 'UTC',
                                                    hour12: false
                                                }): ''}</td>
                                                <td>
                                                    <div className="flexRow orders-actions"
                                                         style={{display: 'flex', gap: '10px'}}>
                                                        <div style={{background: 'green'}}>
                                                            <CButton
                                                                onPress={() => handleApprove(item.id)}
                                                                leftIcon={<img src={iconCheck} alt="approve"/>}
                                                                borderWidth={1}
                                                            />
                                                        </div>
                                                        <div style={{background: 'red'}}>
                                                            <CButton
                                                                onPress={() => handleDeleteClick(item.id)}
                                                                leftIcon={<img src={deleteIcon} alt="delete"/>}
                                                                borderWidth={1}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        <div className="modal">
                            {modalOpen && (
                                <div className="modal-overlay">
                                    <div className="modal-container">
                                        <h3 className="modal-title">{t('fix.confirm_cancelation')}</h3>
                                        <p className="modal-message">
                                            {t('fix.cancel_confirm_text')} Are you sure you want to cancel this order?
                                        </p>
                                        <div className="modal-actions">
                                            <button className="btn btn-confirm" onClick={handleModalConfirm}>
                                                {t('fix.yes')}
                                            </button>
                                            <button className="btn btn-cancel" onClick={handleModalClose}>
                                                {t('fix.no')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </> : null
            }
            <nav>
                <ul className="pagination">
                    {renderPageNumbers()}
                </ul>
            </nav>
        </>
    );
}

