import UnderHeader from "src/components/underHeader/underHeader";
import CInput from "src/cors/input/cInput";
import React, {useContext, useEffect, useRef, useState} from "react";
import {sellerAddProductStyle} from "./seller-add-product-style";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import CSelect from "src/cors/select/cSelect";
import CTab from "src/cors/tab/cTab";
import CTextarea from "src/cors/textarea/cTextarea";
import {useTranslation} from "react-multi-lang";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {errorMessages} from "src/helpers/error-messages";
import {GlobalContext} from "src/context/global-context";
import UploadVideo from "src/components/uploadVideo/upload-video";

function SellerViewProduct() {
    const navigation = useNavigate()
    const t = useTranslation()
    const {state} = useContext(GlobalContext)
    const userId = state?.userinfo?.role_id
    const [checked] = useState(false)
    const [images, setImages] = useState([]);
    const [videoes, setVideo] = useState('');
    const [tabLanguage, setTabLanguage] = useState(0)
    const classes = sellerAddProductStyle()
    let {id} = useParams();
    const [values, setValues] = useState({
        type: "",
        fineness: '',
        color: '',
        published: '',
        origin: '',
        gemstone: 'no',
        initial_weight: "",
        weight: "",
        product_code: '',
        movement_type: '',
        brand: '',
        bracelet_belt: "",
    })
    const [categories, setCategories] = useState({
        first_category: '',
        metal: '',
        second_category: '',
        state: "ready"
    })
    const [productPrice, setProductPrice] = useState({
        oder_cost: '',
        cost: '',
        price: ''
    })
    const [stones, setStones] = useState([])
    const [resetStone] = useState(true)
    const [resetAll] = useState(true)
    const [resetValues] = useState(true)
    const [resetType] = useState(true)

    const [en, setEn] = useState({
        name: '',
        description: ''
    })
    const [ru, setRu] = useState({
        name: '',
        description: ''
    })
    const [am, setAm] = useState({
        name: '',
        description: ''
    })

    useEffect(() => {
        if (id) {
            Axios(`product/${id}`).then(res => {
                let data = res.data
                data.pictures.forEach((item) => {
                    if (item.is_video) {
                        setVideo(item)
                    }
                })
                setImages(data.pictures)
                setProductPrice({
                    oder_cost: data.oder_costs,
                    cost: data.cost,
                    price: data.price
                })
                setStones(data.stones)
                setEn({
                    name: data.translation_data.en.name,
                    description: data.translation_data.en.description
                })
                setAm({
                    name: data.translation_data.am.name,
                    description: data.translation_data.am.description
                })
                setRu({
                    name: data.translation_data.ru.name,
                    description: data.translation_data.ru.description
                })
                setCategories({
                    first_category: data.category,
                    metal: data.metal,
                    second_category: data.for_whom,
                    state: data.state
                })
                setValues({
                    type: data.type,
                    fineness: data.fineness,
                    color: data.color,
                    published: data.published,
                    origin: data.origin,
                    gemstone: data.gemstone ? data.gemstone : 'no',
                    initial_weight: data.initial_weight,
                    weight: data.weight,
                    product_code: data.code,
                    movement_type: data.movement_type,
                    brand: data.brand,
                    bracelet_belt: data.bracelet_belt,
                    size: data.size,
                    name: data.name,
                    condition: data.condition
                })
            }).catch(err => {
                console.log(err, 'err')
                toast(errorMessages(err?.response?.data?.code))
                navigation('/sellerProduct')
            })
        } else {
            navigation('/sellerProduct')
        }
    }, [])

    return (
        <div className={classes.container}>
            <UnderHeader
                goBack={() => navigation('/sellerProduct')}
                back={true}
                title={en.name}
            />
            <div className={classes.addProductContainer}>
                <div className={classes.addProductContainerBlock}>
                    <CTab
                        onPress={setTabLanguage} active={tabLanguage}
                        items={[{value: 'ENG', key: 0}, {value: 'Рус', key: 1}, {value: 'Հայ', key: 2}]}
                    />
                    <div className={classes.checkBoxUndertabWrapp}>
                    </div>
                    {tabLanguage === 0 && <>
                        <CInput
                            disabled={true}
                            value={en.name}
                            placeholder={t("seller_product.product_name")}
                            label={t("seller_product.product_name")}
                        />
                        <div className={classes.textAreaWrapp}>
                            <CTextarea
                                disabled={true}
                                value={en.description}
                                placeholder={t("seller_product.product_description")}
                                label={t("seller_product.product_description")}
                            />
                        </div>
                    </>}
                    {tabLanguage === 1 && <>
                        <CInput
                            disabled={true}
                            value={ru.name}
                            placeholder={t("seller_product.product_name")}
                            label={t("seller_product.product_name")}
                        />
                        <div className={classes.textAreaWrapp}>
                            <CTextarea
                                disabled={true}
                                value={ru.description}
                                placeholder={t("seller_product.product_description")}
                                label={t("seller_product.product_description")}
                            />
                        </div>
                    </>}
                    {tabLanguage === 2 && <>
                        <CInput
                            disabled={true}
                            value={am.name}
                            placeholder={t("seller_product.product_name")}
                            label={t("seller_product.product_name")}
                        />
                        <div className={classes.textAreaWrapp}>
                            <CTextarea
                                disabled={true}
                                value={am.description}
                                placeholder={t("seller_product.product_description")}
                                label={t("seller_product.product_description")}
                            />
                        </div>
                    </>}

                </div>
                <div className={classes.addProductContainerBlock}>
                    <p className={classes.title}>{t("seller_product.product_category")}</p>
                    <div className={classes.clearBothUnderTab}/>
                    {userId === 3 &&
                        <CSelect
                            isDisabled={true}
                            label={t("seller_product.state")}
                            placeholder={t(`seller_product.${categories.state}`)}
                        />}
                    <CSelect
                        isDisabled={true}
                        // value={categories.first_category}
                        label={t("category.category")}
                        placeholder={t(`category.${categories.first_category}`)}
                        // placeholder={categories.first_category}
                    />
                    {resetAll && <>
                        {categories.first_category !== 'souvenir' &&
                            <CSelect
                                isDisabled={true}
                                label={t("for_whom.for_whom")}
                                placeholder={t(`for_whom.${categories.second_category}`)}
                            />
                        }
                        <CSelect
                            isDisabled={true}
                            label={t("metals.metals")}
                            placeholder={t(`metals.${categories.metal}`)}
                        />
                    </>}
                </div>
            </div>
            {categories.first_category && categories.metal ?
                <div className={classes.containerSettings}>
                    <p className={classes.title}>{t("seller_product.product_details")}</p>
                    <div className={classes.inputBlock}>
                        {(categories.first_category === 'watches') || (categories.first_category === 'souvenir') ? null :
                            <div className={classes.selectWrapp}>
                                {resetType &&
                                    <CSelect
                                        isDisabled={true}
                                        label={t("type.type")}
                                        placeholder={t(`type.${values.type}`)}
                                    />
                                }
                            </div>}
                        {categories.first_category === 'souvenir' &&
                            <div className={classes.selectWrapp}>
                                {resetType &&
                                    <CSelect
                                        isDisabled={true}
                                        label={t("type.type")}
                                        placeholder={t(`type.${values.type}`)}
                                    />
                                }
                            </div>}
                        {resetValues && <>
                            {(categories.first_category === 'watches' && categories.metal === 'other') || categories.first_category === 'souvenir' ? null :
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        isDisabled={true}
                                        defaultValue={{label: values.fineness, value: values.fineness}}
                                        label={t("seller_product.fineness")}
                                        placeholder={t("seller_product.fineness")}
                                    />
                                </div>}

                            {values.type !== 'bars' && categories.first_category === 'souvenir' && categories.metal !== 'other' &&
                                values.type !== 'antique' && <div className={classes.selectWrapp}>
                                    <CSelect
                                        defaultValue={{label: values.fineness, value: values.fineness}}
                                        isDisabled={true}
                                        label={t("seller_product.fineness")}
                                        placeholder={t("seller_product.fineness")}
                                    />
                                </div>}
                            {values.type === 'bars' &&
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        defaultValue={{label: values.fineness, value: values.fineness}}
                                        isDisabled={true}
                                        label={t("seller_product.fineness")}
                                        placeholder={t("seller_product.fineness")}
                                    />
                                </div>}
                            {categories.metal === 'gold' && values.type === 'coin' &&
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        defaultValue={{label: values.country, value: values.country}}
                                        label={t("seller_product.country")}
                                        placeholder={t("seller_product.country")}
                                        isDisabled={true}
                                    />
                                </div>}

                            {categories.first_category !== 'souvenir' && <div className={classes.selectWrapp}>
                                <CSelect
                                    defaultValue={{label: t(`colors.${values.color}`), value: values.color}}
                                    isDisabled={true}
                                    label={t("seller_product.color")}
                                    placeholder={t("seller_product.color")}
                                />
                            </div>}
                            <div className={classes.selectWrapp}>
                                <CSelect
                                    defaultValue={{
                                        label: t(`seller_product.${values.published}`),
                                        value: values.published
                                    }}
                                    isDisabled={true}
                                    label={t("seller_product.published")}
                                    placeholder={t("seller_product.published")}
                                />
                            </div>
                            {categories.first_category !== 'souvenir' && <div className={classes.selectWrapp}>
                                <CSelect
                                    defaultValue={{label: t(`seller_product.${values.origin}`), value: values.origin}}
                                    isDisabled={true}
                                    label={t("seller_product.origin")}
                                    placeholder={t("seller_product.origin")}
                                />
                            </div>}
                            {(categories.first_category === 'watches' || categories.first_category === 'souvenir') &&
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        defaultValue={{
                                            label: t(`seller_product.${values.condition}`),
                                            value: values.condition
                                        }}
                                        options={
                                            [
                                                {label: t("seller_product.new"), value: "new"},
                                                {label: t("seller_product.used"), value: "used"},
                                            ]
                                        }
                                        label={t("seller_product.condition")}
                                        placeholder={t("seller_product.condition")}
                                        isDisabled={true}
                                    />
                                </div>}
                            {(categories.metal === 'silver') || (categories.first_category === 'watches' && categories.metal === 'other') || (categories.first_category === 'souvenir') ? null :
                                <div className={classes.selectWrapp}>
                                    <CInput
                                        value={values.initial_weight}
                                        disabled={true}
                                        label={t("seller_product.initial_weight")}
                                        placeholder={t("seller_product.initial_weight")}
                                    />
                                </div>}
                            {categories.first_category === 'watches' && categories.metal === 'other' ? null :
                                values.type !== 'bars' && values.type !== 'antique' &&
                                <div className={classes.selectWrapp}>
                                    <CInput
                                        value={values.weight}
                                        disabled={true}
                                        label={t("seller_product.weight")}
                                        placeholder={t("seller_product.weight")}
                                    />
                                </div>}
                            {values.type === 'bars' &&
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        defaultValue={{label: values.weight, value: values.weight}}
                                        isDisabled={true}
                                        label={t("seller_product.weight")}
                                        placeholder={t("seller_product.weight")}
                                    />
                                </div>}

                            {values.type === 'bars' &&
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        defaultValue={{label: values.name, value: values.name}}
                                        isDisabled={true}
                                        label={t("seller_product.name")}
                                        placeholder={t("seller_product.name")}
                                    />
                                </div>}

                            {(values.type === 'chain_bracelet' ||
                                    values.type === 'chain' || values.type === 'hard_bracelet') &&
                                <div className={classes.selectWrapp}>
                                    <CInput
                                        value={values.size}
                                        disabled={true}
                                        label={`${t("seller_product.size")} (${t('seller_product.cm')})`}
                                        placeholder={`${t("seller_product.size")} (${t('seller_product.cm')})`}
                                    />
                                </div>}
                            {(values.type === 'ring' || values.type === 'wed_ring' || values.type === 'eng_ring') &&
                                <div className={classes.selectWrapp}>
                                    <CSelect

                                        defaultValue={{label: values.size, value: values.size}}
                                        isDisabled={true}
                                        label={`${t("seller_product.size")} (${t('seller_product.cm')})`}
                                        placeholder={`${t("seller_product.size")} (${t('seller_product.cm')})`}
                                    />
                                </div>}
                            {(categories.first_category === 'watches') &&
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        defaultValue={{label: values.brand, value: values.brand}}
                                        isDisabled={true}
                                        label={t("seller_product.brand")}
                                        placeholder={t("seller_product.brand")}
                                    />
                                </div>}
                            {(categories.first_category === 'watches') &&
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        defaultValue={{
                                            label: t(`seller_product.${values.movement_type}`),
                                            value: values.movement_type
                                        }}
                                        isDisabled={true}
                                        label={t("seller_product.movement_type")}
                                        placeholder={t("seller_product.movement_type")}
                                    />
                                </div>}
                            {(categories.first_category === 'watches') &&
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        defaultValue={{
                                            label: t(`seller_product.${values.bracelet_belt}`),
                                            value: values.bracelet_belt
                                        }}
                                        isDisabled={true}
                                        label={t("seller_product.bracelet_belt")}
                                        placeholder={t("seller_product.bracelet_belt")}
                                    />
                                </div>}

                            {(categories.state === 'not_ready') || (categories.first_category === 'souvenir') || (categories.first_category === 'watches' && categories.metal === 'other') || (categories.first_category === 'watches' && categories.metal === 'silver') ? null :
                                <div className={classes.selectWrapp}>
                                    <CSelect
                                        defaultValue={{
                                            label: t(`seller_product.${values.gemstone}`),
                                            value: values.gemstone
                                        }}
                                        isDisabled={true}
                                        label={t("seller_product.gemstone")}
                                        placeholder={t("seller_product.gemstone")}
                                    />
                                </div>}
                            <div className={classes.selectWrapp}>
                                <CInput
                                    disabled={true}
                                    value={values.product_code}
                                    isDisabled={true}
                                    label={t("seller_product.product_code")}
                                    placeholder={t("seller_product.product_code")}
                                />
                            </div>
                        </>}
                    </div>
                    {categories.metal !== 'silver' && categories.first_category !== 'souvenir' &&
                        <>
                            <div className={classes.addStonesAttributesTitleBlock}>
                                <p className={classes.addStonesAttributesTitleBlockTitle}>{t("seller_product.stones_attributes")}</p>
                            </div>
                            {!checked && resetStone && <>
                                {stones.map((item, index) => {
                                    return (
                                        <div className={classes.inputBlock} key={index}>
                                            <div className={classes.selectWrapp}>
                                                <p>{t("stone.stone")}</p>
                                                <p className={classes.selectedWrapText}>{t("stone.diamond")}</p>
                                            </div>
                                            <div className={classes.selectedWrapp}>
                                                <p>{t("stone.carat")}</p>
                                                <p className={classes.selectedWrapText}>{item.carat}</p>
                                            </div>
                                            <div className={classes.selectedWrapp}>
                                                <p>{t("stone.pcs")}</p>
                                                <p className={classes.selectedWrapText}>{item.pcs}</p>
                                            </div>
                                            <div className={classes.selectedWrapp}>
                                                <p>{t("stone.color")}</p>
                                                <p className={classes.selectedWrapText}>{item.color ? item.color : 'N/A'}</p>
                                            </div>
                                            <div className={classes.selectedWrapp}>
                                                <p>{t("stone.clarity")}</p>
                                                <p className={classes.selectedWrapText}>{item.clarity ? item.clarity : 'N/A'}</p>
                                            </div>
                                            <div className={classes.selectedWrapp}>
                                                <p>{t("stone.cut")}</p>
                                                <p className={classes.selectedWrapText}>{item.cut}</p>
                                            </div>
                                            <div className={classes.selectedWrapp}>
                                                <p>{t("stone.certificate_code")}</p>
                                           {item.certificate_code ?
                                                    <a href={`https://www.gia.edu/report-check?reportno=${item.certificate_code}`}
                                                       target={'_blank'} className={classes.selectedWrapText}>{item.certificate_code}</a> :
                                                    <p>'N/A'</p>}
                                            </div>
                                            <div className={classes.selectedWrapp}>
                                                <p>{t("stone.diamondPrice")}</p>
                                                <p className={classes.selectedWrapText}>{item.diamondPrice}</p>
                                            </div>
                                            <div style={{margin: '20px 0 50px 0', width: '39%'}}>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>}
                        </>
                    }
                    <div className={classes.addStonesAttributesTitleBlock}>
                        <p className={classes.addStonesAttributesTitleBlockTitle}>{t("seller_product.product_price")}</p>
                    </div>
                    <div className={classes.inputBlock}>
                        <div className={classes.selectWrapp}>
                            <CInput
                                value={productPrice.oder_cost}
                                disabled={true}
                                label={t("seller_product.oder_cost")}
                                placeholder={t("seller_product.oder_cost")}
                            />
                        </div>
                        <div className={classes.selectWrapp}>
                            <CInput
                                value={productPrice.cost}
                                label={t("seller_product.cost")}
                                placeholder={t("seller_product.cost")}
                                disabled={true}
                            />
                        </div>
                        <div className={classes.selectWrapp}>
                            <CInput
                                value={productPrice.price}
                                disabled={true}
                                label={t("seller_product.price")}
                                placeholder={t("seller_product.price")}
                            />
                        </div>
                    </div>
                    <div className={classes.addStonesAttributesTitleBlock}>
                        <p className={classes.addStonesAttributesTitleBlockTitle}>{t("seller_product.image")}</p>
                    </div>
                    <div className={classes.viewImageList}>
                        {images.length > 0 && images.map((item) => {
                            if (!item.is_video) {
                                return (
                                    <img alt={'avatar'} src={item.path} key={item.number}/>
                                )
                            }
                        })}
                    </div>

                    {videoes && <>
                        <div className={classes.liner}></div>
                        <div className={classes.addStonesAttributesTitleBlock}>
                            <p className={classes.addStonesAttributesTitleBlockTitle}>{t("seller_product.video_link")}</p>
                        </div>
                        <div className={classes.selectWrapp}>
                            <UploadVideo
                                setVideo={setVideo}
                                video={videoes}
                                view={true}
                            />
                        </div>
                    </>}
                </div> : <></>}
        </div>
    );
}

export default SellerViewProduct;
