import { useCallback, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-multi-lang";
import {groupStyle} from "./fixing-style";
import {Axios} from "src/api/api";
import {GlobalContext} from "src/context/global-context";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import CInput from "src/cors/input/cInput";
import {CModal} from "src/cors/modal/cModal";


function FixingGroups({getGroupUsers, active}) {
    const t = useTranslation()
    const classes = groupStyle()
    const navigation = useNavigate()
    const {state, setState} = useContext(GlobalContext)
    const [data, setData] = useState([])
    const [offset, setOffset] = useState('0')
    const [openDelete, setOpenDelete] = useState(false)
    const [groupValue, setGroupValue] = useState({
        name: "",
        discount: ""
    })
    const [editGroupValue, setEditGroupValue] = useState({
        id: "",
        name: "",
        discount: ""
    })

    const request = useCallback(() => {
        setState({...state, loader: true})
        Axios(`fixing/groups`).then(res => {
            setData([...res.data.groups])
        }).catch(err => {
            console.error(err)
            toast(errorMessages(err?.response?.data?.code))
        }).finally(() => {
            setState({...state, loader: false})
        })
    }, [offset])

    const handleGroupValue = (value, input, object = groupValue) => {
        let info = {...object}
        info[input] = input === 'name' ? value : +value
        setGroupValue(info)
    }

    const addGroup = () => {
        Axios.post(`fixing/groups`, groupValue).then(res => {
            request()
        }).catch(err => {
            console.error(err)
        })
    }

    const deleteGroup = (group_id) => {
        Axios.delete(`fixing/groups/${group_id}`).then(res => {
            request()
        }).catch(err => {
            console.error(err)
        })
    }

    const closeModal = () => (setOpenDelete(false),setEditGroupValue({id: "", name: "", discount: ""}))

    const editGroup = (group_id) => {
        Axios.put(`fixing/groups/${group_id}`, editGroupValue).then(res => {
            request()
            closeModal()
        }).catch(err => {
            console.error(err)
        })
    }

    useEffect(() => {
        request()
    }, [offset])

    return (
        <div className={classes.groups}>
            <div className={classes.groupsList}>
            {
                data.map((item, index) => {
                    return (
                        <div key={index.toString()} className={classes.group}>
                            <p style={{ fontSize: '14px', display: 'flex', alignItems: 'center' }}>{item.name}, (-{item.discount})</p>
                            <div style={{ display: 'flex', marginTop: 'auto', marginLeft: 'auto' }}>
                                <CButton
                                    // onPress={() => getGroupUsers(item.id)}
                                    onPress={()=>navigation('/fixing/fixingGroup', {state: {prefix: 'group', id: item.id}})}
                                    title={'Open'}
                                    backgroundColor={active !== item.id ? colors.blue : colors.gold}
                                />
                            </div>
                            <div className={classes.groupRightEdit}>
                                <CButton
                                    onPress={() => (
                                        setEditGroupValue({id: item.id, name: item.name, discount: item.discount})
                                    )}
                                    title={'Edit'}
                                    backgroundColor={colors.green}
                                />
                            </div>
                        </div>
                    )
                })
            }
            </div>
            <div className={classes.groupsRight}>
                <CInput
                    value={groupValue.name}
                    onChange={(e) => handleGroupValue(e.target.value, 'name')}
                    placeholder={`Group Name`}
                />
                <CInput
                    value={groupValue.discount}
                    onChange={(e) => handleGroupValue(e.target.value, 'discount')}
                    placeholder={`Discount eg. (-0.15) `}
                />
                <CButton
                    onPress={addGroup}
                    title={t('project.save')}
                    backgroundColor={colors.blue}
                />
            </div>

            <CModal
                visible={editGroupValue.id} 
                closeModal={() => closeModal()}
                title={!openDelete ? t('Edit group') : 'Are you sure?'}
                children={
                    <div className={classes.addUserModalSection}>
                    {
                        !openDelete ? <>
                            <CInput
                                value={groupValue.name}
                                onChange={(e) => handleGroupValue(e.target.value, 'name')}
                                placeholder={`Group Name`}
                            />
                            <CInput
                                value={groupValue.discount}
                                onChange={(e) => handleGroupValue(e.target.value, 'discount')}
                                placeholder={`Discount eg. (-0.15) `}
                            />
                            <CButton
                                onPress={editGroup}
                                title={t('booking.send')}
                                backgroundColor={colors.blue}
                            />
                            <br />
                            <CButton
                                onPress={() => setOpenDelete(true)}
                                title={t('Delete')}
                                backgroundColor={colors.red}
                            />
                        </> : <>
                            <CButton
                                onPress={() => deleteGroup(editGroupValue.id)}
                                title={t('Yes, delete group ' + groupValue.name)}
                                backgroundColor={colors.blue}
                            />
                            <br />
                            <CButton
                                onPress={() => setOpenDelete(false)}
                                title={t('No')}
                                backgroundColor={colors.red}
                            />
                        </>
                    }
                        
                    </div>
                }/>
            
        </div>
    );
}

export default FixingGroups;