import React from "react";
import propTypes from "prop-types";
import CButton from "src/cors/button/cButton";
import {useTranslation} from "react-multi-lang";

const SellerDetailsOfferTable = ({dataBodyOfferSeller}) => {
    const t = useTranslation()
    return (
        <>
            {
                (dataBodyOfferSeller && dataBodyOfferSeller.length) ?
                    <>
                        <div className='tableWrapper'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>{t("table.name")}</th>
                                    <th>{t("table.buyer")}</th>
                                    <th>{t("table.price")}</th>
                                    <th>{t("table.offer_price")}</th>
                                    <th>{t("table.action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dataBodyOfferSeller.map((tdItem, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td>{tdItem.name}</td>
                                                <td>{`${tdItem.buyer_firstname} ${tdItem.buyer_lastname}`}</td>
                                                <td>{tdItem.price}</td>
                                                <td>{tdItem.offer_price}</td>
                                                <td>
                                                    <div className='tableButtonWrapper'>
                                                        <CButton
                                                            title={t("project.view_details")}
                                                            borderWidth={1}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            dataBodyOfferSeller.map((item, index) => {
                                return (
                                    <div key={index.toString()} className='cardContainer'>
                                        <ul className='cardContent'>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.name")}</p>
                                                <p className='cardValue'>{item.name}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.buyer")}</p>
                                                <p className='cardValue'>{`${item.buyer_firstname} ${item.buyer_lastname}`}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.price")}</p>
                                                <p className='cardValue'>{item.price}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.offer_price")}</p>
                                                <p className='cardValue'>{item.offer_price}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <div className='cardButtonWrapper'>
                                                    <CButton
                                                        title={t("project.view_details")}
                                                        borderWidth={1}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </> : null
            }

        </>

    )
}

SellerDetailsOfferTable.propTypes = {
    dataBody: propTypes.array
};

export default SellerDetailsOfferTable