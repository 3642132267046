import React from "react";
import { cardInfoStyle } from "./cardInfo-style";
import {useTranslation} from "react-multi-lang";

interface CardInfoProps {
    title: string
    info: any
}

const CardInfo = ({ title, info }: CardInfoProps) => {
    const classes = cardInfoStyle();
    const t = useTranslation()
    return (
        <>
            {title || (typeof info==='number') ? <div className={classes.cardInfoContainer}>
                <h1>{title}</h1>
                <p>{info || t('project.empty')}</p>
            </div>: null}
        </>
    )
}

export default CardInfo

