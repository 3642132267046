import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";


export const cModalStyle = makeStyles(() =>
    createStyles({
        container: {
            width: 632,
            padding: '40px !important',
            margin: 0,
            marginTop: (props) => (props.center ? 0 : 100),
            backgroundColor: colors.white,
            "@media (max-width:680px)": {
                width: '100%',
                position: 'absolute',
                bottom: 0,
                left: 0,
                borderRadius: ' 16px 16px 0px 0px',
                padding: '17px !important',
                marginTop: 0,
            },
        },
        containerOut: {
            transform: 'scale(0.2)',
        },
        title: {
            textTransform: 'uppercase',
            fontSize: 32,
            color: colors.blue,
            "@media (max-width:680px)": {
                fontSize: 22,
            },
        }

    })
)