import React from "react";
import propTypes from "prop-types";
import {RenderStatus} from "src/components/render-status/renderStatus";
import CButton from "src/cors/button/cButton";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-multi-lang";
import CInput from "src/cors/input/cInput";
import {colors} from "src/utils/color";
import {config} from "src/api/config";
import editIcon from "../../../../assets/icons/iconEdit.svg";
import SmallBtn from "../../../../cors/smallBtn/smallBtn";

const EditSellerDetailsProductTable = ({dataBody, sellerId}) => {
    const navigation = useNavigate()


    const t = useTranslation()

    function editRequest(id) {
        
    }

    return (
        <>
            {
                (dataBody && dataBody.length) ?
                    <>
                        <div className='tableWrapper'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>{t("table.photo")}</th>
                                    <th>{t("table.name")}</th>
                                    <th>{t("table.category")}</th>
                                    <th>{t("table.code")}</th>
                                    <th>{t("table.code")}</th>
                                    <th>{t("table.price")}</th>
                                    <th>{t("table.status")}</th>
                                    <th>{t("table.action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dataBody.map((tdItem, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td>
                                                    <img className='productImage'
                                                         src={tdItem?.pictures && tdItem.pictures[0].path} alt="photo"/>
                                                </td>
                                                <td style={{maxWidth: 200}}>{tdItem.name}</td>
                                                <td>{t(`type.${tdItem.type}`)}</td>
                                                <td>{tdItem.pcode}</td>
                                                <td>{tdItem.product_code}</td>
                                                <td>{tdItem.price}</td>
                                                <td><RenderStatus status={tdItem.status}/></td>
                                                <td>
                                                    <div className='tableButtonWrapper' style={{display: "flex"}}>
                                                        <Link style={{display: "block", marginRight: 8}}
                                                           to={`/seller/editProduct/${tdItem.id}`}
                                                        >
                                                            <SmallBtn
                                                                icon={editIcon}
                                                                borderColor={colors.black}
                                                            />

                                                        </Link>
                                                        <a style={{display: "block", marginRight: 8, marginLeft: 8}}
                                                           href={`${config.adminUrl}/seller/productSingle/${tdItem.id}`}
                                                           target={'_blank'}
                                                        >
                                                            <CButton
                                                                title={t("project.view_details")}
                                                                borderWidth={1}
                                                            />
                                                        </a>

                                                    </div>

                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            dataBody.map((item, index) => {
                                return (
                                    <div key={index.toString()} className='cardContainer sellerProductCard'>
                                        <img src={item?.pictures && item.pictures[0].path} alt="photo"/>
                                        <ul className='cardContent sellerProductContent'>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.name")}</p>
                                                <p className='cardValue'>{item.name}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.category")}</p>
                                                <p className='cardValue'>{item.type}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.code")}</p>
                                                <p className='cardValue'>{item.product_code}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.price")}</p>
                                                <p className='cardValue'>{item.price}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.status")}</p>
                                                {<RenderStatus status={item.status}/>}
                                            </li>
                                            <li className='cardItem'>
                                                <div className='cardButtonWrapper'>
                                                    <a style={{display: "block", marginRight: 8}}
                                                       href={`${config.adminUrl}seller/productSingle/${item.id}`}
                                                       target={'_blank'}
                                                    >
                                                        <CButton
                                                            title={t("project.view_details")}
                                                            borderWidth={1}
                                                        />
                                                    </a>

                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </> : null
            }

        </>

    )
}

EditSellerDetailsProductTable.propTypes = {
    dataBody: propTypes.array,
};

export default EditSellerDetailsProductTable