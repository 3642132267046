import {createStyles, makeStyles} from "@mui/styles";

export const smallBtnStyle = makeStyles(() =>
    createStyles({
        smallBtnContainer: {
            width: 40,
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: '0 0 40px',
            cursor: 'pointer',
            ["@media (max-width:500px)"]: {
                width: 28,
                height: 28,
                flex: '0 0 28px',
            },
        },
        icon: {
            width: 15,
            height: 15
        }
    })
)