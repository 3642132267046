import React, {useEffect, useState} from "react";
import {sellerBookingStyle} from "./seller-booking-style";
import UnderHeader from "src/components/underHeader/underHeader";
import CInput from "src/cors/input/cInput";
import CButton from "src/cors/button/cButton";
import {Axios} from "src/api/api";
import CSelect from "src/cors/select/cSelect";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import {checkError} from "src/helpers/check-error";
import SellerBookingTable from "./seller-booking-table";

function SellerBooking() {
    const t = useTranslation();
    const classes = sellerBookingStyle();
    const [uuid, setUuid] = useState('')
    const [validateUser, setValidateUser] = useState(false)
    const [prices, setPrices] = useState([])
    const [data, setData] = useState([])

    const [bookingValues, setBookingValues] = useState({
        metal: 'gold',
        type: '',
        fineness: '',
        weight: '',
        // time: '',
        // date: "",
        taking_time: '',
        market: ""
    })
    const [bookingValuesError, setBookingValuesError] = useState({})

    const bookingInputValues = (e, keyName) => {
        let reg = /-?[0-9]*\.?[0-9]+/g
        if (keyName === 'taking_time') {
            if (isNaN(+e) || !reg || +e > 48) {
                return
            }
        }
        if (keyName === 'weight') {
            if (isNaN(+e) || !reg ) {
                return
            }
        }
        let newValues = {...bookingValues}
        newValues[keyName] = e
        setBookingValues({...newValues})
        if (e.length || typeof e === 'number') {
            setBookingValuesError({...bookingValuesError, [keyName]: ''})
        }
    }

    const handleBookingRequest = () => {
        if (bookingValues.fineness && bookingValues.type && bookingValues.weight && bookingValues.taking_time && bookingValues.market) {
            // const dateStr = `${bookingValues.date}:${bookingValues.time}`;
            // const date = new Date(dateStr);
            // const unixTimestamp = Math.floor(date.getTime() / 1000);
            ////////////////
            const date = new Date().getTime()
            const newDate = date + +bookingValues.taking_time * 60 * 60 * 1000
            const n = new Date(newDate)
            const unixTimestamp = Math.floor(n / 1000);
            ////////////////
            Axios.post('booking/request', {
                "metal": "gold",
                "type": `${bookingValues.type}_${bookingValues.fineness}`,
                "weight": bookingValues.weight,
                "taking_time": unixTimestamp,
                market: bookingValues.market
            }).then(res => {
                setUuid(res.data.uuid)
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
                console.log(err, 'err')
            })
        } else {
            checkError(bookingValues, setBookingValuesError)
        }
    }
    const handleBookingConfirm = () => {
        Axios.post('booking/confirm', {uuid}).then(res => {
            toast('done')
            setUuid('')
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err')
        })
    }

    useEffect(() => {
        if (uuid) {
            setTimeout(() => {
                setUuid('')
            }, 15000)
        }
    }, [uuid])

    useEffect(() => {
        Axios('booking/user/validate').then(res => {
            setValidateUser(res.data.valid)

        }).catch(err => {
            console.log(err, 'err');
            toast(errorMessages(err?.response?.data?.code))
        })
    }, [])

    useEffect(() => {
        Axios(`rate/local`).then(res => {
            if (res.data.data){
                setPrices(res.data.data)
            }
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }, []);

    useEffect(() => {
        Axios(`booking/list/all`).then(res => {
            if (res.data){
                setData(res.data)
            }
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }, []);

    return (
        <div className={classes.sellerToolsContainer}>
            <UnderHeader title={t("header.booking")}/>
            {validateUser && <>
                <p style={{marginTop: 30}} className={classes.subTitle}>{t("tools.booking")}</p>
                <div style={{marginTop: 20, marginBottom: 100}} className={classes.boxShadowContainer}>
                    <div style={{margin:20}}>
                        <p style={{fontWeight:"bold", marginBottom:10}}>{`999 ${t('seller_rate.sell')} - ${prices[0].sell}, ${t('seller_rate.buy')} -  ${prices[0].buy} `}</p>
                        <p style={{fontWeight:"bold", marginBottom:10}}>{`995 ${t('seller_rate.sell')} - ${prices[1].sell}, ${t('seller_rate.buy')} -  ${prices[1].buy} `}</p>
                    </div>
                    <div style={{flexWrap: "wrap"}} className={classes.inputsRow}>
                        <CSelect
                            errorMessage={bookingValuesError.fineness}
                            placeholder={t("tools.fineness")}
                            label={t("tools.fineness")}
                            onChange={(e) => bookingInputValues(e.value, 'fineness')}
                            options={[{label: '999', value: "999"}, {label: '995', value: "995"}]}
                        />
                        <CSelect
                            errorMessage={bookingValuesError.type}
                            placeholder={t("tools.type")}
                            label={t("tools.type")}
                            onChange={(e) => bookingInputValues(e.value, 'type')}
                            options={[{label: t(`seller_rate.sell`), value: "sell"}, {
                                label: t(`seller_rate.buy`),
                                value: "buy"
                            }]}
                        />
                        <CSelect
                            errorMessage={bookingValuesError.market}
                            placeholder={t("setting.market")}
                            label={t("setting.market")}
                            onChange={(e) => bookingInputValues(e.value, 'market')}
                            options={[
                                {label: t('markets.gold_center'), value: 'gold_center'},
                                {label: t('markets.multi_gold'), value: 'multi_gold'},
                            ]}
                        />
                        <CInput
                            errorMessage={bookingValuesError.weight}
                            value={bookingValues.weight}
                            onChange={(e) => bookingInputValues(e.target.value, 'weight')}
                            // type='number'
                            placeholder={t("tools.weight")}
                            label={t("tools.weight")}
                        />
                        {/*<CInput*/}
                        {/*    errorMessage={bookingValuesError.date}*/}
                        {/*    value={bookingValues.date}*/}
                        {/*    onChange={(e) => bookingInputValues(e.target.value, 'date')}*/}
                        {/*    type='date'*/}
                        {/*    placeholder={t("tools.date")}*/}
                        {/*    label={t("tools.date")}*/}
                        {/*/>*/}
                        {/*<CInput*/}
                        {/*    errorMessage={bookingValuesError.time}*/}
                        {/*    value={bookingValues.time}*/}
                        {/*    onChange={(e) => bookingInputValues(e.target.value, 'time')}*/}
                        {/*    type='time'*/}
                        {/*    placeholder={t("tools.time")}*/}
                        {/*    label={t("tools.time")}*/}
                        {/*/>*/}
                        <CInput
                            errorMessage={bookingValuesError.taking_time}
                            value={bookingValues.taking_time}
                            onChange={(e) => bookingInputValues(e.target.value, 'taking_time')}
                            type='taking_time'
                            placeholder={t("tools.taking_time")}
                            label={t("tools.taking_time")}
                        />
                        {uuid && <CButton
                            onPress={handleBookingConfirm}
                            title={t("project.approve")}
                            borderWidth={1}
                        />}
                    </div>

                    <div className={classes.flexEnd}>
                        {!uuid && <CButton
                            onPress={handleBookingRequest}
                            title={t("project.booking")}
                            borderWidth={1}
                        />}
                    </div>
                    <div className={classes.priceContainer}>
                        {uuid && <p>{t("tools.you_have_15_sec")}</p>}
                    </div>
                </div>
            </>}
            <SellerBookingTable dataBody={[{}]} />
        </div>
    );
}

export default SellerBooking;
