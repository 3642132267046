import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const uploadVideoStyles = makeStyles(() =>
    createStyles({
        videoInput:{
            position:"relative"
        },
        deleteImg: {
             position: 'absolute',
            backgroundColor: colors.red,
            top: 6,
            right: 19,
            width: 26,
            height: 26,
            borderRadius: "50%",
            display: "flex",
            alignItems: 'center',
            justifyContent: "center",
            cursor: "pointer",
            zIndex:9
        },

    })
);