import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const buyerDetailStyle = makeStyles(() =>
    createStyles({
        buyerDetailsContainer: {
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto 30px',
            "@media (max-width:1250px)": {
                width: '96%',
            },
        },
        buyerPersonalInfo: {
            paddingBottom: 40,
            borderBottom: `1px solid ${colors.greyLiner}`,
        },
        buyerFormWrapper: {
            margin: '30px -1% 0',
            flexWrap: 'wrap',
            display: 'flex',
            '& > div': {
                margin: '0 1%',
                width: '47.9%',
                "@media (max-width:1300px)": {
                    width: '47.8%',
                },
                "@media (max-width:800px)": {
                    width: '100%',
                },
            }
        },
        resetWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            "@media (max-width:500px)": {
                flexDirection: 'column',
            },
        },
        deactivate: {
            fontSize: 14,
            lineHeight: '16px',
            fontWeight: 400,
            color: colors.brightRed,
            cursor: 'pointer',
            textTransform: 'uppercase',
        },
        activate: {
            fontSize: 14,
            lineHeight: '16px',
            fontWeight: 400,
            color: colors.green,
            cursor: 'pointer',
            textTransform: 'uppercase',
        },
        tabWrapper: {
            marginTop: 30,
        },
        buttonWrapper: {
            width: 230,
            "@media (max-width:500px)": {
                marginBottom: 20
            },
        },
        resetInputWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        applyBtn: {
            fontSize: 14,
            color: colors.blue,
            fontWeight: 600,
            marginLeft: 15,
            cursor: 'pointer',
        },
        seeMoreBtnWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 30,
            '& > div': {
                width: 230
            },
            "@media (max-width:500px)": {
                marginBottom: 30
            },
        },
        deleteModalBtns:{
            display: 'flex',
            justifyContent: "space-between",
            marginTop: 50
        },
        deleteModalBtnWrap:{
            width: '48%'
        }
    })
)