import React from "react";
import {messagesStyle} from "./messages-style";
import PropTypes from "prop-types";

export default function MessageUsers({onUser}) {
    const classes = messagesStyle();

    return (

        <div className={classes.usersBox} onClick={onUser}>
            <div className={classes.usersBoxHeader}>
                <p className={classes.usersBoxHeaderName}>Karen karapetyan</p>
                <p className={classes.usersBoxHeaderDate}>14 May, 2021</p>
            </div>
            <p className={classes.shortMessage}>Hey, Karen. Can we get on a asdas asdas sdgdfg sdfgfdhdfgquick call?
                ... </p>
        </div>
    );
}

MessageUsers.propTypes = {
    onUser: PropTypes.func
}