import React from "react";
import propTypes from "prop-types";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import {useNavigate} from "react-router-dom";
import removeIcon from "src/assets/icons/iconRemove.svg";
import SmallBtn from "src/cors/smallBtn/smallBtn";
import {Axios} from "src/api/api";
import {useTranslation} from "react-multi-lang";


const RequestSellerTable = ({dataBodyRequestSeller,aproveSeller,deleteRequest,type}) => {
    const navigation = useNavigate()
    const t = useTranslation()

    const goToDetail = (id)=>{
        if (type==='service'){
            navigation('/services/serviceDetail',{state: {id}})
        }
        navigation('/seller/sellerDetail',{state: {id}})
    }

    return (
        <>
            {
                (dataBodyRequestSeller && dataBodyRequestSeller.length) ?
                    <>
                        <div className='tableWrapper'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>{t("table.username")}</th>
                                    <th>{t("table.phone_number")}</th>
                                    <th>{t("table.code")}</th>
                                    <th>{t("table.country")}</th>
                                    <th>{t("table.email")}</th>
                                    <th>{t("table.action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dataBodyRequestSeller.map((tdItem, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td>{tdItem.firstname && `${tdItem.firstname} ${tdItem.lastname}`}</td>
                                                <td>{tdItem.phone_number}</td>
                                                <td>{tdItem.ucode}</td>
                                                <td>{tdItem.country}</td>
                                                <td>{tdItem.email}</td>
                                                <td>
                                                    <div className='tableButtonWrapper flexRow'>
                                                        <CButton onPress={()=>goToDetail(tdItem.id)} title={t("project.view_details")} borderWidth={1}/>
                                                        <CButton onPress={()=>aproveSeller(tdItem.id)} title={t("project.approve")} backgroundColor={colors.blue}/>
                                                        <SmallBtn onPress={()=>deleteRequest(tdItem.id)} icon={removeIcon} borderColor={colors.softRed} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            dataBodyRequestSeller.map((item, index) => {
                                return (
                                    <div key={index.toString()} className='cardContainer'>
                                        <ul className='cardContent'>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.username")}</p>
                                                <p className='cardValue'>{`${item.firstname} ${item.lastname}`}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.phone_number")}</p>
                                                <p className='cardValue'>{item.phone_number}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.country")}</p>
                                                <p className='cardValue'>{item.country}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.email")}</p>
                                                <p className='cardValue'>{item.email}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <div className='cardButtonWrapper flexRow'>
                                                    <CButton  onPress={()=>goToDetail(item.id)}  title={t("project.view_details")} borderWidth={1}/>
                                                    <CButton title={t("project.approve")} onPress={()=>aproveSeller(item.id)} backgroundColor={colors.blue}/>
                                                    <SmallBtn onPress={()=>deleteRequest(item.id)} icon={removeIcon} borderColor={colors.softRed} />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </> : null
            }

        </>

    )
}

RequestSellerTable.propTypes = {
    dataBody: propTypes.array,
    aproveSeller: propTypes.func,
    deleteRequest: propTypes.func,
};

export default RequestSellerTable