import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const productItemStyle = makeStyles(() =>
    createStyles({
        productItemContainer: {
            display: 'flex',
            alignItems: 'flex-start',
            borderBottom: `1px solid ${colors.greyLiner}`,
            marginTop: 25,
            paddingBottom: 30,
        },
        productNumber: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '19px',
        },
        productImageWrapper: {
            width: 160,
            height: 160,
            border: `1px solid ${colors.greyLiner}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 20px 0 42px',
            '& img': {
                width: 140,
                height: 140,
            },
            "@media (max-width:600px)": {
                width: 80,
                height: 80,
                margin: '0 13px 0 5px',
                '& img': {
                    width: 70,
                    height: 70,
                },
            },
        },
        productList: {
            listStyleType: 'none',
            '& li': {
                display: 'flex',
                alignItems: 'center',
                paddingBottom: 8,
                '& p': {
                    paddingLeft: 5,
                }
            }
        },
        lineThrough: {
            textDecoration: 'line-through',
            color: `rgba(0, 0, 0, .5)`,
        },
        uppercase: {
            textTransform: 'uppercase',
        },
        opacityColor: {
            color: 'rgba(0,0,0,.5)',
        },
        colorBlue: {
            color: colors.blue
        },
    })
)