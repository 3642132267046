import React, {useCallback, useContext, useEffect, useState} from "react";
import {productRequestsStyle} from "./product-reqests-style";
import ProductRequestTable from "./productRequestTable/productRequestTable";
import UnderHeader from "src/components/underHeader/underHeader";
import CSearchInput from "src/cors/searchInput/searchInput";
import {useLocation, useNavigate} from "react-router-dom";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import {GlobalContext} from "src/context/global-context";
import ScrollInfiniteWrapper from "src/components/scroll-wrapper/scroll-wrapper";

function ProductRequests() {
    const classes = productRequestsStyle()
    const t = useTranslation()
    const navigation = useNavigate()
    const location = useLocation()
    const {state, setState} = useContext(GlobalContext)
    const [data, setData] = useState([])
    const [offset, setOffset] = useState(0)
    const [total_count, setTotal_count] = useState(0)
    const [searchValue, setSearchValue] = useState('')
    const [scrolling, setScrolling] = useState(false)
    const [searched, setSearched] = useState(false)

    const request = useCallback(() => {
        setState({...state, loader: true})
        Axios(`${searched?'search': 'admin'}/seller_product_requests/all/${location.state.seller_id}?key=${searchValue}&limit=10&offset=${offset}`).then(res => {
            setData([...data, ...res.data.catalog])
            setTotal_count(res?.data?.total_count)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        }).finally(() => {
            setState({...state, loader: false})
            setScrolling(false)
        })
    }, [offset])

    const updateView = () => {
        setData([])
        setOffset(prev => prev === 0 ? '' : 0)
    }

    const search = () => {
        updateView()
        setSearched(true)
    }

    const handleClear = () => {
        setSearchValue('')
        updateView()
        setSearched(false)
    }

    const showMore = () => {
        setOffset(+offset + 10)
    }

    useEffect(() => {
        request()
    }, [offset])

    useEffect(() => {
        if (scrolling && total_count !== data?.length) {
            showMore()
        }
    }, [scrolling])

    return (
        <ScrollInfiniteWrapper
            scrollUpdate={setScrolling}
            context={<div className={classes.productRequestContainer}>
                <UnderHeader
                    back={true}
                    goBack={() => navigation('/product', {state: {seller_id: location.state.seller_id}})}
                    title={t("header.product_request")}
                    context={<div className={classes.underHeaderWrapp}>
                        <CSearchInput
                            onChange={setSearchValue}
                            handleSearch={search}
                            value={searchValue}
                            handleClear={handleClear}
                        />
                    </div>}
                />
                <div className={classes.productRequestsTableWrapper}>
                    <ProductRequestTable dataBody={data}/>
                </div>
            </div>}/>
    );
}

export default ProductRequests;