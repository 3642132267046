import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import {collectionStyle} from "./collection-style";
import CSearchInput from "src/cors/searchInput/searchInput";
import PropTypes from "prop-types";

export default function CollectionUnderHeader({openModal}) {
    const classes = collectionStyle()

    return (
        <div className={classes.collectionUnderHeader}>
            <div className={classes.buttonWrapp}>
                <CButton onPress={openModal} backgroundColor={colors.blue} title={"+ ADD NEW"}/>
            </div>
            <div className={classes.inputWrapp}>
                <CSearchInput/>
            </div>
        </div>
    );
}

CollectionUnderHeader.propTypes = {
    openModal: PropTypes.func
}