export const UPDATE_STATE = 'UPDATE_STATE';

export const updateState = (payload) => ({type: UPDATE_STATE, payload});

const GlobalContextReducer = (state, {type, payload}) => {
    switch (type) {
        case UPDATE_STATE: {
            return {...state, ...payload};
        }
        default:
            return state;
    }
};

export default GlobalContextReducer;
