import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const sellerBookingStyle = makeStyles(() =>
    createStyles({
        sellerToolsContainer: {
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto 30px',
            "@media (max-width:1250px)": {
                width: '96%',
            },
        },
        subTitle: {
            fontSize: 20,
            lineHeight: '33px',
            fontWeight: 400,
            marginTop: 15,
            "@media (max-width:600px)": {
                fontSize: 14,
                lineHeight: '17px',
            },
        },
        boxShadowContainer: {
            boxShadow: '1px 1px 16px #f3eaea',
            padding: '10px 0 30px',
            marginTop: 10
        },
        inputsRow: {
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${colors.greyLiner}`,
            margin: '15px 40px 15px 10px',
            position: 'relative',
            '& > div': {
                margin: '0 0.5%',
                width: '25%',
                "@media (max-width:600px)": {
                    width: '100%',
                },
            },
            "@media (max-width:600px)": {
                flexDirection: 'column',
            },
        },
        priceContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '20px 40px 0 0',
            '& > p': {
                fontSize: 16,
                lineHeight: '14px',
                fontFamily: 'mardotoBold',
                textTransform: 'uppercase',
            },
        },
        flexEnd: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: 40,
            '& >div': {
                width: '24%',
                "@media (max-width:600px)": {
                    width: '100%',
                },
            }
        },
        deleteIcon: {
            cursor: 'pointer',
            position: 'absolute',
            top: '41%',
            right: -27,
            "@media (max-width:600px)": {
                display: 'none'
            },
        },
        deleteButtonWrapper: {
            display: 'none',
            '& > div': {
                width: 300,
                marginBottom: 20
            },
            "@media (max-width:600px)": {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
        }
    })
)