import {createStyles, makeStyles} from "@mui/styles";


export const onlineShopRequestStyle = makeStyles(() =>
    createStyles({
        container: {
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto 30px',
            ["@media (max-width:1250px)"]: {
                width: '96%',
            },
        },
        selectWrap:{
            width: 160,
        },
    })
)