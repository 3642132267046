import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const collectionStyle = makeStyles(() =>
    createStyles({
        container: {
            maxWidth: 1200,
            width: '100%',
            margin: 'auto',
            "@media (max-width:1250px)": {
                width: '96%',
            },
        },
        collectionWrap: {
            display: 'flex',
            flexWrap: 'wrap',
            margin: '32px -10px 0 -10px',
            "@media (max-width:768px)": {
                margin: '32px -5px 0 -10px',
            },
            "@media (max-width:480px)": {
                marginTop: 17,
            },
        },
        collectionBox: {
            margin: '0 10px 36px 10px',
            width: 386,
            "@media (max-width:1250px)": {
                width: '31%',
            },
            "@media (max-width:900px)": {
                width: '30%',
            },
            "@media (max-width:768px)": {
                width: '30%',
                margin: '0 5px 36px 5px',
            },
            "@media (max-width:480px)": {
                width: '45%',
                margin: '0 7px 8px 7px',
            },
        },
        collectionBlock: {
            width: '100%',
            height: 455,
            backgroundColor: colors.grey,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: "center",
            "@media (max-width:900px)": {
                height: 380,
            },
            "@media (max-width:768px)": {
                height: 234,
            },
            "@media (max-width:480px)": {
                height: 169,
            },

        },
        collectionBottom: {
            padding: "17px 0 0 0",
            display: 'flex',
            alignItems: "center",
            justifyContent: "space-between",
            "@media (max-width:768px)": {
                padding: "15px 0 0 0 ",
            },
            "@media (max-width:480px)": {
                padding: "10px 0 0 0",
            },
        },
        collectionBottomText: {
            fontSize: 22,
            width: 150,
            color: colors.black,
            textTransform: 'uppercase',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            "@media (max-width:768px)": {
                width: 100,
            },
            "@media (max-width:480px)": {
                fontSize: 14,
                width: 80,
            },
        },

        collectionBottomRight: {
            display: 'flex',
        },
        collectionBlockBtn: {
            width: 40,
            height: 40,
            backgroundColor: colors.white,
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
            border: `1px solid ${colors.blue}`,
            cursor: 'pointer',
            '&:hover': {
                opacity: .5
            },
            "@media (max-width:480px)": {
                width: 25,
                height: 25,
            },
        },
        collectionReject: {
            border: `1px solid ${colors.red}`,
            marginLeft: 10,
            "@media (max-width:480px)": {
                marginLeft: 4,
            },
        },
        collectionUnderHeader: {
            display: 'flex',
            "@media (max-width:480px)": {
                flexDirection: 'column-reverse',
                width: '100%',
            },

        },
        buttonWrapp: {
            width: 208,
            marginRight: 20,
            "@media (max-width:768px)": {
                width: 137,
            },
            "@media (max-width:480px)": {
                width: '100%',
                marginBottom: 11,
            },
        },
        inputWrapp: {
            width: 211,
            "@media (max-width:768px)": {
                width: 137,
                marginBottom: 10,
            },
            "@media (max-width:480px)": {
                width: '100%',
            },
        },
        addImageTitle: {
            fontSize: 14,
            fontWeight: 'bold',
            margin: '32px 0 27px 0',
            "@media (max-width:480px)": {
                margin: '0px 0 9px 0',
            },
        },
        modalInputWrapp: {
            marginTop: 30,
            "@media (max-width:480px)": {
                marginTop: 15,
            },
        },
        modalBtnWrapp: {
            marginTop: 32,
            "@media (max-width:480px)": {
                marginTop: 15,
            },
        }
    })
)