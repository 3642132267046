import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const headerStyles = makeStyles(() =>
    createStyles({
        mainContent: {},
        headerTop: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '15px 0',
            maxWidth: 1440,
            width: '100%',
            margin: 'auto',
            ["@media (max-width:1450px)"]: {
                width: '96%',
                margin: 'auto'
            },
            ["@media (max-width:768px)"]: {
                borderBottom: `1px solid ${colors.greyLiner}`,
            },
        },
        flexContainer: {
            display: 'flex',
            alignItems: 'center',
            '& p': {
                fontSize: 14,
                paddingRight: 8,
                letterSpacing: '1px',
                textTransform: 'uppercase',
                color: colors.black,
                ["@media (max-width:450px)"]: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: 110,
                },
            }
        },
        headerNavBarContent: {
            maxWidth: 1440,
            width: '100%',
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            ["@media (max-width:1450px)"]: {
                width: '96%',
                margin: 'auto'
            },
        },
        headerNavBar: {
            borderBottom: `1px solid ${colors.greyLiner}`,
            borderTop: `1px solid ${colors.greyLiner}`,
            ["@media (max-width:768px)"]: {
                display: 'none'
            },
        },
        headerNavBarSeller: {
            '& > div > div:last-child': {
                display: 'flex',
            },
            '& a': {
                ["@media (max-width:1150px)"]: {
                    padding: '22px 5px!important',
                },
            },
            '& p': {
                ["@media (max-width:1150px)"]: {
                    fontSize: '8px!important',
                },
            }

        },
        navBarContainer: {
            display: 'flex',
            alignItems: 'center',
            '& > a': {
                display: 'flex',
                alignItems: 'center',
                padding: '22px 13px',
                textDecoration: 'none',
                borderBottom: '2px solid transparent',
                marginBottom: -1,
                position:"relative"
            },
            '& > a:hover': {
                borderBottom: `2px solid ${colors.blue}`,
                '&  svg': {
                    fill: colors.blue,
                },
                '& p': {
                    color: colors.blue
                },
            },

            '& > a.active': {
                borderBottom: `2px solid ${colors.blue}`,
                marginBottom: -1
            },
            '& p': {
                fontSize: 14,
                paddingLeft: 10,
                lineHeight: '14px',
                letterSpacing: '1px',
                color: colors.black,
                textTransform: 'uppercase',
            },
            '& a.active p': {
                color: colors.blue
            },
            '& a.active svg': {
                fill: colors.blue
            },
            '& a svg': {
                flex: '0 0 16px',
            },
        },
        logoutContainer: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '& p': {
                lineHeight: '14px',
                letterSpacing: '1px',
                color: colors.black,
                paddingLeft: 8
            },
            ["@media (max-width:1100px)"]: {
                display: 'none'
            },
        },
        moreBtn: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            '& ul': {
                display: 'block',
                position: 'absolute',
                backgroundColor: colors.white,
                listStyleType: 'none',
                width: 236,
                top: 55,
                boxShadow: colors.lightGrayShadow,
                zIndex: 100,
            },
            '& ul li': {
                padding: '20px 13px',
            },
            '& ul li > a': {
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                justifyContent: 'flex-start',
                cursor: 'unset'
            },
            '& ul li svg': {
                cursor: 'pointer'
            },
            '& ul li p': {
                paddingTop: 2,
                cursor: 'pointer'
            },
            ['& ul li p:hover']: {
                color: colors.blue,
            },
            '& > div': {
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                padding: '20px 13px',
            },
            '& > div:hover': {
                borderBottom: `2px solid ${colors.blue}`,
                marginBottom: -2
            },
        },
        userTypes: {
            paddingTop: 10,
            paddingLeft: 25,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& a': {
                lineHeight: '13px',
                color: colors.black,
                fontSize: 13,
                textTransform: 'uppercase',
                textDecoration: 'none',
                padding: '10px 0 15px 0'
            },
        },
        usersContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        usersRow: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
        },
        mobileItemNav: {
            display: 'flex',
            ["@media (max-width:1100px)"]: {
                display: 'none!important'
            },
        },
        mobileItem: {
            display: 'none',
            ["@media (max-width:1100px)"]: {
                display: 'block'
            },
        },
        mobileLogout: {
            display: 'none',
            ["@media (max-width:1100px)"]: {
                display: 'flex'
            },
        },
        logo: {
            ["@media (max-width:768px)"]: {
                display: 'flex',
                alignItems: 'center'
            },
            // '& > svg': {
            //     ["@media (max-width:768px)"]: {
            //         width: 96,
            //         height: 24,
            //     },
            // }
        },
        logoWrap: {
            '& > svg': {
                ["@media (max-width:768px)"]: {
                    // width: 96,
                    height: 40,
                },
            }
        },
        logoImg: {
            // width: 152,
            height: 60,
            objectFit: 'contain',
            ["@media (max-width:768px)"]: {
                // width: 96,
                height: 40,
                marginLeft: 60
            },
        },
        burgerIcon: {
            display: 'none',
            cursor: 'pointer',
            marginRight: 10,
            ["@media (max-width:768px)"]: {
                display: 'block',
            },
        },
        mobileHeader: {
            width: 250,
            height:550,
            listStyleType: 'none',
            backgroundColor: 'white',
            position: 'fixed',
            top: 70,
            transition: '.6s',
            zIndex: 1000,
            overflowY:"scroll",
            boxShadow: colors.lightGrayShadow,
            '& li': {
                padding: 10,
                display: 'flex',
                alignItems: 'center',
                position:"relative",
                width:"100%"
            },
            '& li > a': {
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
            },
            '& li a span': {
                paddingLeft: 9,
                fontSize: 14,
                lineHeight: '14px',
                fontWeight: 400,
                textTransform: 'uppercase',
                paddingTop: 3
            },
            '& li a.active': {
                '& span': {
                    color: colors.blue,
                },
                '& svg': {
                    fill: colors.blue,
                }
            },
        },
        userBtn: {
            display: 'block!important'
        },
        userContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: 8,
            '& span': {
                cursor: 'pointer',
                paddingLeft: 9,
            },
            '& svg': {
                cursor: 'pointer',
            }
        },
        mobileUserTypes: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            paddingLeft: 35,
            '& a': {
                textDecoration: 'none',
                fontSize: 13,
                lineHeight: '14px',
                fontWeight: 400,
                textTransform: 'uppercase',
                '&:not(:last-child)': {
                    paddingBottom: 15
                },
                '&:first-child': {
                    paddingTop: 5
                }
            }
        },
        indicator:{
            width:14,
            height:14,
            background:colors.red,
            borderRadius:7,
            position:'absolute',
            right:0,
            top:17,
            display:'flex',
            alignItems:'center',
            justifyContent:"center"
        },
        indicatorMobile:{
            width:14,
            height:14,
            background:colors.red,
            borderRadius:7,
            display:'flex',
            alignItems:'center',
            justifyContent:"center"
        },
        indicatorTitle:{
            fontSize:8,
            color:colors.white,
            marginLeft:2,
            marginTop:2
        },
    })
);
