import React, { useCallback, useContext, useEffect, useState } from 'react';
import GroupList from 'src/components/preOrder/GroupList'; // Adjust import path
import { Axios } from 'src/api/api'; // Adjust import path
import { toast } from 'react-toastify';
import { errorMessages } from 'src/helpers/error-messages'; // Adjust import path
import { GlobalContext } from 'src/context/global-context';
import CButton from "../../../cors/button/cButton";
import {useTranslation} from "react-multi-lang";
import {Link} from "react-router-dom";
import ConfirmationModal from "../../../components/confimationModal/confirmationModal"; // Adjust import path

const Groups = () => {
    const [groups, setGroups] = useState([]);
    const { state, setState } = useContext(GlobalContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const t = useTranslation()
    const handleDeleteClick = (id) => {
        setSelectedGroupId(id);
        setModalOpen(true);
    };

    const handleModalConfirm = () => {
        handleDeleteGroup(selectedGroupId);
        setModalOpen(false);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleDeleteGroup = (id) => {
        const updatedGroups = groups.filter(group => group.id !== id);
        Axios.delete(`preorder/group/${id}`).then(res => {
            request()
        }).catch(err => {
            console.error(err)
        })
    };

    const request = useCallback(() => {
        setState({ ...state, loader: true });
        Axios('preorder/groups').then(res => {
            setGroups(res.data.groups);
        }).catch(err => {
            console.error(err);
            toast(errorMessages(err?.response?.data?.code));
        }).finally(() => {
            setState({ ...state, loader: false });
        });
    }, [state, setState]);

    useEffect(() => {
        request();
    }, []);

    return (
        <div>
            <GroupList
                groups={groups}
                onDelete={handleDeleteClick}
                onEdit={(id) => {
                    // Handle Edit Logic, e.g., navigate to an edit page
                }}
            />
            {modalOpen && (
                <ConfirmationModal
                    isOpen={modalOpen}
                    onConfirm={handleModalConfirm}
                    onCancel={handleModalClose}
                    title="Confirm Cancelation"
                    message="Are you sure you want to cancel this order?"
                />
            )}
        </div>
    );
};

export default Groups;
