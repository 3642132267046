import React, {useCallback, useContext, useEffect, useState} from "react";
import {useSearchParams, useLocation, useNavigate} from 'react-router-dom';
import {sellerProductStyle} from "./seller-product-style";
import SellerProductTable from "./sellerProductTable/sellerProductTable";
import CSelect from "src/cors/select/cSelect";
import CButton from "src/cors/button/cButton";
import UnderHeader from "src/components/underHeader/underHeader";
import SellerProductUnderHeader from "./seller-product-underHeader";
import {colors} from "src/utils/color";
import {Axios} from "src/api/api";
import {CModal} from "src/cors/modal/cModal";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import {GlobalContext} from "src/context/global-context";
import SellerProductAddSetModal from "./seller-product-add-set-modal";
import Cropper from "react-easy-crop";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import getCroppedImg, {dataURLtoFile, resizeFile} from "src/components/cropImage/cropImage";
import ScrollInfiniteWrapper from "src/components/scroll-wrapper/scroll-wrapper";

function SellerProduct() {
    const classes = sellerProductStyle();
    const t = useTranslation()
    const {state, setState} = useContext(GlobalContext)
    const [data, setData] = useState([])
    const [status, setStatus] = useState('all')
    const [reset, setReset] = useState(true)
    const [deleteModal, setDeleteModal] = useState(false)
    const [productID, setProductId] = useState('')
    const [offset, setOffset] = useState('')
    const [total_count, setTotal_count] = useState(0)
    const [selectValue, setSelectValue] = useState()
    const [man_rings, setMan_rings] = useState([])
    const [woman_rings, setWoman_rings] = useState([])
    const [products, setProducts] = useState([])
    const [images, setImages] = useState([]);
    const [video, setVideo] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchValue, setSearchValue] = useState(searchParams.get('key') || '');
    const [productItem, setProductItem] = useState({});
    const [scrolling, setScrolling] = useState(false)
    const [weddingValues, setWeddingValues] = useState({
        man: "",
        woman: ""
    })

    const [setsValues, setSetsValues] = useState({
        ring: "",
        bracelet: "",
        necklace: "",
        earrings: "",
        code: ""
    })

    const [filteredData, setFilteredData] = useState({
        category: "",
        metal: '',
        type: '',
        forwhom: ""
    })


    // Cropper States

    const [crop, setCrop] = useState({x: 0, y: 0})
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [cropperModal, setCropperModal] = useState(false);
    const [showImage, setShowImage] = useState({})
    const edit = Object.keys(productItem).length === 0

    // Cropper functions Start

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, []);
    const cropImage = useCallback(async () => {
        setState({...state, loader: true})
        try {
            const croppedImage = await getCroppedImg(
                showImage?.data_url,
                croppedAreaPixels,
            )
            let myFile = {};
            myFile.file = await resizeFile(dataURLtoFile(croppedImage, showImage?.file?.name));
            myFile.data_url = croppedImage;

            if (!edit) {
                handleSendImage(`product/add_pictures/${productItem.id}`, myFile)
            } else {
                setImages([...images, myFile]);
            }

        } catch (e) {
            console.error(e)
        } finally {
            setState({...state, loader: false})
            setCropperModal(false);
            setZoom(1);
        }
    }, [croppedAreaPixels])

    const handleOpenCropperModal = (imgUrl) => {
        setCropperModal(true);
        setShowImage(imgUrl[0]);
    }

    const handleSendImage = (url, file) => {
        const formData = new FormData();
        formData.append("files", file.file);
        Axios.put(url, formData).then(res => {
            setImages([...res.data.pictures])
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code));
        }).finally(() => {
            setState({...state, loader: false})
            setCropperModal(false);
            setZoom(1);
        })
    }

    // Cropper functions End


    const handleChange = (e, keyName) => {
        if (keyName === 'category') {
            setReset(false)
            setFilteredData({
                category: e,
                metal: '',
                type: '',
                forwhom: ""
            })
        } else {
            let newValues = {...filteredData}
            newValues[keyName] = e
            setFilteredData({...newValues})
        }
    }

    const deleteProduct = (id) => {
        setState({...state, loader: true})
        Axios.delete(`product/delete/${id}`).then(res => {
            updateView()
            toast(t("notification.deleted"))
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        }).finally(() => {
            setState({...state, loader: false})
        })
        setDeleteModal(false)
    }

    const updateView = () => {
        setData([])
        setOffset(prev => prev === 0 ? '' : 0)
    }

    const request = () => {
        setState({...state, loader: true})
        Axios(`catalog/for_seller/${status}?key=${searchValue}&category=${filteredData.category}&type=${filteredData.type}&forwhom=${filteredData.forwhom}&metal=${filteredData.metal}&limit=10&offset=${offset}`)
            .then(res => {
                setData([...data, ...res?.data?.catalog])
                setTotal_count(res?.data?.total_count)
            }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        }).finally(() => {
            setState({...state, loader: false})
            setScrolling(false)
        })
    }

    const handleOnFilter = () => {
        updateView()
        // setState({...state, loader: true})
        // Axios(`catalog/for_seller/${status}?key=${searchValue}&category=${filteredData.category}&type=${filteredData.type}&forwhom=${filteredData.forwhom}&metal=${filteredData.metal}&limit=10&offset=${offset}`)
        //     .then(res => {
        //         setData([...res?.data?.catalog])
        //         setTotal_count(res?.data?.total_count)
        //         console.log(res, 'eeee')
        //     }).catch(err => {
        //     toast(errorMessages(err?.response?.data?.code))
        //     console.log(err, 'err')
        // }).finally(() => {
        //     setState({...state, loader: false})
        //     setOffset(0)
        //     setSearchValue('')
        // })
    }

    const handleClear = () => {
        setSearchValue('')
        setSearchParams({})
        updateView()
    }

    const search = () => {
        if (searchValue) {
            setSearchParams({ key: searchValue })
            updateView()
            //     setState({...state, loader: true})
            //     Axios(`catalog/for_seller/${status}?key=${searchValue}&limit=10&offset=0`).then(res => {
            //         setData(res.data.catalog)
            //         setTotal_count(res?.data?.total_count)
            //         setFilteredData({
            //             category: "",
            //             metal: '',
            //             type: '',
            //             forwhom: ""
            //         })
            //         setReset(false)
            //     }).catch(err => {
            //         console.log(err)
            //     }).finally(() => {
            //         setState({...state, loader: false})
            //         setOffset(0)
            //     })
            // } else if (searchValue === '') {
            //     request()
        }
    }
    const showMore = () => {
        setOffset(+offset + 10)
    }

    // const showMore = () => {
    //     request()
    //     // setState({...state, loader: true})
    //     // Axios(`catalog/for_seller/${status}?category=${filteredData.category}&key=${searchValue}&type=${filteredData.type}&forwhom=${filteredData.forwhom}&metal=${filteredData.metal}&limit=10&offset=${+offset + 10}`).then(res => {
    //     //     setData([...data, ...res?.data?.catalog])
    //     //     setOffset((prev) => prev + 10)
    //     // }).catch(err => {
    //     //     toast(errorMessages(err?.response?.data?.code))
    //     // }).finally(() => {
    //     //     setState({...state, loader: false})
    //     // })
    // }

    const onWeddingValues = (e, keyName) => {
        let newValues = {...weddingValues}
        newValues[keyName] = e
        setWeddingValues({...newValues})
    }

    const onSetValues = (e, keyName) => {
        let newValues = {...setsValues}
        newValues[keyName] = e
        setSetsValues({...newValues})
    }

    const handleChangeStatus = (e) => {
        setStatus(e.value)
        updateView()
    }

    const addWeddingSet = () => {
        if (weddingValues.man && weddingValues.woman) {
            let formData = new FormData()
            formData.append('type', 'wedding')
            formData.append('sub_id_arr', weddingValues.man)
            formData.append('sub_id_arr', weddingValues.woman)
            setState({...state, loader: true})
            Axios.post(`product/add_set`, formData).then(res => {
                closeModal()
                toast(t('project.save'))
            }).catch(err => {
                console.log(err, 'err')
                toast(errorMessages(err?.response?.data?.code))
                closeModal()
            }).finally(() => {
                setState({...state, loader: false})
            })
        }
    }

    const addSet = () => {
        let formData = new FormData()
        formData.append('type', 'set')
        formData.append('product_code', setsValues.code)
        formData.append('sub_id_arr', setsValues.ring)
        formData.append('sub_id_arr', setsValues.bracelet)
        formData.append('sub_id_arr', setsValues.earrings)
        formData.append('sub_id_arr', setsValues.necklace)
        images.forEach((item, index) => {
            formData.append("files", images[index]?.file);
        })
        if (video) {
            formData.append("files", video);
        }
        setState({...state, loader: true})
        Axios.post(`product/add_set`, formData).then(res => {
            updateView()
            closeModal()
            toast(t('project.save'))
        }).catch(err => {
            console.log(err, 'err')
            toast(errorMessages(err?.response?.data?.code))
        }).finally(() => {
            setState({...state, loader: false})
        })
    }

    const closeModal = () => {
        setSelectValue('')
        setWeddingValues({
            man: "",
            woman: ""
        })
        setSetsValues({
            ring: "",
            bracelet: "",
            necklace: "",
            earrings: "",
            code: ""
        })
        setVideo('')
        setImages([])
    }

    const deleteImg = (item) => {
        if (Object.keys(productItem).length !== 0) {
            Axios.delete(`product/delete_pictures/${productItem.id}/${item}`).then(res => {
                setImages(res.data.pictures)
                toast(t("notification.deleted"))
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
                console.log(err, 'err')
            })
        } else {
            let arr = []
            for (let i = 0; i < images.length; i++) {
                if (item === images[i].data_url) {
                    continue
                } else {
                    arr.push(images[i])
                }
            }
            setImages(arr)
        }
    }


    const handleEditSet = (tdItem) => {
        Axios(`product/${tdItem?.id}`).then(res => {
            res?.data?.pictures?.forEach((item) => {
                if (item.is_video) {
                    setVideo(item)
                }
            })
            setSelectValue(tdItem.type)
            setImages(res?.data?.pictures)
            setProductItem({...tdItem, ...res.data})
        })

    }

    useEffect(() => {
        if (scrolling && total_count !== data?.length) {
            showMore()
        }
    }, [scrolling])

    useEffect(() => {
        if (selectValue === 'wedding') {
            Axios(`catalog/${selectValue}`).then(res => {
                if (res.data?.man_rings?.length > 0) {
                    let man = []
                    res.data.man_rings.forEach((item) => {
                        man.push({label: item.code, value: item.id})
                    })
                    setMan_rings(man)
                }
                if (res.data?.woman_rings?.length > 0) {
                    let woman = []
                    res.data.woman_rings.forEach((item) => {
                        woman.push({label: item.code, value: item.id})
                    })
                    setWoman_rings(woman)
                }
            })
        }
        if (selectValue === 'set') {
            Axios(`catalog/${selectValue}`).then(res => {
                if (res.data?.products?.length > 0) {
                    let productsData = []
                    res.data.products.forEach((item) => {
                        productsData.push({label: item.code, value: item.id})
                    })
                    setProducts(productsData)
                }
            })
        }
    }, [selectValue])

    useEffect(() => {
        if (reset === false) {
            setReset(true)
        }
        if (filteredData.category === 'jewelery' && filteredData.metal === '' && filteredData.type === '') {
        }
    }, [filteredData])

    useEffect(() => {
        request()
    }, [offset, status])


    return (
        <ScrollInfiniteWrapper
            scrollUpdate={setScrolling}
            context={
                <div className={classes.sellerProductContainer}>
                    <UnderHeader
                        title={t("header.product")}
                        context={
                            <SellerProductUnderHeader
                                setProductItem={setProductItem}
                                search={search}
                                setSearchValue={setSearchValue}
                                setSelectValue={setSelectValue}
                                handleClear={handleClear}
                                searchValue={searchValue}
                                sort={handleChangeStatus}
                            />
                        }
                    />
                    <div className={classes.selectWrapper}>
                        <CSelect
                            showErrorMessage={false}
                            options={
                                [
                                    {label: t("category.jewelry"), value: "jewelery"},
                                    {label: t("category.watches"), value: "watches"},
                                    {label: t("category.souvenir"), value: "souvenir"}
                                ]}
                            onChange={(e) => handleChange(e.value, 'category')}
                            placeholder={t("category.category")}/>

                        {reset && <>
                            {filteredData.category === 'jewelery' &&
                                <CSelect
                                    showErrorMessage={false}
                                    options={
                                        filteredData.category === 'watches' ? [
                                            {label: t("for_whom.woman"), value: "woman"},
                                            {label: t("for_whom.man"), value: "man"},
                                        ] : [
                                            {label: t("for_whom.woman"), value: "woman"},
                                            {label: t("for_whom.man"), value: "man"},
                                            {label: t("for_whom.boy"), value: "boy"},
                                            {label: t("for_whom.girl"), value: "girl"}
                                        ]
                                    }
                                    onChange={(e) => handleChange(e.value, 'forwhom')}
                                    placeholder={t("for_whom.for_whom")}
                                />}
                            <CSelect
                                showErrorMessage={false}
                                options={
                                    filteredData.category === 'watches' || filteredData.category === 'souvenir' ? [
                                        {label: t("metals.gold"), value: "gold"},
                                        {label: t("metals.silver"), value: "silver"},
                                        {label: t("metals.other"), value: 'other'},
                                    ] : [{label: t("metals.gold"), value: "gold"},
                                        {label: t("metals.silver"), value: "silver"},]
                                }
                                onChange={(e) => handleChange(e.value, 'metal')}
                                placeholder={t("metals.metals")}
                            />
                            {filteredData.category === 'jewelery' &&
                                <CSelect
                                    placeholder={t("type.type")}
                                    showErrorMessage={false}
                                    options={
                                        filteredData.metal === 'silver' ?
                                            filteredData.forwhom === 'man' ? [
                                                {label: t("type.ring"), value: "ring"},
                                                {label: t("type.cross"), value: "cross"},
                                                {label: t("type.pendant"), value: "pendant"},
                                                {label: t("type.chain"), value: "chain"},
                                                {label: t("type.chain_bracelet"), value: "chain_bracelet"},
                                                {label: t("type.hard_bracelet"), value: "hard_bracelet"},
                                                {label: t("type.other"), value: "other"},
                                            ] : filteredData.forwhom === 'girl' ? [
                                                {label: t("type.ring"), value: "ring"},
                                                {label: t("type.earring"), value: "earring"},
                                                {label: t("type.pendant"), value: "pendant"},
                                                {label: t("type.chain_bracelet"), value: "chain_bracelet"},
                                                {label: t("type.hard_bracelet"), value: "hard_bracelet"},
                                                {label: t("type.other"), value: "other"},
                                            ] : filteredData.forwhom === 'boy' ?
                                                [
                                                    {label: t("type.ring"), value: "ring"},
                                                    {label: t("type.pendant"), value: "pendant"},
                                                    {label: t("type.chain_bracelet"), value: "chain_bracelet"},
                                                    {label: t("type.hard_bracelet"), value: "hard_bracelet"},
                                                    {label: t("type.other"), value: "other"},
                                                ] : [
                                                    {label: t("type.ring"), value: "ring"}, // all
                                                    {label: t("type.necklace"), value: "necklace"}, // woman
                                                    {label: t("type.cross"), value: "cross"}, // woman man
                                                    {label: t("type.chain"), value: "chain"},
                                                    {label: t("type.set"), value: "set"}, // woman
                                                    {label: t("type.earring"), value: "earring"}, // woman , girl
                                                    {label: t("type.pendant"), value: "pendant"}, // all
                                                    {label: t("type.hard_bracelet"), value: "hard_bracelet"}, // woman
                                                    {label: t("type.chain_bracelet"), value: "chain_bracelet"}, // man woman
                                                    {label: t("type.other"), value: "other"}, //woman
                                                ] : filteredData.forwhom === 'man' ? [
                                                {label: t("type.ring"), value: "ring"},
                                                {label: t("type.wed_ring"), value: "wed_ring"},
                                                {label: t("type.cross"), value: "cross"},
                                                {label: t("type.pendant"), value: "pendant"},
                                                {label: t("type.chain"), value: "chain"},
                                                {label: t("type.chain_bracelet"), value: "chain_bracelet"},
                                                {label: t("type.hard_bracelet"), value: "hard_bracelet"},
                                                {label: t("type.other"), value: "other"},
                                            ] : filteredData.forwhom === 'girl' ? [
                                                {label: t("type.ring"), value: "ring"},
                                                {label: t("type.earring"), value: "earring"},
                                                {label: t("type.pendant"), value: "pendant"},
                                                {label: t("type.chain_bracelet"), value: "chain_bracelet"},
                                                {label: t("type.hard_bracelet"), value: "hard_bracelet"},
                                                {label: t("type.other"), value: "other"},
                                            ] : filteredData.forwhom === 'boy' ?
                                                [
                                                    {label: t("type.ring"), value: "ring"},
                                                    {label: t("type.pendant"), value: "pendant"},
                                                    {label: t("type.chain_bracelet"), value: "chain_bracelet"},
                                                    {label: t("type.hard_bracelet"), value: "hard_bracelet"},
                                                    {label: t("type.other"), value: "other"},
                                                ] : [
                                                    {label: t("type.ring"), value: "ring"}, // all
                                                    {label: t("type.wed_ring"), value: "wed_ring"}, // woman , man
                                                    {label: t("type.eng_ring"), value: "eng_ring"}, // woman
                                                    {label: t("type.necklace"), value: "necklace"}, // woman
                                                    {label: t("type.cross"), value: "cross"}, // woman man
                                                    {label: t("type.chain"), value: "chain"},
                                                    {label: t("type.set"), value: "set"}, // woman
                                                    {label: t("type.earring"), value: "earring"}, // woman , girl
                                                    {label: t("type.pendant"), value: "pendant"}, // all
                                                    {label: t("type.hard_bracelet"), value: "hard_bracelet"}, // woman
                                                    {label: t("type.chain_bracelet"), value: "chain_bracelet"},// woman
                                                    {label: t("type.other"), value: "other"}, //woman
                                                ]
                                    }
                                    onChange={(e) => handleChange(e.value, 'type')}
                                />}
                        </>}
                        <CButton
                            onPress={handleOnFilter}
                            backgroundColor={colors.blue}
                            title={t("seller_product.filter")}
                        />
                    </div>


                    <p className={classes.total_count}>{`${t('seller_product.total_count')}: ${total_count ? total_count : 0}`}</p>
                    <div className={classes.tableWrapper}>
                        <SellerProductTable
                            setProductId={setProductId}
                            setDeleteModal={setDeleteModal}
                            dataBody={data}
                            setSelectValue={setSelectValue}
                            setImages={setImages}
                            setProductItem={setProductItem}
                            setVideo={setVideo}
                            handleEditSet={handleEditSet}
                        />
                    </div>
                    {/*{(total_count !== data?.length) && <div className={classes.seeMoreBtnWrapper}>*/}
                    {/*    <CButton*/}
                    {/*        onPress={showMore}*/}
                    {/*        title={t("project.show_more")}*/}
                    {/*        borderWidth={1}*/}
                    {/*    />*/}
                    {/*</div>}*/}
                    <CModal
                        title={t("seller_product.delete_product")}
                        visible={deleteModal}
                        closeModal={() => setDeleteModal(false)}
                        children={<div className={classes.deleteModalBtns}>
                            <div style={{width: '48%'}}>
                                <CButton
                                    borderWidth={1}
                                    title={t("project.cancel")}
                                    onPress={() => setDeleteModal(false)}
                                />
                            </div>
                            <div style={{width: '48%'}}>
                                <CButton
                                    backgroundColor={colors.blue}
                                    title={t("project.delete")}
                                    onPress={() => deleteProduct(productID)}
                                />
                            </div>
                        </div>}
                    />
                    <SellerProductAddSetModal
                        closeModal={closeModal}
                        addSet={addSet}
                        selectValue={selectValue}
                        man_rings={man_rings}
                        woman_rings={woman_rings}
                        onWeddingValues={onWeddingValues}
                        addWeddingSet={addWeddingSet}
                        products={products}
                        onSetValues={onSetValues}
                        deleteImg={deleteImg}
                        images={images}
                        setImages={setImages}
                        handleOpenCropperModal={handleOpenCropperModal}
                        video={video}
                        productItem={productItem}
                        setVideo={setVideo}
                    />
                    {
                        cropperModal && <CModal
                            center={false}
                            title={t("seller_product.resize_image")}
                            closeModal={() => setCropperModal(false)}
                            visible={cropperModal}
                            children={<div>
                                <div className={classes.cropperWrapper}>
                                    <Cropper
                                        image={showImage?.data_url}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={1}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                    />
                                </div>
                                <div className={classes.sliderContainer}>
                                    <Typography
                                        variant="overline"
                                        classes={{root: classes.sliderLabel}}
                                    >
                                        Zoom
                                    </Typography>
                                    <Slider
                                        value={zoom}
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        aria-labelledby="Zoom"
                                        onChange={(e, zoom) => setZoom(zoom)}
                                    />
                                </div>
                                <div className={classes.cropperButtonWrapper}>
                                    <CButton
                                        onPress={cropImage}
                                        title={t("project.save")}
                                        borderWidth={1}
                                    />
                                </div>
                            </div>
                            }
                        />
                    }
                </div>
            }/>
    );
}

export default SellerProduct;
