import {sellerCustomPriceStyle} from "../seller-custom-price-style";
import React, {useContext, useEffect, useState} from "react";
import {cCheckboxStyle} from "src/cors/checkbox/cCheckbox-style";
import {ReactComponent as IconCheck} from "src/assets/icons/iconCheck.svg";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import CSwitch from "src/cors/switch/cSwitch";
import {Axios} from "src/api/api";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {GlobalContext} from "src/context/global-context";
import {useTranslation} from "react-multi-lang";

const UpdateRateModal = ({closeModal, type, edit, id, requestGetAll}) => {
    const {state, setState} = useContext(GlobalContext)
    const t = useTranslation()
    const classes = sellerCustomPriceStyle();
    const checkboxStyles = cCheckboxStyle();
    const [labels, setLabels] = useState(
        [
            {
                label: t('categories.metal_price'),
                id: 1,
                isChecked: true,
                isDisable: true
            },
            {
                label: t('categories.diamond_price'),
                id: 2,
                isChecked: false,
                isDisable: false
            },
        ]
    );
    const [checked, setChecked] = useState(true)
    const [update, setUpdate] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [data, setData] = useState({
        diamond_price_coefficient: '',
        metal_price_coefficient: '',
    })

    const saveUpdate = () => {
        if (data.metal_price_coefficient || data.diamond_price_coefficient) {
            const url = edit ? `category/settings/${id}` : `categories/${type}/all/settings`
            setState({...state, loader: true})
            Axios.put(url, {
                "diamond_price_coefficient": labels[1].isChecked ? +data.diamond_price_coefficient : 0,
                "metal_price_coefficient": +data.metal_price_coefficient,
                "metal_price_update_type": (checked || type === "other_with_diamond" || type === "other") ? 2 : 1,
                "update": update
            }).then(() => {
                toast(t("project.saved"))
                requestGetAll()
                closeModal()
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
            }).finally(() => {
                setState({...state, loader: false})
            })
        }

    }

    const changeValue = (e, keyName) => {
        let newValues = {...data}
        newValues[keyName] = e
        setData({...newValues})
    }

    const changeActive = (index) => {
        const arr = [...labels];
        arr[index].isChecked = arr[index].isChecked !== true;
        setLabels(arr)
    }

    const getUpdateData = (res) => {
        if (res.data.metal_price_update_type === 1 || type === "other_with_diamond" || type === "other") {
            setChecked(false)
        } else {
            setChecked(true)
        }
        let arr = [...labels];
        if (res.data.diamond_price_coefficient) {
            arr[1].isChecked = true;
            setLabels(arr)
        } else {
            arr[1].isChecked = false;
            setLabels(arr)
        }
        setData(res.data)
    }

    useEffect(() => {
        if (edit) {
            Axios(`category/settings/${id}`).then(res => {
                getUpdateData(res)
            })
        } else {
            Axios(`categories/${type}/all/settings`).then(res => {
                getUpdateData(res)

            })
        }

    }, [])

    return (
        <div className={classes.sellerUpdateRateModal}>
            {confirm ? <>
                <p>{update? t('categories.are_you_sure_you_want_to_keep_and_update'): t('categories.are_you_sure_you_want_to_keep')}</p>
                <div className={classes.saveBtn}>
                    <CButton
                        title={t('seller_product.yes')}
                        backgroundColor={colors.blue}
                        onPress={() => saveUpdate()}
                    />
                </div>
                <CButton
                    title={t('project.cancel')}
                    borderWidth={1}
                    onPress={() => setConfirm(false)}
                />
            </> :    <>  <p>{t('categories.update_info')}</p>
                {(type === 'gold' || type === 'other_with_diamond') &&
                    <div className={classes.checkboxContainer}>
                        {
                            labels.map((label, index) => {
                                return (
                                    <div className={`${classes.checkBoxWrap}`} key={index}>
                                        <div
                                            className={`${checkboxStyles.container} ${label.isChecked && checkboxStyles.active} ${label.isDisable && checkboxStyles.disable}`}
                                            onClick={() => {
                                                if (label.isDisable) {
                                                    return
                                                }
                                                changeActive(index)
                                            }}>
                                            <IconCheck width={10} height={8}/>
                                        </div>
                                        <p>{label.label}</p>
                                    </div>
                                )
                            })
                        }
                    </div>}
                {
                    labels[0].isChecked ? (
                        <div className={classes.priceRow}>
                            <p> {t(`metals.${type}`)} {t(`table.price`)}:</p>
                            <div className='inputWithText updateRateModal'>
                                <input
                                    onChange={(e) => changeValue(e.target.value, 'metal_price_coefficient')}
                                    value={!checked ? data.metal_price_coefficient : ''}
                                    type="number"
                                    disabled={checked}
                                />
                                <p>  {(type === 'gold' || type === 'silver') ? '$' : '%'}</p>
                            </div>
                            {(type === 'gold' || type === 'silver') && <>
                                <div className={classes.switcherWrapper}>
                                    <CSwitch
                                        width={25}
                                        height={15}
                                        onChange={() => {
                                            setData({...data, metal_price_coefficient: ''})
                                            setChecked(!checked)
                                        }
                                        }
                                        checked={checked}
                                    />
                                </div>
                                <div className='inputWithText updateRateModal'>
                                    <input
                                        onChange={(e) => changeValue(e.target.value, 'metal_price_coefficient')}
                                        value={checked ? data.metal_price_coefficient : ''}
                                        type="number"
                                        disabled={!checked}
                                    />
                                    <p>%</p>
                                </div>
                            </>}
                        </div>
                    ) : null
                }

                {
                    labels[1].isChecked ? (
                        <div className={classes.priceRow}>
                            <p>{t('categories.diamond_price')}:</p>
                            <div className='inputWithText updateRateModal'>
                                <input
                                    onChange={(e) => changeValue(e.target.value, 'diamond_price_coefficient')}
                                    value={data.diamond_price_coefficient ? data.diamond_price_coefficient : ''}
                                    type="number"
                                />
                                <p>%</p>
                            </div>
                        </div>
                    ) : null
                }
                <div className={classes.saveBtn}>
                    <CButton
                        title={t('project.update_and_save')}
                        backgroundColor={colors.blue}
                        onPress={() => {
                            setUpdate(true)
                            setConfirm(true)
                        }}
                    />
                </div>
                <CButton
                    title={t('project.save')}
                    borderWidth={1}
                    onPress={() => {
                        setUpdate(false)
                        setConfirm(true)
                    }}
                />
            </>}
        </div>
    )
}

UpdateRateModal.propTypes = {
    type: PropTypes.any,
    closeModal: PropTypes.func,
    requestGetAll: PropTypes.func,
    edit: PropTypes.bool,
    id: PropTypes.any
}


export default UpdateRateModal