import UnderHeader from "src/components/underHeader/underHeader";
import {useTranslation} from "react-multi-lang";
import {groupStyle} from "./fixing-style";
import {useCallback, useContext, useEffect, useState} from "react";
import {Axios} from "src/api/api";
import {GlobalContext} from "src/context/global-context";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import FixingTable from "./fixing-table";
import FixingUsers from "./fixing-users-table";
import FixingGroups from "./fixing-groups";
import ScrollInfiniteWrapper from "src/components/scroll-wrapper/scroll-wrapper";
import CSwitch from "src/cors/switch/cSwitch";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import {CModal} from "src/cors/modal/cModal";
import CInput from "src/cors/input/cInput";


function Booking() {
    const classes = groupStyle()
    const t = useTranslation()
    const [prices, setPrices] = useState([])
    const [users, setUsers] = useState([])
    const [orders, setOrders] = useState([])
    const [offset, setOffset] = useState('0')
    const [active, setActive] = useState(null)
    // const [scrolling, setScrolling] = useState(false)
    const [checked, setChecked] = useState(false)

    // const updateView = () => {
    //     setOffset(prev => prev === 0 ? '' : 0)
    // }

    /* Orders */
    const getOrders = () => {
        Axios.get(`fixing/orders`).then(res => {
            setOrders(res.data.orders)
        })
    }
    
    const addOrder = () => {
        Axios.delete(`fixing/orders/prefixate`).then(res => {
            // setPrices(res.data.orders)
        })
    }

    const deleteOrder = (group_id) => {
        Axios.delete(`fixing/orders/${group_id}`).then(res => {
            // setPrices(res.data.orders)
        })
    }

    const editOrderConfirm = (group_id) => {
        Axios.put(`fixing/orders/${group_id}/confirm`).then(res => {
            // setPrices(res.data.orders)
        })
    }

    const editOrderStatus = (group_id) => {
        Axios.put(`fixing/orders/${group_id}/status`).then(res => {
            // setPrices(res.data.orders)
        })
    }

    const getPrices = () => {
        Axios.get(`fixing/prices`).then(res => {
            setPrices(res.data.prices)
        })
    }

    const getMyOrders = () => {
        Axios.get(`fixing/myorders`).then(res => {
            setPrices(res.data.orders)
        })
    }

    /* Group */
    const getGroupUsers = (group_id) => {
        Axios.get(`fixing/groups/${group_id}/users`).then(res => {
            setActive(group_id)
            setUsers(res.data.users)
        })
    }

    useEffect(() => {
        getOrders()
        getPrices()
    }, [offset])

    return (
        // <ScrollInfiniteWrapper
        //     scrollUpdate={setScrolling}
        //     context={
                <div className={classes.container}>
                    <UnderHeader
                        title={t("header.fixing")}
                        context={<div className={classes.flex} style={{ marginLeft: 'auto' }}>
                            <CInput
                                type='number'
                                placeholder={'Available now'}
                                showErrorMessage={false}
                            />
                            <div style={{ marginLeft: '20px', marginRight: '20px', width: '100px' }}>
                                <CButton
                                    title={'Update'}
                                    backgroundColor={colors.blue}
                                />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CSwitch
                                    width={25}
                                    height={15}
                                    checked={checked}
                                />
                                <span style={{ paddingLeft: '10px' }}>Gsheet</span>
                            </div>
                        </div>}
                    />
                    <FixingGroups getGroupUsers={getGroupUsers} active={active} />
                    {/* <FixingUsers dataBody={users} active={active} /> */}
                    <FixingTable dataBody={orders} />
                </div>
        //     }
        // />
    );
}

export default Booking;