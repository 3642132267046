import React from "react";
import propTypes from "prop-types";
import CButton from "src/cors/button/cButton";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {useTranslation} from "react-multi-lang";

const BuyerTable = ({dataBody}) => {
    const t = useTranslation()
    let navigation = useNavigate();
    return (
        <>
            {
                (dataBody && dataBody.length) ?
                    <>
                        <div className='tableWrapper'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>{t("table.username")}</th>
                                    <th>{t("table.phone_number")}</th>
                                    <th>{t("table.country")}</th>
                                    <th>{t("table.email")}</th>
                                    <th>{t("table.code")}</th>
                                    <th>{t("table.last_visit")}</th>
                                    <th>{t("table.action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dataBody.map((tdItem, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td>{`${tdItem.firstname} ${tdItem.lastname}`}</td>
                                                <td>{tdItem.phone_number}</td>
                                                <td>{t(`setting.${tdItem.country}`)}</td>
                                                <td>{tdItem.email}</td>
                                                <td>{tdItem.ucode}</td>
                                                <td>{moment(tdItem.last_visit).format('YYYY MM DD')}</td>
                                                <td>
                                                    <div className='tableButtonWrapper'>
                                                        <CButton
                                                            title={t("project.view_details")}
                                                            borderWidth={1}
                                                            onPress={() => navigation("/buyer/buyerDetail",{state : tdItem})}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            dataBody.map((item, index) => {
                                return (
                                    <div key={index.toString()} className='cardContainer'>
                                        <ul className='cardContent'>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.username")}</p>
                                                <p className='cardValue'>{`${item.firstname} ${item.lastname}`}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.phone_number")}</p>
                                                <p className='cardValue'>{item.phone_number}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.country")}</p>
                                                <p className='cardValue'>{t(`setting.${item.country}`)}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.email")}</p>
                                                <p className='cardValue'>{item.email}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.last_visit")}</p>
                                                <p className='cardValue'>{moment(item.last_visit).format('YYYY MM DD')}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <div className='cardButtonWrapper'>
                                                    <CButton
                                                        onPress={() => navigation("/buyer/buyerDetail",{state : item})}
                                                        title={t("project.view_details")}
                                                        borderWidth={1}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </> : null
            }
        </>
    )
}

BuyerTable.propTypes = {
    dataBody: propTypes.array
};

export default BuyerTable