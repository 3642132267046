import React from "react";
import {messagesStyle} from "./messages-style";

function MessageItem() {
    const classes = messagesStyle();

    return (
        <div className={classes.messageItem}>
            <p className={classes.messageText}> tincidunt nec.</p>
        </div>
    );
}

export default MessageItem;