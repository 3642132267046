import React from "react";
import Switch from "react-switch";
import {colors} from "src/utils/color";

interface CSwitchProps {
    onChange: any
    checked: boolean
    disabled: boolean
    height: number
    width: number
}

export default function CSwitch({
                                    onChange,
                                    checked,
                                    disabled,
                                    height = 28,
                                    width = 56
                                }: CSwitchProps) {
    return (
        <label>
            <Switch
                height={height}
                width={width}
                offColor={colors.blue}
                onColor={colors.gold}
                disabled={disabled}
                onChange={() => onChange()}
                uncheckedIcon={false}
                checkedIcon={false}
                checked={checked}
            />
        </label>
    );
}