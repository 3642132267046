import React, {useEffect, useState} from "react";
import {sellerOrderItemStyle} from "./seller-orders-item-style";
import UnderHeader from "src/components/underHeader/underHeader";
import CardInfo from "src/components/cardInfo/cardInfo";
import ProductItem from "src/components/productItem/productItem";
import {colors} from "src/utils/color";
import CButton from "src/cors/button/cButton";
import removeIcon from "src/assets/icons/iconRemove.svg";
import SmallBtn from "src/cors/smallBtn/smallBtn";
import {useLocation, useNavigate} from "react-router-dom";
import {Axios} from "src/api/api";
import moment from "moment";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";

function SellerOrderItem() {
    const t = useTranslation()
    const classes = sellerOrderItemStyle();
    const navigation = useNavigate();
    const {state} = useLocation();
    const [showInput, setShowInput] = useState(false);
    const [trackingNumber, setTrackingNumber] = useState('');
    const [orderDetails, setOrderDetails] = useState({})

    const showInputHandler = () => {
        setShowInput(true)
    }

    const setTrackingNumberHandler = () => {
        if (trackingNumber.length) {
            Axios.put(`order/update/${state.id}`, {"tracking_number": trackingNumber || orderDetails?.tracking_details?.tracking_number}).then(res => {
                setTrackingNumber(res.data.tracking_number)
                setShowInput(false);
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
                console.log(err, 'err');
            })
        }
    }

    const trackingNumberRemoveHandler = () => {
        Axios.put(`order/delete/${state.id}`).then(res => {
            setTrackingNumber(res.data.tracking_number)
            setShowInput(false);
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err');
        })

    }

    const trackingNumberEditHandler = () => {
        setShowInput(true);
    }

    useEffect(() => {
            if (state?.id) {
                Axios(`order/${state.id}`).then(res => {
                    if (res?.data?.tracking_details?.tracking_number) {
                        setTrackingNumber(res.data.tracking_details.tracking_number)
                    }
                    setOrderDetails(res.data);
                }).catch(err => {
                    toast(errorMessages(err?.response?.data?.code))
                    console.log(err, 'err');
                })
            } else {
                navigation('/sellerOrder')
            }
        },
        [state?.id])

    return (
        <div className={classes.sellerOrderContainer}>
            <UnderHeader goBack={() => navigation('/sellerOrder')} title={orderDetails?.order_number} back={true}/>
            <h1 className={classes.title}>{t("order.order_info")}</h1>
            <div className={classes.flexBetweenContainer}>
                <CardInfo title={t("order.order_info")} info={moment(orderDetails?.time_placed).format('lll')}/>
                <CardInfo title={t("order.time_placed")} info={orderDetails?.order_number}/>
                <CardInfo title={t("order.total")}
                          info={orderDetails?.total_price && orderDetails?.total_item ? `AMD ${orderDetails?.total_price} (${orderDetails?.total_item} ITEM)` : 'EMPTY'}/>
                <CardInfo title={t("order.sold_by")} info={orderDetails?.sold_by}/>
            </div>
            <h1 className={classes.title}>{t('order.tracking_details')}</h1>
            <CardInfo title={t("order.shipping_service")} info={orderDetails?.tracking_details?.sipping_service}/>
            <div className={classes.trackingNumberContainer}>
                <p>{t("order.tracking_number")}</p>
                {
                    showInput ?
                        <div className={classes.inputWrapper}>
                            <input value={trackingNumber}
                                   onChange={(e) => setTrackingNumber(e.target.value)}
                                   type="text"
                                   placeholder={t("order.tracking_number")}
                            />
                            <CButton onPress={setTrackingNumberHandler}
                                     title={t("project.add")}
                                     backgroundColor={colors.blue}
                            />
                        </div>
                        : trackingNumber ?
                            <div className={classes.trackingNumberContent}>
                                <p>{trackingNumber || orderDetails?.tracking_details?.tracking_number}</p>
                                <div className={classes.editButtonWrapper}>
                                    <CButton
                                        onPress={trackingNumberEditHandler}
                                        title={t("project.edit")}
                                        borderWidth={1}
                                    />
                                </div>
                                <SmallBtn onPress={trackingNumberRemoveHandler} icon={removeIcon}
                                          borderColor={colors.softRed}/>
                            </div>
                            :
                            <div className={classes.buttonWrapper}>
                                <CButton onPress={showInputHandler}
                                         title={t("order.add_tracking_number")}
                                         backgroundColor={colors.blue}
                                />
                            </div>
                }
            </div>
            <h1 className={classes.title}>{t("order.shipping_address")}</h1>
            <div className={classes.flexBetweenContainer}>
                <CardInfo title={t("order.full_name")}
                          info={(orderDetails?.payment_info?.contact_name || orderDetails?.payment_info?.contact_surname) ? `${orderDetails?.payment_info?.contact_name} ${orderDetails?.payment_info?.contact_surname}` : 'EMPTY'}/>
                <CardInfo title={t("order.address")}
                          info={orderDetails?.address?.country ? `${orderDetails?.address?.country}, ${orderDetails?.address?.city}, ${orderDetails?.address?.street_address} ${orderDetails?.address?.street_address_optional ? `( ${orderDetails?.address?.street_address_optional} )` : ''}` : 'EMPTY'}/>
            </div>
            <h1 className={classes.title}>{t("order.shipping_address")}</h1>
            <div className='payment-info_container'>
                <div className={'payment-info-item'}>
                    <p>{t("order.status")}</p>
                    <span>....................................................................................................................................................................................................................................</span>
                    <p>{orderDetails?.payment_info?.status}</p>
                </div>
                <div className={'payment-info-item'}>
                    <p>{t("order.full_name")}</p>
                    <span>....................................................................................................................................................................................................................................</span>
                    <p>{orderDetails?.payment_info?.contact_name ? `${orderDetails?.payment_info?.contact_name} ${orderDetails?.payment_info?.contact_surname}` : ''}</p>
                </div>
                <div className={'payment-info-item'}>
                    <p>{t("order.date_and_time")}</p>
                    <span>....................................................................................................................................................................................................................................</span>
                    <p>{moment(orderDetails?.payment_info?.date).format('lll')}</p>
                </div>
                <div className={'payment-info-item'}>
                    <p>{`${orderDetails?.payment_info?.total_items ? orderDetails?.payment_info?.total_items : ''} ${t("order.item")}`}</p>
                    <span>....................................................................................................................................................................................................................................</span>
                    <p></p>
                </div>
                <div className={'payment-info-item'}>
                    <p>{t("order.shipping")}</p>
                    <span>....................................................................................................................................................................................................................................</span>
                    <p></p>
                </div>
                <div className={'payment-info-item'}>
                    <p>{t("order.order_total")}</p>
                    <span>....................................................................................................................................................................................................................................</span>
                    <p>{orderDetails?.payment_info?.order_total_price ? `${orderDetails?.payment_info?.order_total_price} AMD` : ''}</p>
                </div>
            </div>
            <h1 className={classes.title}>{`${t("order.products")} (${orderDetails.products?.length ? orderDetails.products?.length : ''})`}</h1>
            <div className={classes.productItemWrapper}>
                {
                    orderDetails && orderDetails.products ?
                        orderDetails.products.map((productItem, index) => {
                            return (
                                <ProductItem index={index} key={productItem.id} productItem={productItem}/>
                            )
                        }) : null
                }
            </div>
        </div>
    );
}

export default SellerOrderItem;