export const typeLabels = [
    { label: "ring", id: 1 },
    { label: "earring", id: 2 },
    { label: "set", id: 3 },
    { label: "eng_ring", id: 4 },
    { label: "wed_ring", id: 5 },
    { label: "pendant", id: 6 },
    { label: "cross", id: 7 },
    { label: "necklace", id: 8 },
    { label: "chain", id: 9 },
    { label: "hard_bracelet", id: 10 },
    { label: "chain_bracelet", id: 11 },
    { label: "bars", id: 14 },
    { label: "other", id: 15 },
    { label: "bracelets", id: 16 },
    { label: "accessories", id: 17 },
    { label: "book", id: 21 },
    { label: "watches", id: 22 },
    { label: "brooch", id: 23 },
]

export const typeCheckboxes = typeLabels.reduce((acc, { id }) => {
    acc[id] = false;
    return acc;
}, {});