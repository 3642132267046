import {cButtonStyles} from "./cButton-styles.js";


interface CButtonProps {
    title?: string
    backgroundColor?: string
    borderWidth?: number
    disable?: boolean
    leftIcon?: JSX.Element
    rightIcon?: JSX.Element
    onPress?: any
}

export default function CButton({
                                    title,
                                    onPress = () => undefined,
                                    backgroundColor,
                                    leftIcon,
                                    rightIcon,
                                    borderWidth,
                                    disable = false
                                }: CButtonProps) {

    const classes = cButtonStyles({backgroundColor, borderWidth, disable});

    return (
        <div
            onClick={disable? null: onPress}
            className={classes.container}
        >
            <div className={classes.leftIcon}>
                {leftIcon && leftIcon}
            </div>
            <p className={classes.btnText}>{title}</p>
            <div className={classes.rightIcon}>
                {rightIcon && rightIcon}
            </div>
        </div>
    );
}
