import React, {useState} from "react";
import {sellerOfferStyle} from "./seller-offers-style";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import CInput from "src/cors/input/cInput";
import {useTranslation} from "react-multi-lang";
import {Axios} from "src/api/api";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";


function SellerOfferChangePriceModal({selected, setPriceModal, request}) {
    const classes = sellerOfferStyle();
    const t = useTranslation()
    const [yourOffer, setYourOffer] = useState('')

    const handleCounterPrice = ()=>{
       if (yourOffer){
           Axios.put(`offer/counter/${selected.id}`,{
               "final": false,
               "price": +yourOffer
           }).then(res => {
               toast(t('project.sent'))
               request()
               setPriceModal(false)
           }).catch(err=>{
               toast(errorMessages(err?.response?.data?.code))
           })
       }
    }

    const handleFinalPrice = ()=>{
     if (yourOffer){
         Axios.put(`offer/counter/${selected.id}`,{
             "final": true,
             "price": +yourOffer
         }).then(res => {
             request()
             setPriceModal(false)
             toast(t('project.sent'))
         }).catch(err=>{
             toast(errorMessages(err?.response?.data?.code))
         })
     }
    }


    return (
        <div className={classes.priceModal}>
            <p className={classes.priceModalInfo}>{t("seller_offer.price")}</p>
            <p className={classes.priceModalPrice}>{selected.price}</p>
            <p className={classes.priceModalInfo}>{t("seller_offer.client_offer")}</p>
            <p className={classes.priceModalPrice}>{selected.offer_price}</p>
            <p className={classes.priceModalYouroffer}>{t("seller_offer.your_offer")}</p>
            <CInput
                type={'number'}
                onChange={(e)=>setYourOffer(e.target.value)}
                placeholder={t("seller_offer.your_offer")}
            />
            <div className={classes.agreeModalBtn}>
                <CButton
                    onPress={handleCounterPrice}
                    title={t("seller_offer.make_counter_offer")}
                    backgroundColor={colors.blue}
                />
            </div>
            <CButton
                onPress={handleFinalPrice}
                title={t("seller_offer.final_price")}
                backgroundColor={colors.blue}
            />
        </div>
    );
}

SellerOfferChangePriceModal.propTypes = {
    selected: PropTypes.object,
    setPriceModal : PropTypes.func,
    request : PropTypes.func,
}

export default SellerOfferChangePriceModal;