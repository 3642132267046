import React, {useCallback, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-multi-lang";
import UnderHeader from "src/components/underHeader/underHeader";
import {sellerVWarehouseStyle} from "./seller-warehouse-style";
import CRadio from "src/cors/radio/cRadio";
import WarehouseTableGold from "./seller-warehouse-table/warehouse-table-gold";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import {GlobalContext} from "src/context/global-context";


function SellerWarehouse() {
    const classes = sellerVWarehouseStyle();
    const t = useTranslation();
    const {state, setState} = useContext(GlobalContext)
    const [data, setData] = useState([])
    const [radios, setRadios] = useState([
        {checked: true, title: t('metals.gold'), id: 0, value: "gold"},
        {checked: false, title: t('metals.silver'), id: 1, value: "silver"},
        {checked: false, title: t('metals.other'), id: 2, value: 'other'},
        {checked: false, title: t('metals.other_with_diamond'), id: 3, value: 'other_with_diamond'},
    ]);
    const [active, setActive] = useState('gold');

    const request = useCallback(() => {
        setState({...state, loader: true})
        Axios(`/categories/${active}/common?limit=1000&offset=0`).then(res => {
             setData(res?.data)
        }).catch(err => {
             toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err');
        }).finally(() => {
            setState({...state, loader: false})
        })
    }, [data,active])

    const changeRadios = (arr) => {
        setRadios(arr)
        const activeRadio = radios.find(item => item.checked).value
        setActive(activeRadio)
    }

    useEffect(()=>{
        request()
    },[active])

    return (
        <div className={classes.container}>
            <UnderHeader title={t("header.seller_warehouse")}/>
            <div className={classes.radioGroup}>
                <CRadio
                    radioList={radios}
                    onChange={changeRadios}
                />
            </div>
            <WarehouseTableGold dataBody={data} type={active} />
        </div>
    );
}

export default SellerWarehouse;