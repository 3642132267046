import React from "react";
import propTypes from "prop-types";
import moment from "moment";
import CInput from "src/cors/input/cInput";
import {useTranslation} from "react-multi-lang";

const RateTable = ({dataBody, title, handleEdit, status}) => {
    const t = useTranslation()
    return (
        <>
            {
                (dataBody && dataBody.length) ?
                    <>
                        <div className='tableWrapper rateTable equalWidthTable'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>{title}</th>
                                    <th>{t("seller_rate.buy")}</th>
                                    <th>{t("seller_rate.sell")}</th>
                                    <th>{`+ / -`}</th>
                                    <th>{t("seller_rate.time")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dataBody.map((tdItem, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td className='bold'>
                                                    {tdItem.name}
                                                </td>
                                                <td className='bold'><CInput showErrorMessage={false}
                                                                             onChange={(e) => handleEdit(e.target.value, index, 'buy')}
                                                                             value={`${tdItem.buy}`}/></td>
                                                <td><CInput showErrorMessage={false}
                                                            onChange={(e) => handleEdit(e.target.value, index, 'sell')}
                                                            value={`${tdItem.sell}`}/></td>
                                                <td className={tdItem.sell - tdItem.yesterday_sell < 0 ? 'redTitle' : 'greenTitle'}>
                                                    {(tdItem.sell - tdItem.yesterday_sell).toFixed(2)}</td>
                                                <td>{status ? moment(tdItem.updated_at).format('HH:mm:ss') : t('seller_rate.is_close')}</td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </> : null
            }

        </>

    )
}

RateTable.propTypes = {
    dataBody: propTypes.array,
    handleEdit: propTypes.func,
    title: propTypes.any,
    status: propTypes.bool
};

export default RateTable