import React from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {ReactComponent as IconDelete} from "src/assets/icons/delete.svg";
import {sellerAddProductStyle} from "src/pages/seller-pages/seller-add-product/seller-add-product-style";

const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    display: 'flex',
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};


export default function ImageListAbout({images, setImages, deleteImg, id, showDeleteIcon=true}){
    const classes = sellerAddProductStyle()
    const onDragEnd =(result)=> {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            images,
            result.source.index,
            result.destination.index
        );
        setImages(items)

    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable  droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={{...getListStyle(snapshot.isDraggingOver), flexWrap: 'wrap'}}
                        {...provided.droppableProps}
                    >
                        {images.map((item, index) => {
                            return (
                                <Draggable key={`${item.data_url}${index}`} draggableId={`${item.data_url}${index}`}
                                           index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <div key={index} className={classes.imageListItem}>
                                                {showDeleteIcon && <div onClick={() => deleteImg(item)}
                                                     className={classes.deleteImg}>
                                                    <IconDelete/>
                                                </div>}
                                                <img src={`${item}`} className={classes.uploadImg} alt="img"/>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            )
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}


