import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const sellerOrderItemStyle = makeStyles(() =>
    createStyles({
        sellerOrderContainer: {
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto 30px',
            "@media (max-width:1250px)": {
                width: '96%',
            },
        },
        flexBetweenContainer: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
        },
        title: {
            fontSize: 20,
            lineHeight: '31px',
            fontWeight: 400,
            margin: '24px 0 6px',
            "@media (max-width:600px)": {
                fontSize: 18,
                margin: '12px 0 0',
            },
        },
        productItemWrapper: {
            maxWidth: 894,
            width: '100%',
            marginBottom: 30,
        },
        trackingNumberContainer: {
            marginTop: 30,
            '& > p': {
                fontSize: 12,
                lineHeight: '14px',
                fontWeight: 400,
                textTransform: 'uppercase',
                paddingBottom: 10,
            }
        },
        buttonWrapper: {
            '& > div': {
                width: 236,
            }
        },
        inputWrapper: {
            display: 'flex',
            alignItems: 'center',
            '& > div:last-child': {
                width: 80,
            },
            '& input': {
                width: 230,
                marginRight: 10,
                height: 40,
                backgroundColor: colors.grey,
                borderColor: colors.grey,
                borderWidth: 0.01,
                outline: 'none',
                border: 'none',
                transition: '.5s',
                fontWeight: 'lighter',
                padding: '0 19px',
                borderStyle: 'ridge',
                "&:hover": {
                    borderWidth: 0.01,
                    borderColor: 'rgb(86 81 81 / 50%)',
                    borderStyle: 'ridge'
                },
                "&:focus": {
                    borderWidth: 0.01,
                    borderColor: (props) =>
                        props.borderColor,
                    borderStyle: 'ridge'
                }
            }
        },
        trackingNumberContent: {
            display: 'flex',
            alignItems: 'center',
            '& p': {
                fontSize: 14,
                lineHeight: '16px',
                fontWeight: 500,
                fontFamily: 'mardotoMedium'
            },
            '& > div:last-child': {
                width: 40,
                height: 40,
                flex: '0 0 40px',
            },
        },
        editButtonWrapper: {
            width: 230,
            margin: '0 10px',
        }
    })
)