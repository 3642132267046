import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";


export const cInputStyles = makeStyles(() =>
    createStyles({
        container: {
            width: '100%',
            height: 40,
            backgroundColor: colors.grey,
            borderColor: colors.grey,
            borderWidth: 0.01,
            outline: 'none',
            border: 'none',
            transition: '.5s',
            fontWeight: 'lighter',
            padding: '0 19px',
            borderStyle: 'ridge',
            "&:hover": {
                borderWidth: 0.01,
                borderColor: 'rgb(86 81 81 / 50%)',
                borderStyle: 'ridge'
            },
            "&:focus": {
                borderWidth: 0.01,
                borderColor: (props) =>
                    props.borderColor,
                borderStyle: 'ridge'
            }
        },
        containerError: {
            borderColor: colors.red,
            borderWidth: 1,
            borderStyle: 'ridge'
        },

        label: {
            fontSize: 12,
            marginBottom: 12,
            color: colors.mainGreyDark,
            textTransform: 'uppercase'
        },
        labelError: {
            color: colors.red,
        },
        errorBlock: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: 6,
            height: 17,
        },
        errorText: {
            color: colors.red,
            fontSize: 12,
            marginLeft: 5,
            verticalAlign: 'center'
        }
    })
);
