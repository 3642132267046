export const config = {
    url: "https://api.goldcenter.am/v1/",
    socketUrl:"notifier.goldcenter.am",
    imgUr: "https://storage.yandexcloud.net/image-goldcenter/",
    adminUrl: 'https://admin.goldcenter.am',

    // url: "https://api.goldcentr.am/v1/",
    // socketUrl: "notifier.goldcentr.am",
    // imgUr: "https://storage.yandexcloud.net/archive-goldcenter/",
    // adminUrl: 'http://localhost:3001',

    webUrl: 'https://goldcenter.am',
    // url : "http://51.250.77.129:8080/",
}
