import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const cardInfoStyle = makeStyles(() =>
    createStyles({
        cardInfoContainer: {
            width: 437,
            borderBottom: `1px solid ${colors.greyLiner}`,
            padding: '12px 0',
            marginRight: 20,
            "@media (max-width:960px)": {
                width: '47%',
                marginRight: '3%',
            },
            "@media (max-width:550px)": {
                width: '100%',
                marginRight: 0,
            },
            '& h1': {
                fontSize: 12,
                lineHeight: '14px',
                fontWeight: 400,
                textTransform: 'uppercase',
                paddingBottom: 12
            },
            '& p': {
                fontSize: 14,
                lineHeight: '16px',
                fontWeight: 400,
                textTransform: 'uppercase',
                color: colors.blue,
            }
        },
    })
);