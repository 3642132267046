import React from "react";
import propTypes from "prop-types";
import {Link, useNavigate} from "react-router-dom";
import CButton from "src/cors/button/cButton";
import moment from "moment";
import {useTranslation} from "react-multi-lang";

const OrderTable = ({dataBody}) => {
    const navigation = useNavigate()
    const t = useTranslation();
    return (
        <>
            {
                (dataBody && dataBody.length) ?
                    <>
                        <div className='tableWrapper'>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>{t("table.id")}</th>
                                    <th>{t("table.username")}</th>
                                    <th>{t("table.seller")}</th>
                                    <th>{t("table.date")}</th>
                                    <th>{t("table.quantity")}</th>
                                    <th>{t("table.total_price")}</th>
                                    <th>{t("table.action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dataBody.map((tdItem, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td>
                                                    <Link to='#' className='link'>{tdItem.id}</Link>
                                                </td>
                                                <td>{`${tdItem.buyer_firstname} ${tdItem.buyer_lastname}`}</td>
                                                <td>{tdItem.seller_company_name}</td>
                                                <td>{moment(tdItem.created_at).format('YYYY MM DD')}</td>
                                                <td>{tdItem.quantity}</td>
                                                <td>{tdItem.total_price}</td>
                                                <td>
                                                        <div className='tableButtonWrapper'>
                                                            <CButton
                                                                onPress={()=>navigation('/order/orderItem', {state: {prefix: 'order', id: tdItem.id}})}
                                                                title={t("project.view_details")}
                                                                borderWidth={1}
                                                            />
                                                        </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            dataBody.map((item, index) => {
                                return (
                                    <div key={index.toString()} className='cardContainer'>
                                        <ul className='cardContent'>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.id")}</p>
                                                <p className='cardValue'>
                                                    <Link className='link' to='#'>{item.id}</Link>
                                                </p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.username")}</p>
                                                <p className='cardValue'>{`${item.buyer_firstname} ${item.buyer_lastname}`}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.seller")}</p>
                                                <p className='cardValue'>{item.seller_company_name}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.date")}</p>
                                                <p className='cardValue'>{moment(item.created_at).format('YYYY MM DD')}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.quantity")}</p>
                                                <p className='cardValue'>{item.quantity}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardKey'>{t("table.total_price")}</p>
                                                <p className='cardValue'>{item.total_price}</p>
                                            </li>
                                            <li className='cardItem'>
                                                 <div className='cardButtonWrapper'>
                                                     <CButton
                                                         onPress={()=>navigation('/order/orderItem', {state: {prefix: 'order', id: item.id}})}
                                                         title={t("project.view_details")}
                                                         borderWidth={1}
                                                     />
                                                 </div>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </> : null
            }

        </>

    )
}

OrderTable.propTypes = {
    dataBody: propTypes.array
};

export default OrderTable