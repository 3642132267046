import {createStyles, makeStyles} from "@mui/styles";

export const orderItemStyle = makeStyles(() =>
    createStyles({
        orderDetailsContainer: {
            maxWidth: 1200,
            width: '100%',
            margin: 'auto',
            ["@media (max-width:1250px)"]: {
                width: '96%',
            },
        },
        flexBetweenContainer: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
        },
        title: {
            fontSize: 20,
            lineHeight: '31px',
            fontWeight: 400,
            margin: '24px 0 6px',
            ["@media (max-width:600px)"]: {
                fontSize: 18,
                margin: '12px 0 0',
            },
        },
        productItemWrapper: {
            maxWidth: 894,
            width: '100%',
            marginBottom: 30,
        },
    })
)