import React from "react";
import {productItemStyle} from "./productItem-style";
import {useTranslation} from "react-multi-lang";

interface ProductItemProps{
    productItem: any
    index : number
}

const ProductItem = ({productItem, index}:ProductItemProps) => {
    const classes = productItemStyle();
    const t = useTranslation()
    return (
        <div className={classes.productItemContainer}>
            <span className={classes.productNumber}>{index + 1}</span>
            <div className={classes.productImageWrapper}>
                <img src={`${productItem?.pictures[0].path}`} alt="jewelry"/>
            </div>
            <ul className={classes.productList}>
                <li className={classes.colorBlue}>{productItem?.name}</li>
                <li><span className={[`${classes.uppercase} ${classes.opacityColor}`].join(' ')}>{productItem?.company_name}</span></li>
                <li>
                    <span className={classes.opacityColor}>{t("order.products_code")}:</span>
                    <p>{productItem?.code}</p>
                </li>
                <li>
                    <span>{t("order.size")}:</span>
                    <p>{productItem?.size}</p>
                </li>
                <li>
                    <span>{t("order.color")}:</span>
                    <p>{productItem?.color}</p>
                </li>
                <li>
                    <span className={classes.colorBlue}>{`${productItem?.price ? productItem?.price : ''} AMD`}</span>
                </li>
            </ul>
        </div>
    )
}

export default ProductItem