import React from "react";
import ScrollToTop from "react-scroll-to-top";
import {colors} from "src/utils/color";


export default function CScrollToTop() {
    return (
        <ScrollToTop
            smooth color={colors.white}
            style={{background: colors.blue}}
            width={'20'}
            height={'20'}
        />
    )
}