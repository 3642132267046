import React from "react";

import {translationPageStyle} from "./translation-page-style";
import TranslationPageSection from "./translation-page-section";
import TranslationPageMultiple from "./Translation-page-multiple";

function TranslationPage() {
    const classes = translationPageStyle()
    return (
        <div className={classes.container}>
            <TranslationPageSection/>
            {/*<TranslationPageMultiple/>*/}
        </div>
    );
}

export default TranslationPage;



