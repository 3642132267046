import {createStyles, makeStyles} from "@mui/styles";

export const sellerSoldStyle = makeStyles(() =>
    createStyles({
        sellerSoldContainer: {
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto 30px',
            "@media (max-width:1250px)": {
                width: '96%',
            },
        },
        wrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            margin: '30px -10px -10px -10px',
            "@media (max-width:500px)": {
                flexDirection: 'column',
                margin: 0
            },
        },
        sellerOfferCardWrapper: {
            marginTop: 30,
        },
        seeMoreBtnWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 30,
            '& > div': {
                width: 230
            },
            "@media (max-width:500px)": {
                marginBottom: 30
            },
        },
    })
)