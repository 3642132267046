import React from 'react';
import GlobalContextProvider from "./context/global-context";
import App from "./App";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";  // Import QueryClient and QueryClientProvider

export const GlobalContextWrapper = (props) => {
    const queryClient = new QueryClient();

    return (
        <GlobalContextProvider>
            <QueryClientProvider client={queryClient}>
                <App {...props}  />
            </QueryClientProvider>
        </GlobalContextProvider>
    );
};

export default GlobalContextWrapper;
