export const colors = {
    black: '#000000',
    white: '#ffffff',
    blue : '#1D3865',
    strongBlue: '#2183d1',
    gold : '#CCA653',
    vividOrange: '#F3BB1C',
    green : '#219653',
    limeGreen: '#3CC13B',
    red : '#E54623',
    softRed: '#EB5757',
    brightRed: '#F44336',
    grey : '#F1F2F3',
    greyLiner : 'rgba(29,56,101,0.1)',
    greyLiner1 : 'rgba(29,56,101,0.2)',
    greyLiner2 : 'rgba(29,56,101,0.5)',
    lightGrayShadow: '1px 1px 16px #d7d7d7;',
    shadowBox: '0px 0px 96px rgba(0, 0, 0, 0.1)',
    mainGrey : '#BCBCBC',
    mainGreyLight : '#EDEDED',
    mainGreyDark : '#7C7C7C',
    veryLightGray: '#F9F9F9',
    lightGray:'rgba(195,195,195,0.1)',
    veryDarkGray: '#313131',
    grayishBlue: '#B4B8BB',
}