import React from "react";
import propTypes from "prop-types";
import {RenderStatus} from "src/components/render-status/renderStatus";
import {useTranslation} from "react-multi-lang";

const BuyerDetailsOffer = ({dataBodyOfferBuyer = []}) => {
    const t = useTranslation()
    return (
        dataBodyOfferBuyer?.map((item, index) => {
            return (
                <div key={index.toString()} className='payment-info_container buyerDetailsOffer'>
                    <div className='payment-info-title'>
                        <p>{item.link}</p>
                        <RenderStatus status={(item.status)} />
                    </div>
                    <div className={'payment-info-item'}>
                        <p>{t("table.buyer")}</p>
                        <span>................................................................................................................................................................................................................................................................</span>
                        <p>{item.name}</p>
                    </div>
                    <div className={'payment-info-item'}>
                        <p>{t("table.price")}</p>
                        <span>....................................................................................................................................................................................................................................................................</span>
                        <p>{item.price}</p>
                    </div>
                    <div className={'payment-info-item'}>
                        <p className='redTitle'>{t("table.offer_price")}</p>
                        <span
                            className='redTitle'>....................................................................................................................................................................................................................................................................</span>
                        <p className='redTitle'>{item.offer_price}</p>
                    </div>
                </div>
            )
        })
    )
}

BuyerDetailsOffer.propTypes = {
    dataBody: propTypes.array
};

export default BuyerDetailsOffer