import {fakeJewelryStyle} from "./fake-jewelry-style";
import FakeJewelryItem from "./fake-jewelry-item";
import UnderHeader from "src/components/underHeader/underHeader";
import {useTranslation} from "react-multi-lang";
import React, {Fragment, useCallback, useContext, useEffect, useState} from "react";
import {Axios} from "src/api/api";
import CButton from "src/cors/button/cButton";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import ScrollInfiniteWrapper from "src/components/scroll-wrapper/scroll-wrapper";
import {GlobalContext} from "src/context/global-context";
import FakeJewelryUnderHeader from "./fake-jewelry-underHeader";


function FakeJewelry() {
    const classes = fakeJewelryStyle()
    const t = useTranslation()
    const {state, setState} = useContext(GlobalContext)
    const [fakeJewelry, setFakeJewelry] = useState([])
    const [offset, setOffset] = useState('')
    const [total_count, setTotal_count] = useState(0)
    const [scrolling, setScrolling] = useState(false)
    const [status, setStatus] = useState('created')


    const request = useCallback(() => {
        setState({...state, loader: true})
        Axios(`admin/fake_jewelry_requests?status=${status}&limit=10&offset=${offset}`).then(res => {
            setFakeJewelry([...fakeJewelry, ...res.data.fake_jewelry])
            setTotal_count(res?.data?.total_count)
        }).catch(err => {
            console.log(err, 'err')
            toast(errorMessages(err.response.data.code))
        }).finally(() => {
            setState({...state, loader: false})
            setScrolling(false)
        })
    }, [offset,status,fakeJewelry])

    const showMore = () => {
        setOffset(+offset + 10)
    }

    const updateView = () => {
        setFakeJewelry([])
        setOffset(prev => prev === 0 ? '' : 0)
    }

    const handleChangeStatus = (e) => {
        setStatus(e)
        updateView()
    }


    useEffect(() => {
        request()
    }, [offset])

    useEffect(() => {
        if (scrolling && total_count !== fakeJewelry?.length) {
            showMore()
        }
    }, [scrolling])

    return (
        <ScrollInfiniteWrapper
            scrollUpdate={setScrolling}
            context={<div className={classes.container}>
                <UnderHeader title={t("header.fake_jewelry")}
                context={<FakeJewelryUnderHeader user={'admin'} handleChangeStatus={handleChangeStatus} />}
                />
                <div className={classes.fakeJewelryWrap}>
                    {fakeJewelry.length > 0 && fakeJewelry.map((item, index) => {
                        return (
                            <Fragment key={index}>
                                <FakeJewelryItem
                                    data={item}
                                    admin={true}
                                    request={updateView}
                                />
                            </Fragment>
                        )
                    })}
                </div>
            </div>}/>
    );
}

export default FakeJewelry;