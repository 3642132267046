import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const uploadStyles = makeStyles(() =>
    createStyles({
        container: {
            width: '100%',
            height: 143,
            border: `1px dashed ${colors.grey}`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center"
        },
        titleClick: {
            marginTop: 32,
            color: colors.black,
            fontSize: 14
        },
        info: {
            marginTop: 9,
            fontSize: 12,
            color: colors.mainGrey
        },
        errorBlock: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: 6,
            height: 17,
        },
        errorText: {
            color: colors.red,
            fontSize: 12,
            marginLeft: 5,
            verticalAlign: 'center'
        }

    })
);