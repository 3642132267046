import React from 'react';
import GroupForm from 'src/components/preOrder/GroupForm'; // Adjust import path

const CreateGroup = () => {
    return (
        <div>
            <GroupForm />
        </div>
    );
};

export default CreateGroup;
