import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const sellerCustomPriceStyle = makeStyles(() =>
    createStyles({
        sellerUpdateRateModal: {
            '& > p': {
                fontSize: 16,
                lineHeight: '24px',
                color: colors.veryDarkGray,
                padding: '22px 0',
            }
        },
        sellerCustomPriceContainer: {
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto 50px',
            "@media (max-width:1250px)": {
                width: '96%',
            },
        },
        sellerCustomPriceUnderHeader: {
            display: 'flex',
            '& > div:not(:last-child)': {
                marginRight: 12
            },
            "@media (max-width:600px)": {
                flexDirection: 'column',
                width: '100%',
            },
        },
        buttonWrap: {
            width: 205,
            "@media (max-width:600px)": {
                width: '100%',
                marginBottom: 11,
            },
        },
        inputWrap: {
            width: 211,
            "@media (max-width:600px)": {
                width: '100%',
                marginBottom: 10,
            },
        },
        settingsIconContainer: {
            width: 59,
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${colors.mainGrey}`,
            cursor: 'pointer'
        },
        radioGroup: {
            display: 'flex',
            alignItems: 'center',
            marginTop: 20,
            paddingBottom: 20,
            borderBottom: `1px solid ${colors.greyLiner}`,
            '& p:not(:last-child)': {
                marginRight: 48,
                "@media (max-width:500px)": {
                    marginRight: 8,
                },
            },
        },
        sellerUnderHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 20,
            '& > p': {
                textTransform: 'capitalize',
                fontSize: 24,
                color: colors.black,
                fontFamily: 'montserratRegular',
                "@media (max-width:767px)": {
                    paddingBottom: 10
                },
                "@media (max-width:480px)": {
                    fontSize: 18,
                },
            },
            "@media (max-width:767px)": {
                flexDirection: 'column'
            },
        },
        checkboxContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        checkBoxWrap: {
            display: 'flex',
            alignItems: 'center',
            '& p': {
                cursor: 'pointer',
                paddingLeft: 5,
                paddingRight: 5,
                fontSize: 16,
                lineHeight: '22px',
                color: colors.blue
            }
        },
        sellerCustomPriceOptions: {
            display: 'flex',
            alignItems: 'center',
        },
        seeMoreBtnWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 30,
            '& > div': {
                width: 230
            },
            "@media (max-width:500px)": {
                marginBottom: 30
            },
        },
        priceRow: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            backgroundColor: colors.veryLightGray,
            padding: '15px 20px',
            "@media (max-width:500px)": {
                padding: '7px 10px',
            },
            marginTop: 21,
            '& > p': {
                fontSize: 19,
                lineHeight: '19px',
                color: colors.black,
                textTransform: 'uppercase',
                marginRight: 90,
                "@media (max-width:500px)": {
                    marginRight: 10,
                    fontSize: 14,
                },
            }
        },
        switcherWrapper: {
            margin: '0 16px',
        },
        saveBtn: {
            marginBottom: 20,
            marginTop: 32,
        },
        createCategoryModal: {
            marginTop: 20,
        },
        categoryInputWrapper: {
            marginTop: 30,
            '& input::placeholder': {
                textTransform: 'uppercase',
            }
        },
        //    custom price edit
        customPriceRateDate: {
            display: 'flex',
            alignItems: 'center',
            margin: 10,
            paddingBottom: 20,
            borderBottom: `1px solid ${colors.greyLiner}`,
            '& p': {
                fontSize: 14,
                lineHeight: '17px',
            },
            '& p:first-child': {
                color: colors.blue
            },
            '& p:last-child': {
                color: colors.black,
                paddingLeft: 5,
            }
        },
        // move product modal
        moveProductModal: {
            marginTop: 20,
        },
        tableWrapper: {
            margin: '30px 0 20px',
        },
        tableTitle: {
            width: '100%',
            height: 1,
            backgroundColor: colors.greyLiner,
            position: 'relative',
            '& h1': {
                position: 'absolute',
                right: '32%',
                top: -6,
                fontSize: 14,
                lineHeight: '14px',
                color: colors.black,
                textTransform: 'uppercase',
                backgroundColor: 'white',
                width: 200,
                textAlign: 'center'
            }
        },
        sellerCustomSelect:{
            marginLeft: 12,
            width: 220
        },
        total_count:{
            textAlign:"right",
            fontWeight:'bold',
            marginTop:10,
            fontSize:14
        }
    })
)