import React, {useEffect, useState} from "react";
import {messagesStyle} from "./messages-style";
import UnderHeader from "src/components/underHeader/underHeader";
import MessageUsers from "./message-users";
import MessageItem from "./message-item";
import CSearchInput from "src/cors/searchInput/searchInput";

function Messages() {
    const {innerWidth: width, innerHeight: height} = window;
    const [chat, setChat] = useState(true)
    const [mobile, setMobile] = useState(false)
    const classes = messagesStyle();


    useEffect(() => {
        if (width < 768) {
            setMobile(true)
        }
    }, [width])

    return (
        <div className={classes.container}>
            <UnderHeader
                back={!chat}
                title={'Message'}
                goBack={() => setChat(true)}
                context={<div className={classes.searchInputWrapp}><CSearchInput/></div>}
            />
            <div className={classes.containerMessages}>
                {!mobile ? (<div className={classes.usersBlock}>
                    <MessageUsers/>
                    <MessageUsers/>
                    <MessageUsers/>
                    <MessageUsers/>
                    <MessageUsers/>

                </div>) : chat && <div className={classes.usersBlock}>
                    <MessageUsers onUser={() => setChat(false)}/>
                    <MessageUsers onUser={() => setChat(false)}/>
                    <MessageUsers onUser={() => setChat(false)}/>
                    <MessageUsers onUser={() => setChat(false)}/>
                </div>}
                {!mobile ? (
                    <div className={classes.chat}>
                        <MessageItem/>
                        <MessageItem/>
                        <MessageItem/>
                        <MessageItem/>
                    </div>
                ) : (!chat && <div className={classes.chat}>
                    <MessageItem/>
                    <MessageItem/>
                    <MessageItem/>
                    <MessageItem/>
                    <MessageItem/>
                </div>)}
            </div>
        </div>
    );
}

export default Messages;