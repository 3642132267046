import React, {useCallback, useContext, useEffect, useState} from "react";
import {sellerOfferStyle} from "./seller-offers-style";
import SellerOffersCard from "./sellerOffersCard/sellerOffersCard";
import CButton from "src/cors/button/cButton";
import {CModal} from "src/cors/modal/cModal";
import {colors} from "src/utils/color";
import UnderHeader from "src/components/underHeader/underHeader";
import {useTranslation} from "react-multi-lang";
import {Axios} from "src/api/api";
import SellerOfferChangePriceModal from "./seller-offer-change-price-modal";
import {toast} from "react-toastify";
import {errorMessages} from "src/helpers/error-messages";
import ScrollInfiniteWrapper from "src/components/scroll-wrapper/scroll-wrapper";
import {GlobalContext} from "src/context/global-context";
import {SellerOfferContext} from "src/context/seller-offer-context";


function SellerOffer() {
    const classes = sellerOfferStyle();
    const t = useTranslation()
    const {state, setState} = useContext(GlobalContext)
    const {local, setLocal} = useContext(SellerOfferContext)
    const [priceModal, setPriceModal] = useState(false)
    const [agreeModal, setAgreeModal] = useState(false)
    const [selected, setSelected] = useState({})
    const [data, setData] = useState([])
    const [offset, setOffset] = useState('')
    const [total_count, setTotal_count] = useState(0)
    const [scrolling, setScrolling] = useState(false)


    const request = useCallback(() => {
        setState({...state, loader: true})
        Axios(`offer/seller/all?limit=10&offset=${offset}`).then(res => {
            setData([...data, ...res?.data?.offers])
            setTotal_count(res?.data?.total_count)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        }).finally(() => {
            setState({...state, loader: false})
            setScrolling(false)
        })
    }, [total_count, offset])


    const updateView = () => {
        setData([])
        setOffset(prev => prev === 0 ? '' : 0)
    }

    const handleAccept = () => {
        Axios.put(`offer/accept/${selected.id}`).then(res => {
            updateView()
            toast(t('project.approve'))
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
        setAgreeModal(false)
    }

    const showMore = () => {
        setOffset(offset + 10)
    }

    const handleReject = (id) => {
        Axios.put(`offer/reject/${id}`).then(res => {
            toast(t('project.reject'))
            updateView()
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
        })
    }

    useEffect(() => {
        request()
    }, [offset])

    useEffect(() => {
        if (scrolling && total_count !== data?.length) {
            showMore()
        }
    }, [scrolling])

    return (
        <ScrollInfiniteWrapper
            scrollUpdate={setScrolling}
            context={<div className={classes.sellerOfferContainer}>
                <UnderHeader title={t("header.offers")}/>
                <div className={classes.sellerOfferCardWrapper}>
                    <SellerOffersCard
                        handleReject={handleReject}
                        setSelected={setSelected}
                        dataBody={data}
                        setPriceModal={setPriceModal}
                        setAgreeModal={setAgreeModal}
                    />
                </div>
                <CModal
                    center={false}
                    title={t("seller_offer.change_price")}
                    closeModal={() => setPriceModal(false)}
                    visible={priceModal}
                    children={<SellerOfferChangePriceModal
                        request={request}
                        setPriceModal={setPriceModal}
                        selected={selected}
                    />}
                />
                <CModal
                    center={false}
                    title={t("seller_offer.confirmation")}
                    closeModal={() => setAgreeModal(false)}
                    visible={agreeModal}
                    children={
                        <div>
                            <p className={classes.agreeModalText}>{t("seller_offer.are_you_sure_you_want_to_agree")}</p>
                            <div className={classes.agreeModalBtn}>
                                <CButton
                                    onPress={handleAccept}
                                    title={t("seller_offer.yes")}
                                    backgroundColor={colors.blue}
                                />
                            </div>
                            <CButton
                                onPress={() => setAgreeModal(false)}
                                title={t("seller_offer.close")}
                                borderWidth={1}
                            />
                        </div>}
                />
            </div>}/>

    );
}

export default SellerOffer;