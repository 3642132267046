import React, {useContext, useEffect, useState} from "react";
import logo from "src/assets/images/logo.png";
import {ReactComponent as IconAvatar} from "src/assets/icons/avatar.svg";
import {ReactComponent as IconSettings} from "src/assets/icons/iconSettings.svg";
import {ReactComponent as IconLogout} from "src/assets/icons/iconLogout.svg";
import {ReactComponent as IconMore} from "src/assets/icons/iconMore.svg";
import {ReactComponent as IconUser} from "src/assets/icons/iconUser.svg";
import {ReactComponent as IconAboutUs} from "src/assets/icons/iconAboutUs.svg";
import {ReactComponent as IconPrivacy} from "src/assets/icons/iconPrivacy.svg";
import {ReactComponent as IconFakeJewelry} from "src/assets/icons/iconFakeJewelry.svg";
import {ReactComponent as IconBurger} from "src/assets/icons/iconBurger.svg";
import IconArrow from "src/assets/icons/iconArrow.svg";

import {headerStyles} from "./header-styles";
import {NavLink} from "react-router-dom";
import LanguageBar from "src/components/languageBar/languageBar";
import {GlobalContext} from "src/context/global-context";
import {getLanguage, useTranslation} from "react-multi-lang";
import {config} from "src/api/config";
import {storage} from "src/utils/storage";


const HeaderAdmin = () => {
    const {state, setState} = useContext(GlobalContext)
    const storageToken = storage('get').token
     const socket = new WebSocket(`wss://${config.socketUrl}/ws/notifications/subscribe/online_market?x-access-token=${storageToken}`)
    const [onlineMarket, setOnlineMarket] = useState({})
    const {morePopUp, burgerLeft} = state
    const classes = headerStyles()
    const [isUsersOpen, setIsUsersOpen] = useState(false);
    const t = useTranslation()
    const left = burgerLeft ? 0 : -1500;

    const logOut = () => {
        window.location = `${config.webUrl}?logout=${storage('get').token}&lang=${getLanguage()}`
        storage('remove')
    }

    const onOnlineMarket = () => {
        socket.onmessage = function (event) {
            setOnlineMarket(JSON.parse(event.data))
            socket.send(JSON.stringify({"event": "read_notification", "notification_id": 1}));
        };
    }

    useEffect(() => {
        socket.onopen = function (e) {
            socket.send(JSON.stringify({"event": "get_notifications", "limit": 1000}));
        };
        socket.onmessage = function (event) {
            setOnlineMarket(JSON.parse(event.data))
            console.log(JSON.parse(event.data),'121212123')
        };
        return  ()=>{
            // socket.onclose((e)=>{
            //     console.log(e)
            // })
        }
    }, [])

    return (
        <div className={classes.mainContent}>
            <div className={classes.headerTop}>
                <LanguageBar/>
                <div className={classes.logo}
                     style={{cursor: "pointer"}}
                >
                    <div className={classes.burgerIcon} onClick={() => {
                        setState({burgerLeft: !burgerLeft})
                    }}>
                        <IconBurger/>
                    </div>
                    <div className={classes.logoWrap}
                         onClick={() => window.location = `${config.webUrl}?lang=${getLanguage()}`}
                    >
                        <img className={classes.logoImg} src={logo} alt="logo"/>
                    </div>
                </div>
                <div className={classes.flexContainer}>
                    <p>{`${state?.userinfo?.firstname ? state.userinfo.firstname:""}`} {`${state?.userinfo?.lastname ? state.userinfo.lastname:""}`}</p>
                    <IconAvatar width={16} height={16}/>
                </div>
            </div>
            <div className={classes.headerNavBar}>
                <div className={classes.headerNavBarContent}>
                    <div className={classes.navBarContainer}>
                        <NavLink to='/dashboard'>
                            <p>{t("header.dashboard")}</p>
                        </NavLink>
                        <NavLink to='/product'>
                            <p>{t("header.product")}</p>
                        </NavLink>
                        <NavLink to='/categories'>
                            <p>{t("header.categories")}</p>
                        </NavLink>
                        <NavLink to='/order'>
                            <p>{t("header.orders")}</p>
                        </NavLink>
                        <NavLink to='/sold'>
                            <p>{t("header.sold")}</p>
                        </NavLink>
                        <NavLink to='/rate'>
                            <p>{t("header.rate")}</p>
                        </NavLink>
                        <NavLink className={classes.mobileItemNav} to='/settings'>
                            <p>{t("header.settings")}</p>
                        </NavLink>
                        { state?.userinfo?.role_id === 1 &&
                            <NavLink className={classes.mobileItemNav} to='/pre-order-groups'>
                                <p>Preorder Groups</p>
                            </NavLink>
                        }
                        <div className={classes.moreBtn}>
                            <div onClick={() => {
                                setState({morePopUp: !morePopUp})
                                setIsUsersOpen(false)
                            }}>
                                <IconMore width={18} height={18}/>
                                <p>{t("header.more")}</p>
                            </div>
                            {morePopUp && <ul onClick={(e) => e.stopPropagation()}>
                                <li className={classes.mobileItem}>
                                    <NavLink to='/settings' onClick={() => setState({morePopUp: false})}>
                                        <IconSettings width={16} height={16}/>
                                        <p>{t("header.settings")}</p>
                                    </NavLink>
                                </li>
                                <li style={{paddingBottom: isUsersOpen ? 0 : 20}}>
                                    <div className={classes.usersRow} onClick={() => {
                                        setIsUsersOpen(!isUsersOpen)
                                    }}>
                                        <div className={classes.usersContainer}>
                                            <IconUser width={16} height={16}/>
                                            <p>{t("header.users")}</p>
                                        </div>
                                        <img style={{transform: isUsersOpen ? 'rotate(0deg)' : 'rotate(180deg)'}}
                                             src={IconArrow} alt="arrow icon"/>
                                    </div>
                                    {isUsersOpen && <div className={classes.userTypes}>
                                        <NavLink onClick={() => setState({morePopUp: false})}
                                                 to='/seller'>{t("header.sellers")}</NavLink>
                                        <NavLink onClick={() => setState({morePopUp: false})}
                                                 to='/buyer'>{t("header.buyers")}</NavLink>
                                        <NavLink onClick={() => setState({morePopUp: false})}
                                                 to='/services'>{t("header.services")}</NavLink>
                                    </div>}
                                </li>
                                <li>
                                    <NavLink onClick={() => setState({morePopUp: false})} to='/aboutUs'>
                                        <IconAboutUs width={16} height={16}/>
                                        <p>{t("header.about_us")}</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={() => setState({morePopUp: false})} to='/fixing'>
                                        <IconAboutUs width={16} height={16}/>
                                        <p>{t("header.fixing")}</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={() => setState({morePopUp: false})} to='/booking-users'>
                                        <IconAboutUs width={16} height={16}/>
                                        <p>{t("header.booking_users")}</p>
                                    </NavLink>
                                </li>
                                <li onClick={onOnlineMarket}>
                                    <NavLink onClick={() => setState({morePopUp: false})} to='/onlineShopRequest'>
                                        <IconAboutUs width={16} height={16}/>
                                        <p>{t("header.onlineShopRequest")}</p>
                                        {onlineMarket.unread_notifications > 0 && <div className={classes.indicatorMobile}>
                                            <h6 className={classes.indicatorTitle}>{onlineMarket.unread_notifications}</h6>
                                        </div>}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={() => setState({morePopUp: false})} to='/privacyPolicy'>
                                        <IconPrivacy width={16} height={16}/>
                                        <p>{t("header.privacy_policy")}</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={() => setState({morePopUp: false})} to='/fakeJewelry'>
                                        <IconFakeJewelry width={16} height={16}/>
                                        <p>{t("header.fake_jewelry")}</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/aja' onClick={() => setState({morePopUp: false})}>
                                        <IconAboutUs width={16} height={16}/>
                                        <p>{t("header.aja")}</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/translations' onClick={() => setState({morePopUp: false})}>
                                        <IconAboutUs width={16} height={16}/>
                                        <p>{t("header.translations")}</p>
                                    </NavLink>
                                </li>
                                <li onClick={logOut} className={classes.mobileLogout}>
                                    <IconLogout width={16} height={16}/>
                                    <p>{t("header.log_out")}</p>

                                </li>
                            </ul>
                            }
                        </div>
                    </div>
                    <div onClick={logOut} className={classes.logoutContainer}>
                        <IconLogout width={16} height={16}/>
                        <p>{t("header.log_out")}</p>
                    </div>
                </div>
            </div>
            <ul onClick={e => e.stopPropagation()} className={classes.mobileHeader} style={{left: left}}>
                <li>
                    <NavLink to='/dashboard' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.dashboard")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/product' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.product")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/order' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.orders")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/sold' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.sold")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/rate' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.rate")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/settings' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.settings")}</span>
                    </NavLink>
                </li>
                <li className={classes.userBtn}>
                    <div className={classes.userContainer}>
                        <div onClick={() => {
                            setIsUsersOpen(!isUsersOpen)
                        }}>
                            <span>{t("header.users")}</span>
                        </div>
                        <img style={{transform: isUsersOpen ? 'rotate(0deg)' : 'rotate(180deg)'}}
                             src={IconArrow} alt="arrow icon"/>
                    </div>
                    {isUsersOpen && <div className={classes.mobileUserTypes}>
                        <NavLink onClick={() => setState({burgerLeft: false})}
                                 to='/seller'>{t("header.sellers")}</NavLink>
                        <NavLink onClick={() => setState({burgerLeft: false})}
                                 to='/buyer'>{t("header.buyers")}</NavLink>
                        <NavLink onClick={() => setState({burgerLeft: false})}
                                 to='/services'>{t("header.services")}</NavLink>
                    </div>}
                </li>
                <li>
                    <NavLink to='/aboutUs' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.about_us")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/fixing' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.fixing")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/booking-users' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.booking_users")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/onlineShopRequest' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.onlineShopRequest")}</span>
                        {onlineMarket.unread_notifications >0 && <div className={classes.indicatorMobile}>
                            <p className={classes.indicatorTitle}>{onlineMarket.unread_notifications}</p>
                        </div>}
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/privacyPolicy' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.privacy_policy")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/fakeJewelry' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.fake_jewelry")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/aja' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.aja")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/translations' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.translations")}</span>
                    </NavLink>
                </li>  <li>
                    <NavLink to='/translations' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.translations")}</span>
                    </NavLink>
                </li>  <li>
                    <NavLink to='/translations' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.translations")}</span>
                    </NavLink>
                </li>  <li>
                    <NavLink to='/translations' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.translations")}</span>
                    </NavLink>
                </li>  <li>
                    <NavLink to='/translations' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.translations")}</span>
                    </NavLink>
                </li>  <li>
                    <NavLink to='/translations' onClick={() => setState({burgerLeft: false})}>
                        <span>{t("header.translations")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={''} onClick={logOut}>
                        <IconLogout width={16} height={16}/>
                        <span>{t("header.log_out")}</span>
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

export default HeaderAdmin