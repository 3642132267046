import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";

export const languageBarStyle = makeStyles(() =>
    createStyles({
        languageBar: {
            position: 'relative',
            paddingLeft: 13,
            "@media (max-width:768px)": {
                display: 'none'
            },
        },
        languageSelected: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            width: 60,
            '& > img': {
                paddingBottom: 2,
            },
            '& > p': {
                fontSize: 14,
                lineHeight: '14px',
                color: colors.blue,
                fontWeight: 400,
                paddingLeft: 6,
                textTransform: 'uppercase',
            }
        },
        languageListItem: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: "center",
            '& div': {
                display: 'flex'
            }
        },
        languageList: {
            position: 'absolute',
            top: 30,
            left: 0,
            listStyleType: 'none',
            boxShadow: colors.lightGrayShadow,
            width: 190,
            zIndex: 999,
            backgroundColor: colors.white,

            '& li': {
                padding: '15px 12px',
                cursor: 'pointer',
                "&:hover": {
                    backgroundColor: colors.grey
                },
                '&:not(:last-child)': {
                    borderBottom: `1px solid ${colors.greyLiner}`

                },
            },
            '& li img': {
                marginRight: 6
            },
            '& li p': {
                fontSize: 14,
                lineHeight: '14px',
                color: colors.black,
                fontWeight: 400,
                textTransform: 'uppercase',
            }
        }
    })
);