import {RenderStatusCard} from "./render-status-card";

interface RenderStatusProps {
    status?: string,
    [propName: string]: any
}

export const RenderStatus = ({status}: RenderStatusProps) => {
    switch (status) {
        case 'pending_approval' :
        case 'pending':
        case 'updated_pending_approval':
        case 'created':
        case 'closed':
            return <RenderStatusCard
                translation={`status.${status}`}
                type={'pending'}
            />
        case 'completed':
        case 'accepted':
        case 'extended':
        case 'approved':
            return <RenderStatusCard
                translation={`status.${status}`}
                type={'completed'}
            />
        case 'overdue':
        case 'rejected':
        case 'deniedFinalPrice':
        case 'expired':
            return <RenderStatusCard
                translation={`status.${status}`}
                type={'rejected'}
            />
        default:
            return <RenderStatusCard translation={`status.not_valid_statuses`} type={'rejected'}/>
    }
}