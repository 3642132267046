import React from "react";
import propTypes from "prop-types";
import {RenderStatus} from "src/components/render-status/renderStatus";
import CButton from "src/cors/button/cButton";
import PropTypes from "prop-types";
import {colors} from "src/utils/color";
import {useTranslation} from "react-multi-lang";

const SellerOffersCard = ({dataBody = [], setPriceModal, setAgreeModal, handleReject, setSelected}) => {
    const t = useTranslation()
    return (
        dataBody?.map((item, index) => {
            return (
                <div key={index.toString()} className='detailOfferWrapper'>
                    <img src={`${item?.pictures[0]?.path}`} alt="image"/>
                    <div key={index.toString()} className='payment-info_container'>
                        <div className='payment-info-title seller-offer'>
                            <span>{item.name}</span>
                            <RenderStatus  status={item.status}/>
                        </div>
                        <div className='payment-info-item'>
                            <p>{t("seller_offer.buyer")}</p>
                            <span>................................................................................................................................................................................................................................................................</span>
                            <p>{`${item.buyer_firstname} ${item.buyer_lastname}`}</p>
                        </div>
                        <div className='payment-info-item'>
                            <p>{t("seller_offer.price")}</p>
                            <span>....................................................................................................................................................................................................................................................................</span>
                            <p>{item.price}</p>
                        </div>
                        <div className='payment-info-item'>
                            <p className='redTitle'>{t("seller_offer.offer_price")}</p>
                            <span
                                className='redTitle'>....................................................................................................................................................................................................................................................................</span>
                            <p className='redTitle'>{item.offer_price}</p>
                        </div>
                        <div className='cardButtonWrapper flexEnd seller-offer-buttons'>
                            <CButton
                                onPress={() => {
                                    setSelected(item)
                                    setPriceModal(true)
                                }}
                                title={t("project.change_price")}
                                borderWidth={1}
                            />
                            <CButton
                                title={t("project.reject")}
                                backgroundColor={colors.red}
                                onPress={() => handleReject(item?.id)}
                            />
                            <CButton
                                onPress={() => {
                                    setSelected(item)
                                    setAgreeModal(true)
                                }}
                                title={t("project.approve")}
                                backgroundColor={colors.blue}
                            />
                        </div>
                    </div>
                </div>
            )
        })
    )
}

SellerOffersCard.propTypes = {
    dataBody: propTypes.array,
    setPriceModal: PropTypes.func,
    setAgreeModal: PropTypes.func,
    handleReject: PropTypes.func,
    setSelected: PropTypes.func,
};

export default SellerOffersCard