import {useState, CSSProperties} from "react";
import {RingLoader} from "react-spinners";
import {colors} from "src/utils/color";

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: colors.blue,
};

function Spinner({loading}) {
    return (
        loading ? <div className="sweet-loading custom_spinner">
            <RingLoader
                color={colors.gold}
                loading={true}
                cssOverride={override}
                size={60}
                aria-label="Loading Spinner"
                data-testid="loader"
                className='spinnerContent'
            />
        </div> : <></>
    );
}

export default Spinner;