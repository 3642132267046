import UnderHeader from "src/components/underHeader/underHeader";
import CInput from "src/cors/input/cInput";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import {sellerSettingsStyle} from "./seller-settings-styles";
import React, {useContext, useEffect, useState} from "react";
import Upload from "src/components/upload-image/upload-file";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {checkError} from "src/helpers/check-error";
import CSelect from "src/cors/select/cSelect";
import CTextarea from "src/cors/textarea/cTextarea";
import CCheckbox from "src/cors/checkbox/cCheckbox";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import {GlobalContext} from "src/context/global-context";
import {CModal} from "src/cors/modal/cModal";
import CTab from "src/cors/tab/cTab";


function SellerSettings() {
    const t = useTranslation()
    const classes = sellerSettingsStyle()
    const [update, setUpdate] = useState(false)
    const {state} = useContext(GlobalContext)
    const userId = state?.userinfo?.role_id

    const [userInfo, setUserInfo] = useState({
        firstname: '',
        lastname: '',
        company_name: '',
        phone: "",
        email: '',
        country: 'armenia',
        region: '',
        viber: '',
        whatsapp: '',
        market: '',
        floor: '',
        shop_number: '',
        description_am: '',
        description_en: "",
        description_ru: ""
    })

    const [tabLanguage, setTabLanguage] = useState(0)
    const [initialData, setInitialData] = useState({})
    const [offersEnabled, setOffersEnabled] = useState(false)
    const [onlineShop, setOnlineShop] = useState(false)
    const [logo, setLogo] = useState()
    const [banner, setBanner] = useState()
    const [images, setImages] = useState({})
    const [addressOptions, setAddressOptions] = useState([])
    const [addressModal, setAddressModal] = useState(false)
    const [address, setAddress] = useState('')
    const [userPassword, setUserPassword] = useState({
        password: '',
        newPassword: '',
        confirmNewPassword: '',
    })
    const [errorUserInfo, setErrorUserInfo] = useState({
        firstname: '',
        lastname: '',
        company_name: '',
        phone: "",
        email: '',
        country: '',
        region: '',
        viber: '',
        whatsapp: '',
        market: '',
        floor: '',
        shop_number: '',
    })

    const [errorUserPassword, setErrorUserPassword] = useState({
        password: '',
        newPassword: '',
        confirmNewPassword: ''
    })

    const request = () => {
        Axios(`user/user_info`).then(res => {
            setUpdate(true)
            setImages({
                logo: res.data.logo,
                main_banner: res.data.main_banner
            })
            try {
                if (res.data.address) {
                    setAddress(JSON.parse(res.data.address))
                }
            } catch (err) {
                console.log(err, 'err')
            }
            setInitialData(res.data)
            setUserInfo({
                firstname: res.data.firstname,
                lastname: res.data.lastname,
                company_name: res.data.company_name,
                phone: res.data.phone,
                email: res.data.email,
                country: 'armenia',
                region: res.data.region,
                viber: res.data.viber,
                whatsapp: res.data.whatsapp,
                market: res.data.market,
                floor: +res.data.floor,
                shop_number: +res.data.shop_number,
                description_en: res.data.description_en,
                description_ru: res.data.description_ru,
                description_am: res.data.description_am,
            })
            setOnlineShop(!!res?.data?.is_online_market)
            setOffersEnabled(!!res.data?.offers_enabled)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            setUpdate(true)
            console.log(err, 'err')
        })
    }
    const saveUserInfo = () => {
        if (userInfo.whatsapp && userInfo.market && userInfo.viber && userInfo.firstname && userInfo.lastname && userInfo.company_name && userInfo.phone && userInfo.email && userInfo.region) {
            const formData = new FormData();
            formData.append("company_name", userInfo.company_name);
            formData.append("country", 'armenia');
            formData.append("region", userInfo.region);
            formData.append("email", userInfo.email);
            formData.append("firstname", userInfo.firstname);
            formData.append("lastname", userInfo.lastname);
            formData.append("phone", userInfo.phone);
            formData.append("viber", userInfo.viber);
            formData.append("whatsapp", userInfo.whatsapp);
            formData.append("market", userInfo.market);
            formData.append("address", address ? JSON.stringify(address) : '');
            formData.append("floor", userInfo.floor);
            formData.append("shop_number", +userInfo.shop_number);
            formData.append("description_en", userInfo.description_en);
            formData.append("description_ru", userInfo.description_ru);
            formData.append("description_am", userInfo.description_am);
            formData.append("is_online_market", onlineShop);
            formData.append("offers_enabled", offersEnabled);

            if (logo?.length) {
                formData.append("logo", logo[0]?.file);
            }
            if (banner?.length) {
                formData.append("main_banner", banner[0]?.file);
            }

            Axios.put(`user/update_user_info`, formData).then(res => {
                toast(t("notification.saved"))
                request()
            }).catch(err => {
                console.log(err, 'err');
                toast(err?.response?.data?.code)
            })
        } else {
            checkError(userInfo, setErrorUserInfo)
        }
    }

    const saveUserInfoPassword = () => {
        if (userPassword.password && userPassword.newPassword && userPassword.confirmNewPassword) {
            if (userPassword.newPassword.length >= 8) {
                if (userPassword.newPassword === userPassword.confirmNewPassword) {
                    Axios.put(`auth/update_password`, {
                        "new_password": userPassword.newPassword,
                        "new_password_confirm": userPassword.confirmNewPassword,
                        "old_password": userPassword.password
                    }).then(res => {
                        toast(t("notification.password_reset"))
                    }).catch(err => {
                        console.log(err, 'errr');
                        toast(err?.response?.data?.code)
                    })
                } else {
                    toast(t("notification.confirm_and_new_password"))
                }
            } else {
                toast(t("notification.length_must_be_more"))
            }
        } else {
            checkError(errorUserPassword, setErrorUserPassword)
        }
    }

    const handleChangeInfo = (value, input) => {
        if (value.length || typeof value === 'number') {
            setErrorUserInfo({...errorUserInfo, [input]: ''})
        }
        let info = {...userInfo}
        info[input] = value
        setUserInfo(info)
    }


    const handleChangeUserPassword = (value, input) => {
        if (value.length || typeof value === 'number') {
            setErrorUserPassword({...errorUserInfo, [input]: ''})
        }
        let info = {...userPassword}
        info[input] = value
        setUserPassword(info)
    }

    const saveUserInfoValidation = () => {
        const data = {...userInfo, ...images}
        let request = false
        let initialAddress = initialData.address ? JSON.parse(initialData.address) : {}
        Object.keys(data).forEach(item => {
            if (data[item] !== initialData[item]) {
                request = true
            }
        })
        if (request || initialAddress?.properties?.description !== address?.properties?.description ||
            initialAddress?.properties?.name !== address?.properties?.name || onlineShop !== initialData?.is_online_market ||
            initialData?.offers_enabled !== offersEnabled || logo || banner) {
            saveUserInfo()
        }
    }


    useEffect(() => {
        request()
    }, [])

    const getAddress = (value) => {
        if (!value) {
            setAddressOptions([])
        }
        if (value.length > 2) {
            Axios(`https://search-maps.yandex.ru/v1/?text=${value}&type=geo&lang=en_US&apikey=251fef2f-417f-4593-ae17-e139ff21f261`).then(res => {
                setAddressOptions(res.data.features)
            }).catch(err => {
                console.log(err, 'err')
            })
        }
    }

    return (
        <div className={classes.container}>
            <UnderHeader title={t("header.settings")}/>
            <div className={classes.containerSettings}>
                <p className={classes.title}>{t("setting.my_account")}</p>
                {images?.main_banner && <img alt='main banner' className={classes.banner} src={images?.main_banner}/>}
                {userId !== 5 && images?.logo && <img alt='logo' className={classes.logo} src={images?.logo}/>}
                <div className={classes.uploadBlock}>
                    {userId !== 5 && <div className={classes.uploadBlockWrapp}>
                        <Upload
                            multiple={false}
                            list={logo}
                            onChange={setLogo}
                            title={t("setting.upload_logo")}
                            info={'png, jpg (max 2mb)'}
                        />
                    </div>}
                    <div className={classes.uploadBlockWrapp}>
                        <Upload
                            multiple={false}
                            list={banner} onChange={setBanner}
                            title={t("setting.upload_your_main_banner")}
                            info={'png, jpg (max 2mb)'}
                        />
                    </div>
                </div>
                {update && <div className={classes.inputBlock}>
                    <div className={classes.inputWrapp}>
                        <CInput
                            value={userInfo.firstname}
                            onChange={(e) => handleChangeInfo(e.target.value, 'firstname')}
                            label={t("setting.name")}
                            placeholder={t("setting.name")}
                            errorMessage={errorUserInfo.firstname}
                        />
                    </div>
                    <div className={classes.inputWrapp}>
                        <CInput
                            value={userInfo.lastname}
                            onChange={(e) => handleChangeInfo(e.target.value, 'lastname')}
                            errorMessage={errorUserInfo.lastname}
                            label={t("setting.surname")}
                            placeholder={t("setting.surname")}
                        />
                    </div>
                    <div className={classes.inputWrapp}>
                        <CInput
                            value={userInfo.company_name}
                            errorMessage={errorUserInfo.company_name}
                            onChange={(e) => handleChangeInfo(e.target.value, 'company_name')}
                            label={t("setting.company_name")}
                            placeholder={t("setting.company_name")}
                        />
                    </div>
                    <div className={classes.inputWrapp}>
                        <CInput
                            value={userInfo.phone}
                            onChange={(e) => handleChangeInfo(e.target.value, 'phone')}
                            errorMessage={errorUserInfo.phone}
                            type={'number'}
                            label={t("setting.phone")}
                            placeholder={t("setting.phone")}
                        />
                    </div>
                    <div className={classes.inputWrapp}>
                        <CInput
                            value={userInfo.viber}
                            onChange={(e) => handleChangeInfo(e.target.value, 'viber')}
                            errorMessage={errorUserInfo.viber}
                            type={'number'}
                            label={t("setting.viber")}
                            placeholder={t("setting.viber")}
                        />
                    </div>
                    <div className={classes.inputWrapp}>
                        <CInput
                            value={userInfo.whatsapp}
                            onChange={(e) => handleChangeInfo(e.target.value, 'whatsapp')}
                            errorMessage={errorUserInfo.whatsapp}
                            type={'number'}
                            label={t("setting.whats_app")}
                            placeholder={t("setting.whats_app")}
                        />
                    </div>
                    <div className={classes.inputWrapp}>
                        <CInput
                            value={userInfo.email}
                            onChange={(e) => handleChangeInfo(e.target.value, 'email')}
                            errorMessage={errorUserInfo.email}
                            type={'email'}
                            label={t("setting.email")}
                            placeholder={t("setting.email")}
                        />
                    </div>
                    <div className={classes.inputWrapp}>
                        <CSelect
                            defaultValue={{label: t("setting.armenia"), value: userInfo.country}}
                            onChange={(e) => handleChangeInfo(e.value, 'country')}
                            errorMessage={errorUserInfo.country}
                            label={t("setting.country")}
                            placeholder={t("setting.country")}
                            options={[{label: t("setting.armenia"), value: 'armenia'}]}
                            isDisabled={true}
                        />
                    </div>
                    <div className={classes.inputWrapp}>
                        <CSelect
                            defaultValue={{label: t(`region.${userInfo.region}`), value: userInfo.region}}
                            onChange={(e) => handleChangeInfo(e.value, 'region')}
                            errorMessage={errorUserInfo.region}
                            label={t("setting.region")}
                            placeholder={t("setting.region")}
                            options={[
                                {label: t("region.yerevan"), value: 'yerevan'},
                                {label: t("region.aragacotn"), value: 'aragacotn'},
                                {label: t("region.ararat"), value: 'ararat'},
                                {label: t("region.armavir"), value: 'armavir'},
                                {label: t("region.gegharkunik"), value: 'gegharkunik'},
                                {label: t("region.kotayk"), value: 'kotayk'},
                                {label: t("region.lori"), value: 'lori'},
                                {label: t("region.shirak"), value: 'shirak'},
                                {label: t("region.syunik"), value: 'syunik'},
                                {label: t("region.tavush"), value: 'tavush'},
                                {label: t("region.vayots_dzor"), value: 'vayots_dzor'},
                            ]}
                        />
                    </div>


                    <div className={classes.inputWrapp}>
                        <CSelect
                            defaultValue={{label: t(`markets.${userInfo.market}`), value: userInfo.market}}
                            onChange={(e) => handleChangeInfo(e.value, 'market')}
                            errorMessage={errorUserInfo.market}
                            label={t("setting.market")}
                            placeholder={t("setting.market")}
                            options={userInfo.region==='yerevan'? [
                                {label: t('markets.gold_center'), value: 'gold_center'},
                                {label: t('markets.dalma_gold_center'), value: 'dalma_gold_center'},
                                {label: t('markets.tashir_gold_center'), value: 'tashir_gold_center'},
                                {label: t('markets.malatia_gold_center'), value: 'malatia_gold_center'},
                                {label: t('markets.rossia_gold_center'), value: 'rossia_gold_center'},
                                {label: t('markets.other'), value: 'other'},
                            ] : userInfo.region==='kotayk' ? [
                                {label: t('markets.multi_gold'), value: 'multi_gold'},
                            ]:[{label: t('markets.other'), value: 'other'}]}
                        />
                    </div>
                    <div className={`${classes.inputWrapp} ${classes.inputWrappAddress}`}
                         onClick={() => setAddressModal(true)}
                    >
                        <CInput
                            readOnly={true}
                            value={address?.properties?.name}
                            errorMessage={errorUserInfo.address}
                            label={t("setting.address")}
                            placeholder={t("setting.address")}
                        />
                    </div>
                    <div className={classes.inputWrapp}>
                        <CInput
                            value={userInfo.floor}
                            onChange={(e) => handleChangeInfo(e.target.value, 'floor')}
                            errorMessage={errorUserInfo.floor}
                            label={t("setting.floor")}
                            placeholder={t("setting.floor")}
                            type={'number'}
                        />
                    </div>
                    <div className={classes.inputWrapp}>
                        <CInput
                            value={userInfo.shop_number}
                            onChange={(e) => handleChangeInfo(e.target.value, 'shop_number')}
                            errorMessage={errorUserInfo.shop_number}
                            label={t("setting.shop")}
                            placeholder={t("setting.shop")}
                            type={'number'}
                        />
                    </div>

                </div>}
                <div className={classes.textAreaWrapper}>
                    <p className={classes.title}>{t("setting.description")}</p>
                    <CTab
                        onPress={setTabLanguage}
                        active={tabLanguage}
                        items={[{value: 'ENG', key: 0}, {value: 'Рус', key: 1}, {value: 'Հայ', key: 2}]}
                    />
                    {tabLanguage===0 &&     <CTextarea
                        value={userInfo.description_en}
                        placeholder={t("setting.description")}
                        onChange={(e) => handleChangeInfo(e.target.value, 'description_en')}
                    />}
                    {tabLanguage===1 &&     <CTextarea
                        value={userInfo.description_ru}
                        placeholder={t("setting.description")}
                        onChange={(e) => handleChangeInfo(e.target.value, 'description_ru')}
                    />}
                    {tabLanguage===2 &&     <CTextarea
                        value={userInfo.description_am}
                        placeholder={t("setting.description")}
                        onChange={(e) => handleChangeInfo(e.target.value, 'description_am')}
                    />}
                </div>
                <div className={classes.checkboxBlock}>
                    <CCheckbox onChange={setOnlineShop} checked={onlineShop}/>
                    <p>{t("setting.online_shop")}</p>
                </div>
                {userId !== 5 && <div className={classes.checkboxBlock}>
                    <CCheckbox onChange={setOffersEnabled} checked={offersEnabled}/>
                    <p>{t("setting.offers_enabled")}</p>
                </div>}
                <div className={classes.containerPrivacyBtns}>
                    <div className={classes.containerPrivacySaveWrap}>
                        <CButton
                            onPress={saveUserInfoValidation}
                            title={t("project.save")}
                            backgroundColor={colors.blue}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.containerSettings}>
                <p className={classes.title}>{t("setting.password")}</p>
                <div className={`${classes.inputWrapp} ${classes.inputPassword}`}>
                    <CInput
                        value={userPassword.password}
                        onChange={(e) => handleChangeUserPassword(e.target.value, 'password')}
                        errorMessage={errorUserPassword.password}
                        label={t("setting.old_password")}
                        placeholder={t("setting.old_password")}
                        type={'password'}
                    />
                </div>
                <div className={`${classes.inputBlock}`}>
                    <div className={`${classes.inputWrapp} ${classes.inputPassword}`}>
                        <CInput
                            value={userPassword.newPassword}
                            onChange={(e) => handleChangeUserPassword(e.target.value, 'newPassword')}
                            errorMessage={errorUserPassword.newPassword}
                            label={t("setting.new_password")}
                            placeholder={t("setting.new_password")}
                            type={'password'}
                        />
                    </div>
                    <div className={`${classes.inputWrapp} ${classes.inputPassword}`}>
                        <CInput
                            value={userPassword.confirmNewPassword}
                            onChange={(e) => handleChangeUserPassword(e.target.value, 'confirmNewPassword')}
                            errorMessage={errorUserPassword.confirmNewPassword}
                            label={t("setting.confirm_new_password")}
                            placeholder={t("setting.confirm_new_password")}
                            type={'password'}
                        />
                    </div>
                </div>
                <div className={classes.containerPrivacyBtns}>
                    <div className={classes.containerPrivacySaveWrap}>
                        <CButton
                            title={t("project.save")}
                            onPress={saveUserInfoPassword}
                            backgroundColor={colors.blue}
                        />
                    </div>
                </div>
            </div>
            <CModal
                visible={addressModal}
                closeModal={() => {
                    setAddressModal(false)
                    setAddressOptions([])
                }}
                title={t('setting.add_address')}
                children={<div style={{marginTop: 20}}>
                    <CInput
                        onChange={(e) => getAddress(e.target.value)}
                        errorMessage={errorUserInfo.address}
                        label={t("setting.address")}
                        placeholder={t("setting.address")}
                    />
                    <div className={classes.addressBlock}>
                        {addressOptions.length > 0 && addressOptions.map((item, index) => {
                            return (
                                <div className={classes.addressBlockItem}
                                     onClick={() => {
                                         setAddress(item)
                                         setAddressOptions([])
                                         setAddressModal(false)
                                     }}
                                     key={index}
                                >
                                    <p>{item?.properties?.name}</p>
                                    <p style={{fontSize: 10}}>{item?.properties?.description}</p>
                                </div>
                            )
                        })}
                    </div>

                </div>}
            />
        </div>
    );
}

export default SellerSettings;
