import {aboutUsStyle} from "./about-us-style";
import UnderHeader from "src/components/underHeader/underHeader";
import Upload from "src/components/upload-image/upload-file";
import CInput from "src/cors/input/cInput";
import CButton from "src/cors/button/cButton";
import {colors} from "src/utils/color";
import CTextarea from "src/cors/textarea/cTextarea";
import {Axios} from "src/api/api";
import {toast} from "react-toastify";
import {checkError} from "src/helpers/check-error";
import {useEffect, useState} from "react";
import ImageListAbout from "./image-list-About";
import {errorMessages} from "src/helpers/error-messages";
import {useTranslation} from "react-multi-lang";
import About_us_aja from "./about_us_aja";


function AboutUs() {
    const classes = aboutUsStyle()
    const t = useTranslation()
    const [image, setImage] = useState([])
    const [inputValues, setInputValues] = useState({
        title: '',
        description: '',
    })

    const [inputValuesError, setInputValuesError] = useState({
        title: '',
        description: '',
    })

    const [imgError, setImgError] = useState({
        message: ''
    })

    const requestGet = ()=>{
        Axios(`about_us`).then(res => {
            setInputValues({
                title: res.data.title,
                description: res.data.description
            })
            setImage(res.data.pictures)
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'errr');
        })
    }

    useEffect(() => {
        requestGet()
    }, [])

    const handleCancel = () =>{
        requestGet()
    }

    const saveAboutUs = () => {
        if (inputValues.title && inputValues.description) {
            Axios.put(`admin/about_us`, {
                "description": inputValues.description,
                "title": inputValues.title
            }).then(res => {
                toast(t("notification.saved"))
            }).catch(err => {
                toast(errorMessages(err?.response?.data?.code))
            })
        } else {
            checkError(inputValues, setInputValuesError)
        }
    }

    const handleChangeInfo = (value, input) => {
        if (value.length) {
            setInputValuesError({...inputValuesError, [input]: ''})
        }
        let info = {...inputValues}
        info[input] = value
        setInputValues(info)
    }

    const deleteImg = (item) => {
        Axios.put(`admin/about_us/delete_pictures?picture_path=${item}`).then(res => {
            setImage(res.data.pictures)
            toast(t('notification.deleted'))
        }).catch(err => {
            toast(errorMessages(err?.response?.data?.code))
            console.log(err, 'err')
        })
    }

    return (
        <div className={classes.container}>
            <UnderHeader title={t("header.about_us")}/>
            <div className={classes.containerAboutUs}>
                <p className={classes.title}>{t("about_us.add_images")}</p>
                <Upload
                    list={image}
                    onChange={setImage}
                    errorMessage={imgError}
                    setError={setImgError}
                    hideImage={false}
                    productEdit={true}
                    url={'admin/about_us/add_pictures'}
                />

                <div>
                    <ImageListAbout images={image} setImages={setImage} deleteImg={deleteImg}/>
                </div>

                <div className={classes.inputWrapp}>
                    <CInput
                        label={t("about_us.title")}
                        placeholder={t("about_us.title")}
                        value={inputValues.title}
                        errorMessage={inputValuesError.title}
                        onChange={(e) => handleChangeInfo(e.target.value, 'title')}
                    />
                </div>
                <div className={classes.textAreaWrapp}>
                    <div className={classes.textAreaWrapp}>
                        <CTextarea
                            value={inputValues.description}
                            errorMessage={inputValuesError.description}
                            onChange={(e) => handleChangeInfo(e.target.value, 'description')}
                            label={t("about_us.description")}
                            placeholder={t("about_us.description")}
                        />
                    </div>
                </div>
                <div className={classes.aboutUsBtns}>
                    <div className={classes.aboutUsBtnWrap}>
                        <CButton onPress={handleCancel} title={t("project.cancel")} borderWidth={1}/>
                    </div>
                    <div className={classes.aboutUsBtnSaveWrap}>
                        <CButton onPress={saveAboutUs} title={t("project.save")} backgroundColor={colors.blue}/>
                    </div>
                </div>
            </div>
            <About_us_aja />
        </div>
    );
}

export default AboutUs;