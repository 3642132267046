import React from "react";
import propTypes from "prop-types";
import SmallBtn from "src/cors/smallBtn/smallBtn";
import removeIcon from 'src/assets/icons/iconRemove.svg';
import {colors} from "src/utils/color";

const SelectedItemTable = ({dataBody, deleteItem}) => {
    return (
        <>
            {
                (dataBody && dataBody.length) ?
                    <>
                        <div className='tableWrapper'>
                            <table className="table">
                                <tbody>
                                {
                                    dataBody.map((tdItem, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td>
                                                    <img className='productImage'
                                                         src={tdItem?.main_picture?.path}
                                                         alt="img"
                                                    />
                                                </td>
                                                <td className='bold' style={{maxWidth: 200}}>{tdItem.name}</td>
                                                <td className='bold'>{tdItem.product_code}</td>
                                                <td className='bold'>{tdItem.total_price_usd} $</td>
                                                <td>
                                                    <div onClick={() => deleteItem(tdItem.id)} className='tableButtonWrapper flexRow'>
                                                        <SmallBtn
                                                            icon={removeIcon}
                                                            borderColor={colors.softRed}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        {
                            dataBody.map((item, index) => {
                                return (
                                    <div key={index.toString()} className='cardContainer sellerProductCard'>
                                        <img
                                            src={item.main_picture?.path}
                                            alt="photo"
                                        />
                                        <ul className='cardContent sellerProductContent'>
                                            <li className='cardItem'>
                                                <p className='cardValue'>{item.name}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardValue'>{item.product_code}</p>
                                            </li>
                                            <li className='cardItem'>
                                                <p className='cardValue'>{item.total_price_usd} $</p>
                                            </li>
                                            <li className='cardItem'>
                                                <div onClick={()=>deleteItem(item.id)} className='cardButtonWrapper flexEnd'>
                                                    <SmallBtn
                                                        icon={removeIcon}
                                                        borderColor={colors.softRed}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </> : null
            }

        </>

    )
}

SelectedItemTable.propTypes = {
    dataBody: propTypes.array,
    deleteItem:propTypes.func
};

export default SelectedItemTable