import {createStyles, makeStyles} from "@mui/styles";
import {colors} from "src/utils/color";


export const aboutUsStyle = makeStyles(() =>
    createStyles({
        container: {
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto 30px',
            ["@media (max-width:1250px)"]: {
                width: '96%',
            },
        },
        containerAboutUs: {
            backgroundColor: colors.white,
            boxShadow: ' 0px 0px 96px rgba(0, 0, 0, 0.1)',
            padding: 40,
            marginTop: 30,
            ["@media (max-width:768px)"]: {
                padding: 20,
                marginTop: 27,
                boxShadow: 'none',
            },
            ["@media (max-width:480px)"]: {
                padding: 7,
                marginTop: 9,
            },
        },
        title: {
            fontSize: 14,
            color: colors.black,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            marginBottom: 27,
            ["@media (max-width:480px)"]: {
                marginBottom: 9,
            },
        },
        inputWrapp: {
            marginTop: 40,
            ["@media (max-width:768px)"]: {
                marginTop: 30,
            },
            ["@media (max-width:480px)"]: {
                marginTop: 9,
            },
        },
        textAreaWrapp: {
            marginTop:20,
            height: 220,
            ["@media (max-width:767px)"]: {
                height: 350,

            },
            ["@media (max-width:480px)"]: {
                height: 300,
                marginBottom: 40
            },
        },

        aboutUsBtns: {
            marginTop: 40,
            display: 'flex',
            justifyContent: 'flex-end',
            ["@media (max-width:768px)"]: {
                marginTop: 30,
            },
            ["@media (max-width:480px)"]: {
                marginTop: 9,
                flexDirection: 'column-reverse'
            },
        },
        aboutUsBtnWrap: {
            width: 236,
            ["@media (max-width:480px)"]: {
                width: '100%',
            },
        },
        aboutUsBtnSaveWrap: {
            width: 236,
            marginLeft: 10,
            ["@media (max-width:480px)"]: {
                width: '100%',
                marginLeft: 0,
                marginBottom: 10

            },
        },
        uploadImg: {
            width: 125,
            height: 125,
            objectFit: 'cover',
            border: `1px solid ${colors.grey}`,
            "@media (max-width:480px)": {
                marginRight: 8,
                width: '100%',
                height: 125,
            },
        },
    })
)