import {cInputStyles} from "./cInput-styles";
import {colors} from "src/utils/color";
import {ReactComponent as IconError} from "src/assets/icons/error.svg";

interface CInputProps {
    type: string
    disabled: boolean
    label: string
    errorMessage: string
    placeholder: string
    value: string
    showErrorMessage: boolean
    readOnly: boolean
    onChange: () => string
}

export default function CInput({
                                   onChange,
                                   type,
                                   disabled,
                                   label,
                                   errorMessage,
                                   placeholder,
                                   value,
                                   showErrorMessage = true,
                                   readOnly = false,
                               }: CInputProps) {

    const classes = cInputStyles({borderColor: errorMessage ? colors.red : colors.blue});
    return (
        <div>
            {label && <p className={`${classes.label} ${errorMessage && classes.labelError}`}>{label}</p>}
            <input
                readOnly={readOnly}
                className={`${classes.container} ${errorMessage && classes.containerError} `}
                onChange={onChange}
                type={type}
                disabled={disabled}
                placeholder={placeholder}
                value={value}
            />
            {
                showErrorMessage && <div className={classes.errorBlock}>
                    {errorMessage && <IconError width={17} height={17}/>}
                    <p className={classes.errorText}>{errorMessage}</p>
                </div>
            }

        </div>
    );
}